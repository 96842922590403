import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { GeoJSON, Tooltip, LayerGroup } from "react-leaflet";
import { RhinoQueryContext } from "../RhinoQueryContext";
import {
  API_ROOT_COMMON,
  COLOR_RHINO,
  colorContextlayers,
} from "../../common/constants";
import L from "leaflet";

function DisposalWellsLayer(props) {
  const [query, dispatch] = useContext(RhinoQueryContext);

  const [disposalWellsData, setdisposalWellsData] = useState({
    type: "FeatureCollection",
    features: [],
  });

  useEffect(() => {
    const fetchMapData = async () => {
      try {
        if (
          query.contextLayersVisibility.disposalWells &&
          disposalWellsData.features.length === 0
        ) {
          //if (query.contextLayersVisibility.disposalWells) {
          // const response = await axios.get(`${API_ROOT}` + "/disposal_wells/");
          const response = await axios.get(
            `${API_ROOT_COMMON}` + "/poi/getOilDisposalWells"
          );
          let f = response.data.features;
          let fc = f.map((obj) => {
            obj.color = colorContextlayers.disposalWells; //COLOR_RHINO.bucket6;
            return obj;
          });
          setdisposalWellsData({ type: "FeatureCollection", features: fc });

          let numFeaturesContext = query.contextLayersNumbFeatures;
          numFeaturesContext.disposalWells = fc.length;
          dispatch({
            type: "setContextLayersNumbFeatures",
            selection: numFeaturesContext,
          });
        }
      } catch (e) {
        console.log(e);
      }
    };
    fetchMapData();
  }, [query.contextLayersVisibility]);

  const pointToLayer = (feature, latlng) => {
    return L.circleMarker(latlng, null);
  };

  /* @see See https://jsfiddle.net/thbh99nu/2/ */
  const onEachFeature = (feature, layer) => {
    layer.on({
      mouseover: handleMouseOver.bind(null, feature),
      mouseout: handleMouseOut.bind(null, feature),
      //click: handleClick.bind(null, feature, layer)
    });
  };

  /* Highlights on mouseOver */
  const handleMouseOver = (feature, e) => {
    const layer = e.target;
    feature.color = COLOR_RHINO.selected;
    layer.setStyle({
      //radius: 5,
      fillColor: feature.color, //"#ff7800",
      color: feature.color, //"#000",
    });
  };

  /* Reset default style on mouseOut */
  const handleMouseOut = (feature, e) => {
    const layer = e.target;
    feature.color = colorContextlayers.disposalWells;
    layer.setStyle({
      fillColor: feature.color, //"#ff7800",
      color: feature.color, //"#000",
    });
  };

  return (
    <LayerGroup>
      {query.contextLayersVisibility.disposalWells &&
      disposalWellsData.features.length > 0 && ( //disposalWellsData.features.length > 0 && (
          <GeoJSON
            /* key={geoJsonKey} */
            data={disposalWellsData}
            //style={getStyle}
            style={{
              radius: 3,
              fillColor: colorContextlayers.disposalWells, //'#e31a1c',
              color: colorContextlayers.disposalWells, //"#000",
              weight: 0.6,
              opacity: 0.6,
              fillOpacity: 0.6,
            }}
            onEachFeature={onEachFeature}
            pointToLayer={pointToLayer}
          >
            <Tooltip>{"Disposal Well"}</Tooltip>
          </GeoJSON>
        )}
    </LayerGroup>
  );
}

export default DisposalWellsLayer;
