import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import { GeoJSON, Tooltip, LayerGroup, Popup, Marker } from "react-leaflet";
import { RhinoQueryContext } from "../RhinoQueryContext";
import {
  // API_ROOT,
  // COLOR_RHINO,
  // colorContextlayers,
  utpCategoryLookups,
  // tx100_monitored_counties,
} from "../../common/constants";
import L from "leaflet";
// import { makeStyles } from "@material-ui/core/styles";
// import { icon } from "leaflet";
// import Icon from "@material-ui/core/Icon";
// import { renderToStaticMarkup } from "react-dom/server";
// import { divIcon } from "leaflet";

//import customMarkerIconSvg from "../../assets/icons/construction_white_24dp.svg";
import UtpIconSVG1 from "../../assets/icons/construction_white_on_red_24dp.svg";
import UtpIconSVG2 from "../../assets/icons/construction_white_on_purple_24dp.svg";
import UtpIconSVG3 from "../../assets/icons/construction_white_on_green_24dp.svg";
import UtpIconSVG4 from "../../assets/icons/construction_white_on_blue_24dp.svg";

// const useStyles = makeStyles((theme) => ({
//   heading: {
//     fontSize: theme.typography.pxToRem(15),
//     fontWeight: theme.typography.fontWeightBold,
//   },
//   legendContainer: {
//     background: "white",
//     padding: "5px",
//   },
//   legendIcon: {
//     //height: "5%",
//     //width: "auto",
//     //border: "1px",
//     marginRight: "5px",
//   },
//   wrapIcon: {
//     //verticalAlign: 'middle',
//     display: "flex",
//     alignItems: "center",
//   },
//   roadwayLegendLine: {
//     width: "25px",
//     height: "5px",
//     background: "#4d4dff",
//     //float: 'left',
//     marginRight: "5px",
//   },
//   icon1: {
//     backgroundColor: "#7F0000",
//     width: "1.25rem",
//     height: "1.25rem",
//     display: "block",
//     // left: "-0.25rem",
//     // top: "-0.25rem",
//     // position: "relative",
//     // borderRadius: "3rem 3rem 0",
//     // transform: "rotate(45deg)",
//     // border: "1px solid #FFFFFF",
//   },
//   icon2: {
//     backgroundColor: "#6f00ff",
//     width: "1.25rem",
//     height: "1.25rem",
//     display: "block",
//     // left: "-0.25rem",
//     // top: "-0.25rem",
//     //position: "relative",
//     // borderRadius: "3rem 3rem 0",
//     // transform: "rotate(45deg)",
//     // border: "1px solid #FFFFFF",
//   },
//   icon3: {
//     backgroundColor: "#009a00",
//     width: "1.25rem",
//     height: "1.25rem",
//     display: "block",
//     // left: "-0.25rem",
//     // top: "-0.25rem",
//     //position: "relative",
//     // borderRadius: "3rem 3rem 0",
//     // transform: "rotate(45deg)",
//     // border: "1px solid #FFFFFF",
//   },
//   icon4: {
//     backgroundColor: "#4040ff",
//     width: "1.25rem",
//     height: "1.25rem",
//     display: "block",
//     // left: "-0.25rem",
//     // top: "-0.25rem",
//     //position: "relative",
//     // borderRadius: "3rem 3rem 0",
//     // transform: "rotate(45deg)",
//     // border: "1px solid #FFFFFF",
//   },
// }));

export default function UTPLayer(props) {
  const [query, dispatch] = useContext(RhinoQueryContext);
  // const classes = useStyles();
  //const { utpData, utpCategoryLookups } = props;
  const {
    countyList,
    // utpMapLayersCount,
    setutpMapLayersCount,
  } = props;

  // const iconMarkup1 = renderToStaticMarkup(
  //   <span className={classes.icon1}>
  //     <Icon fontSize="small" style={{ color: "#ffffff" }}>
  //       {"construction"}
  //     </Icon>
  //   </span>
  // );
  // const customMarkerIcon1 = divIcon({
  //   html: iconMarkup1,
  //   className: "dummy", // Specify something to get rid of the default class.
  // });

  const customMarkerIcon1 = new L.icon({
    iconUrl: UtpIconSVG1,
    iconRetinaUrl: UtpIconSVG1,
    //iconAnchor: [20, 40],
    //popupAnchor: [0, -35],
    iconSize: [15, 15],
    //className: classes.icon1,
  });

  // const iconMarkup2 = renderToStaticMarkup(
  //   <span className={classes.icon2}>
  //     <Icon fontSize="small" style={{ color: "#ffffff" }}>
  //       {"construction"}
  //     </Icon>
  //   </span>
  //  );
  // const customMarkerIcon2 = divIcon({
  //   html: iconMarkup2,
  //   className: "dummy", // Specify something to get rid of the default class.
  // });

  const customMarkerIcon2 = new L.icon({
    iconUrl: UtpIconSVG2,
    iconRetinaUrl: UtpIconSVG2,
    //iconAnchor: [20, 40],
    //popupAnchor: [0, -35],
    iconSize: [15, 15],
    //className: classes.icon2,
  });

  // const iconMarkup3 = renderToStaticMarkup(
  //   <span className={classes.icon3}>
  //     <Icon fontSize="small" style={{ color: "#ffffff" }}>
  //       {"construction"}
  //     </Icon>
  //   </span>
  // );
  // const customMarkerIcon3 = divIcon({
  //   html: iconMarkup3,
  //   className: "dummy", // Specify something to get rid of the default class.
  // });

  const customMarkerIcon3 = new L.icon({
    iconUrl: UtpIconSVG3,
    iconRetinaUrl: UtpIconSVG3,
    //iconAnchor: [20, 40],
    //popupAnchor: [0, -35],
    iconSize: [15, 15],
    //className: classes.icon3,
  });

  // const iconMarkup4 = renderToStaticMarkup(
  //   <span className={classes.icon4}>
  //     <Icon fontSize="small" style={{ color: "#ffffff" }}>
  //       {"construction"}
  //     </Icon>
  //   </span>
  // );
  // const customMarkerIcon4 = divIcon({
  //   html: iconMarkup4,
  //   className: "dummy", // Specify something to get rid of the default class.
  // });

  const customMarkerIcon4 = new L.icon({
    iconUrl: UtpIconSVG4,
    iconRetinaUrl: UtpIconSVG4,
    //iconAnchor: [20, 40],
    //popupAnchor: [0, -35],
    iconSize: [15, 15],
    //className: classes.icon4,
  });

  /* UTP projects data in GeoJSON format */
  const [utpData, setUTPData] = useState({
    data: {},
    route: "",
  });

  /*Fetch the UTP projects data*/
  useEffect(() => {
    const fetchUTP = async () => {
      try {
        if (
          (query.utpMapLayersVisibility.underwayorsoon ||
            query.utpMapLayersVisibility.within4years ||
            query.utpMapLayersVisibility.fivetotenyears ||
            query.utpMapLayersVisibility.tenplusyears) &&
          !utpData.data.all
        ) {
          const filterPhases = [
            "begins soon",
            "4 years",
            "5 to 10 years",
            "10+ years",
          ].map((val) => {
            return (fc) => fc.properties.PT_PHASE.includes(val);
          });

          const dataUrl =
            "https://services.arcgis.com/KTcxiTD9dsQw4r7Z/arcgis/rest/services/TxDOT_Projects/FeatureServer/0/query?where=COUNTY_NUMBER IN (" +
            countyList.toString() +
            //") AND Shape__Length IS NOT NULL &outFields=*&outSR=4326&f=geojson";
            ") AND Shape__Length IS NOT NULL AND PRJ_UTP IS NOT NULL &outFields=*&outSR=4326&f=geojson";
          const response = await axios.get(dataUrl);

          let f = response.data.features;
          if (f.length > 0) {
            let data = {
              all: {
                type: "FeatureCollection",
                features: f,
              },
              category0: {
                name: "UTP-Underway or begins soon",
                type: "FeatureCollection",
                features: f.filter(filterPhases[0]), //f.properties.PT_PHASE.includes("begins soon"),
              },
              category1: {
                name: "UTP-Begins in 4 years",
                type: "FeatureCollection",
                features: f.filter(filterPhases[1]),
              },
              category2: {
                name: "UTP-Begins in 5-10 years",
                type: "FeatureCollection",
                features: f.filter(filterPhases[2]),
              },
              category3: {
                name: "UTP-Begins in 10+ years",
                type: "FeatureCollection",
                features: f.filter(filterPhases[3]),
              },
            };

            // dispatch({
            //   type: "setutpMapLayersCount",
            //   selection: {
            //     underwayorsoon: f.filter(filterPhases[0]).length,
            //     within4years: f.filter(filterPhases[1]).length,
            //     fivetotenyears: f.filter(filterPhases[2]).length,
            //     tenplusyears: f.filter(filterPhases[3]).length,
            //   }, //fs //tableDataUTPvalues.concat(fs) //fs
            // });

            const featureCount = {
              underwayorsoon: f.filter(filterPhases[0]).length,
              within4years: f.filter(filterPhases[1]).length,
              fivetotenyears: f.filter(filterPhases[2]).length,
              tenplusyears: f.filter(filterPhases[3]).length,
            };
            dispatch({
              type: "setutpMapLayersCount",
              selection: featureCount, //fs //tableDataUTPvalues.concat(fs) //fs
            });

            // setutpMapLayersCount(featureCount);

            setUTPData({ data: data });
          }
        }
      } catch (e) {
        console.log(e);
      }
    };
    fetchUTP();
  });

  /*Create the marker cluster overlay*/
  const [utpLayersChecked, setutpLayersChecked] = useState(
    query.utpMapLayersVisibility
  );

  useEffect(() => {
    setutpLayersChecked(query.utpMapLayersVisibility);
  }, [query.utpMapLayersVisibility]);
  const [selected, setSelected] = useState(false);
  const selectedMarker = useRef({
    type: "FeatureCollection",
    features: [],
  });
  const createMarkersLayer2 = (props) => {
    let checkedVariable = utpCategoryLookups.find(
      (x) => x["nameDesc"] === props.data.name
    )["checkedVariable"];
    let checked = utpLayersChecked[checkedVariable];
    // let markerIcon = utpCategoryLookups.find(
    //   (x) => x["nameDesc"] === props.data.name
    // )["markerIcon"];

    if (checked) {
      if (props.data.features.length <= 0) return <div key={props.name} />;

      const getFirstCoordinates = (g) => {
        switch (g.type) {
          case "LineString":
            return [g.coordinates[0][1], g.coordinates[0][0]];
          case "MultiLineString":
            return [g.coordinates[0][0][1], g.coordinates[0][0][0]];
          default:
            return null;
        }
      };

      // let markerIcon2 = icon({
      //   //iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png',
      //   //shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
      //   iconUrl: markerIcon,
      //   iconSize: [25, 41],
      //   iconAnchor: [12, 41],
      //   //popupAnchor: [1, -34],
      //   //shadowSize: [41, 41]
      // });
      //"category0", "category1", "category2", "category3"
      let customMarkerIcon =
        props.name === "category0"
          ? customMarkerIcon1
          : props.name === "category1"
          ? customMarkerIcon2
          : props.name === "category2"
          ? customMarkerIcon3
          : customMarkerIcon4;

      if (checked)
        return (
          <LayerGroup key={props.name}>
            {props.data.features.map((val, idx) => (
              <Marker
                position={getFirstCoordinates(val.geometry)}
                //icon={markerIcon2}
                icon={customMarkerIcon}
                key={
                  val.properties.HIGHWAY_NUMBER +
                  "-" +
                  val.properties.LIMITS_FROM +
                  "-" +
                  val.properties.LIMITS_TO +
                  "-" +
                  idx
                }
              >
                <Tooltip>
                  <b>Control Section Job: {val.properties.CONTROL_SECT_JOB}</b>
                  <br />
                  <b>Road:</b> {val.properties.HIGHWAY_NUMBER}
                  <br />
                  <b>From:</b> {val.properties.LIMITS_FROM}
                  <br />
                  <b>To:</b> {val.properties.LIMITS_TO}
                  <br />
                  <b>Type of Work:</b> {val.properties.TYPE_OF_WORK}
                  <br />
                  <b>Layman Description:</b>{" "}
                  {val.properties.LAYMAN_DESCRIPTION1}
                  <br />
                  <b>Phase:</b> {val.properties.PT_PHASE}
                  <br />
                  <b>Freight Element:</b> {val.properties.FREIGHT}
                  <br />
                  <b>
                    <h6>(Click on marker for more information)</h6>
                  </b>
                  <br />
                </Tooltip>
                <Popup
                  position={getFirstCoordinates(val.geometry)}
                  onOpen={(e) => {
                    selectedMarker.current = {
                      type: "FeatureCollection",
                      features: [val],
                    };
                    setSelected(true);
                  }}
                  onClose={(e) => {
                    selectedMarker.current = {
                      type: "FeatureCollection",
                      features: [],
                    };
                    setSelected(false);
                  }}
                >
                  <b>Control Section Job: {val.properties.CONTROL_SECT_JOB}</b>
                  <br />
                  <b>Road:</b> {val.properties.HIGHWAY_NUMBER}
                  <br />
                  <b>From:</b> {val.properties.LIMITS_FROM}
                  <br />
                  <b>To:</b> {val.properties.LIMITS_TO}
                  <br />
                  <b>Type of Work:</b> {val.properties.TYPE_OF_WORK}
                  <br />
                  <b>Layman Description:</b>{" "}
                  {val.properties.LAYMAN_DESCRIPTION1}
                  <br />
                  <b>Phase:</b> {val.properties.PT_PHASE}
                  <br />
                  <b>UTP Project Tier:</b> {val.properties.PRJ_TIER}
                  <br />
                  <b>UTP Level of Authority:</b> {val.properties.PRIORITY_CODE}
                  <br />
                  <b>Freight Element:</b> {val.properties.FREIGHT}
                  <br />
                  <b>Project Let Date:</b> {val.properties.ACTUAL_LET_DATE}
                  <br />
                  <b>Month of Estimated Project Let Date:</b>{" "}
                  {val.properties.NBR_LET_MONTH}
                  <br />
                  <b>Year of Estimated Project Let Date:</b>{" "}
                  {val.properties.NBR_LET_YEAR}
                  <br />
                  <a
                    href={
                      "https://gis-txdot.opendata.arcgis.com/datasets/txdot-projects"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    TxDOT Open Data Portal - Projects Overview
                  </a>
                  <br />
                  <a
                    href={
                      "http://txdot.maps.arcgis.com/sharing/rest/content/items/7889360c299b476ab21e88494b009d4d/data"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    TxDOT Open Data Portal - Projects Data Dictionary
                  </a>
                  <br />
                  <a
                    href={
                      "https://gis-txdot.opendata.arcgis.com/datasets/txdot-projects/data?orderBy=CONTROL_SECT_JOB&where=CONTROL_SECT_JOB%20%3D%20%27" +
                      val.properties.CONTROL_SECT_JOB +
                      "%27"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    TxDOT Open Data Portal - Project
                  </a>
                  <br />
                  <a
                    href={
                      "https://services.arcgis.com/KTcxiTD9dsQw4r7Z/arcgis/rest/services/TxDOT_Projects/FeatureServer/0/query?outFields=*&where=CONTROL_SECT_JOB%20%3D%20'" +
                      val.properties.CONTROL_SECT_JOB +
                      "'"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    TxDOT Open Data Portal - GeoService Query
                  </a>
                  <br />
                </Popup>
              </Marker>
            ))}

            {selected && selectedMarker.current.features.length > 0 && (
              <GeoJSON
                data={selectedMarker.current}
                style={{
                  color: "#00FF00",
                  weight: 5,
                  fillOpacity: 0.7,
                }}
              />
            )}
          </LayerGroup>
        );
    }
  };

  return (
    <LayerGroup>
      {["category0", "category1", "category2", "category3"].map((name) => {
        return (
          typeof utpData.data[name] !== "undefined" &&
          createMarkersLayer2({
            data: utpData.data[name],
            name: name,
          })
        );
      })}
    </LayerGroup>
  );
}
