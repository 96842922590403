import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import {
  GeoJSON,
  LayerGroup,
  // Tooltip,
  // Popup,
  // Marker,
} from "react-leaflet";
import { RhinoQueryContext } from "../RhinoQueryContext";
import L from "leaflet";
// import { makeStyles } from "@material-ui/core/styles";
// import Icon from "@material-ui/core/Icon";
// import { renderToStaticMarkup } from "react-dom/server";
// import { divIcon } from "leaflet";

import customMarkerIconSvg from "../../assets/icons/pink_box_24dp.svg";

// const useStyles = makeStyles((theme) => ({
//   heading: {
//     fontSize: theme.typography.pxToRem(15),
//     fontWeight: theme.typography.fontWeightBold,
//   },
//   legendContainer: {
//     background: "white",
//     padding: "5px",
//   },
//   legendIcon: {
//     //height: "5%",
//     //width: "auto",
//     //border: "1px",
//     marginRight: "5px",
//   },
//   wrapIcon: {
//     //verticalAlign: 'middle',
//     display: "flex",
//     alignItems: "center",
//   },
//   roadwayLegendLine: {
//     width: "25px",
//     height: "5px",
//     background: "#4d4dff",
//     //float: 'left',
//     marginRight: "5px",
//   },
//   icon: {
//     backgroundColor: "#c465a4",
//     width: "0.75rem",
//     height: "0.755rem",
//     display: "block",
//     // left: "-0.25rem",
//     // top: "-0.25rem",
//     position: "relative",
//     // borderRadius: "3rem 3rem 0",
//     // transform: "rotate(45deg)",
//     // border: "1px solid #FFFFFF",
//   },
// }));

//props.route

export default function RailBorderCrossingLayer(props) {
  const [query, dispatch] = useContext(RhinoQueryContext);
  //const classes = useStyles();
  const { layerRef } = props;

  // const iconMarkup = renderToStaticMarkup(
  //   <span className={classes.icon}>
  //     <Icon fontSize="small" style={{ color: "#ffffff" }}>
  //       {/* change_history */}
  //     </Icon>
  //   </span>
  // );
  // const customMarkerIcon = divIcon({
  //   html: iconMarkup,
  // });

  const customMarkerIcon = new L.icon({
    iconUrl: customMarkerIconSvg,
    iconRetinaUrl: customMarkerIconSvg,
    //iconAnchor: [20, 40],
    //popupAnchor: [0, -35],
    iconSize: [10, 10],
    //className: classes.icon,
  });

  const [featureData, setFeatureData] = useState({
    type: "FeatureCollection",
    features: [],
  });

  useEffect(() => {
    const fetchFeatureData = async () => {
      try {
        if (
          query.contextLayersVisibility.railBorderCrossings &&
          featureData.features.length === 0
        ) {
          // const dataUrl =
          //   "https://services.arcgis.com/KTcxiTD9dsQw4r7Z/arcgis/rest/services/TxDOT_Border_Crossings/FeatureServer/0/query?where=VEH_COML%20%3C%3E%201%20AND%20VEH_CAR%20%3C%3E%201%20AND%20RR_BRDGE%20=%201&outFields=*&outSR=4326&f=geojson";
          const dataUrl =
            "https://services.arcgis.com/KTcxiTD9dsQw4r7Z/arcgis/rest/services/TxDOT_Border_Crossings/FeatureServer/0/query?where=RR_BRDGE%20=%201&outFields=*&outSR=4326&f=geojson";
          const response = await axios.get(dataUrl);
          if (response.data.error)
            return console.log("rail-border-crossing-error", response.data);
          setFeatureData(response.data);

          let numFeaturesContext = query.contextLayersNumbFeatures;
          numFeaturesContext.railBorderCrossings =
            response.data.features.length;
          dispatch({
            type: "setContextLayersNumbFeatures",
            selection: numFeaturesContext,
          });
        }
      } catch (e) {
        console.log("rail-border-crossing-error", e);
      }
    };
    fetchFeatureData();
  }, [query.contextLayersVisibility.railBorderCrossings]);

  const pointToLayer = (feature, latlng) => {
    let commercialAccess = feature.properties.VEH_COML === 1 ? "Yes" : "No";
    let noncommercialAccess = feature.properties.VEH_CAR === 1 ? "Yes" : "No";
    let railAccess = feature.properties.RR_BRDGE === 1 ? "Yes" : "No";
    let pedestrianAccess = feature.properties.PED === 1 ? "Yes" : "No";
    let tooltipText = `
    <div>
      <div style="text-align: center;border-bottom: 2.0px solid black;">
        <h5 style="margin-bottom: 0.25rem;margin-top: 0.25rem;">Border Crossings - Rail Only Layer</h5>
      </div>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Name:</strong> ${feature.properties.CROSS_NM}</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Type:</strong> ${feature.properties.CRS_TYPE}</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Owner:</strong> ${feature.properties.OWNER}</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Operator:</strong> ${feature.properties.OPRTR}</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Jurisdiction Type:</strong> ${feature.properties.JURIS_TYPE}</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Commercial:</strong> ${commercialAccess}</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Non-Commercial:</strong> ${noncommercialAccess}</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Rail:</strong> ${railAccess}</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Pedestrian:</strong> ${pedestrianAccess}</p>
    </div>
    `;
    return L.marker(latlng, {
      icon: customMarkerIcon,
    }).bindTooltip(tooltipText);
    //  let popupText = `
    // <div>
    //   <div style="text-align: center;border-bottom: 2.0px solid black;">
    //     <h3 style="margin-bottom: 0.25rem;margin-top: 0.25rem;">Counties</h3>
    //   </div>
    //   <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Name:</strong> ${feature.properties.COUNTY}</p>
    //   <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>County Code:</strong> ${feature.properties.COUNTYNUM}</p>
    //   <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>County FIPS:</strong> ${feature.properties.COUNTY_FIP}</p>
    //   <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>District:</strong> ${feature.properties.DISTRICT}</p>
    // </div>
    // `;
    //   return layer.bindTooltip(tooltipText).bindPopup(popupText);
  };

  useEffect(() => {
    if (layerRef.current) {
      layerRef.current.leafletElement.clearLayers().addData(featureData);
    }
  }, [featureData]);

  return (
    <LayerGroup>
      {query.contextLayersVisibility.railBorderCrossings &&
        featureData.features.length > 0 && (
          <GeoJSON
            data={featureData}
            //onEachFeature={onEachFeature}
            pointToLayer={pointToLayer}
          />
        )}
    </LayerGroup>
  );
}
