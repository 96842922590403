/**
 * @fileOverview the main UI component
 */

import React, { useMemo, useState, useEffect } from "react";

import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import { makeTheme, sidebarStyles } from "./ui/styles";
import { ThemeProvider, responsiveFontSizes } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import "./App.scss";

import {
  geographic_options_TXDOTdist,
  geographic_options_REGIONS,
  geographic_options_MPO,
} from "./common/constants";

import Sidebar from "./ui/Sidebar-mui";

import { RhinoQueryProvider } from "./map/RhinoQueryContext";

import TX100LeafletMap from "./map/TX100LeafletMap.js";
import RhinoLeafletMap from "./map/RhinoLeafletMap.js";

import DeckglMap from "./map/DeckglMap.js";

import Grid from "@material-ui/core/Grid";

import DataTabs from "./table/DataTabs.js";
import DataTabsTx100 from "./table/DataTabsTx100";

import { makeStyles } from "@material-ui/core/styles";

import axios from "axios";
import { API_ROOT } from "./common/constants";

import { fetchRoadData, fetchMonitoredRoads } from "./data-loaders/DataLoaders";

import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import LinearProgress from "@mui/material/LinearProgress";

import normalizeRoadSegments from "./utils/normalizeRoadSegments";
import { databaseVariablesHPMS } from "./common/constants";

import TrkTop100ODSelLnkMap from "./map/TrkTop100ODSelLnkMap";
import Utils from "./common/utils.js";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  logo1: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: "1.5px",
  },
  logo2: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  title: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  linearProgress: {
    marginTop: "5px",
  },
}));

function App() {
  LoadingOverlay.propTypes = undefined;
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const mode = prefersDarkMode ? "dark" : "light";
  let theme = makeTheme(mode);
  theme = responsiveFontSizes(theme);
  const classes = sidebarStyles();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const classes2 = useStyles();

  const [file, setFile] = useState({file:"", name:"", lastModified:""});
  const [statsLayerParam, setStatsLayer] = useState("Top 100 Bottlenecks");

  /* Year of the data for map and summary table */
  const [year, setYear] = useState(2023);

  /* Set route for GeoJSON */
  const [geoRoute, setGeoRoute] = useState(
    "/roads_tx100/findByTrank?min=0&max=100"
  );
  const [segmentsTable, setSegmentsTable] = useState("roads");
  const [segmentSelection, setSegmentSelection] = useState("all");

  const container =
    window !== undefined ? () => window.document.body : undefined;

  const [statusGuide, setStatusGuide] = useState([
    "TX100",
    "Top100 Truck Segments",
  ]);
  const [mapType, setMapType] = useState("leaflet");
  const [roadGeomFeatures, setRoadGeomFeatures] = useState({
    features: [],
    numFeatures: 0,
    type: null,
    minValue: 0,
    maxValue: 1000000000,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [monitoredRoads, setMonitoredRoads] = useState(null);
  const [monitoredRoadsData, setMonitoredRoadsData] = useState([]);
  const keyField = "label";
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [map, setMap] = useState(
    <TX100LeafletMap
      year={year}
      layerID={statsLayerParam}
      route={geoRoute}
      statusGuide={statusGuide}
      mode={mode}
      mapType={mapType}
      roadGeomFeatures={null}
      setRoadGeomFeatures={setRoadGeomFeatures}
      setIsLoading={setIsLoading}
    />
  );
  useEffect(()=>{
    if(!monitoredRoads){
    fetchMonitoredRoads()
    .then((response) => {
      setMonitoredRoads(response.data);
    })
    .catch((err) => console.error(err));
  }
  },[monitoredRoads])
 
  function initializeMonitoredRoads(fc) {
    let monitoredRoadsDataTemp = [];
    if (monitoredRoads) {
      monitoredRoads.map((fMonitored, index) => {
        let fcTemp = fc.filter(
          (f) =>
            f.properties.ria_rte_id === fMonitored.ria_rte_id &&
            f.properties.frm_dfo >= fMonitored.frm_dfo &&
            f.properties.to_dfo <= fMonitored.to_dfo
        );
        if (fcTemp.length > 0) {
          let fcTempData = [];
          let frm_dfo_min = 1000000;
          let to_dfo_max = 0;
          fcTemp.map((obj) => {
            obj[keyField] = fMonitored.id;
            fcTempData.push(obj.properties);

            if (obj.properties.frm_dfo < frm_dfo_min)
              frm_dfo_min = obj.properties.frm_dfo;
            if (obj.properties.to_dfo > to_dfo_max)
              to_dfo_max = obj.properties.to_dfo;
          });

          let normalizedData = normalizeRoadSegments(
            databaseVariablesHPMS,
            fcTempData,
            fMonitored
          );
          normalizedData["frm_dfo_orig"] = normalizedData["frm_dfo"];
          normalizedData["to_dfo_orig"] = normalizedData["to_dfo"];
          normalizedData["frm_dfo"] = frm_dfo_min;
          normalizedData["to_dfo"] = to_dfo_max;
          normalizedData["tableData"] = { showDetailPanel: "" };
          normalizedData["id"] = fMonitored.id;
          monitoredRoadsDataTemp.push(normalizedData);
        }
      });
      setMonitoredRoadsData(monitoredRoadsDataTemp);
    }
  }

  useEffect(() => {
    setIsLoading(true);
    let cntyCounter = 0;
    let geoRouteTemp = geoRoute;
    if (statusGuide[0] === "MPO" && year === 2021) {
      geoRouteTemp = geographic_options_MPO.filter(
        (itm) => itm.geoRoute === geoRoute
      )[0].geoRoute2021;
    }
    if (
      statusGuide[0] === "TxDOT District" ||
      statusGuide[0] === "Regions" ||
      statusGuide[0] === "Networks"
    ) {
      const fetchCountyPromise = async (dataUrl, index) => {
        const countyPromise = await axios.get(dataUrl);
        cntyCounter = cntyCounter + 1;
        setLoadingProgress((cntyCounter / countyIds.length) * 100);
        return countyPromise;
      };
      const fetchDistrictPromise = async (dataUrl) => {
        const countyPromise = await axios.get(dataUrl);
        cntyCounter = cntyCounter + 1;
        setLoadingProgress((cntyCounter / 25) * 100);
        return countyPromise;
      };
      let countyIds = [];
      if (statusGuide[0] === "TxDOT District")
        countyIds = geographic_options_TXDOTdist.filter(
          (obj) => obj.statusGuide === statusGuide
        )[0].counties;
      if (statusGuide[0] === "Regions")
        countyIds = geographic_options_REGIONS.filter(
          (obj) => obj.statusGuide === statusGuide
        )[0].counties;
      let promises = [];
      if (statusGuide[0] === "TxDOT District" || statusGuide[0] === "Regions") {
        promises = countyIds.map((r, index) => {
          const dataUrl =
            `${API_ROOT}` + `/roads/findByCounty?county=` + r + "&year=" + year;
          return fetchCountyPromise(dataUrl, index);
        });
      }
      if (statusGuide[0] === "Networks") {
        promises = geographic_options_TXDOTdist.map((r) => {
          const dataUrl =
            `${API_ROOT}` +
            geoRoute +
            `?district=` +
            r.mapped +
            "&year=" +
            year;
          return fetchDistrictPromise(dataUrl);
        });
      }
      const promiseResult = Promise.allSettled(promises);
      let fTemp = [];

      promiseResult.then((responses) => {
        responses.forEach((response, index) => {
          if (
            statusGuide[0] === "TxDOT District" ||
            statusGuide[0] === "Regions"
          )
            fTemp = fTemp.concat(response.value.data);
          if (statusGuide[0] === "Networks")
            fTemp = fTemp.concat(response.value.data);
        });
        fTemp = fTemp.filter((f) => {
          return Utils.isValidFeature(f);
        });
        setRoadGeomFeatures({
          features: fTemp,
          numFeatures: fTemp.length,
          type: "rhino",
          minValue: roadGeomFeatures.minValue,
          maxValue: roadGeomFeatures.maxValue,
        });

        if (fTemp.length > 10000) setMapType("deckGL-lines");
        initializeMonitoredRoads(fTemp)
        setIsLoading(false);
      });
    } else if (statusGuide[1] === "Polygon") {
      setRoadGeomFeatures({
        features: [],
        numFeatures: 0,
        type: null,
        minValue: 0,
        maxValue: 1000000000,
      });
      setIsLoading(false);
    } else {
      fetchRoadData({
        type: statusGuide[0],
        route: geoRouteTemp,
        year: year,
        file: file.file,
      })
        .then((response) => {
          let fc = [];
          fc = response && response.filter((f) => {
            return Utils.isValidFeature(f);
          });
          setRoadGeomFeatures({
            features: fc,
            numFeatures: fc.length,
            type: statusGuide[0] === "TX100" ? "tx100" : "rhino",
            minValue: roadGeomFeatures.minValue,
            maxValue: roadGeomFeatures.maxValue,
          });
          fc && initializeMonitoredRoads(fc);        
          setIsLoading(false);
        })
        .catch((err) => console.error(err));
    }
  }, [geoRoute, year]);

  useMemo(() => {
    if (statusGuide[1] === "Polygon") {
      setMap(
        <DeckglMap
          year={year}
          layerID={statsLayerParam}
          route={geoRoute}
          statusGuide={statusGuide}
          mode={mode}
          mapType={mapType}
          setMapType={setMapType}
          roadGeomFeatures={roadGeomFeatures}
          setRoadGeomFeatures={setRoadGeomFeatures}
        />
      );
    } else if (roadGeomFeatures && roadGeomFeatures.features.length > 0) {
      if (mapType === "leaflet") {
        if (statusGuide[0] !== "TX100") {
          setMap(
            <RhinoLeafletMap
              year={year}
              setYear={setYear}
              layerID={statsLayerParam}
              route={geoRoute}
              statusGuide={statusGuide}
              mode={mode}
              mapType={mapType}
              roadGeomFeatures={roadGeomFeatures}
              setRoadGeomFeatures={setRoadGeomFeatures}
            />
          );
        } else {
          setMap(
            <TX100LeafletMap
              year={year}
              layerID={statsLayerParam}
              route={geoRoute}
              statusGuide={statusGuide}
              mode={mode}
              mapType={mapType}
              roadGeomFeatures={roadGeomFeatures}
              setRoadGeomFeatures={setRoadGeomFeatures}
              setIsLoading={setIsLoading}
            />
          );
        }
      } else {
        setMap(
          <DeckglMap
            year={year}
            layerID={statsLayerParam}
            route={geoRoute}
            statusGuide={statusGuide}
            mode={mode}
            mapType={mapType}
            setMapType={setMapType}
            roadGeomFeatures={roadGeomFeatures}
            setRoadGeomFeatures={setRoadGeomFeatures}
          />
        );
      }
    }
  }, [roadGeomFeatures, mapType]);

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <Icon>menu</Icon>
            </IconButton>
            <Grid container spacing={3} align="center" justifyContent="center">
              <Grid item xs={2} className={classes2.logo1}>
                <img
                  className="logo"
                  src={
                    process.env.PUBLIC_URL +
                    "/Transparent Background Color3lineLOGO_TxDOT_WHITE_300dpi.png"
                  }
                  alt="TxDOT Logo"
                  title="TxDOT Logo"
                />
              </Grid>
              <Grid item xs={8} className={classes2.title}>
                <Typography variant="h4" noWrap style={{ paddingTop: "7.5px" }}>
                  Truck Congestion Analysis Tool (TCAT)
                </Typography>
              </Grid>
              <Grid item xs={2} className={classes2.logo2}>
                <img
                  className="logo2"
                  src={process.env.PUBLIC_URL + "/TTI-Color-reverse.png"}
                  alt="TTI Logo"
                  title="TTI Logo"
                />
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer} aria-label="mailbox folders">
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true,
              }}
            >
              <Sidebar
                statsLayerDesc={statsLayerParam}
                setStatsLayer={setStatsLayer}
                setGeoRoute={setGeoRoute}
                setSegmentsTable={setSegmentsTable}
                setSegmentSelection={setSegmentSelection}
                mode={mode}
                geoRoute={geoRoute}
                statusGuide={statusGuide}
                setStatusGuide={setStatusGuide}
                setRoadGeomFeatures={setRoadGeomFeatures}
                setMapType={setMapType}
                file={file}
                setFile={setFile}
                setYear={setYear}
              />
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Sidebar
              statsLayerDesc={statsLayerParam}
              setStatsLayer={setStatsLayer}
              setGeoRoute={setGeoRoute}
              setSegmentsTable={setSegmentsTable}
              setSegmentSelection={setSegmentSelection}
              mode={mode}
              geoRoute={geoRoute}
              statusGuide={statusGuide}
              setStatusGuide={setStatusGuide}
              setRoadGeomFeatures={setRoadGeomFeatures}
              setMapType={setMapType}
              file={file}
              setFile={setFile}
              setYear={setYear}
            />
          </Hidden>
        </nav>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <div>
            <RhinoQueryProvider>
              <LoadingOverlay
                active={isLoading}
                spinner={
                  <>
                    <BounceLoader />
                    <LinearProgress
                      variant="determinate"
                      value={loadingProgress}
                      className={classes2.linearProgress}
                    />
                  </>
                }
                text={
                  statsLayerParam
                    ? "Loading stats layer..."
                    : "Please select a layer from the menu on the left..."
                }
                styles={{
                  overlay: (base) => ({
                    ...base,
                    height: "650px",
                  }),
                }}
              >
                <div id="mapContainer" className={mode} style={{ zIndex: 2 }}>
                  {map}
                </div>
              </LoadingOverlay>
              {statusGuide[0] === "TX100" && (
                <DataTabsTx100
                  theme={theme}
                  layerID={statsLayerParam}
                  year={year}
                  setYear={setYear}
                  statusGuide={statusGuide}
                  mapType={mapType}
                  setMapType={setMapType}
                  roadGeomFeatures={roadGeomFeatures}
                  setRoadGeomFeatures={setRoadGeomFeatures}
                />
              )}
              {statusGuide[0] !== "TX100" && (
                <DataTabs
                  theme={theme}
                  layerID={statsLayerParam}
                  year={year}
                  setYear={setYear}
                  statusGuide={statusGuide}
                  mapType={mapType}
                  setMapType={setMapType}
                  roadGeomFeatures={roadGeomFeatures}
                  setRoadGeomFeatures={setRoadGeomFeatures}
                  monitoredRoadsData={monitoredRoadsData}
                />
              )}
              <TrkTop100ODSelLnkMap />
            </RhinoQueryProvider>
          </div>
        </main>
      </div>
    </ThemeProvider>
  );
}

export default App;
