import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { GeoJSON, LayerGroup } from "react-leaflet";
import { RhinoQueryContext } from "../RhinoQueryContext";

import L from "leaflet";
import { makeStyles } from "@material-ui/core/styles";
// import Icon from "@material-ui/core/Icon";
// import { renderToStaticMarkup } from "react-dom/server";
// import { divIcon } from "leaflet";

import customMarkerIconSvg from "../../assets/icons/flight_yellow_24dp.svg";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
  },
  legendContainer: {
    background: "white",
    padding: "5px",
  },
  legendIcon: {
    //height: "5%",
    //width: "auto",
    //border: "1px",
    marginRight: "5px",
  },
  wrapIcon: {
    //verticalAlign: 'middle',
    display: "flex",
    alignItems: "center",
  },
  roadwayLegendLine: {
    width: "25px",
    height: "5px",
    background: "#4d4dff",
    //float: 'left',
    marginRight: "5px",
  },
  icon2: {
    backgroundColor: "#11b461",
    width: "0.75rem",
    height: "0.755rem",
    display: "block",
    // left: "-0.25rem",
    // top: "-0.25rem",
    //position: "relative",
    // borderRadius: "3rem 3rem 0",
    // transform: "rotate(45deg)",
    // border: "1px solid #FFFFFF",
  },
  icon: {
    backgroundColor: "#000000",
    width: "1.25rem",
    height: "1.25rem",
    display: "block",
    //left: "-0.25rem",
    //top: "-0.25rem",
    //position: "relative",
    // borderRadius: "3rem 3rem 0",
    // transform: "rotate(45deg)",
    // border: "1px solid #FFFFFF",
  },
}));

export default function CargoAirportsLayer(props) {
  const [query, dispatch] = useContext(RhinoQueryContext);
  const classes = useStyles();
  const { layerRef } = props;

  // const iconMarkup = renderToStaticMarkup(
  //   <span className={classes.icon}>
  //     <Icon fontSize="small" style={{ color: "#FFFF00" }}>
  //       {"flight"}
  //     </Icon>
  //   </span>
  // );
  // const customMarkerIcon = divIcon({
  //   html: iconMarkup,
  // });

  const customMarkerIcon = new L.icon({
    iconUrl: customMarkerIconSvg,
    iconRetinaUrl: customMarkerIconSvg,
    //iconAnchor: [20, 40],
    //popupAnchor: [0, -35],
    iconSize: [15, 15],
    //className: classes.icon,
  });

  const [featureData, setFeatureData] = useState({
    type: "FeatureCollection",
    features: [],
  });

  useEffect(() => {
    const fetchFeatureData = async () => {
      try {
        if (
          query.contextLayersVisibility.cargoAirports &&
          featureData.features.length === 0
        ) {
          //NOTE: the % in ' ' is a wildcard identifier
          // const dataUrl =
          //   "https://geo.dot.gov/mapping/rest/services/NTAD/Airports/MapServer/0/query?where=State_Post_Office_Code+%3D+%27TX%27+AND+Other_Services+LIKE+%27%25CARGO%25%27&text=&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&distance=&units=esriSRUnit_Foot&relationParam=&outFields=*&returnGeometry=true&returnTrueCurves=false&maxAllowableOffset=&geometryPrecision=&outSR=&havingClause=&returnIdsOnly=false&returnCountOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&gdbVersion=&historicMoment=&returnDistinctValues=false&resultOffset=&resultRecordCount=&returnExtentOnly=false&datumTransformation=&parameterValues=&rangeValues=&quantizationParameters=&featureEncoding=esriDefault&f=geojson";
          const dataUrl =
            "https://geo.dot.gov/mapping/rest/services/NTAD/Aviation_Facilities/MapServer/0/query?where=OTHER_SERVICES+like+%27%25CARGO%25%27+and+STATE_CODE+%3D+%27TX%27&text=&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&distance=&units=esriSRUnit_Foot&relationParam=&outFields=*&returnGeometry=true&returnTrueCurves=false&maxAllowableOffset=&geometryPrecision=&outSR=&havingClause=&returnIdsOnly=false&returnCountOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&gdbVersion=&historicMoment=&returnDistinctValues=false&resultOffset=&resultRecordCount=&returnExtentOnly=false&datumTransformation=&parameterValues=&rangeValues=&quantizationParameters=&featureEncoding=esriDefault&f=geojson";
          const response = await axios.get(dataUrl);
          // console.log("cargo-airports", response);
          if (response.data.error)
            return console.log("cargo-airports-error", response.data);
          setFeatureData(response.data);

          let numFeaturesContext = query.contextLayersNumbFeatures;
          numFeaturesContext.cargoAirports = response.data.features.length;
          dispatch({
            type: "setContextLayersNumbFeatures",
            selection: numFeaturesContext,
          });
        }
      } catch (e) {
        console.log(e);
      }
    };
    fetchFeatureData();
  }, [query.contextLayersVisibility.cargoAirports]);

  const pointToLayer = (feature, latlng) => {
    //Fac_Name, City, Commercial_Ops, Commuter_Ops, Other_Services
    let tooltipText = `
    <div>
      <div style="text-align: center;border-bottom: 2.0px solid black;">
        <h5 style="margin-bottom: 0.25rem;margin-top: 0.25rem;">Cargo Airports Layer</h5>
      </div>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Facitlity Name:</strong> ${feature.properties.Fac_Name}</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Location:</strong> ${feature.properties.City}, TX</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Commercial Ops:</strong> ${feature.properties.Commercial_Ops}</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Commuter Ops:</strong> ${feature.properties.Commuter_Ops}</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Other Services:</strong> ${feature.properties.Other_Services}</p>
    </div>
    `;
    return L.marker(latlng, {
      icon: customMarkerIcon,
    }).bindTooltip(tooltipText);
    //  let popupText = `
    // <div>
    //   <div style="text-align: center;border-bottom: 2.0px solid black;">
    //     <h3 style="margin-bottom: 0.25rem;margin-top: 0.25rem;">Counties</h3>
    //   </div>
    //   <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Name:</strong> ${feature.properties.COUNTY}</p>
    //   <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>County Code:</strong> ${feature.properties.COUNTYNUM}</p>
    //   <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>County FIPS:</strong> ${feature.properties.COUNTY_FIP}</p>
    //   <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>District:</strong> ${feature.properties.DISTRICT}</p>
    // </div>
    // `;
    //   return layer.bindTooltip(tooltipText).bindPopup(popupText);
  };

  useEffect(() => {
    if (layerRef.current) {
      layerRef.current.leafletElement.clearLayers().addData(featureData);
    }
  }, [featureData]);

  return (
    <LayerGroup>
      {query.contextLayersVisibility.cargoAirports &&
        featureData.features.length > 0 && (
          <GeoJSON
            data={featureData}
            //onEachFeature={onEachFeature}
            pointToLayer={pointToLayer}
          />
        )}
    </LayerGroup>
  );
}
