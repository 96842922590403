import React from 'react'
import { TXDOTdist_VARIABLES, COUNTY_LIST, MPO_LIST, FUNCTION_CLASS2014, RURAL_URBAN_CODE } from '../common/constants'
const formatNumber = (num, digits) => {
  let n = (typeof digits !== 'undefined') ? digits : 0;
  if(num===null) return "NULL";
  let fixed = num.toFixed(n);
  let num_parts = fixed.toString().split(".");
  num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return num_parts.join(".");
};

const findLabel = (dict, name, value, label) => {
  //console.log('mpo id '+value + ' stands for ' + label);
  var labelText = 'Unknown';
  var labelItem = [];
  if (!(typeof value === undefined || value === '' || value === null)) {
    labelItem = dict.filter(x => x[name] === value);
    if (typeof labelItem[0] !== undefined) labelText = labelItem[0][label];
  }
  return labelText;
};


export const DICTIONARY_COLUMNS = [
  {
    "name": "ogc_fid_new",
    "label": "Unique Id",
    "options": null,
    "weight": null
  },
  {
    "name": "rec",
    "label": "Record Type",
    "options": null,
    "weight": null
  },
  {
    "name": "di",
    "label": "District",
    "options": { customBodyRender: value => <span>{findLabel(TXDOTdist_VARIABLES, 'mapped', value, 'definition')}</span> },
    "weight": null
  },
  {
    "name": "co",
    "label": "County",
    "options": { customBodyRender: value => <span>{findLabel(COUNTY_LIST, 'id', value, 'name')}</span> },
    "weight": null
  },
  {
    "name": "hsys",
    "label": "Highway System",
    "options": null,
    "weight": null
  },
  {
    "name": "rdbd_id",
    "label": "Roadbed",
    "options": null,
    "weight": null
  },
  {
    "name": "len_sec",
    "label": "Length Of Section",
    "options": { customBodyRender: value => <span>{formatNumber(value, 2)}</span> },
    "weight": "none"
  },
  {
    "name": "hwy_stat",
    "label": "Highway Status",
    "options": null,
    "weight": null
  },
  {
    "name": "f_system",
    "label": "Functional System",
    "options": { customBodyRender: value => <span>{findLabel(FUNCTION_CLASS2014, 'id', value, 'label')}</span> },
    "weight": null
  },
  {
    "name": "sec_nhs",
    "label": "Section Rte Nhs",
    "options": null,
    "weight": null
  },
  {
    "name": "ru",
    "label": "Rural Urban Code",
    "options": { customBodyRender: value => <span>{findLabel(RURAL_URBAN_CODE, 'id', value, 'label')}</span> },
    "weight": null
  },
  {
    "name": "mpa",
    "label": "Metro Planning Area",
    "options": { customBodyRender: value => <span>{findLabel(MPO_LIST, 'id', value, 'name')}</span> },
    "weight": null
  },
  {
    "name": "from_dfo",
    "label": "From Dfo",
    "options": null,
    "weight": null
  },
  {
    "name": "to_dfo",
    "label": "To Dfo",
    "options": null,
    "weight": null
  },
  {
    "name": "ria_rte_id",
    "label": "Ria Rte Id",
    "options": null,
    "weight": null
  },
  {
    "name": "shape_leng",
    "label": "Length Of Shape",
    "options": { customBodyRender: value => <span>{formatNumber(value, 3)}</span> },
    "weight": null
  },
  {
    "name": "fun_sys",
    "label": "Alternative Functional System",
    "options": null,
    "weight": null
  },
  {
    "name": "adt_cur",
    "label": "AADT",
    "options": { customBodyRender: value => <span>{formatNumber(value)}</span> },
    "weight": "len_sec"
  },
  {
    "name": "num_lanes",
    "label": "Number Of Lines",
    "options": null,
    "weight": null
  },
  {
    "name": "aadt_singl",
    "label": "AADT-Single Unit Truck",
    "options": { customBodyRender: value => <span>{formatNumber(value)}</span> },
    "weight": "len_sec"
  },
  {
    "name": "aadt_combi",
    "label": "AADT-Combination Truck",
    "options": { customBodyRender: value => <span>{formatNumber(value)}</span> },
    "weight": "len_sec"
  },
  {
    "name": "estimated",
    "label": "Stats Estimated?",
    "options": null,
    "weight": null
  },
  {
    "name": "tti",
    "label": "Travel Time Index",
    "options": { customBodyRender: value => <span>{formatNumber(value, 2)}</span> },
    "weight": "ttivmt"
  },
  {
    "name": "ttivmt",
    "label": "VMT Associated With The TTI",
    "options": { customBodyRender: value => <span>{formatNumber(value)}</span> },
    "weight": "none"
  },
  {
    "name": "trktti",
    "label": "Truck Travel Time Index",
    "options": { customBodyRender: value => <span>{formatNumber(value, 2)}</span> },
    "weight": "trkttivmt"
  },
  {
    "name": "trkttivmt",
    "label": "Truck VMT Associated With Truck Tti",
    "options": { customBodyRender: value => <span>{formatNumber(value)}</span> },
    "weight": "none"
  },
  {
    "name": "allspeed",
    "label": "Congested Speed",
    "options": { customBodyRender: value => <span>{formatNumber(value, 2)}</span> },
    "weight": null
  },
  {
    "name": "trkspeed",
    "label": "Truck Congested Speed",
    "options": { customBodyRender: value => <span>{formatNumber(value, 2)}</span> },
    "weight": null
  },
  {
    "name": "allrefspee",
    "label": "Reference Speed",
    "options": { customBodyRender: value => <span>{formatNumber(value, 2)}</span> },
    "weight": null
  },
  {
    "name": "trkrefspee",
    "label": "Truck Reference Speed",
    "options": { customBodyRender: value => <span>{formatNumber(value, 2)}</span> },
    "weight": null
  },
  {
    "name": "delay",
    "label": "Person Hours Of Delay",
    "options": { customBodyRender: value => <span>{formatNumber(value)}</span> },
    "weight": "none"
  },
  {
    "name": "trkdelay",
    "label": "Truck Vehicle Hours Of Delay",
    "options": { customBodyRender: value => <span>{formatNumber(value)}</span> },
    "weight": "none"
  },
  {
    "name": "ghg",
    "label": "Congested CO2 lbs",
    "options": { customBodyRender: value => <span>{formatNumber(value)}</span> },
    "weight": "none"
  },
  {
    "name": "ghgbase",
    "label": "Normal CO2 lbs",
    "options": { customBodyRender: value => <span>{formatNumber(value)}</span> },
    "weight": "none"
  },
  {
    "name": "congcost",
    "label": "Congested Cost",
    "options": { customBodyRender: value => <span>${formatNumber(value)}</span> },
    "weight": "none"
  },
  {
    "name": "pti95",
    "label": "Planning Time Index 95",
    "options": { customBodyRender: value => <span>{formatNumber(value, 2)}</span> },
    "weight": "vmt"
  },
  {
    "name": "pti80",
    "label": "Planning Time Index 80",
    "options": { customBodyRender: value => <span>{formatNumber(value, 2)}</span> },
    "weight": "vmt"
  },
  {
    "name": "trkpti95",
    "label": "Truck Planning Time Index 95",
    "options": { customBodyRender: value => <span>{formatNumber(value, 2)}</span> },
    "weight": "trkvmt"
  },
  {
    "name": "trkpti80",
    "label": "Truck Planning Time Index 80",
    "options": { customBodyRender: value => <span>{formatNumber(value, 2)}</span> },
    "weight": "trkvmt"
  },
  {
    "name": "lanemiles",
    "label": "Lane Length In Miles",
    "options": { customBodyRender: value => <span>{formatNumber(value)}</span> },
    "weight": "none"
  },
  {
    "name": "vmt",
    "label": "VMT",
    "options": { customBodyRender: value => <span>{formatNumber(value)}</span> },
    "weight": "none"
  },
  {
    "name": "pct_sadt",
    "label": "AADT Percentage Of Single Truck",
    "options": { customBodyRender: value => <span>{formatNumber(value,2)}%</span> },
    "weight": "simple"
  },
  {
    "name": "pct_cadt",
    "label": "AADT Percentage Of Combination Truck",
    "options": { customBodyRender: value => <span>{formatNumber(value,2)}%</span> },
    "weight": "simple"
  },
  {
    "name": "pct_trk",
    "label": "AADT Percentage Of All Truck",
    "options": { customBodyRender: value => <span>{formatNumber(value,2)}%</span> },
    "weight": "simple"
  },
  {
    "name": "tottrk",
    "label": "Total Number Of Truck",
    "options": { customBodyRender: value => <span>{formatNumber(value)}</span> },
    "weight": "none"
  },
  {
    "name": "trkvmt",
    "label": "Truck VMT",
    "options": { customBodyRender: value => <span>{formatNumber(value)}</span> },
    "weight": "none"
  },
  {
    "name": "year",
    "label": "Year",
    "options": null,
    "weight": null
  },
  {
    "name": "cnty_name",
    "label": "County Name",
    "options": null,
    "weight": null
  },
  {
    "name": "county_num",
    "label": "County Number",
    "options": null,
    "weight": null
  },
  {
    "name": "triangle_region",
    "label": "",
    "options": null,
    "weight": null
  },
  {
    "name": "border_region",
    "label": "",
    "options": null,
    "weight": null
  },
  {
    "name": "permian_basin",
    "label": "",
    "options": null,
    "weight": null
  },
  {
    "name": "hou_area",
    "label": "",
    "options": null,
    "weight": null
  },
  {
    "name": "dfw_area",
    "label": "",
    "options": null,
    "weight": null
  },
  {
    "name": "rio_grande_val",
    "label": "",
    "options": null,
    "weight": null
  },
  {
    "name": "interstate_r",
    "label": "",
    "options": null,
    "weight": null
  },
  {
    "name": "state_loop",
    "label": "",
    "options": null,
    "weight": null
  },
  {
    "name": "inters_connect_loop",
    "label": "",
    "options": null,
    "weight": null
  },
  {
    "name": "connect_loop",
    "label": "",
    "options": null,
    "weight": null
  },
  {
    "name": "port_to_pl",
    "label": "",
    "options": null,
    "weight": null
  },
  {
    "name": "center_line",
    "label": "",
    "options": null,
    "weight": null
  },
  {
    "name": "sum_distance",
    "label": "",
    "options": null,
    "weight": null
  },
  {
    "name": "sum_trkvmt",
    "label": "",
    "options": null,
    "weight": null
  },
  {
    "name": "trucknumber",
    "label": "Truck Number",
    "options": null,
    "weight": null
  },
  {
    "name": "ranks",
    "label": "Ranks",
    "options": null,
    "weight": null
  },
  {
    "name": "mpa_2018",
    "label": "",
    "options": null,
    "weight": null
  },
  {
    "name": "mpa_2019",
    "label": "",
    "options": null,
    "weight": null
  },
  {
    "name": "tx100seg_length",
    "label": "Tx100 Segmentation Length",
    "options": null,
    "weight": "none"
  },
  {
    "name": "aadt_tx100",
    "label": "Tx100 Segmentation AADT",
    "options": { customBodyRender: value => <span>{formatNumber(value)}</span> },
    "weight": "none"
  },
  {
    "name": "trkaadt_tx100",
    "label": "Tx100 Segmentation Truck Aadt",
    "options": { customBodyRender: value => <span>{formatNumber(value)}</span> },
    "weight": "none"
  },
  {
    "name": "tx100id",
    "label": "Id For Tx100 Segmentation",
    "options": null,
    "weight": null
  },
  {
    "name": "rank",
    "label": "2019 Rank -- All Delay",
    "options": null,
    "weight": "simple"
  },
  {
    "name": "trank",
    "label": "2019 Rank -- Truck Delay",
    "options": null,
    "weight": "simple"
  },
  {
    "name": "dmile",
    "label": "Person Hours of Delay Per Mile",
    "options": { customBodyRender: value => <span>{formatNumber(value)}</span> },
    "weight": "none"
  },
  {
    "name": "tdmile",
    "label": "Truck Person Hours of Delay Per Mile",
    "options": { customBodyRender: value => <span>{formatNumber(value)}</span> },
    "weight": "none"
  },
  {
    "name": "tci",
    "label": "Travel Time Index",
    "options": { customBodyRender: value => <span>{formatNumber(value, 2)}</span> },
    "weight": "vmt"
  },
  {
    "name": "pti",
    "label": "Planning Time Index",
    "options": { customBodyRender: value => <span>{formatNumber(value, 2)}</span> },
    "weight": "vmt"
  },
  {
    "name": "csi",
    "label": "Commuter Stress Index",
    "options": { customBodyRender: value => <span>{formatNumber(value)}</span> },
    "weight": "vmt"
  },
  {
    "name": "spd",
    "label": "Average Peak Period Speed (Based On 6-9am, 4-7pm)",
    "options": { customBodyRender: value => <span>{formatNumber(value)}</span> },
    "weight": null
  },
  {
    "name": "tspd",
    "label": "Average Truck Peak Period Speed (Based On 6-9am, 4-7pm)",
    "options": { customBodyRender: value => <span>{formatNumber(value)}</span> },
    "weight": null
  },
  {
    "name": "rspd",
    "label": "Freeflow Speed For All Vehicles",
    "options": { customBodyRender: value => <span>{formatNumber(value)}</span> },
    "weight": null
  },
  {
    "name": "trspd",
    "label": "Freeflow Speed For Trucks",
    "options": { customBodyRender: value => <span>{formatNumber(value)}</span> },
    "weight": null
  },
  {
    "name": "vmt",
    "label": "VMT",
    "options": { customBodyRender: value => <span>{formatNumber(value)}</span> },
    "weight": "none"
  },
  {
    "name": "pkdly",
    "label": "Peak Period Annual Hours Of Delay (Person-hours)",
    "options": { customBodyRender: value => <span>{formatNumber(value)}</span> },
    "weight": "none"
  },
  {
    "name": "opdly",
    "label": "Off-peak Annual Hours Of Delay (Person-hours)",
    "options": { customBodyRender: value => <span>{formatNumber(value)}</span> },
    "weight": "none"
  },
  {
    "name": "wedly",
    "label": "Weekend Annual Hours Of Delay (Person-hours)",
    "options": { customBodyRender: value => <span>{formatNumber(value)}</span> },
    "weight": "none"
  },
  {
    "name": "pktdly",
    "label": "Peak Period Annual Hours Of Truck Delay (Person-hours)",
    "options": { customBodyRender: value => <span>{formatNumber(value)}</span> },
    "weight": "none"
  },
  {
    "name": "optdly",
    "label": "Off-peak Annual Hours Of Truck Delay (Person-hours)",
    "options": { customBodyRender: value => <span>{formatNumber(value)}</span> },
    "weight": "none"
  },
  {
    "name": "wetdly",
    "label": "Weekend Person Hours Of Truck Delay (Person-hours)",
    "options": { customBodyRender: value => <span>{formatNumber(value)}</span> },
    "weight": "none"
  },
  {
    "name": "dly",
    "label": "Person Hours Of Delay",
    "options": { customBodyRender: value => <span>{formatNumber(value)}</span> },
    "weight": "none"
  },
  {
    "name": "tdly",
    "label": "Truck Person Hours Of Delay",
    "options": { customBodyRender: value => <span>{formatNumber(value)}</span> },
    "weight": "none"
  },
  {
    "name": "ttci",
    "label": "Truck Congestion Index",
    "options": { customBodyRender: value => <span>{formatNumber(value, 2)}</span> },
    "weight": "vmt"
  },
  {
    "name": "tpti",
    "label": "Truck Planning Time Index",
    "options": { customBodyRender: value => <span>{formatNumber(value, 2)}</span> },
    "weight": "vmt"
  },
  {
    "name": "cost",
    "label": "Congested Cost",
    "options": { customBodyRender: value => <span>{formatNumber(value)}</span> },
    "weight": "none"
  },
  {
    "name": "tcost",
    "label": "Truck Congested Cost",
    "options": { customBodyRender: value => <span>{formatNumber(value)}</span> },
    "weight": "none"
  },
  {
    "name": "fuel",
    "label": "Annual Excess Fuel Used Due To Congestion (Gallons)",
    "options": { customBodyRender: value => <span>{formatNumber(value)}</span> },
    "weight": "none"
  },
  {
    "name": "tfuel",
    "label": "Annual Excess Fuel Used Due To Truck Congestion (Gallons)",
    "options": { customBodyRender: value => <span>{formatNumber(value)}</span> },
    "weight": "none"
  },
  {
    "name": "co2",
    "label": "Annual Excess Co2 Produced Due To Congestion (Pounds)",
    "options": { customBodyRender: value => <span>{formatNumber(value)}</span> },
    "weight": "none"
  },
  {
    "name": "tco2",
    "label": "Annual Excess Co2 Produced Due To Truck Congestion (Pounds)",
    "options": { customBodyRender: value => <span>{formatNumber(value)}</span> },
    "weight": "none"
  },
  {
    "name": "fc",
    "label": "Functional System",
    "options": null,
    "weight": null
  },
  {
    "name": "data",
    "label": "Data Availability",
    "options": null,
    "weight": null
  },
  {
    "name": "urb",
    "label": "Urban Area",
    "options": null,
    "weight": null
  },
  {
    "name": "rank2018",
    "label": "Delay Rank -Year",
    "options": null,
    "weight": null
  },
  {
    "name": "rank2017",
    "label": "",
    "options": null,
    "weight": null
  },
  {
    "name": "rank2016",
    "label": "",
    "options": null,
    "weight": null
  },
  {
    "name": "rank2015",
    "label": "",
    "options": null,
    "weight": null
  },
  {
    "name": "rank2014",
    "label": "",
    "options": null,
    "weight": null
  },
  {
    "name": "rank2013",
    "label": "",
    "options": null,
    "weight": null
  },
  {
    "name": "rank2012",
    "label": "",
    "options": null,
    "weight": null
  },
  {
    "name": "rank2011",
    "label": "",
    "options": null,
    "weight": null
  },
  {
    "name": "rank2010",
    "label": "",
    "options": null,
    "weight": null
  },
  {
    "name": "trank2018",
    "label": "Truck Delay Rank - Year",
    "options": null,
    "weight": null
  },
  {
    "name": "trank2017",
    "label": "",
    "options": null,
    "weight": null
  },
  {
    "name": "trank2016",
    "label": "",
    "options": null,
    "weight": null
  },
  {
    "name": "trank2015",
    "label": "",
    "options": null,
    "weight": null
  },
  {
    "name": "trank2014",
    "label": "",
    "options": null,
    "weight": null
  },
  {
    "name": "vmt_tx100seg",
    "label": "VMT",
    "options": { customBodyRender: value => <span>{formatNumber(value)}</span> },
    "weight": "none"
  },
  {
    "name": "trkvmt_tx100seg",
    "label": "Truck VMT",
    "options": { customBodyRender: value => <span>{formatNumber(value)}</span> },
    "weight": "none"
  },
  {
    "name": "cost",
    "label": "Congested Cost",
    "options": { customBodyRender: value => <span>{formatNumber(value)}</span> },
    "weight": "none"
  },
  {
    "name": "length_tx100",
    "label": "Length",
    "options": { customBodyRender: value => <span>{formatNumber(value, 2)}</span> },
    "weight": "none"
  },
];