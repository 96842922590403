import React, { useState, useContext, useEffect } from "react";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import FormGroup from "@material-ui/core/FormGroup";
// import IconButton from "@material-ui/core/IconButton";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ViewColumnIcon from "@material-ui/icons/ViewColumn";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";

//import { QueryContext } from "../map/QueryContext";
import { RhinoQueryContext } from "../map/RhinoQueryContext";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    maxWidth: 400,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  colselectLabel: {
    paddingRight: 15,
  },
  colselectColumn2: {
    paddingLeft: 15,
  },
  buttonClass: {
    position: "relative",
    top: "-2px",
  },
}));

export default function TableColumnSelector(props) {
  const classes = useStyles();
  const [query, dispatch] = useContext(RhinoQueryContext);
  const columns = props.columns;
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [columnsState, setColumnsState] = useState(query.tableColumns);
  useEffect(() => {
    setColumnsState(query.tableColumns);
  }, [query.tableColumns]); //query.roadLayerType

  const handleChange = (event) => {
    // NOTE: TRUCK % MUST HAVE AADT AND TRUCK AADT.
    if (event.target.name === "trkvolper" && event.target.checked) {
      columnsState.aadt = true;
      columnsState.trkaadt = true;
    }

    dispatch({
      type: "setTableColumns",
      selection: { ...columnsState, [event.target.name]: event.target.checked },
    });
  };

  const handleUnselectAllColumnsAll = (event) => {
    let cols = {};

    cols = buildColsState(cols, "route", "keep");
    cols = buildColsState(cols, "all", false);
    cols = buildColsState(cols, "truck", "keep");

    dispatch({
      type: "setTableColumns",
      selection: cols,
    });
  };

  const handleSelectAllColumnsAll = (event) => {
    let cols = {};

    cols = buildColsState(cols, "route", "keep");
    cols = buildColsState(cols, "all", true);
    cols = buildColsState(cols, "truck", "keep");

    dispatch({
      type: "setTableColumns",
      selection: cols,
    });
  };

  const handleUnselectAllColumnsTruck = (event) => {
    let cols = {};

    cols = buildColsState(cols, "route", "keep");
    cols = buildColsState(cols, "all", "keep");
    cols = buildColsState(cols, "truck", false);

    dispatch({
      type: "setTableColumns",
      selection: cols,
    });
  };

  const handleSelectAllColumnsTruck = (event) => {
    let cols = {};

    cols = buildColsState(cols, "route", "keep");
    cols = buildColsState(cols, "all", "keep");
    cols = buildColsState(cols, "truck", true);

    dispatch({
      type: "setTableColumns",
      selection: cols,
    });
  };

  function buildColsState(cols, category, state) {
    columns
      .filter((item) => item.category === category)
      .map(
        (col, index) =>
          (cols = {
            ...cols,
            [col.id]: state === "keep" ? columnsState[col.id] : state,
          })
      );

    return cols;
  }

  function DialogColumn(props) {
    return (
      <div>
        <FormGroup
          key={props.formGroupKey}
          className={classes.colselectColumn2}
        >
          <FormLabel component="legend" className={classes.colselectLabel}>
            {props.categoryLabel}
          </FormLabel>
          {columns
            .filter((item) => item.category === props.category)
            .map((col, index) => (
              <FormControlLabel
                control={
                  <Checkbox
                    name={col.id}
                    key={`check-rdsegment-col-${index}`}
                    checked={columnsState[col.id]}
                    onChange={handleChange}
                  />
                } //{<Checkbox checked={jason} onChange={handleChange} name="jason" />}
                label={col.label}
                key={`check-rdsegment-col-fgl-${index}`}
              />
            ))}
          <Grid
            container
            alignItems="center"
            className={classes.colselectLabel}
          >
            {props.selectAllButton !== "none" && (
              <Grid item xs>
                <Button onClick={props.selectAllButton} color="primary">
                  Select All
                </Button>
              </Grid>
            )}
            {props.unselectAllButton !== "none" && (
              <Grid item>
                <Button onClick={props.unselectAllButton} color="primary">
                  Unselect All
                </Button>
              </Grid>
            )}
          </Grid>
        </FormGroup>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <HtmlTooltip placement="bottom-start" title="Select data table columns">
        {/* <IconButton
          component="span"
          color="primary"
          aria-label="select columns"
          onClick={handleClickOpen}
        >
          <ViewColumnIcon />
        </IconButton> */}
        <ViewColumnIcon
          onClick={handleClickOpen}
          fontSize="medium"
          className={classes.buttonClass}
        />
      </HtmlTooltip>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>Select table columns below:</DialogTitle>
        <DialogContent>
          <Grid container alignItems="flex-start">
            <DialogColumn
              formGroupKey={`check-rdsegment-col-fg1`}
              category={"route"}
              categoryLabel={"Route Information"}
              selectAllButton={"none"}
              unselectAllButton={"none"}
            />
            <Divider orientation="vertical" flexItem />
            <DialogColumn
              formGroupKey={`check-rdsegment-col-fg2`}
              category={"all"}
              categoryLabel={"All Vehicle Performance Measures"}
              selectAllButton={handleSelectAllColumnsAll}
              unselectAllButton={handleUnselectAllColumnsAll}
            />
            <Divider orientation="vertical" flexItem />
            <DialogColumn
              formGroupKey={`check-rdsegment-col-fg3`}
              category={"truck"}
              categoryLabel={"Truck Performance Measures"}
              selectAllButton={handleSelectAllColumnsTruck}
              unselectAllButton={handleUnselectAllColumnsTruck}
            />
          </Grid>
          <Divider variant="middle" />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
