import axios from "axios";

const fetchUTP = async (rhinoData, setUTPData) => {
  try {
    //setIsLoading(true);
    if (rhinoData == null) return;
    const response = await axios.get(
      process.env.PUBLIC_URL + `/TxDOT_Projects.geojson`
    );
    //console.log(response);
    //console.log(response.data);
    let cntyList = findUnique(rhinoData.features, "co");
    //log("Different counties in the fetched rhino data");
    //console.log(rhinoData);
    //console.log(cntyList);
    let filterAll = (fc) => {
      return (fc) =>
        fc.geometry != null &&
        cntyList.includes(fc.properties.COUNTY_NUMBER) &&
        fc.properties.PRJ_UTP != null;
    };
    let filterPhases = ["8111", "5836", "1807", "687"].map((val) => {
      return (fc) => fc.properties.PT_PHASE.includes(val);
    });
    let f = response.data.features.filter(filterAll);
    setUTPData({
      type: "FeatureCollection",
      features: filterPhases.map((fn) => f.filter(fn)),
    });
    //setIsLoading(false);
  } catch (e) {
    console.log(e);
  }
};

const findUnique = (array, key) => {
  var unique = array
    .map((p) => p.properties[key])
    .filter((key, index, arr) => arr.indexOf(key) == index);
  return unique;
};

const isValidFeature = (feature) => {
  // Check if feature has geometry and coordinates
  if (feature.geometry && feature.geometry.coordinates) {
    // Additional checks for valid coordinates can be added here
    const coordinates = feature.geometry.coordinates;
    // Example check: ensure coordinates are finite numbers
    const areCoordinatesValid = coordinates.flat(Infinity).every(coord => Number.isFinite(coord));
    return areCoordinatesValid;
  }
  return false;
};

//Sets csv export data for custom corridors excluding TX100
const customExportData = (data, year) => {
  const temp = [];
  let id = 0;
  if (data) {
      (data.map(i => {
          temp.push({
              ria_rte_id: i.ria_rte_id,
              frm_dfo: i.frm_dfo,
              to_dfo: i.to_dfo,
              id: id + 1,
              year: year
          })
          id++;
      }));
      return temp;
  }
}

const Utils = {
  fetchUTP,
  findUnique,
  isValidFeature,
  customExportData,
};

export default Utils;
