import React, { useState, useContext, useEffect } from "react";

import { lighten, makeStyles } from "@material-ui/core/styles";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import { RhinoQueryContext } from "../map/RhinoQueryContext";

import Typography from "@material-ui/core/Typography";

import colorScale from "../utils/colorScale";

const useStyles = makeStyles((theme) => ({
  roadSegmentSelector: {
    backgroundColor: theme.palette.background.paper,
  },
  wrapIcon: {
    //verticalAlign: 'middle',
    display: "flex",
    alignItems: "center",
  },
  roadwayLegendLine: {
    width: "100%",
    height: "5px",
    background: "#4d4dff",
    //float: 'left',
    marginLeft: "5px",
    marginRight: "5px",
    marginBottom: "10px",
  },

  roadwayLegendLine2: {
    width: "100%",
    height: "20px",
    //background: '#4d4dff',
    //float: 'left',
    marginLeft: "5px",
    marginRight: "5px",
    marginBottom: "10px",
  },
  roadwayLegendLine2Text: {
    //width: "100%",
    //height: '20px',
    //background: '#4d4dff',
    //float: 'left',
    //marginLeft: '5px',
    //marginRight: '5px',
    marginTop: "10px",
  },
  roadwayLegendDiv: {
    borderBottomStyle: "solid",
    borderWidth: "1px",
    borderColor: "grey",
    marginBottom: "5px",
  },
}));

export default function RoadSegmentLegend(props) {
  const classes = useStyles();
  const [query, dispatch] = useContext(RhinoQueryContext);

  const [roadSegmentCategory, setroadSegmentCategory] = useState(
    //"none"
    query.roadSegmentCategory
  );

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const optionRHINO = [
    "None (all single color)",
    "Annual Person-Hours of Truck Delay",
    "Truck Planning Time Index 95",
    "Truck Planning Time Index 80",
    "Truck Travel Time Index",
    "Truck Reference Speed",
    "Truck Congested Speed",
    "Truck AADT",
    "Daily Truck VMT",
  ];
  const optionsAbbrevRHINO = [
    "none",
    "tdly",
    "tpti95",
    "tpti80",
    "ttti",
    "trefspd",
    "tcongspd",
    "taadt",
    "tvmt",
  ];

  const optionsTX100 = [
    "None (all single color)",
    "Truck Top 100 Ranking",
    "Annual Person-Hours of Truck Delay",
    "Truck Delay per Mile",
    "Truck Travel Time Index",
    "Truck Planning Time Index",
    "Truck Congested Costs",
  ];
  const optionsAbbrevTX100 = [
    "none",
    "trank",
    "tdly",
    "tdmile",
    "ttci",
    "tpti",
    "tcost",
  ];

  let options = [];
  //   let optionsAbbrev = [];
  let featureType = "tx100";
  if (!query.features[0].top100id) featureType = "rhino";
  else featureType = "tx100";
  if (featureType === "rhino") {
    //   if (props.type === "rhino") {
    options = optionRHINO;
    // optionsAbbrev = optionsAbbrevRHINO;
  } else {
    options = optionsTX100;
    // optionsAbbrev = optionsAbbrevTX100;
  }

  function LegendNone(props) {
    return (
      //<div id="legend-1n" className={classes.roadwayLegendDiv}>
      //    <Typography variant="subtitle1" className={classes.wrapIcon}>
      //        <i className={classes.roadwayLegendLine}></i>
      //    </Typography>
      //</div>
      <Typography variant="subtitle1" className={classes.wrapIcon}>
        <i className={classes.roadwayLegendLine}></i>
      </Typography>
    );
  }

  function LegendOther(props) {
    return (
      //<div id="legend-1" className={classes.roadwayLegendDiv}>
      <svg className={classes.roadwayLegendLine2}>
        <defs>
          <linearGradient
            id="Gradient-1"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="0%"
            spreadMethod="pad"
          >
            <stop offset="0%" stopColor={colorScale(1, 1, 10)} />
            <stop offset="10%" stopColor={colorScale(2, 1, 10)} />
            <stop offset="20%" stopColor={colorScale(3, 1, 10)} />
            <stop offset="30%" stopColor={colorScale(4, 1, 10)} />
            <stop offset="40%" stopColor={colorScale(5, 1, 10)} />
            <stop offset="50%" stopColor={colorScale(6, 1, 10)} />
            <stop offset="60%" stopColor={colorScale(7, 1, 10)} />
            <stop offset="70%" stopColor={colorScale(8, 1, 10)} />
            <stop offset="80%" stopColor={colorScale(9, 1, 10)} />
            <stop offset="90%" stopColor={colorScale(10, 1, 10)} />
          </linearGradient>
        </defs>
        <rect
          width="100%"
          height="5px"
          style={{ fill: "url(#Gradient-1)" }}
        ></rect>

        <text
          x="0%"
          y="100%"
          fill={props.textColor}
          fontFamily="sans-serif"
          fontSize="11pt"
        >
          {minValueText}
        </text>
        <text
          x={maxValueOffset}
          y="100%"
          fill={props.textColor}
          fontFamily="sans-serif"
          fontSize="11pt"
          align="right"
        >
          {maxValueText}
        </text>
      </svg>
      //</div>
    );
  }

  function Legend(props) {
    // console.log("query.roadSegmentCategory", query.roadSegmentCategory);
    if (query.roadSegmentCategory === "none") {
      //    if (props.type === "none") {

      return <LegendNone />;
    } else {
      return <LegendOther textColor={props.textColor} />;
    }
  }

  const rdSettingsHdr = `Roadway Settings (${props.numFeatures} features)  `;
  let minValueText = `${props.minValue}`;
  let maxValueText = `${props.maxValue}`;
  let maxValueOffset = `${100 -
    Math.round(props.maxValue.toString().length * 2.5)}%`;

  if (
    // optionsAbbrev[selectedIndex] === "trank" ||
    // optionsAbbrev[selectedIndex] === "trefspd" ||
    // optionsAbbrev[selectedIndex] === "tcongspd"
    query.roadSegmentCategory === "trank" ||
    query.roadSegmentCategory === "trefspd" ||
    query.roadSegmentCategory === "tcongspd"
  ) {
    minValueText = `${props.maxValue}`;
    maxValueText = `${props.minValue}`;
    maxValueOffset = `${100 -
      Math.round(props.minValue.toString().length * 2.5)}%`;
  }

  return (
    <div className={classes.roadSegmentSelector}>
      <List
        component="nav"
        aria-label="Device settings"
        style={{ marginTop: "0px", paddingTop: "0px" }}
      >
        <ListItem
          style={{ marginBottom: "1px", marginTop: "0px", paddingTop: "0px" }}
          //   button
          aria-haspopup="true"
          aria-controls="lock-menu"
          aria-label="when device is locked"
          onClick={handleClickListItem}
        >
          {/* const rdSettingsHdr = `Roadway Settings (${props.numFeatures}{" "}
          features) `; */}
          <ListItemText
            //primary={rdSettingsHdr}
            primary={
              <ListItemText
                // primary={"Roadway Settings:"}
                primary={`Roadways: ${options[query.roadSegmentCategoryIndex]}`}
                secondary={`${props.numFeatures} features`}
                style={{ marginTop: "1px", paddingTop: "1px" }}
              />
            }
            // secondary={options[query.roadSegmentCategoryIndex]}
            style={{
              marginTop: "1px",
              paddingTop: "1px",
              marginBottom: "1px",
              paddingBottom: "1px",
            }}
          />
        </ListItem>
      </List>

      <Legend
        type={roadSegmentCategory}
        textColor={props.mode === "dark" ? "white" : "black"}
      />
    </div>
  );
}
