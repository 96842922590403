import React, { useState, useEffect, useContext, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { GeoJSON, Tooltip, LayerGroup, Popup, Marker } from "react-leaflet";
import { RhinoQueryContext } from "../RhinoQueryContext";
import {
  API_ROOT,
  API_ROOT_COMMON,
  // COLOR_RHINO,
  // colorContextlayers,
  // tx100_monitored_counties,
  colorContextlayers,
} from "../../common/constants";
import L from "leaflet";

import customMarkerIconSvg from "../../assets/icons/local_parking_white_on_green_24dp.svg";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
  },
  legendContainer: {
    background: "white",
    padding: "5px",
  },
  legendIcon: {
    //height: "5%",
    //width: "auto",
    //border: "1px",
    marginRight: "5px",
  },
  wrapIcon: {
    //verticalAlign: 'middle',
    display: "flex",
    alignItems: "center",
  },
  roadwayLegendLine: {
    width: "25px",
    height: "5px",
    background: "#4d4dff",
    //float: 'left',
    marginRight: "5px",
  },
  icon: {
    backgroundColor: "#0000ff",
    width: "1.25rem",
    height: "1.25rem",
    display: "block",
    //left: "-0.25rem",
    //top: "-0.25rem",
    // position: "relative",
    // borderRadius: "3rem 3rem 0",
    // transform: "rotate(45deg)",
    // border: "1px solid #FFFFFF",
    path: { fill: "red" },
  },
}));

export default function TruckParkingLayer(props) {
  const [query, dispatch] = useContext(RhinoQueryContext);
  const classes = useStyles();
  //const { utpData, utpCategoryLookups } = props;
  const { layerRef, countyList } = props;

  const customMarkerIcon = new L.icon({
    iconUrl: customMarkerIconSvg,
    iconRetinaUrl: customMarkerIconSvg,
    //iconAnchor: [20, 40],
    //popupAnchor: [0, -35],
    iconSize: [15, 15],
    //className: classes.icon,
  });

  const [featureData, setFeatureData] = useState({
    type: "FeatureCollection",
    features: [],
  });

  useEffect(() => {
    const fetchFeatureData = async () => {
      try {
        //http://localhost:5000/api/tcat_boundaries/findCountyBoundariesByCountyNumber/10,11,23
        // const dataUrlCnty = `${API_ROOT}/tcat_boundaries/findCountyBoundariesByCountyNumber/${countyList.join(
        //   ","
        // )}`;
        // const responseCnty = await axios.get(dataUrlCnty);
        // setCountyBoundaries(responseCnty.data.jsonb_build_object);
        if (
          query.contextLayersVisibility.truckParkingLayer &&
          featureData.features.length === 0
        ) {
          //countyList.toString()
          // const response = await axios.get(
          //   `${API_ROOT}` + "/truck_parking_centroids/countyfips/48001,48003/"
          // );
          // const response = await axios.get(
          //   `${API_ROOT}` +
          //     "/truck_parking_centroids/county/" +
          //     countyList.toString() +
          //     "/"
          // );
          const dataUrl = `${API_ROOT_COMMON}/poi/getTruckParkingLocations?counties=${countyList.join(
            ","
          )}`;
          const response = await axios.get(dataUrl);
          let f = response.data.features;
          let fc = f.map((obj) => {
            obj.color = colorContextlayers.sandMines;
            return obj;
          });
          setFeatureData({ type: "FeatureCollection", features: fc });

          let numFeaturesContext = query.contextLayersNumbFeatures;
          numFeaturesContext.truckParkingLayer = fc.length;
          dispatch({
            type: "setContextLayersNumbFeatures",
            selection: numFeaturesContext,
          });
          //   const dataUrl =
          //     "https://opendata.arcgis.com/datasets/595556c111e14519be70b955e10aa8ff_0.geojson";
          //   const response = await axios.get(dataUrl);
          //   setFeatureData(response.data);

          //   let numFeaturesContext = query.contextLayersNumbFeatures;
          //   numFeaturesContext.portsLayer = response.data.features.length;
          //   dispatch({
          //     type: "setContextLayersNumbFeatures",
          //     selection: numFeaturesContext,
          //   });
        }
      } catch (e) {
        console.log(e);
      }
    };
    fetchFeatureData();
  }, [query.contextLayersVisibility.truckParkingLayer]);

  const pointToLayer = (feature, latlng) => {
    // let tooltipText = `
    // <div>
    //   <div style="text-align: center;border-bottom: 2.0px solid black;">
    //     <h5 style="margin-bottom: 0.25rem;margin-top: 0.25rem;">Ports Layer</h5>
    //   </div>
    //   <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Name:</strong> ${feature.properties.PORT_NM}</p>
    //   <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Type:</strong> ${feature.properties.PORT_TYPE}</p>
    // </div>
    // `;
    // return L.marker(latlng, {
    //   icon: customMarkerIcon,
    // }).bindTooltip(tooltipText);
    return L.marker(latlng, {
      icon: customMarkerIcon,
    });
    //  let popupText = `
    // <div>
    //   <div style="text-align: center;border-bottom: 2.0px solid black;">
    //     <h3 style="margin-bottom: 0.25rem;margin-top: 0.25rem;">Counties</h3>
    //   </div>
    //   <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Name:</strong> ${feature.properties.COUNTY}</p>
    //   <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>County Code:</strong> ${feature.properties.COUNTYNUM}</p>
    //   <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>County FIPS:</strong> ${feature.properties.COUNTY_FIP}</p>
    //   <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>District:</strong> ${feature.properties.DISTRICT}</p>
    // </div>
    // `;
    //   return layer.bindTooltip(tooltipText).bindPopup(popupText);
  };

  useEffect(() => {
    if (layerRef.current) {
      layerRef.current.leafletElement.clearLayers().addData(featureData);
    }
  }, [featureData, layerRef]);

  return (
    <LayerGroup>
      {query.contextLayersVisibility.truckParkingLayer &&
        featureData.features.length > 0 && (
          // <Overlay name="Counties" style={{ zIndex: 499 }}>
          <GeoJSON
            data={featureData}
            //onEachFeature={onEachFeature}
            pointToLayer={pointToLayer}
          >
            <Tooltip>{"Truck Parking Location"}</Tooltip>
          </GeoJSON>

          // </Overlay>
        )}
    </LayerGroup>
  );
}
