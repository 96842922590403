const turf = require('@turf/turf');

export default function filterFeatureByPolygon(polygonCoordinates, props) {
  return new Promise((resolve, reject) => {
    try {
      const filteredFeatures = props.data.features.reduce((acc, feature) => {
        const polygonFeature = turf.polygon([polygonCoordinates])
        if (feature.geometry.type === 'MultiLineString') {
          // Iterate over LineString features within MultiLineString
          feature.geometry.coordinates.forEach(lineString => {
            checkIntersection(lineString, polygonFeature, acc, feature, props)

          });
        } else if (feature.geometry.type === 'LineString') {
          checkIntersection(feature.geometry.coordinates, polygonFeature, acc, feature, props)
        }
        return acc;
      }, []);
      resolve(filteredFeatures);
    } catch (error) {
      reject(error);
    }
  });
};

function checkIntersection(lineString, polygonFeature, acc, feature, props) {
  const lineStringFeature = turf.lineString(lineString);
  if (turf.booleanIntersects(lineStringFeature, polygonFeature)) {
    if (props.statusGuide[0] === 'TX100' && !acc.some(existingFeature => existingFeature.properties.trank === feature.properties.trank)) {
      acc.push(feature);
    }
    if (props.statusGuide[0] !== 'TX100' && !acc.some(existingFeature => existingFeature.id === feature.id
      && existingFeature.properties.frm_dfo === feature.properties.frm_dfo
      && existingFeature.properties.to_dfo === feature.properties.to_dfo)) {
      acc.push(feature);
    }
  }
};
