/** By default, IE displays webpages in the Intranet zone in compatibility view To change this.
 * It causes polyfill not to work. turn off compatibility view to test the site within the same network
 * The following two lines of code must be the first line in src/index.js
 */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "./index.css";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App-new-mui";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
