import React from "react";
import { IconButton } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const clearFiltersButtonStyle = {
    backgroundColor: '#fff',
    borderRadius: "4px",
    border: '2px solid rgba(0,0,0,.2)',
    width: '34px',
    height: '34px',
    padding: '3px',
    color: 'rgb(0 0 0 / 75%)',
    backgroundClip: 'padding-box',
    '&:hover': {
        backgroundColor: '#fff',
    },
};

const ClearFilter = (props) => {
    const { data, mapType, onFilteredGeoJSON } = props;
    let additionalStyles, combinedStyles = {};

    if (mapType !== 'leaflet') {
        additionalStyles = {
            position: 'absolute',
            top: '58%',
            left: '10px',
            transform: "translateY(-50%)",
        }
        combinedStyles = { ...clearFiltersButtonStyle, ...additionalStyles };
    } else {
        combinedStyles = clearFiltersButtonStyle;
    }

    // Custom delete function
    const handleDelete = (e) => {
        onFilteredGeoJSON({
            type: 'FeatureCollection',
            features: data.features,
        });
    };

    return (
        <IconButton title="Clear polygon filter" aria-label="Clear polygon filter"
            onClick={handleDelete}
            sx={combinedStyles}>
            <DeleteForeverIcon sx={{ fontSize: '0.8em' }} />
        </IconButton>
    );
}

export default ClearFilter;
