// TrkTop100ODSelLnk.js;
import React, { useState, useContext, useEffect, useMemo } from "react";
import Box from "@mui/material/Box";

import Draggable from "react-draggable";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

import DeckGL from "@deck.gl/react";
import { GeoJsonLayer, IconLayer } from "@deck.gl/layers";
import {
  StaticMap,
  MapContext,
  NavigationControl,
  Popup,
  ScaleControl,
  WebMercatorViewport,
} from "react-map-gl";
import { MAPBOX_ACCESSTOKEN, MAPBOX_ATTRIBUTION } from "../common/constants";

import { RhinoQueryContext } from "../map/RhinoQueryContext";

import MaterialTable from "@material-table/core";

import MapIcon from "@material-ui/icons/Map";
import TimelineIcon from "@material-ui/icons/Timeline";
import { Typography } from "@mui/material";
import Highcharts from "highcharts";
import HC_exporting from "highcharts/modules/exporting";
import HighchartsReact from "highcharts-react-official";

import axios from "axios";
import { API_ROOT } from "../common/constants";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function TrkTop100ODSelLnk(props) {
  const [query, dispatch] = useContext(RhinoQueryContext);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    dispatch({
      type: "setTrkTop100ODSelLnkMap",
      selection: {
        ...query.TrkTop100ODSelLnkMap,
        odVisibility: true,
      },
    });
    setOpen(!open);
  };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  // const viewport = {
  //   // height: "500px",
  //   //height: "65vh",
  //   // height: "65%",
  //   // width: "100%",
  //   //initViewport
  //   width: "100%",
  //   height: "100%",
  //   // height: "500px",
  // };
  // const [homeMapBounds, setHomeMapBounds] = useState({
  //   longitude: -99.458333,
  //   latitude: 31.243333,
  //   zoom: 4.85,
  // });
  // const [baseMap, setBaseMap] = useState("mapbox://styles/mapbox/streets-v11");

  const [top100Features, setTop100Features] = useState(null);
  useMemo(() => {
    if (query.features)
      setTop100Features(
        query.features.slice(0).sort((a, b) => a.trank - b.trank)
      );
  }, []);

  const [selectedRows, setSelectedRows] = useState([]);
  const isRoadSelected = (selectedRows, feature) => {
    return selectedRows.includes(feature.top100id);
  };

  const updateSelectedRows = (srows) => {
    dispatch({
      type: "setHighlightQuery",
      query: isRoadSelected.bind(null, srows),
    });
  };

  useMemo(() => {
    if (!query.TrkTop100ODSelLnkMap.odVisibility) setSelectedRows([]);
  }, [query.TrkTop100ODSelLnkMap.odVisibility]);

  const [todData, setTodData] = useState(null);
  useMemo(() => {
    if (!todData) {
      async function fetchTodData() {
        const [weekDayResponse, weekendDayResponse] = await Promise.all([
          axios.get(
            `${API_ROOT}/roads_tx100/fetchTodByDow?year=${2021}&dow=Average Weekday`
          ),
          axios.get(
            `${API_ROOT}/roads_tx100/fetchTodByDow?year=${2021}&dow=Average Weekend Day`
          ),
        ]);

        setTodData(weekDayResponse.data.concat(weekendDayResponse.data));
        return;
      }
      fetchTodData();
    }
  });

  function getTodData(rowData, dow) {
    let todDataTemp = [
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
    ];

    let filteredTodData = todData.filter(
      (itm) => itm.texas_100_id === rowData.trank && itm.dow === dow
    );
    // console.log("filteredTodData-check", filteredTodData, todData);
    filteredTodData.map(
      (itm) => (todDataTemp[itm.obs_start_hour] = itm.dow_dist_raw * 100)
    );

    return todDataTemp;
  }

  const hourLookups = [
    { hour: 0, hourDesc: "12:00 a.m." },
    { hour: 1, hourDesc: "1:00 a.m." },
    { hour: 2, hourDesc: "2:00 a.m." },
    { hour: 3, hourDesc: "3:00 a.m." },
    { hour: 4, hourDesc: "4:00 a.m." },
    { hour: 5, hourDesc: "5:00 a.m." },
    { hour: 6, hourDesc: "6:00 a.m." },
    { hour: 7, hourDesc: "7:00 a.m." },
    { hour: 8, hourDesc: "8:00 a.m." },
    { hour: 9, hourDesc: "9:00 a.m." },
    { hour: 10, hourDesc: "10:00 a.m." },
    { hour: 11, hourDesc: "11:00 a.m." },
    { hour: 12, hourDesc: "12:00 p.m." },
    { hour: 13, hourDesc: "1:00 p.m." },
    { hour: 14, hourDesc: "2:00 p.m." },
    { hour: 15, hourDesc: "3:00 p.m." },
    { hour: 16, hourDesc: "4:00 p.m." },
    { hour: 17, hourDesc: "5:00 p.m." },
    { hour: 18, hourDesc: "6:00 p.m." },
    { hour: 19, hourDesc: "7:00 p.m." },
    { hour: 20, hourDesc: "8:00 p.m." },
    { hour: 21, hourDesc: "9:00 p.m." },
    { hour: 22, hourDesc: "10:00 p.m." },
    { hour: 23, hourDesc: "11:00 p.m." },
  ];

  let hourDesc = [];
  hourLookups.map((itm) => hourDesc.push(itm.hourDesc));

  return (
    <div>
      <MaterialTable
        title=""
        columns={[
          { title: "Road", field: "road" },
          { title: "From", field: "from_road" },
          { title: "To", field: "to_road" },
          { title: "Truck Top 100 Ranking", field: "trank" },
        ]}
        data={top100Features}
        options={{
          exportButton: false,
          selection: false,
          // exportAllData: true,
          // exportMenu: [
          //   // {
          //   //   label: "Export PDF",
          //   //   exportFunc: (cols, datas) =>
          //   //     ExportPdf(cols, datas, "myPdfFileName"),
          //   // },
          //   // {
          //   //   label: "Export CSV",
          //   //   exportFunc: (cols, datas) =>
          //   //     ExportCsv(cols, datas, "myCsvFileName"),
          //   // },
          //   {
          //     label: "Export Table",
          //     exportFunc: (cols, data) => {
          //       setOpenExportDialog(true);
          //       setExportCols(cols);
          //       setExportData(data);
          //     },
          //   },
          // ],
          // toolbarButtonAlignment: selectedRows.length > 0 ? "left" : "right", // here is the option to change toolbar buttons' alignment
        }}
        // icons={{
        //   Export: () => (
        //     // <div className={classes.exportButtonDiv}>
        //     <SaveAlt fontSize="medium" className={classes.exportButton} />
        //     // </div>
        //   ),
        // }}
        actions={[
          (rowData) => {
            let index = selectedRows.indexOf(rowData.trank);

            return {
              icon:
                index !== -1
                  ? () => (
                      <div style={{ lineHeight: 0 }}>
                        <p style={{ fontSize: 12 }}>Hide</p>
                        <MapIcon style={{ color: "green" }} />
                      </div>
                    )
                  : () => (
                      <div style={{ lineHeight: 0 }}>
                        <p style={{ fontSize: 12 }}>Show O-D</p>
                        <MapIcon style={{ color: "red" }} />
                      </div>
                    ),
              tooltip: index !== -1 ? "Hide Map" : "Show Map",
              onClick: (event, rowData) => {
                let index = selectedRows.indexOf(rowData.trank);

                if (index === -1) {
                  setSelectedRows([rowData.trank]);
                  dispatch({
                    type: "setTrkTop100ODSelLnkMap",
                    selection: {
                      ...query.TrkTop100ODSelLnkMap,
                      odVisibility: true,
                      showLayer: true,
                      trank: rowData.trank,
                      title:
                        "# " +
                        rowData.trank +
                        " - " +
                        rowData.road +
                        " from " +
                        rowData.from_road +
                        " to " +
                        rowData.to_road,
                    },
                  });
                  setOpen(!open);
                  updateSelectedRows([rowData.top100id]);
                } else {
                  let tempSelectedRows = selectedRows.filter(
                    (item) => item !== rowData.trank
                  );
                  setSelectedRows(tempSelectedRows);
                  dispatch({
                    type: "setTrkTop100ODSelLnkMap",
                    selection: {
                      ...query.TrkTop100ODSelLnkMap,
                      odVisibility: false,
                      showLayer: true,
                      trank: rowData.trank,
                    },
                  });
                  updateSelectedRows([]);
                  setOpen(!open);
                }
              },
            };
          },
        ]}
        // actions={[
        //   {
        //     tooltip: "Remove Selected Roads",
        //     icon: "delete",
        //     onClick: (evt, data) => handleRemoveSelected(data),
        //   },
        //   {
        //     tooltip: "",
        //     icon: () => {
        //       return (
        //         <div>
        //           <TableColumnSelector columns={columns} />
        //         </div>
        //       );
        //     },
        //     onClick: () => {},
        //     isFreeAction: true,
        //   },
        //   {
        //     tooltip: "",
        //     icon: () => {
        //       return (
        //         <div>
        //           <Button variant="contained" color="secondary">
        //             Clear Selection
        //           </Button>
        //         </div>
        //       );
        //     },
        //     onClick: () => {
        //       let roadsDataTemp = roadsData.map((row) =>
        //         selectedRows.find((selected) => selected.id === row.id)
        //           ? {
        //               ...row,
        //               tableData: {
        //                 checked: false,
        //               },
        //             }
        //           : row
        //       );
        //       setRoadsData(roadsDataTemp);

        //       updateSelectedRows([]);
        //       setSelectedRows([]);
        //     },
        //     isFreeAction: false,
        //   },
        // ]}
        // onSelectionChange={(rows) => handleClick(rows)}
        detailPanel={[
          {
            icon: "timeline",
            tooltip: "Show Timeline",
            render: (rowData) => {
              // console.log("rowData-check", rowData);

              let RhinochartOptions = {
                title: {
                  text: "",
                },
                chart: {
                  type: "line",
                  margin: [60, 240, 70, 70],
                },
                legend: {
                  align: "right",
                  verticalAlign: "top",
                  layout: "vertical",
                  x: 15,
                  y: 30,
                },
                xAxis: {
                  categories: hourDesc,
                  // title: {
                  //   text:
                  //     "Congestion Reporting Year (data is for prior year)" +
                  //     "<br />" +
                  //     "2021 reporting year (2020 data) was impacted by the COVID-19 pandemic and is not reflective of a typical year.",
                  // },
                },
                yAxis: { title: { text: "Percent of Daily Trips" } },
                credits: {
                  enabled: false,
                },
              };

              let chartOptions = Object.assign({}, RhinochartOptions, {
                series: [
                  {
                    name: "Average Weekday",
                    data: getTodData(rowData.rowData, "Average Weekday"),
                  },
                  {
                    name: "Average Weekend Day",
                    data: getTodData(rowData.rowData, "Average Weekend Day"),
                  },
                ],
                title: {
                  text: rowData.rowData.road,
                },
                subtitle: {
                  text:
                    rowData.rowData.from_road +
                    " to " +
                    rowData.rowData.to_road,
                },
                legend: {
                  enabled: true,
                  layout: "vertical",
                  align: "right",
                  verticalAlign: "middle",
                  itemMarginTop: 2,
                  itemMarginBottom: 2,
                  symbolWidth: 40,
                  itemStyle: {
                    fontSize: "10px",
                  },
                },
                exporting: {
                  sourceWidth: 1000,
                  sourceHeight: 400,
                  scale: 1,
                },
              });

              return (
                <div
                  style={{
                    fontSize: 100,
                    textAlign: "center",
                    color: "white",
                    backgroundColor: "#43A047",
                  }}
                >
                  {/* {materialTableDetailPanel(rowData)} */}
                  <HighchartsReact
                    containerProps={{
                      style: {
                        width: "97%",
                      },
                    }}
                    highcharts={Highcharts}
                    options={chartOptions}
                  />
                </div>
              );
            },
          },
        ]}
      />
    </div>
  );
}
