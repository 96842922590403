import React from 'react';
import { EditControl } from "react-leaflet-draw";
import { FeatureGroup } from "react-leaflet";
import filterFeatureByPolygon from '../utils/filterFeatureByPolygon';

const DrawPolygon = (props) => {
    const { polyRef } = props;

    function onCreate(e) {
        var layer = e.layer;
        var latLngArray = layer.editing.latlngs[0][0];

        //changing it to api data coords and closing the polygon with same end coord
        let latLng2DArray = latLngArray.map(({ lat, lng }) => [lng, lat]);
        latLng2DArray = [...latLng2DArray, latLng2DArray[0]];

        filterFeatureByPolygon(latLng2DArray, props).then(filteredFeatures => {
            props.onFilteredGeoJSON({
                type: 'FeatureCollection',
                features: filteredFeatures,
            });
            polyRef.current.leafletElement.removeLayer(layer);
        }).catch(error => {
            console.error(error);
        });
    }

    return (
        <FeatureGroup>
            <EditControl
                position='topleft'
                edit={{
                    remove: false,
                    edit: false
                }}
                onCreated={onCreate}
                draw={{
                    marker: false,
                    circle: false,
                    polyline: false,
                    rectangle: false,
                    circlemarker: false
                }}
            />
        </FeatureGroup>
    );
};

export default DrawPolygon;