import React, { useState, useContext } from "react";

import { utpCategoryLookups, colorContextlayers } from "../common/constants";

import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";

//import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

import { RhinoQueryContext } from "./RhinoQueryContext";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";

import SettingsIcon from "@material-ui/icons/Settings";
import InfoIcon from "@material-ui/icons/Info";

import RoadSegmentLegend from "./RoadSegmentLegend";

// Year selection moved to "Settings/Input Tab"
// import YearSelection from "./rhinoYearSelection";

// import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

// import Icon from "@material-ui/core/Icon";

import SvgIcon from "@material-ui/core/SvgIcon";

import { ReactComponent as CargoAirportIconSVG } from "../assets/icons/flight_yellow_24dp.svg";
import { ReactComponent as OtherAirportIconSVG } from "../assets/icons/flight_white_24dp.svg";
import { ReactComponent as PortsIconSVG } from "../assets/icons/anchor_white_24dp.svg";
import { ReactComponent as UtpIconSVG1 } from "../assets/icons/construction_white_on_red_24dp.svg";
import { ReactComponent as UtpIconSVG2 } from "../assets/icons/construction_white_on_purple_24dp.svg";
import { ReactComponent as UtpIconSVG3 } from "../assets/icons/construction_white_on_green_24dp.svg";
import { ReactComponent as UtpIconSVG4 } from "../assets/icons/construction_white_on_blue_24dp.svg";
import { ReactComponent as CommercialBorderCrossingIconSVG } from "../assets/icons/green_box_24dp.svg";
import { ReactComponent as NonCommercialBorderCrossingIconSVG } from "../assets/icons/violet_box_24dp.svg";
import { ReactComponent as RailBorderCrossingIconSVG } from "../assets/icons/pink_box_24dp.svg";
import { ReactComponent as SandMinesIconSVG } from "../assets/icons/circle_black_24dp.svg";
import { ReactComponent as DisposalWellsIconSVG } from "../assets/icons/circle_black_24dp.svg";
import { ReactComponent as TruckParkingIconSVG } from "../assets/icons/local_parking_white_on_green_24dp.svg";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box margin={-1}>
          {/*<Typography>{children}</Typography>*/}
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-prevent-tab-${index}`,
    "aria-controls": `scrollable-prevent-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  roadSegmentSelector: {
    backgroundColor: theme.palette.background.paper,
  },
  wrapIcon: {
    //verticalAlign: 'middle',
    display: "flex",
    alignItems: "center",
  },
  roadwayLegendLine: {
    width: "100%",
    height: "5px",
    background: "#4d4dff",
    //float: 'left',
    marginLeft: "5px",
    marginRight: "5px",
    marginBottom: "10px",
  },

  roadwayLegendLine2: {
    width: "100%",
    height: "20px",
    //background: '#4d4dff',
    //float: 'left',
    marginLeft: "5px",
    marginRight: "5px",
    marginBottom: "10px",
  },
  roadwayLegendLine1Text: {
    marginBottom: "2px",
    paddingBottom: "2px",
    height: "20px",
  },
  roadwayLegendLine2Text: {
    //width: "100%",
    //height: '20px',
    //background: '#4d4dff',
    //float: 'left',
    //marginLeft: '5px',
    //marginRight: '5px',
    marginTop: "0.1px",
    paddingTop: "0.1px",
    marginLeft: "25px",
  },
  roadwayLegendDiv: {
    borderBottomStyle: "solid",
    borderWidth: "1px",
    borderColor: "grey",
    marginBottom: "5px",
  },
  tabHeader: {
    borderBottomStyle: "solid",
    borderWidth: "1px",
    borderColor: "grey",
    marginBottom: "5px",
    display: "block",
  },
  icon1: {
    backgroundColor: "#7F0000",
    width: "1.25rem",
    height: "1.25rem",
    display: "block",
    // left: "-0.25rem",
    // top: "-0.25rem",
    // position: "relative",
    // borderRadius: "3rem 3rem 0",
    // transform: "rotate(45deg)",
    // border: "1px solid #FFFFFF",
  },
  icon2: {
    backgroundColor: "#6f00ff",
    width: "1.25rem",
    height: "1.25rem",
    display: "block",
    // left: "-0.25rem",
    // top: "-0.25rem",
    position: "relative",
    // borderRadius: "3rem 3rem 0",
    // transform: "rotate(45deg)",
    // border: "1px solid #FFFFFF",
  },
  icon3: {
    backgroundColor: "#009a00",
    width: "1.25rem",
    height: "1.25rem",
    display: "block",
    // left: "-0.25rem",
    // top: "-0.25rem",
    position: "relative",
    // borderRadius: "3rem 3rem 0",
    // transform: "rotate(45deg)",
    // border: "1px solid #FFFFFF",
  },
  icon4: {
    backgroundColor: "#4040ff",
    width: "1.25rem",
    height: "1.25rem",
    display: "block",
    // left: "-0.25rem",
    // top: "-0.25rem",
    position: "relative",
    // borderRadius: "3rem 3rem 0",
    // transform: "rotate(45deg)",
    // border: "1px solid #FFFFFF",
  },
  iconCommercialBorderCrossing: {
    backgroundColor: "#11b461",
    width: "0.75rem",
    height: "0.755rem",
    display: "block",
    // left: "-0.25rem",
    // top: "-0.25rem",
    position: "relative",
    // borderRadius: "3rem 3rem 0",
    // transform: "rotate(45deg)",
    // border: "1px solid #FFFFFF",
  },
  iconNonCommercialBorderCrossing: {
    backgroundColor: "#8565c4",
    width: "0.75rem",
    height: "0.755rem",
    display: "block",
    // left: "-0.25rem",
    // top: "-0.25rem",
    position: "relative",
    // borderRadius: "3rem 3rem 0",
    // transform: "rotate(45deg)",
    // border: "1px solid #FFFFFF",
  },
  iconRailBorderCrossing: {
    backgroundColor: "#c465a4",
    width: "0.75rem",
    height: "0.755rem",
    display: "block",
    // left: "-0.25rem",
    // top: "-0.25rem",
    position: "relative",
    // borderRadius: "3rem 3rem 0",
    // transform: "rotate(45deg)",
    // border: "1px solid #FFFFFF",
  },
  iconPorts: {
    backgroundColor: "#0000ff",
    width: "1.25rem",
    height: "1.25rem",
    display: "block",
    // left: "-0.25rem",
    // top: "-0.25rem",
    position: "relative",
    // borderRadius: "3rem 3rem 0",
    // transform: "rotate(45deg)",
    // border: "1px solid #FFFFFF",
  },
  iconCargoAirports: {
    backgroundColor: "#000000",
    width: "1.25rem",
    height: "1.25rem",
    display: "block",
    //left: "-0.25rem",
    //top: "-0.25rem",
    position: "relative",
    // borderRadius: "3rem 3rem 0",
    // transform: "rotate(45deg)",
    // border: "1px solid #FFFFFF",
  },
  iconAirports: {
    backgroundColor: "#A9A9A9",
    width: "1.25rem",
    height: "1.25rem",
    display: "block",
    //left: "-0.25rem",
    //top: "-0.25rem",
    position: "relative",
    // borderRadius: "3rem 3rem 0",
    // transform: "rotate(45deg)",
    // border: "1px solid #FFFFFF",
  },
  container: {
    width: "475px",
  },
  titleRow: {
    width: "100%",
    // height: "20px",
    marginTop: "10px",
  },
  titleLine: {
    marginTop: "5px",
    flexGrow: 1,
    textAlign: "center",
  },
}));

export default function MapTabs(props) {
  const classes = useStyles();
  const [query] = useContext(RhinoQueryContext);
  const { utpMapLayersCount } = props;

  const [tabValue, settabValue] = React.useState(0);
  const [expanded, setExpanded] = useState(false);
  const handleChangeAccordion = (event) => {
    setExpanded(!expanded);
  };

  const handleClickTab = (Event) => {
    if (expanded === true) {
      Event.stopPropagation();
    } else {
      setExpanded(true);
    }
  };

  const handleChangeTabs = (event, newValue) => {
    event.stopPropagation();
    settabValue(newValue);
    setExpanded(true);
  };

  // const iconAirports = (
  //   <span className={classes.iconAirports}>
  //     <Icon fontSize="small" style={{ color: "#FFFFFF" }}>
  //       {"flight"}
  //     </Icon>
  //   </span>

  //   // <Icon fontSize="medium" style={{ color: "#7F0000" }}>
  //   //   {"construction"}
  //   // </Icon>
  // );

  // const iconCargoAirports = (
  //   <span className={classes.iconCargoAirports}>
  //     <Icon fontSize="small" style={{ color: "#FFFF00" }}>
  //       {"flight"}
  //     </Icon>
  //   </span>

  //   // <Icon fontSize="medium" style={{ color: "#7F0000" }}>
  //   //   {"construction"}
  //   // </Icon>
  // );

  // const iconMarkupPorts = (
  //   <span className={classes.iconPorts}>
  //     <Icon fontSize="small" style={{ color: "#ffffff" }}>
  //       {"anchor"}
  //     </Icon>
  //   </span>

  //   // <Icon fontSize="medium" style={{ color: "#7F0000" }}>
  //   //   {"construction"}
  //   // </Icon>
  // );

  // const iconMarkupRailBorderCrossing = (
  //   <span className={classes.iconRailBorderCrossing}>
  //     <Icon fontSize="small" style={{ color: "#ffffff" }}>
  //       {""}
  //     </Icon>
  //   </span>

  //   // <Icon fontSize="medium" style={{ color: "#7F0000" }}>
  //   //   {"construction"}
  //   // </Icon>
  // );

  // const iconMarkupNonCommercialBorderCrossing = (
  //   <span className={classes.iconNonCommercialBorderCrossing}>
  //     <Icon fontSize="small" style={{ color: "#ffffff" }}>
  //       {""}
  //     </Icon>
  //   </span>

  //   // <Icon fontSize="medium" style={{ color: "#7F0000" }}>
  //   //   {"construction"}
  //   // </Icon>
  // );

  // const iconMarkupCommercialBorderCrossing = (
  //   <span className={classes.iconCommercialBorderCrossing}>
  //     <Icon fontSize="small" style={{ color: "#ffffff" }}>
  //       {""}
  //     </Icon>
  //   </span>

  //   // <Icon fontSize="medium" style={{ color: "#7F0000" }}>
  //   //   {"construction"}
  //   // </Icon>
  // );

  // const iconMarkup1 = (
  //   <span className={classes.icon1}>
  //     <Icon fontSize="small" style={{ color: "#ffffff" }}>
  //       {"construction"}
  //     </Icon>
  //   </span>

  //   // <Icon fontSize="medium" style={{ color: "#7F0000" }}>
  //   //   {"construction"}
  //   // </Icon>
  // );
  // const iconMarkup2 = (
  //   <span className={classes.icon2}>
  //     <Icon fontSize="small" style={{ color: "#ffffff" }}>
  //       {"construction"}
  //     </Icon>
  //   </span>
  //   // <Icon fontSize="medium" style={{ color: "#6f00ff" }}>
  //   //   {"construction"}
  //   // </Icon>
  // );
  // const iconMarkup3 = (
  //   <span className={classes.icon3}>
  //     <Icon fontSize="small" style={{ color: "#ffffff" }}>
  //       {"construction"}
  //     </Icon>
  //   </span>
  //   // <Icon fontSize="medium" style={{ color: "#009a00" }}>
  //   //   {"construction"}
  //   // </Icon>
  // );
  // const iconMarkup4 = (
  //   <span className={classes.icon4}>
  //     <Icon fontSize="small" style={{ color: "#ffffff" }}>
  //       {"construction"}
  //     </Icon>
  //   </span>
  //   // <Icon fontSize="medium" style={{ color: "#4040ff" }}>
  //   //   {"construction"}
  //   // </Icon>
  // );

  const addLegendUtp = (props) => {
    let checkedVariable = utpCategoryLookups.find(
      (x) => x["category"] === props.name
    )["checkedVariable"];
    let checked = query.utpMapLayersVisibility[checkedVariable]; //utpLayersChecked[checkedVariable];
    // let markerIcon = utpCategoryLookups.find(
    //   (x) => x["category"] === props.name
    // )["markerIcon"];
    let nameDesc = utpCategoryLookups.find((x) => x["category"] === props.name)[
      "nameDesc"
    ];
    // let numrecs = utpMapLayersCount[checkedVariable];
    let numrecs = query.utpMapLayersCount[checkedVariable];
    //if (utpData.data[props.name] !== undefined) numrecs = utpData.data[props.name].features.length
    let newMarkerIcon =
      props.name === "category0"
        ? UtpIconSVG1
        : props.name === "category1"
        ? UtpIconSVG2
        : props.name === "category2"
        ? UtpIconSVG3
        : UtpIconSVG4;
    if (checked === true) {
      return (
        <Typography
          variant="subtitle2"
          className={classes.wrapIcon}
          key={props.name + "-legend"}
        >
          {/* <img src={markerIcon} className={classes.legendIcon}></img>{" "}
                {nameDesc} ({numrecs.toLocaleString()} features) */}
          {/* <Icon fontSize="small" style={{ color: "#7F0000" }}>
            {"construction"}
          </Icon> */}
          {/* {newMarkerIcon} */}
          <SvgIcon
            component={newMarkerIcon}
            //viewBox="0 0 600 476.6"
            viewBox="0 0 32 32"
            htmlColor="#ffffff"
          />
          {nameDesc} ({numrecs.toLocaleString()} features)
        </Typography>
      );
    }
    return "";
  };

  return (
    <div className={classes.container} style={{ fontSize: "12.5px" }}>
      <Accordion onChange={handleChangeAccordion} defaultExpanded>
        {/*<AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordSummary}>*/}
        <AccordionSummary
          expandIcon={<ExpandLessIcon />}
          className={classes.accordSummary}
        >
          {" "}
          <div
            className={classes.titleRow}
            style={{ paddingTop: "-5px", marginTop: "-10px" }}
          >
            {props.type !== "tx100" && (
              <div>
                <Typography
                  // variant="subtitle1"
                  variant="h6"
                  className={classes.titleLine}
                  // style={{ fontWeight: 600 }}
                  display="inline"
                >
                  Layer: {props.statusGuide[1]}
                </Typography>
                <br />
                <Typography
                  // variant="subtitle1"
                  variant="h6"
                  className={classes.titleLine}
                  // style={{ fontWeight: 600 }}
                  display="inline"
                >
                  Reporting Year:{" "}
                  {query.yearSelection +
                    " (" +
                    (query.yearSelection - 1) +
                    " data)"}
                </Typography>
                <br />
                {query.yearSelection === 2021 && (
                  // <Typography
                  //   // variant="subtitle1"
                  //   // variant="h6"
                  //   className={classes.titleLine}
                  //   // style={{ fontWeight: 600 }}
                  //   display="inline"
                  // >
                  //   Note: 2021 reporting year (2020 data) was impacted by the
                  //   COVID-19 pandemic and is not reflective of a typical year.
                  // </Typography>
                  <Typography variant="subtitle1">
                    <strong>Note:</strong> This year of data was impacted by the
                    COVID-19 pandemic and is not reflective of a typical year.
                  </Typography>
                )}
              </div>
            )}
            {props.type === "tx100" && (
              <div>
                <Typography
                  variant="h6"
                  className={classes.titleLine}
                  //style={{ fontWeight: 600 }}
                  display="inline"
                >
                  Layer: Texas 100 - {props.statusGuide[1]}
                </Typography>
                <br />
                <Typography
                  // variant="subtitle1"
                  variant="h6"
                  className={classes.titleLine}
                  // style={{ fontWeight: 600 }}
                  display="inline"
                >
                  Reporting Year:{" "}
                  {query.yearSelection +
                    " (" +
                    (query.yearSelection - 1) +
                    " data)"}
                </Typography>
                <br />
                {query.yearSelection === 2021 && (
                  // <Typography
                  //   // variant="subtitle1"
                  //   // variant="h6"
                  //   className={classes.titleLine}
                  //   // style={{ fontWeight: 600 }}
                  //   display="inline"
                  // >
                  //   Note: 2021 reporting year (2020 data) was impacted by the
                  //   COVID-19 pandemic and is not reflective of a typical year.
                  // </Typography>
                  <Typography variant="subtitle1">
                    <strong>Note:</strong> This year of data was impacted by the
                    COVID-19 pandemic and is not reflective of a typical year.
                  </Typography>
                )}
              </div>
            )}
            {/* <Typography
              className={classes.secondaryHeading}
              display="block"
              variant="caption"
              style={{ paddingBottom: "0px", marginBottom: "0px" }}
            >
              Click on a road segment to add to road segment table.
            </Typography> */}
          </div>
        </AccordionSummary>
        <AccordionSummary
          // expandIcon={<ExpandLessIcon />}
          className={classes.accordSummary}
        >
          <Tabs
            value={tabValue}
            onChange={handleChangeTabs}
            //variant="scrollable"
            variant="fullWidth"
            scrollButtons="off"
            aria-label="scrollable prevent tabs example"
            style={{
              width: "100%",
              marginTop: "-25px",
            }}
            onClick={(event) => handleClickTab(event)} //event.stopPropagation()}
            TabIndicatorProps={{
              style: {
                backgroundColor: "#CC7B29",
                height: "0.5vh",
              },
            }}
          >
            <Tab
              icon={
                <InfoIcon style={{ paddingTop: "0px", marginTop: "0px" }} />
              }
              label={
                <Typography
                  className={classes.tabHeader}
                  style={{ paddingTop: "0px", marginTop: "0px" }}
                >
                  Quick Info/Hover
                </Typography>
              }
              aria-label="info"
              {...a11yProps(0)}
              onClick={(event) => handleClickTab(event)}
              style={{ paddingTop: "0px", marginTop: "0px" }}
            />
            <Tab
              icon={
                <SettingsIcon style={{ paddingTop: "0px", marginTop: "0px" }} />
              }
              label={
                <Typography
                  className={classes.tabHeader}
                  style={{ paddingTop: "0px", marginTop: "0px" }}
                >
                  Legend
                </Typography>
              }
              aria-label="settings"
              {...a11yProps(1)}
              onClick={(event) => handleClickTab(event)}
              style={{ paddingTop: "0px", marginTop: "0px" }}
            />
          </Tabs>
        </AccordionSummary>
        <AccordionDetails className={classes.accordDetails}>
          <TabPanel value={tabValue} index={0}>
            <div children={props.hoverInfo} />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            {/* // Year selection moved to "Settings/Input Tab"
            {props.type !== "tx100" && (
              <div style={{ backgroundColor: "white" }}>
                <YearSelection />
              </div>
            )} */}
            {/*<div children={mapLegendSettingsInfo} />*/}
            <RoadSegmentLegend
              type={props.type}
              numFeatures={props.numFeatures}
              minValue={props.minValue}
              maxValue={props.maxValue}
              mode={props.mode}
            />
            {/*numFeatures={rhino.features.length.toLocaleString()} minValue={minTrkCatValue} maxValue={maxTrkCatValue} />*/}
            {/* <Typography variant="subtitle1" className={classes.legendContainer}>
              {["category0", "category1", "category2", "category3"].map(
                (name) => {
                  return addLegendUtp({ name: name });
                }
              )}
            </Typography> */}
            {["category0", "category1", "category2", "category3"].map(
              (name) => {
                return addLegendUtp({ name: name });
              }
            )}
            {query.contextLayersVisibility.odessaDistSandMines && (
              <Typography variant="subtitle1" className={classes.wrapIcon}>
                {/* <FiberManualRecordIcon
                  style={{ color: colorContextlayers.sandMines }}
                /> */}
                <SvgIcon
                  component={SandMinesIconSVG}
                  //viewBox="0 0 600 476.6"
                  viewBox="0 0 32 32"
                  htmlColor={colorContextlayers.sandMines}
                  //color="#ff7800"
                />
                {"Odessa District Sand Mines"} (
                {query.contextLayersNumbFeatures.odessaDistSandMines} features)
              </Typography>
            )}
            {query.contextLayersVisibility.disposalWells && (
              <Typography variant="subtitle1" className={classes.wrapIcon}>
                {/* <FiberManualRecordIcon
                  style={{ color: colorContextlayers.disposalWells }}
                /> */}
                <SvgIcon
                  component={DisposalWellsIconSVG}
                  //viewBox="0 0 600 476.6"
                  viewBox="0 0 32 32"
                  htmlColor={colorContextlayers.disposalWells}
                  //color="#ff7800"
                />
                {"Disposal Wells"} (
                {query.contextLayersNumbFeatures.disposalWells} features)
              </Typography>
            )}
            {query.contextLayersVisibility.commercialBorderCrossings && (
              <Typography variant="subtitle1" className={classes.wrapIcon}>
                {/* {iconMarkupCommercialBorderCrossing} */}
                <SvgIcon
                  component={CommercialBorderCrossingIconSVG}
                  //viewBox="0 0 600 476.6"
                  viewBox="0 0 32 32"
                  //htmlColor="none"
                  color="none"
                />
                {"Border Crossings - Commercial"} (
                {query.contextLayersNumbFeatures.commercialBorderCrossings}{" "}
                features)
              </Typography>
            )}
            {query.contextLayersVisibility.nonCommercialBorderCrossings && (
              <Typography variant="subtitle1" className={classes.wrapIcon}>
                {/* {iconMarkupNonCommercialBorderCrossing} */}
                <SvgIcon
                  component={NonCommercialBorderCrossingIconSVG}
                  //viewBox="0 0 600 476.6"
                  viewBox="0 0 32 32"
                  //htmlColor="none"
                  color="none"
                />
                {"Border Crossings - Non-Commercial"} (
                {query.contextLayersNumbFeatures.nonCommercialBorderCrossings}{" "}
                features)
              </Typography>
            )}{" "}
            {query.contextLayersVisibility.railBorderCrossings && (
              <Typography variant="subtitle1" className={classes.wrapIcon}>
                {/* {iconMarkupRailBorderCrossing} */}
                <SvgIcon
                  component={RailBorderCrossingIconSVG}
                  //viewBox="0 0 600 476.6"
                  viewBox="0 0 32 32"
                  //htmlColor="none"
                  color="none"
                />
                {"Border Crossings - Rail Only"} (
                {query.contextLayersNumbFeatures.railBorderCrossings} features)
              </Typography>
            )}{" "}
            {query.contextLayersVisibility.portsLayer && (
              <div>
                <Typography
                  variant="subtitle1"
                  className={classes.roadwayLegendLine1Text}
                >
                  {/* {iconMarkupPorts} */}
                  <SvgIcon
                    component={PortsIconSVG}
                    //viewBox="0 0 600 476.6"
                    viewBox="0 0 32 32"
                    htmlColor="#ffffff"
                  />
                  {/* {"Maritime Ports, Inland ports, International Ports of Entry"} ( */}
                  {"Maritime Ports, Inland ports"}
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.roadwayLegendLine2Text}
                >
                  {/* <Typography variant="subtitle1" className={classes.wrapIcon}> */}
                  {"International Ports of Entry"} (
                  {query.contextLayersNumbFeatures.portsLayer} features)
                </Typography>
              </div>
            )}{" "}
            {query.contextLayersVisibility.cargoAirports && (
              <Typography variant="subtitle1" className={classes.wrapIcon}>
                {/* {iconCargoAirports} */}
                <SvgIcon
                  component={CargoAirportIconSVG}
                  //viewBox="0 0 600 476.6"
                  viewBox="0 0 32 32"
                  htmlColor="#FFFF00"
                />
                {"Cargo Airports"} (
                {query.contextLayersNumbFeatures.cargoAirports} features)
              </Typography>
            )}{" "}
            {query.contextLayersVisibility.airports && (
              <Typography variant="subtitle1" className={classes.wrapIcon}>
                {/* {iconAirports} */}
                <SvgIcon
                  component={OtherAirportIconSVG}
                  //viewBox="0 0 600 476.6"
                  viewBox="0 0 32 32"
                  htmlColor="#FFFFFF"
                />
                {"Other Major Airports"} (
                {query.contextLayersNumbFeatures.airports} features)
              </Typography>
            )}{" "}
            {query.contextLayersVisibility.truckParkingLayer && (
              <Typography variant="subtitle1" className={classes.wrapIcon}>
                {/* {iconAirports} */}
                <SvgIcon
                  component={TruckParkingIconSVG}
                  //viewBox="0 0 600 476.6"
                  viewBox="0 0 32 32"
                  htmlColor="#FFFFFF"
                />
                {"Truck Parking"} (
                {query.contextLayersNumbFeatures.truckParkingLayer} features)
              </Typography>
            )}{" "}
          </TabPanel>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
