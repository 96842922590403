// import rgbToHex from "../utils/rgbtohex";
// import { scaleLinear } from "d3-scale";
// import { interpolateTurbo } from "d3-scale-chromatic";
// import { color as colorF } from "d3-color";

export default function normalizeRoadSegments(
  databaseVariables,
  roadSegs,
  roadSelection
) {
  const sumByName = (data, name) => {
    return data.reduce(function(a, b) {
      return a + b[name];
    }, 0);
  };

  const sumNames = (data, totalNames) => {
    let total = {};

    totalNames.forEach((name) => {
      total[name] = sumByName(data, name);
    });
    return total;
  };

  const weightedSumByName = (data, name, weightString) => {
    return data.reduce(function(a, b) {
      return a + b[name] * b[weightString];
    }, 0);
  };

  const weightedSumByNames = (data, totalNames, weightString) => {
    let total = {};

    totalNames.forEach((name) => {
      total[name] = weightedSumByName(data, name, weightString);
    });
    return total;
  };

  //Calculate sums for corridor
  let totalNames = [];
  databaseVariables
    .filter((obj) => obj.condition === "sum")
    .map((slt) => totalNames.push([slt.variable]));
  let statsTotals = sumNames(roadSegs, totalNames);

  // Calculate weighted values
  let weightValues = [
    "trkttivmt",
    "trkttivmt1",
    "trkttivmt2",
    "ttivmt",
    "ttivmt1",
    "ttivmt2",
  ];
  let weightValuesTotals = sumNames(roadSegs, weightValues);

  for (var jjj = weightValues.length - 1; jjj >= 0; jjj--) {
    totalNames = [];
    databaseVariables
      .filter(
        (obj) =>
          obj.condition === "normalized" &&
          obj.normalizedBy === weightValues[jjj]
      )
      .map((slt) => totalNames.push([slt.variable]));

    let statsTotalsTemp = weightedSumByNames(
      roadSegs,
      totalNames,
      weightValues[jjj]
    );

    for (var iiii = totalNames.length - 1; iiii >= 0; iiii--) {
      statsTotals[totalNames[iiii][0]] =
        statsTotalsTemp[totalNames[iiii][0]] /
        weightValuesTotals[weightValues[jjj]];
    }
  }

  return {
    ria_rte_id: roadSelection.ria_rte_id,
    frm_dfo: roadSelection.frm_dfo,
    to_dfo: roadSelection.to_dfo,
    id: roadSelection.id,
    trkvolper: (statsTotals.trkaadt / statsTotals.aadt) * 100,
    ...statsTotals,
  };
}
