import React, { useState } from "react";
import Papa from "papaparse";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Button, TextField, Alert } from "@mui/material";

// Allowed extensions for input file
const allowedExtensions = ["csv"];

// expected CSV file headers
const expectedHeaders = ["id", "ria_rte_id", "frm_dfo", "to_dfo", "year"];

const FileUpload = (props) => {
  const {
    setStatusGuide,
    setMapType,
    setStatsLayer,
    setGeoRoute,
    setSegmentsTable,
    setSegmentSelection,
    setFile,
    setYear,
    handleCloseCustom,
  } = props;

  const [error, setError] = useState("");

  const [chosenfile, setChosenFile] = useState("");

  const handleFileChange = (e) => {
    setError("");

    // Check if user has entered the file
    if (e.target.files.length) {
      const inputFile = e.target.files[0];

      // If file uploaded is not a csv set error
      const fileExtension = inputFile?.type.split("/")[1];
      if (!allowedExtensions.includes(fileExtension)) {
        setError("Please input a csv file");
        return;
      }
      const fileSizeInBytes = new Blob([inputFile]).size;
      if (fileSizeInBytes > 1024 * 1024 * 20) {
        setError("File larger than 20MB.");
        return;
      }
      // If input type is correct set the state
      setChosenFile(inputFile);
    }
  };
  const uploadCSV = () => {
    if (!chosenfile) return setError("Enter a valid file");
    Papa.parse(chosenfile, {
      header: true,
      complete: function(results) {
        const allHeadersPresent = expectedHeaders.every(header => results.meta.fields.includes(header));
        if (!allHeadersPresent){
          setError("Expected headers " + expectedHeaders);
          return;
        } else if(results.data[0].year) {
          setStatusGuide(["Custom", "CSV"]);
          setMapType("leaflet");
          setStatsLayer("statewide");
          setGeoRoute("/upload_csv");
          setSegmentsTable("roads");
          setSegmentSelection("all");
          setFile({file:chosenfile,name:chosenfile.name, lastModified:chosenfile.lastModified})
          handleCloseCustom(true);
          setYear(results.data[0].year);
        }
      },
    });
    
  };

  return (
    <div className="file-upload" style={{ marginLeft: "3rem" }}>
      <TextField
        onChange={handleFileChange}
        id="csvInput"
        name="file"
        type="File"
        size="small"
      />

      <Button
        aria-label="upload csv"
        size="small"
        variant="contained"
        onClick={uploadCSV}
        endIcon={<FileUploadIcon />}
      >
        Upload CSV
      </Button>

      {error.length > 0 && (
        <Alert
          sx={{ position: "absolute" }}
          severity="error"
          onClose={() => setError(false)}
        >
          {error}
        </Alert>
      )}
    </div>
  );
};

export default FileUpload;
