import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { GeoJSON, LayerGroup, Pane } from "react-leaflet";
import { RhinoQueryContext } from "../RhinoQueryContext";
import { API_ROOT, COLOR_RHINO } from "../../common/constants";
import { makeStyles } from "@material-ui/core/styles";

import { LineLayer, GeoJsonLayer } from "@deck.gl/layers";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
  },
  legendContainer: {
    background: "white",
    padding: "5px",
  },
  legendIcon: {
    //height: "5%",
    //width: "auto",
    //border: "1px",
    marginRight: "5px",
  },
  wrapIcon: {
    //verticalAlign: 'middle',
    display: "flex",
    alignItems: "center",
  },
  roadwayLegendLine: {
    width: "25px",
    height: "5px",
    background: "#4d4dff",
    //float: 'left',
    marginRight: "5px",
  },
}));

//props.route

export default function CountyBoundaryLayer(props) {
  const [query, dispatch] = useContext(RhinoQueryContext);
  const classes = useStyles();
  //const { utpData, utpCategoryLookups } = props;
  const { countyList, layerRef, mapType } = props;

  const [countyBoundaries, setCountyBoundaries] = useState({
    type: "FeatureCollection",
    features: [],
  });

  //useEffect(() => {
  //    const fetchCountyBoundaries = async () => {
  //        try {
  //            if (props.counties.length > 0) {
  //                const dataUrl = `${API_ROOT}/counties/${props.counties.join(
  //                    ","
  //                )}/boundary/`;
  //                const response = await axios.get(dataUrl);
  //                setCountyBoundaries(response.data);
  //                /* console.log(response.data);*/
  //            }
  //        } catch (e) {
  //            console.log(e);
  //        }
  //    };
  //    fetchCountyBoundaries();
  //}, [props.counties]);

  useEffect(() => {
    const fetchCountyBoundaries = async () => {
      try {
        //http://localhost:5000/api/tcat_boundaries/findCountyBoundariesByCountyNumber/10,11,23
        // const dataUrlCnty = `${API_ROOT}/tcat_boundaries/findCountyBoundariesByCountyNumber/${countyList.join(
        //   ","
        // )}`;
        // const responseCnty = await axios.get(dataUrlCnty);
        // setCountyBoundaries(responseCnty.data.jsonb_build_object);
        // const dataUrl = `${API_ROOT}/boundaries/findByCounty?counties=${countyList.join(
        //   ","
        // )}`;
        const dataUrl =
          "https://services.arcgis.com/KTcxiTD9dsQw4r7Z/arcgis/rest/services/Texas_County_Boundaries_Detailed/FeatureServer/0/query?outFields=*&where=1%3D1&f=geojson";
        const responseCnty = await axios.get(dataUrl);
        setCountyBoundaries(responseCnty.data);
      } catch (e) {
        console.log(e);
      }
    };
    fetchCountyBoundaries();
  }, [countyList]);
  useEffect(() => {
    if (layerRef.current) {
      layerRef.current.leafletElement.clearLayers().addData(countyBoundaries);
    }
  }, [countyList]);

  if (mapType === "leaflet") {
    const onEachFeature = (feature, layer) => {
      // let tooltipText = `
      // <div>
      //   <p><strong>${feature.properties.cnty_nm} County</strong></p>
      //   <p>FIPS: ${feature.properties.cnty_fips}</p>
      // </div>
      // `;
      let tooltipText = `
    <div>
      <div style="text-align: center;border-bottom: 2.0px solid black;">
        <h5 style="margin-bottom: 0.25rem;margin-top: 0.25rem;">Counties Layer</h5>
      </div>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>${feature.properties.CNTY_NM} County</strong></p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>County Code:</strong> ${feature.properties.CNTY_NBR}</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>County FIPS:</strong> ${feature.properties.CNTY_FIPS}</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>TxDOT District:</strong> ${feature.properties.DIST_NM}</p>
    </div>
    `;
      return layer.bindTooltip(tooltipText);
      //  let popupText = `
      // <div>
      //   <div style="text-align: center;border-bottom: 2.0px solid black;">
      //     <h3 style="margin-bottom: 0.25rem;margin-top: 0.25rem;">Counties</h3>
      //   </div>
      //   <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Name:</strong> ${feature.properties.COUNTY}</p>
      //   <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>County Code:</strong> ${feature.properties.COUNTYNUM}</p>
      //   <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>County FIPS:</strong> ${feature.properties.COUNTY_FIP}</p>
      //   <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>District:</strong> ${feature.properties.DISTRICT}</p>
      // </div>
      // `;
      //   return layer.bindTooltip(tooltipText).bindPopup(popupText);
    };

    return (
      <Pane style={{ zIndex: 499 }}>
        {/* <LayerGroup> */}
        {query.contextLayersVisibility.countyBoundaries &&
          countyBoundaries.features.length > 0 && (
            // <Overlay name="Counties" style={{ zIndex: 499 }}>
            <GeoJSON
              data={countyBoundaries}
              ref={layerRef}
              onEachFeature={onEachFeature}
              style={{
                color: "#ffa305",
                weight: 2,
                fillOpacity: 0.1,
              }}
            />
            // </Overlay>
          )}

        {/* </LayerGroup> */}
      </Pane>
    );
  }
  if (mapType === "deckGL") {
    return (
      <GeoJsonLayer
        id="geojson"
        data={countyBoundaries}
        // filled: true,
        // opacity: 0.01,
        pickable={true}
        pickingRadius={5}
        stroked={false}
        filled={true}
        extruded={true}
        pointType={"circle"}
        lineWidthScale={20}
        lineWidthMinPixels={1.5}
        getPointRadius={100}
        getLineWidth={3}
        getElevation={300}
        // onClick={(d, event) => {
        //   setActivePopup(true);
        //   setSelectedPoint(d);
        // }}
        // onHover={(d, event) => {
        //   if (d.object !== undefined && statusGuide[0] === "TX100")
        //     setInfo(tx100Info(d.object.properties));
        //   if (d.object !== undefined && statusGuide[0] !== "TX100")
        //     setInfo(rhinoInfo(d.object.properties));
        // }}
        autoHighlight={true}
        highlightColor={COLOR_RHINO.selected
          .match(/[0-9a-f]{2}/g)
          .map((x) => parseInt(x, 16))}
        getLineColor={"#ffa305".selected
          .match(/[0-9a-f]{2}/g)
          .map((x) => parseInt(x, 16))}
        getFillColor={"#ffa305".selected
          .match(/[0-9a-f]{2}/g)
          .map((x) => parseInt(x, 16))}
        // getFillColor={(d) => {
        //   if (
        //     selectedPoint &&
        //     d.properties.top100id === selectedPoint.object.properties.top100id
        //   )
        //     return COLOR_RHINO.selected
        //       .match(/[0-9a-f]{2}/g)
        //       .map((x) => parseInt(x, 16));

        //   return COLOR_RHINO.normal
        //     .match(/[0-9a-f]{2}/g)
        //     .map((x) => parseInt(x, 16));
        // }}
      />
    );
  }
}
