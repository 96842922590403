import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
// import Grid from "@material-ui/core/Grid";
// import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import NewReleasesOutlinedIcon from "@material-ui/icons/NewReleasesOutlined";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(10),
  },
}));

const WhatsNewDialog = (props) => {
  const classes = useStyles();
  const { openWhatsNew, setOpenWhatsNew } = props;

  const handleCloseContactUs = () => {
    setOpenWhatsNew(false);
  };

  const update13 = [
    "New Feature - Monitored roadways tab for statewide-based layers.",
  ];
  const update12 = [
    "Settings/Inputs Tab - New Feature: Created new location for tool settings, inputs, and filters",
    "New Map Type - New Feature: Deck.GL 3-D maps",
    "Charts Updates: Improved chart features",
  ];
  const update11 = [
    "Other Map Layers - New Feature: Added locations of truck parking",
    "Other Map Layers: Improved layout",
  ];
  const update10 = ["Road Segments Table Update: Improved functionality/look"];
  const update9 = [
    "Summary Table Update: Added row selection (highlights selection on map)",
  ];
  const update8 = [
    "NEW Data Update: 2020 reporting year (2019 data) added ",
    "UI Update: Improved functionality/look",
    "Summary Table Update: Improved functionality/look",
  ];
  const update7 = [
    'Feature Update: Energy sector layers in "Other Map Layers"  tab',
    "Document Update: Updated Quick Reference/Basic Help",
  ];
  const update6 = [
    "Feature Update: County layer",
    "Document Update: Updated Quick Reference/Basic Help",
  ];
  const update5 = [
    'UI Update: Addition of "Filters/Legend" and "Quick Info/Hover" collapsibles on leaflet map',
    'UI Update: Year selection moved to "Filters/Legend" collapsible',
    'New Feature: User control of road segment hues based on data categories under "Filters/Legend"',
    "New Document: What is TCAT?",
  ];
  const update4 = [
    "New Feature: Inclusion UTP projects via TxDOT Open Data Portal API",
    "New Feature: Other Map Layers Tab",
  ];
  const update3 = [
    "Bug fixes: Custom Summary Tab",
    "Feature improvements: Custom Summary Tab",
    "Data revision: display of 2019 on Texas 100 layers",
  ];
  const update2 = [
    "New Feature: Custom Summary Tab",
    "General UI improvements",
  ];
  const update1 = ["New Feature: Summary Tab", "General UI improvements"];

  return (
    <Dialog
      open={openWhatsNew}
      onClose={handleCloseContactUs}
      fullWidth
      maxWidth="md"
      //scroll="body"
    >
      <DialogTitle>What's New...</DialogTitle>
      <DialogContent dividers>
        <List
          component="div"
          aria-labelledby="nested-list-subheader"
          //   subheader={
          //     <ListSubheader component="div" id="nested-list-subheader">
          //       Nested List Items
          //     </ListSubheader>
          //   }
          className={classes.root}
        >
          <ListItem>
            <ListItemIcon>
              <NewReleasesOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="February 21, 2022 - TCAT Version 2.2" />
          </ListItem>
          {update13.map((item, index) => (
            <ListItem
              className={classes.nested}
              key={"whats-new-02212022-" + index}
            >
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <ListItemText primary={item} />
            </ListItem>
          ))}{" "}
          <ListItem>
            <ListItemIcon>
              <NewReleasesOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="December 1, 2021 - TCAT Version 2.2" />
          </ListItem>
          {update12.map((item, index) => (
            <ListItem
              className={classes.nested}
              key={"whats-new-07302021-" + index}
            >
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <ListItemText primary={item} />
            </ListItem>
          ))}{" "}
          <ListItem>
            <ListItemIcon>
              <NewReleasesOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="July 30, 2021 - TCAT Version 2.1" />
          </ListItem>
          {update11.map((item, index) => (
            <ListItem
              className={classes.nested}
              key={"whats-new-07302021-" + index}
            >
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <ListItemText primary={item} />
            </ListItem>
          ))}{" "}
          <ListItem>
            <ListItemIcon>
              <NewReleasesOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="July 15, 2021 - TCAT Version 2.1" />
          </ListItem>
          {update10.map((item, index) => (
            <ListItem
              className={classes.nested}
              key={"whats-new-07152021-" + index}
            >
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <ListItemText primary={item} />
            </ListItem>
          ))}{" "}
          <ListItem>
            <ListItemIcon>
              <NewReleasesOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="June 25, 2021 - TCAT Version 2.1" />
          </ListItem>
          {update9.map((item, index) => (
            <ListItem
              className={classes.nested}
              key={"whats-new-06252021-" + index}
            >
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <ListItemText primary={item} />
            </ListItem>
          ))}{" "}
          <ListItem>
            <ListItemIcon>
              <NewReleasesOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="June 16, 2021 - TCAT Version 2.1" />
          </ListItem>
          {update8.map((item, index) => (
            <ListItem
              className={classes.nested}
              key={"whats-new-06162021-" + index}
            >
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <ListItemText primary={item} />
            </ListItem>
          ))}{" "}
          <ListItem>
            <ListItemIcon>
              <NewReleasesOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="February 3, 2021 - TCAT Version 2.1" />
          </ListItem>
          {update7.map((item, index) => (
            <ListItem
              className={classes.nested}
              key={"whats-new-02032021-" + index}
            >
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <ListItemText primary={item} />
            </ListItem>
          ))}
          <ListItem>
            <ListItemIcon>
              <NewReleasesOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="January 19, 2021 - TCAT Version 1.4" />
          </ListItem>
          {update6.map((item, index) => (
            <ListItem className={classes.nested} key={"whats-new-6-" + index}>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <ListItemText primary={item} />
            </ListItem>
          ))}
          <ListItem>
            <ListItemIcon>
              <NewReleasesOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="January 14, 2021 - TCAT Version 1.4" />
          </ListItem>
          {update5.map((item, index) => (
            <ListItem className={classes.nested} key={"whats-new-5-" + index}>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <ListItemText primary={item} />
            </ListItem>
          ))}
          <ListItem>
            <ListItemIcon>
              <NewReleasesOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="November 30, 2020 - TCAT Version 1.4" />
          </ListItem>
          {update4.map((item, index) => (
            <ListItem className={classes.nested} key={"whats-new-4-" + index}>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <ListItemText primary={item} />
            </ListItem>
          ))}
          <ListItem>
            <ListItemIcon>
              <NewReleasesOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="November 11, 2020 - TCAT Version 1.3" />
          </ListItem>
          {update3.map((item, index) => (
            <ListItem className={classes.nested} key={"whats-new-3-" + index}>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <ListItemText primary={item} />
            </ListItem>
          ))}
          <ListItem>
            <ListItemIcon>
              <NewReleasesOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="October 20, 2020 - TCAT Version 1.3" />
          </ListItem>
          {update2.map((item, index) => (
            <ListItem className={classes.nested} key={"whats-new-2-" + index}>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <ListItemText primary={item} />
            </ListItem>
          ))}
          <ListItem>
            <ListItemIcon>
              <NewReleasesOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="July 31, 2020 - TCAT Version 1.3" />
          </ListItem>
          {update1.map((item, index) => (
            <ListItem className={classes.nested} key={"whats-new-1-" + index}>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <ListItemText primary={item} />
            </ListItem>
          ))}
          {/*<li>March 31, 2020 - TCAT Version 1.2
                                <ul>
                                    <li>New Feature: Time series information</li>
                                    <li>General UI improvements</li>
                                    <li>Minor data revisions</li>
                                </ul>
                            </li>
                            <li>November 30, 2019 - TCAT Version 1</li>*/}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseContactUs} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WhatsNewDialog;
