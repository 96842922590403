/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  // useCallback,
} from "react";
import axios from "axios";
import {
  Map,
  TileLayer,
  GeoJSON,
  LayersControl,
  ScaleControl,
  // AttributionControl,
  // Popup,
  // Tooltip,
  // Marker,
  // LayerGroup,
  withLeaflet,
  Popup,
  Marker,
  Pane,
} from "react-leaflet";

import Control from "react-leaflet-control";
import {
  API_ROOT,
  COLOR_RHINO,
  MAPBOX_ACCESSTOKEN,
  MAPBOX_ATTRIBUTION,
  // MAPBOX_URL,
  // MAPBOX_STREETS_V10_URL,
  tx100_monitored_counties,
  baseLayers,
} from "../common/constants";
import { RhinoQueryContext } from "./RhinoQueryContext";

// import LoadingOverlay from "react-loading-overlay";
// import BounceLoader from "react-spinners/BounceLoader";

import bbox from "@turf/bbox";
//import LayerMarkers from "../components/map-layer-markers";
// import { icon } from "leaflet";
//import MarkerClusterGroup from "react-leaflet-markercluster";

// import { makeStyles } from "@material-ui/core/styles";
// import Typography from "@material-ui/core/Typography";
//import Utils from "../common/utils";

import { scaleLinear } from "d3-scale";
import { interpolateTurbo } from "d3-scale-chromatic";
import { color as colorF } from "d3-color";
//d3.color(d3.interpolateTurbo

import MapTabs from "./mapTabs";

import HomeIcon from "@material-ui/icons/Home";

import SandMinesLayer from "./layers/SandMinesLayer";
import DisposalWellsLayer from "./layers/DisposalWellsLayer";
import CountyBoundaryLayer from "./layers/CountyBoundaryLayer";
import DistrictBoundaryLayer from "./layers/DistrictBoundaryLayer";
import CommercialBorderCrossingLayer from "./layers/CommercialBorderCrossingLayer";
import NonCommercialBorderCrossingLayer from "./layers/NonCommercialBorderCrossingLayer";
import RailBorderCrossingLayer from "./layers/RailBorderCrossingLayer";
import PortsLayer from "./layers/PortsLayer";
//import UTPLayer from "./layers/UTPLayer";
import UTPLayer from "./layers/UTPLayer";
import CargoAirportsLayer from "./layers/CargoAirportsLayer";
import AirportsLayer from "./layers/AirportsLayer";
import TruckParkingLayer from "./layers/TruckParkingLayer";

import { latLngBounds, latLng } from "leaflet";
import DrawPolygon from "../components/draw-polygon";
import ClearFilter from "../components/clear-filter";
import Utils from "../common/utils";

//require('react-leaflet-markercluster/dist/styles.min.css');

import PrintControlDefault from "react-leaflet-easyprint";
const PrintControl = withLeaflet(PrintControlDefault);

// const useStyles = makeStyles((theme) => ({
//   heading: {
//     fontSize: theme.typography.pxToRem(15),
//     fontWeight: theme.typography.fontWeightBold,
//   },
//   legendContainer: {
//     background: "white",
//     padding: "5px",
//   },
//   legendIcon: {
//     //height: "5%",
//     //width: "auto",
//     //border: "1px",
//     marginRight: "5px",
//   },
//   wrapIcon: {
//     //verticalAlign: 'middle',
//     display: "flex",
//     alignItems: "center",
//   },
//   roadwayLegendLine: {
//     width: "25px",
//     height: "5px",
//     background: "#4d4dff",
//     //float: 'left',
//     marginRight: "5px",
//   },
// }));

/**
 * Renders the Leaflet map with the Rhino road segments
 *
 * @param {*} props React props
 */
function RhinoLeafletMap(props) {
  // const classes = useStyles();
  /* County boundaries in GeoJSON format */

  const mapRef = useRef(null);
  const [countyBoundaries, setCountyBoundaries] = useState({
    type: "FeatureCollection",
    features: [],
  });
  /* Rhino road segments in GeoJSON format */
  const [rhino, setRhino] = useState({
    type: "FeatureCollection",
    features: [],
  });

  /*loading overlay status to display spinner*/
  // const [isLoading, setIsLoading] = useState(true);

  const [geoJsonKey, setGeoJsonKey] = useState(Date.now());

  const [info, setInfo] = useState(<h4>Hover over a road</h4>);
  /* const [rhinoColor, setRhinoColor] = useState("#ffffff"); */
  const rhinoColorContainer = useRef("#ffffff");
  const rhinoWeightContainer = useRef(3);

  /* the queries set by selecting the summary table rows */
  const [query, dispatch] = useContext(RhinoQueryContext);

  // const [mapBounds, setmapBounds] = useState();
  const [homeMapBounds, setHomeMapBounds] = useState(null);
  const [countyList, setCountyList] = useState([]);

  const { statusGuide, mapType, roadGeomFeatures, setRoadGeomFeatures } = props;

  const [filteredGeoJSON, setFilteredGeoJSON] = useState(null);
  // Function to receive the filtered GeoJSON from DrawPolygon
  const handleFilteredGeoJSON = (filteredGeoJSON) => {
    setFilteredGeoJSON(filteredGeoJSON);
  };
  useEffect(() => {
    if (filteredGeoJSON) {
      if (roadsLayerRef.current) {
        const layer = roadsLayerRef.current;
        layer.leafletElement.clearLayers().addData(filteredGeoJSON);
        dispatch({
          type: "setFeatures",
          payload: filteredGeoJSON.features,
        });
      }
    } 
  }, [filteredGeoJSON])

  //  useEffect(() => {
  //    const fetchCountyBoundaries = async () => {
  //      try {
  //        if (props.counties.length > 0) {
  //          const dataUrl = `${API_ROOT}/counties/${props.counties.join(
  //            ","
  //          )}/boundary/`;
  //          const response = await axios.get(dataUrl);
  //          setCountyBoundaries(response.data);
  //          /* console.log(response.data);*/
  //        }
  //      } catch (e) {
  //        console.log(e);
  //      }
  //    };
  //    fetchCountyBoundaries();
  //  }, [props.counties]);

  // change fetchRhinoNetwork
  // useEffect(() => {
  //   //console.log(props.layerID)
  //   const fetchRhinoNetwork = async () => {
  //     try {
  //       if (props.quads.length > 0) {
  //         setIsLoading(true);
  //         var promises;
  //         let routes = [props.route];
  //
  //         promises = routes.map(route => {
  //           const dataUrl = `${API_ROOT}`+route+'/year/'+props.year;
  //           console.log(dataUrl)
  //           return axios.get(dataUrl);
  //         });
  //         //console.log(promises)
  //         axios.all(promises).then(
  //           axios.spread((...responses) => {
  //             let f = [];
  //             responses.map(r => {
  //               f = f.concat(r.data.features);
  //             });
  //             let fc = f.map(obj => {
  //               obj.color = COLOR_RHINO.normal;
  //               return obj;
  //             });
  //             // without waiting (timeout), the geojson layer rendering
  //             // finished before the data is copied to rhino state.
  //             // this is a workaround. This should be fixed in different way.
  //             setRhino({ type: "FeatureCollection", features: fc });
  //             /*               setTimeout(function() {
  //               setRhino({ type: "FeatureCollection", features: fc });
  //             }, 500);
  //             */
  //             setGeoJsonKey(Date.now());
  //             setIsLoading(false);
  //           })
  //         );
  //       }
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   };
  //   fetchRhinoNetwork();
  //   console.log(props.quads);
  // }, [props.quads, props.year]);

  // console.log("render-check");
  useEffect(() => {
    //console.log(props.layerID)
    const fetchRhinoNetwork = async () => {
      try {
        //if (props.quads.length > 0) {
        if (props.layerID) {
          // setIsLoading(true);

          // const response = await axios.get(
          //   `${API_ROOT}` + props.route + "&year=" + props.year
          // );
          // //console.log(response);
          // //console.log(response.data);
          // let f = response.data.features;
          let f = roadGeomFeatures.features;

          let fc = f.map((obj) => {
            obj.color = COLOR_RHINO.normal;
            obj.color_base = COLOR_RHINO.normal;
            obj.weight = 4;
            obj.weight_base = 4;
            return obj;
          });
          setRhino({
            type: "FeatureCollection",
            features: fc,
          });
          dispatch({
            type: "setFeatures",
            payload: fc,
          });

          const layer = roadsLayerRef.current;
          if (layer) {
            layer.leafletElement.clearLayers().addData({
              type: "FeatureCollection",
              features: fc,
            });
          }

          setGeoJsonKey(Date.now());
          // setIsLoading(false);

          
          const mapBounds_bbox = bbox(
            { type: "FeatureCollection", features: fc }
          );
          setHomeMapBounds(
            latLngBounds(
              [mapBounds_bbox[1] * 0.999, mapBounds_bbox[0] * 1.001],
              [mapBounds_bbox[3] * 1.001, mapBounds_bbox[2] * 0.999]
            )
          );

          dispatch({
            type: "setroadSegmentCategoryIndex",
            selection: 0,
          });

          const findUnique = (array, key) => {
            var unique = array
              .map((p) => p.properties[key])
              .filter((key, index, arr) => arr.indexOf(key) === index);
            return unique;
          };
          // const response = await axios.get(process.env.PUBLIC_URL + `/TxDOT_Projects.geojson`);
          let cntyList = findUnique(fc, "co");
          //type={props.layerID === "statewide" ? "rhino" : "tx100"}
          if (statusGuide[0] === "TX100") {
            //if (props.layerID !== "statewide") {
            cntyList = tx100_monitored_counties.map((f) => {
              var nbr = f.cnty_nbr;
              return nbr;
            });
          }
          setCountyList(cntyList);
        }
      } catch (e) {
        console.log(e);
      }
    };
    fetchRhinoNetwork();

    setInfo(<h4>Hover over a road</h4>);
    // }, [props.layerID, props.year]);
  }, [roadGeomFeatures]);

  /* Highlights selected Rhino */
  useEffect(() => {
    /* I am cloning the rhino data.
     * Is it the best way to refresh the map
     * with highlights?
     */
    // let newRhino = { ...rhino };
    const isSelected = query.highlightQuery;
    if (typeof isSelected === "function") {
      if (rhino.features.filter((f) => isSelected(f.properties)).length > 0) {
        rhino.features
          .filter((f) => isSelected(f.properties))
          .forEach((f) => {
            // f.color = COLOR_RHINO.selected;
            // f.weight = 5;
            f.color = f.color_base;
            f.weight = f.weight_base;
          });
        rhino.features
          .filter((f) => !isSelected(f.properties))
          //.forEach(f => (f.color = COLOR_RHINO.normal));
          //.forEach(f => (f.color = setColor(f)));
          .forEach((f) => {
            // f.color = f.color_base;
            // f.weight = f.weight_base;
            f.color = COLOR_RHINO.notSelected;
            f.weight = 2;
          });
      } else {
        rhino.features
          // .filter((f) => isSelected(f.properties))
          .forEach((f) => {
            // f.color = COLOR_RHINO.selected;
            f.color = f.color_base;
            f.weight = f.weight_base;
          });
      }

      // setTimeout(() => setRhino(newRhino), 100); .color_tdly color_tdly2
      let selectedFeatures = rhino.features.filter((f) => isSelected(f.properties));
      if (selectedFeatures.length > 0) {
        const mapBounds_bbox = bbox(
          {
            type: "FeatureCollection",
            features: selectedFeatures,
          }
          //{ rhino }
        );
        const selectedFeaturesBounds =  latLngBounds(
          [mapBounds_bbox[1] * 0.999, mapBounds_bbox[0] * 1.001],
          [mapBounds_bbox[3] * 1.001, mapBounds_bbox[2] * 0.999]
        )
        mapRef.current.leafletElement.flyToBounds([selectedFeaturesBounds]);
      } else {
        if (homeMapBounds)
          mapRef.current.leafletElement.flyToBounds([homeMapBounds]);
      }
      setGeoJsonKey(Date.now());
    }
  }, [query.highlightQuery]);

  const [minTrkCatValue, setminTrkCatValue] = useState(0);
  const [maxTrkCatValue, setmaxTrkCatValue] = useState(1000000);
  useEffect(() => {
    /* I am cloning the rhino data.
     * Is it the best way to refresh the map
     * with highlights?
     */
    // let newRhino = { ...rhino };
    const isSelected = query.highlightQuery;
    if (typeof isSelected === "function") {
      let rdSeqCat = "none";
      //if (query.roadSegmentCategory === "none") return f.color_normal
      if (query.roadSegmentCategory === "tdly") rdSeqCat = "trkdelay";
      if (query.roadSegmentCategory === "tpti95") rdSeqCat = "trkpti95";
      if (query.roadSegmentCategory === "tpti80") rdSeqCat = "trkpti80";
      if (query.roadSegmentCategory === "ttti") rdSeqCat = "trktti";
      if (query.roadSegmentCategory === "trefspd") rdSeqCat = "trkrefspee";
      if (query.roadSegmentCategory === "tcongspd") rdSeqCat = "trkspeed";
      if (query.roadSegmentCategory === "taadt") rdSeqCat = "tottrk";
      if (query.roadSegmentCategory === "tvmt") rdSeqCat = "trkttivmt";

      function rgbToHex(r, g, b) {
        return (
          "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)
        );
      }

      function colorScale(height, minValue, maxValue) {
        //const color = d3.color(d3.interpolateRdYlGn(linearScale(height)));
        //const color = d3.color(d3.interpolatePuRd(linearScale(height)));
        //const color = d3.color(d3.interpolateOrRd(linearScale(height)));

        const linearScale = scaleLinear()
          .domain([minValue, maxValue])
          .range([0, 1]);

        //const color = colorF(interpolateTurbo(linearScale(height)));
        let color = colorF(interpolateTurbo(linearScale(height)));
        if (height === null)
          color = colorF(interpolateTurbo(linearScale(minValue)));
        return rgbToHex(color.r, color.g, color.b);
      }
      function colorScaleInv(height, minValue, maxValue) {
        //const color = d3.color(d3.interpolateRdYlGn(linearScale(height)));
        //const color = d3.color(d3.interpolatePuRd(linearScale(height)));
        //const color = d3.color(d3.interpolateOrRd(linearScale(height)));

        const linearScale = scaleLinear()
          .domain([minValue, maxValue])
          .range([1, 0]);

        //const color = colorF(interpolateTurbo(linearScale(height)));
        let color = colorF(interpolateTurbo(linearScale(height)));
        if (height === null)
          color = colorF(interpolateTurbo(linearScale(maxValue)));

        return rgbToHex(color.r, color.g, color.b);
      }

      function weightScale(height, minValue, maxValue) {
        const linearScale = scaleLinear()
          .domain([minValue, maxValue])
          .range([0.05, 2]);

        const weight = linearScale(height);

        return weight;
      }
      function weightScaleInv(height, minValue, maxValue) {
        const linearScale = scaleLinear()
          .domain([minValue, maxValue])
          .range([2, 0.05]);

        const weight = linearScale(height);

        return weight;
      }

      let minValue = 10000000;
      let maxValue = 0;
      function maxCalc(compVal, value) {
        if (value !== null) {
          return Math.max(compVal, value);
        } else {
          return compVal;
        }
      }
      function minCalc(compVal, value) {
        if (value !== null) {
          return Math.min(compVal, value);
        } else {
          return compVal;
        }
      }
      rhino.features.forEach(
        (f) => (
          (maxValue = maxCalc(maxValue, f.properties[rdSeqCat])),
          (minValue = minCalc(minValue, f.properties[rdSeqCat]))
        )
      );
      // rhino.features.forEach(
      //   (f) => (
      //     (maxValue = Math.max(maxValue, f.properties[rdSeqCat])),
      //     (minValue = Math.min(minValue, f.properties[rdSeqCat]))
      //   )
      // );

      if (query.roadSegmentCategory === "tdly")
        setminTrkCatValue(minValue.toFixed(0));
      if (query.roadSegmentCategory === "tpti95")
        setminTrkCatValue(minValue.toFixed(2));
      if (query.roadSegmentCategory === "tpti80")
        setminTrkCatValue(minValue.toFixed(2));
      if (query.roadSegmentCategory === "ttti")
        setminTrkCatValue(minValue.toFixed(2));
      if (query.roadSegmentCategory === "trefspd")
        setminTrkCatValue(minValue.toFixed(2));
      if (query.roadSegmentCategory === "tcongspd")
        setminTrkCatValue(minValue.toFixed(2));
      if (query.roadSegmentCategory === "taadt")
        setminTrkCatValue(minValue.toFixed(0));
      if (query.roadSegmentCategory === "tvmt")
        setminTrkCatValue(minValue.toFixed(0));

      if (query.roadSegmentCategory === "tdly")
        setmaxTrkCatValue(maxValue.toFixed(0));
      if (query.roadSegmentCategory === "tpti95")
        setmaxTrkCatValue(maxValue.toFixed(2));
      if (query.roadSegmentCategory === "tpti80")
        setmaxTrkCatValue(maxValue.toFixed(2));
      if (query.roadSegmentCategory === "ttti")
        setmaxTrkCatValue(maxValue.toFixed(2));
      if (query.roadSegmentCategory === "trefspd")
        setmaxTrkCatValue(maxValue.toFixed(2));
      if (query.roadSegmentCategory === "tcongspd")
        setmaxTrkCatValue(maxValue.toFixed(2));
      if (query.roadSegmentCategory === "taadt")
        setmaxTrkCatValue(maxValue.toFixed(0));
      if (query.roadSegmentCategory === "tvmt")
        setmaxTrkCatValue(maxValue.toFixed(0));

      rhino.features
        .filter((f) => isSelected(f.properties))
        .forEach((f) => (f.color = COLOR_RHINO.selected));
      if (query.roadSegmentCategory === "none") {
        rhino.features
          .filter((f) => !isSelected(f.properties))
          .forEach(
            (f) => (
              (f.color_base = COLOR_RHINO.normal),
              (f.color = f.color_base),
              (f.weight_base = 3),
              (f.weight = f.weight_base)
            )
          );
      } else {
        if (query.roadSegmentCategory !== "tcongspd")
          rhino.features
            .filter((f) => !isSelected(f.properties))
            .forEach(
              (f) => (
                (f.color_base = colorScale(
                  f.properties[rdSeqCat],
                  minValue,
                  maxValue
                )),
                (f.color = f.color_base),
                (f.weight_base = Math.max(
                  0.25,
                  weightScale(f.properties[rdSeqCat], minValue, maxValue) * 7
                )),
                (f.weight = f.weight_base)
              )
            );
        if (
          query.roadSegmentCategory === "tcongspd" ||
          query.roadSegmentCategory === "trefspd"
        )
          rhino.features
            .filter((f) => !isSelected(f.properties))
            .forEach(
              (f) => (
                (f.color_base = colorScaleInv(
                  f.properties[rdSeqCat],
                  minValue,
                  maxValue
                )),
                (f.color = f.color_base),
                (f.weight_base = Math.max(
                  0.25,
                  weightScaleInv(f.properties[rdSeqCat], minValue, maxValue) * 5
                )),
                (f.weight = f.weight_base)
              )
            );
      }

      setGeoJsonKey(Date.now());
    }
  }, [query.roadSegmentCategory]);

  /* Build a list of property as an unordered list in jsx
   * @param {*} rprops Rhino road segment properties
   */
  const stylesHoverBox = {
    h6Header: {
      textAlign: "left",
    },
    leftText: {
      textAlign: "left",
      marginBottom: "0",
      paddingBottom: "0",
    },
    leftBullet: {
      textAlign: "left",
      marginLeft: "-25px",
    },
    rightBullet: {
      textAlign: "left",
      marginLeft: "20px",
    },
    leftHeader: {
      textAlign: "left",
      marginLeft: "-35px",
    },
    rightHeader: {
      textAlign: "left",
      marginLeft: "10px",
    },
  };
  const rhinoInfo2 = (rprops) => {
    return (
      <div
        className={props.mode}
        style={{ padding: "2px", fontSize: "0.75vw" }}
      >
        <h4>
          {/* {rprops.highway_name} ({rprops.frm_dfo} - {rprops.to_dfo}) */}
          {/* {rprops.ria_rte_id} ({rprops.frm_dfo} - {rprops.to_dfo}) */}
          Route ID: {rprops.ria_rte_id}
        </h4>
        <h6>
          <strong>From/To DFO: </strong>
          {rprops.frm_dfo.toLocaleString()} to {rprops.to_dfo.toLocaleString()}
        </h6>
        {/* <p style={stylesHoverBox.leftText}>
          Congestion Stats Year: {rprops.year}{" "}
        </p> */}
        <p style={stylesHoverBox.leftText}>
          Length of Segment: {rprops.len_sec.toLocaleString()}
        </p>
        <p style={stylesHoverBox.leftText}>Estimated: {rprops.estimated}</p>
        <ul style={stylesHoverBox.leftText}>
          <div style={{ display: "flex" }}>
            <div>
              <li style={stylesHoverBox.leftBullet}>
                Adjusted current ADT: {rprops.adt_cur.toLocaleString()}{" "}
              </li>
              <li style={stylesHoverBox.leftBullet}>
                Truck AADT: {rprops.tottrk.toLocaleString()}{" "}
              </li>
              <li style={stylesHoverBox.leftBullet}>
                Daily Truck VMT: {rprops.trkttivmt.toLocaleString()}{" "}
              </li>
              <li style={stylesHoverBox.leftBullet}>
                % of single unit trucks in ADT: {rprops.pct_sadt.toFixed(2)}{" "}
              </li>
              <li style={stylesHoverBox.leftBullet}>
                Truck Delay: {rprops.trkdelay.toLocaleString()}{" "}
              </li>
            </div>
            <div style={{ paddingLeft: "5px" }}>
              <li style={stylesHoverBox.rightBullet}>
                Truck Travel Time Index: {rprops.trktti}{" "}
              </li>
              <li style={stylesHoverBox.rightBullet}>
                Truck Planning Time Index 80: {rprops.trkpti80}{" "}
              </li>
              <li style={stylesHoverBox.rightBullet}>
                Truck Planning Time Index 95: {rprops.trkpti95}{" "}
              </li>
              <li style={stylesHoverBox.rightBullet}>
                % of combo unit trucks in ADT: {rprops.pct_cadt.toFixed(2)}{" "}
              </li>
            </div>
          </div>
        </ul>
      </div>
    );
  };
  const rhinoInfo = (rprops) => {
    return (
      <div className={props.mode} style={{ padding: "2px" }}>
        <h4 style={stylesHoverBox.leftText}>
          {/* {rprops.highway_name} ({rprops.frm_dfo} - {rprops.to_dfo}) */}
          {/* {rprops.ria_rte_id} ({rprops.frm_dfo} - {rprops.to_dfo}) */}
          Route ID: {rprops.ria_rte_id}
        </h4>
        <h6>
          <strong>From/To DFO: </strong>
          {rprops.frm_dfo.toLocaleString()} to {rprops.to_dfo.toLocaleString()}
        </h6>
        {/* <p style={stylesHoverBox.leftText}>
          Congestion Stats Year: {rprops.year}{" "}
        </p> */}
        <p style={stylesHoverBox.leftText}>
          Length of Segment: {rprops.len_sec.toLocaleString()}{" "}
        </p>
        <p style={stylesHoverBox.leftText}>Estimated: {rprops.estimated}</p>
        <ul style={stylesHoverBox.leftText}>
          <div style={{ display: "flex" }}>
            <div>
              <li style={stylesHoverBox.leftBullet}>
                Adjusted current ADT: {rprops.adt_cur.toLocaleString()}{" "}
              </li>
              <li style={stylesHoverBox.leftBullet}>
                Truck AADT: {rprops.tottrk.toLocaleString()}{" "}
              </li>
              <li style={stylesHoverBox.leftBullet}>
                Daily Truck VMT: {rprops.trkttivmt.toLocaleString()}{" "}
              </li>
              <li style={stylesHoverBox.leftBullet}>
                % of single unit trucks in ADT: {rprops.pct_sadt.toFixed(2)}{" "}
              </li>
              <li style={stylesHoverBox.leftBullet}>
                Truck Delay: {rprops.trkdelay.toLocaleString()}{" "}
              </li>
            </div>
            <div>
              <li style={stylesHoverBox.rightBullet}>
                Truck Travel Time Index: {rprops.trktti}{" "}
              </li>
              <li style={stylesHoverBox.rightBullet}>
                Truck Planning Time Index 80: {rprops.trkpti80}{" "}
              </li>
              <li style={stylesHoverBox.rightBullet}>
                Truck Planning Time Index 95: {rprops.trkpti95}{" "}
              </li>
              <li style={stylesHoverBox.rightBullet}>
                % of combo unit trucks in ADT: {rprops.pct_cadt.toFixed(2)}{" "}
              </li>
            </div>
          </div>
        </ul>
      </div>
    );
  };
  const getRhinoStyle = (feature) => {
    //return { color: feature.color };

    return {
      color: feature.color,
      weight: feature.weight,
      opacity: 0.6,
      fillOpacity: 0.6,
    };
  };

  /* Highlights on mouseOver */
  const handleMouseOver = (feature, e) => {
    const layer = e.target;
    rhinoColorContainer.current = feature.color;
    rhinoWeightContainer.current = feature.weight;
    feature.color = COLOR_RHINO.selected;
    feature.weight = 5;
    layer.setStyle({
      weight: feature.weight,
      color: feature.color,
      dashArray: "",
      fillOpacity: 0.1,
    });
    setInfo(rhinoInfo(feature.properties));
  };

  /* Reset default style on mouseOut */
  const handleMouseOut = (feature, e) => {
    const layer = e.target;
    feature.color = rhinoColorContainer.current;
    feature.weight = rhinoWeightContainer.current;
    layer.setStyle({
      weight: feature.weight,
      color: feature.color,
      dashArray: "",
      fillOpacity: 0.6,
      opacity: 0.6,
    });
  };

  /* inside handleClick query.popUpEnabled doesn't work since it has created
   * at the time of creation and not changed. So I am using useRef and useEffect
   * to reflect the changed value of query.popUpEnabled variable.
   * see https://stackoverflow.com/questions/53845595/wrong-react-hooks-behaviour-with-event-listener
   * */
  const popUp = useRef(false);
  useEffect(() => {
    popUp.current = query.popUpEnabled;
  }, [query.popUpEnabled]);

  /* leaflet's layer work only with text not HTML element created by jsx */
  const formatPopUp = (rprops) => {
    const id = `${rprops.ria_rte_id}_${rprops.frm_dfo
      .toString()
      .replace(".", "d")}`;
    // const fromHereTooltip = "Starting segment";
    return [
      `
    <div>
      <h5><strong>Route ID: ${rprops.ria_rte_id}</strong></h5>
      <h6 >From/To DFO: ${rprops.frm_dfo.toLocaleString()} to ${rprops.to_dfo.toLocaleString()}</h6>
      <div>
      <h7>Add corridor to data table:</h7>
      <button id="from-here-${id}" title="Starting segment">From here</button>
      <button id="to-here-${id}" title="Ending segment">To here</button>
      </div>
      <div>
      <h7>Add segment to data table:</h7>
      <button id="add-to-table-${id}" title="Add segment to data table below the map">Add to Data Table</button>
      </div>
      </div>
    `,
      id,
    ];
  };

  /* Tracks user road selection process*/
  const defaultUserSelection = {
    from_ria_rte_id: "",
    from_frm_dfo: Number.Nan,
    from_to_dfo: Number.NaN,
    to_frm_dfo: Number.NaN,
    to_to_dfo: Number.NaN,
  };
  const userSelection = useRef(defaultUserSelection);
  /* Resets the previous user selection highlights */
  const resetUserSelection = (ria_rte_id) => {
    rhino.features
      // .filter((f) => f.properties.ria_rte_id === ria_rte_id)
      .forEach((f) => {
        f.color = f.color_base;
        f.weight = f.weight_base;
      });
    setGeoJsonKey(Date.now());
    userSelection.current = defaultUserSelection;
  };

  const highlightUserSelection = () => {
    const selection = userSelection.current;
    // rhino.features
    //   .filter(
    //     (f) =>
    //       f.properties.ria_rte_id === selection.from_ria_rte_id &&
    //       f.properties.frm_dfo >= selection.from_frm_dfo &&
    //       f.properties.to_dfo <= selection.from_to_dfo
    //   )
    //   .forEach((f) => {
    //     f.color = COLOR_RHINO.selectedSection;
    //     f.weight = 5;
    //   });
    rhino.features
      .filter(
        (f) =>
          f.properties.ria_rte_id === selection.from_ria_rte_id &&
          f.properties.frm_dfo === selection.from_frm_dfo &&
          f.properties.to_dfo === selection.from_to_dfo
      )
      .forEach((f) => {
        f.color = COLOR_RHINO.selectedSection;
        f.weight = 5;
      });
    rhino.features
      .filter((f) => f.properties.ria_rte_id !== selection.from_ria_rte_id)
      .forEach((f) => {
        // f.color = f.color_base;
        f.color = COLOR_RHINO.notSelected;
        f.weight = f.weight_base;
      });
    if (!isNaN(selection.to_to_dfo)) {
      const fromDfo = Math.min(selection.from_frm_dfo, selection.to_frm_dfo);
      const toDfo = Math.max(selection.from_to_dfo, selection.to_to_dfo);
      rhino.features
        .filter(
          (f) =>
            f.properties.ria_rte_id === selection.from_ria_rte_id &&
            f.properties.frm_dfo >= fromDfo &&
            f.properties.to_dfo <= toDfo
        )
        .forEach((f) => {
          f.color = COLOR_RHINO.selectedSection;
          f.weight = 5;
        });
    } else {
      /* if toHere is not selected */
      rhino.features.filter(
        (f) =>
          f.properties.ria_rte_id === selection.from_ria_rte_id &&
          f.properties.frm_dfo === selection.from_frm_dfo
      )[0].color = COLOR_RHINO.selectedSection;
    }
    // setRhino(newRhino);
    setGeoJsonKey(Date.now());
  };

  const handleFromHereClick = (rprops, e) => {
    e.preventDefault();
    resetUserSelection(userSelection.current.from_ria_rte_id);
    userSelection.current = {
      from_ria_rte_id: rprops.ria_rte_id,
      from_frm_dfo: rprops.frm_dfo,
      from_to_dfo: rprops.to_dfo,
    };
    highlightUserSelection();
  };
  const handleToHereClick = (rprops, e) => {
    e.preventDefault();
    let newSelection = { ...userSelection.current };
    newSelection.to_frm_dfo = rprops.frm_dfo;
    newSelection.to_to_dfo = rprops.to_dfo;
    userSelection.current = newSelection;
    const fromDfo = Math.min(
      newSelection.from_frm_dfo,
      newSelection.to_frm_dfo
    );
    const toDfo = Math.max(newSelection.from_to_dfo, newSelection.to_to_dfo);
    dispatch({
      type: "setUserSelection",
      selection: {
        ria_rte_id: newSelection.from_ria_rte_id,
        frm_dfo: fromDfo,
        to_dfo: toDfo,
      },
    });
    highlightUserSelection();
  };
  const handleAddToTableClick = (rprops, e) => {
    e.preventDefault();
    //resetUserSelection(userSelection.current.top100id);
    dispatch({
      type: "setUserSelection",
      selection: {
        ria_rte_id: rprops.ria_rte_id,
        frm_dfo: rprops.frm_dfo,
        to_dfo: rprops.to_dfo,
      },
    });
  };

  /* Binds a popup with the clicked rhino segment and registers event listeners'
   * for "From here" and "To here" links inside the popup.
   * Event listener registerd with classical Javascript way since the popup content
   * was created by Leaflet's bindPopup method not by jsx */
  const [mapClickLatLng, setMapClickLatLng] = useState();
  const [fromPointLocation, setFromPointLocation] = useState([]);

  const handleClick = (feature, layer, e) => {
    setMapClickLatLng(e.latlng);
    // if (popUp.current) {
    //   /* if popUp enabled by selecting the  "My Roads" tab*/
    //   const props = feature.properties;
    //   const [text, id] = formatPopUp(props);
    //   layer.bindPopup(text).openPopup();
    //   const fromHere = document.querySelector(`#from-here-${id}`);
    //   const toHere = document.querySelector(`#to-here-${id}`);
    //   fromHere.addEventListener("click", handleFromHereClick.bind(null, props));
    //   toHere.addEventListener("click", handleToHereClick.bind(null, props));
    //   const addSegment = document.querySelector(`#add-to-table-${id}`);
    //   addSegment.addEventListener(
    //     "click",
    //     handleAddToTableClick.bind(null, props)
    //   );
    // }
  };

  /* @see See https://jsfiddle.net/thbh99nu/2/ */
  const onEachFeature = (feature, layer) => {
    layer.on({
      mouseover: handleMouseOver.bind(null, feature),
      mouseout: handleMouseOut.bind(null, feature),
      click: handleClick.bind(null, feature, layer),
    });
  };

  /* have to pass a unique key to Map to re-render leaflet map
   * when the road data is changed. Since the road data
   * changed based on regionId and year, this pass the combination of both
   * as a key. This is a bug in react-leaflet.
   * See https://stackoverflow.com/questions/44155385/rendering-geojson-with-react-leaflet
   */
  const { BaseLayer, Overlay } = LayersControl;

  const [mapZoom, setMapZoom] = useState(props.Zoom);
  // moved year selection to mapTabs, "Filters/Legend"
  //  const handleYearSelector = (event) => {
  //    dispatch({
  //      type: "setUserSelection",
  //      selection: {
  //        ria_rte_id: "",
  //        frm_dfo: NaN,
  //        to_dfo: NaN
  //      }
  //    });
  //
  //    props.setYear(event.target.value);
  //  }
  useEffect(() => {
    dispatch({
      type: "setUserSelection",
      selection: {
        ria_rte_id: "",
        frm_dfo: NaN,
        to_dfo: NaN,
      },
    });

    props.setYear(query.yearSelection);
  }, [query.yearSelection]);

  const [selectedBaselayer, setselectedBaselayer] = useState(
    query.selectedBaselayer
  );

  useEffect(() => {
    if (query.roadSegmentCategory === "none") {
      setselectedBaselayer("Streets");
    } else {
      setselectedBaselayer(query.selectedBaselayer);
    }
  }, [query.roadSegmentCategory]);
  useEffect(() => {
    dispatch({
      type: "setroadSegmentCategory",
      selection: "none",
    });
  }, [props.route]);

  const createBaseLayer = (props) => {
    if (selectedBaselayer === props.name) {
      return (
        <BaseLayer checked name={props.name} key={props.id + "-baselayer"}>
          <TileLayer
            url={props.url}
            attribution={MAPBOX_ATTRIBUTION}
            id={props.id}
            access_token={MAPBOX_ACCESSTOKEN}
            key={props.id + "-basemap"}
          />
        </BaseLayer>
      );
    } else {
      return (
        <BaseLayer name={props.name} key={props.id + "-baselayer"}>
          <TileLayer
            url={props.url}
            attribution={MAPBOX_ATTRIBUTION}
            id={props.id}
            access_token={MAPBOX_ACCESSTOKEN}
            key={props.id + "-basemap"}
          />
        </BaseLayer>
      );
    }
  };

  const countyBoundariesRef = useRef(null);
  const districtBoundariesRef = useRef(null);
  const commercialBorderCrossingsRef = useRef(null);
  const nonCommercialBorderCrossingsRef = useRef(null);
  const railBorderCrossingsRef = useRef(null);
  const portsLayerRef = useRef(null);
  const utpLayerRef = useRef(null);
  const CargoAirportsLayerRef = useRef(null);
  const airportsLayerRef = useRef(null);
  const truckParkingLayerRef = useRef(null);
  const roadsLayerRef = useRef(null);

  const printControlRef = useRef();
  const exportControlRef = useRef();

  const a3Size = {
    //width: 1045,
    //height: 715,
    width: 1500,
    height: 715,
    className: "a3CssClass",
    tooltip: "A custom A3 size",
    name: "Cropped Size",
  };

  const [utpMapLayersCount, setutpMapLayersCount] = useState({
    underwayorsoon: 0,
    within4years: 0,
    fivetotenyears: 0,
    tenplusyears: 0,
  });

  function setmapDownloadName(type) {
    const date = new Date();
    const mapDownloadName =
      // "tx100-" +
      statusGuide[1].split(" ").join("-") +
      "-" +
      date
        .toLocaleDateString()
        .split("/")
        .join("-") +
      "-" +
      date
        .toLocaleTimeString()
        .split(" ")
        .join("-")
        .split(":")
        .join("-");

    return mapDownloadName;
  }

  const popupRef = useRef(false);
  window.popupRef = popupRef;
  function handlePopupOpen(a, b, c) {
    let featureProps =
      popupRef.current.leafletElement._source.feature.properties;
    setPopupTitle(`Route ID: ${featureProps.ria_rte_id}`);
    setPopupTitle2(
      `From/To DFO: ${featureProps.frm_dfo} to ${featureProps.to_dfo}`
    );
  }
  const [popupTitle, setPopupTitle] = useState("untitled");
  const [popupTitle2, setPopupTitle2] = useState("untitled");

  function handleAddSegment() {
    let rprops = popupRef.current.leafletElement._source.feature.properties;

    // resetUserSelection(userSelection.current.top100id);
    dispatch({
      type: "setUserSelection",
      selection: {
        ria_rte_id: rprops.ria_rte_id,
        frm_dfo: rprops.frm_dfo,
        to_dfo: rprops.to_dfo,
      },
    });

    popupRef.current.leafletElement.remove(); //closes the popup
  }

  function handleFrom() {
    let featureProps =
      popupRef.current.leafletElement._source.feature.properties;

    resetUserSelection(userSelection.current.from_ria_rte_id);
    userSelection.current = {
      from_ria_rte_id: featureProps.ria_rte_id,
      from_frm_dfo: featureProps.frm_dfo,
      from_to_dfo: featureProps.to_dfo,
    };

    highlightUserSelection();

    popupRef.current.leafletElement.remove(); //closes the popup

    setFromToClearButtonDisabled({
      fromButton: true,
      toButton: false,
      clearButton: false,
    });

    setFromPointLocation([mapClickLatLng.lat, mapClickLatLng.lng]);
  }

  function handleTo() {
    let rprops = popupRef.current.leafletElement._source.feature.properties;

    let newSelection = { ...userSelection.current };
    newSelection.to_frm_dfo = rprops.frm_dfo;
    newSelection.to_to_dfo = rprops.to_dfo;
    userSelection.current = newSelection;
    const fromDfo = Math.min(
      newSelection.from_frm_dfo,
      newSelection.to_frm_dfo
    );
    const toDfo = Math.max(newSelection.from_to_dfo, newSelection.to_to_dfo);
    dispatch({
      type: "setUserSelection",
      selection: {
        ria_rte_id: newSelection.from_ria_rte_id,
        frm_dfo: fromDfo,
        to_dfo: toDfo,
      },
    });
    highlightUserSelection();

    resetUserSelection(userSelection.current.from_ria_rte_id);
    popupRef.current.leafletElement.remove(); //closes the popup

    setFromToClearButtonDisabled({
      fromButton: false,
      toButton: true,
      clearButton: true,
    });
    setFromPointLocation([]);
  }

  function handleClearSelection() {
    let featureProps =
      popupRef.current.leafletElement._source.feature.properties;

    resetUserSelection(userSelection.current.from_ria_rte_id);

    setFromToClearButtonDisabled({
      fromButton: false,
      toButton: true,
      clearButton: true,
    });
    setFromPointLocation([]);
  }

  const [fromToCleasrButtonDisabled, setFromToClearButtonDisabled] = useState({
    fromButton: false,
    toButton: true,
    clearButton: true,
  });

  //    <Map bounds={props.bounds} maxBounds={props.bounds} zoom={mapZoom} minZoom={6} attributionContol={true}>
  return (
    <Map
      bounds={homeMapBounds}
      attributionContol={true}
      zoomSnap={0.25}
      zoomDelta={0.25}
      //wheelPxPerZoomLevel={30}
      ref={mapRef}
    >
      {/* <LoadingOverlay
        active={isLoading}
        spinner={<BounceLoader />}
        text={
          props.layerID
            ? "Loading stats layer..."
            : "Please select a layer from the menu on the left..."
        }
        styles={{
          overlay: (base) => ({
            ...base,
            height: "650px",
          }),
        }}
      > */}
      {<SandMinesLayer />}
      {<DisposalWellsLayer />}
      {countyList.length > 0 && (
        <Pane style={{ zIndex: 499 }}>
          <CountyBoundaryLayer
            countyList={countyList}
            layerRef={countyBoundariesRef}
            mapType={mapType}
          />
        </Pane>
      )}
      {<DistrictBoundaryLayer layerRef={districtBoundariesRef} />}
      {
        <CommercialBorderCrossingLayer
          layerRef={commercialBorderCrossingsRef}
        />
      }
      {
        <NonCommercialBorderCrossingLayer
          layerRef={nonCommercialBorderCrossingsRef}
        />
      }
      {<RailBorderCrossingLayer layerRef={railBorderCrossingsRef} />}
      {<PortsLayer layerRef={portsLayerRef} />}
      {
        <UTPLayer
          layerRef={utpLayerRef}
          countyList={countyList}
          utpMapLayersCount={utpMapLayersCount}
          setutpMapLayersCount={setutpMapLayersCount}
        />
      }
      {<CargoAirportsLayer layerRef={CargoAirportsLayerRef} />}
      {<AirportsLayer layerRef={airportsLayerRef} />}
      {countyList.length > 0 && (
        <TruckParkingLayer
          layerRef={truckParkingLayerRef}
          countyList={countyList}
        />
      )}
      <LayersControl position="topleft">
        {baseLayers.map((obj) => createBaseLayer(obj))}

        {countyBoundaries.features.length > 0 && (
          <Overlay name="Counties" style={{ zIndex: 499 }}>
            <GeoJSON data={countyBoundaries} />
          </Overlay>
        )}
        {rhino.features.length > 0 && (
          <Overlay checked name="Roads" style={{ zIndex: 500 }}>
            <Pane style={{ zIndex: 500 }}>
              <GeoJSON
                /* key={geoJsonKey} */
                data={rhino}
                style={getRhinoStyle}
                onEachFeature={onEachFeature}
                ref={roadsLayerRef}
              >
                <Popup ref={popupRef} onOpen={handlePopupOpen}>
                  <div className="custom-block">
                    <p className="custom-block-layer">
                      <strong>Layer ID:</strong> {props.layerID}
                    </p>
                    <h6 className="custom-block-title">{popupTitle}</h6>
                    <p className="custom-block-text">{popupTitle2}</p>
                    <div>
                      <p className="custom-block-text">
                        To add road segment to the data table, click{" "}
                        <strong>Add segment</strong> below.
                      </p>
                      <p className="from-to-links">
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={handleAddSegment}
                        >
                          Add segment
                        </button>
                      </p>
                    </div>
                    <div>
                      <div>
                        <p> To add a corridor to the data table:</p>
                        <ol>
                          <li>
                            Click <strong>From Here</strong> on first segment
                          </li>
                          <li>
                            Click <strong>To Here</strong> on final segment to
                            add corridor to data table.
                          </li>
                        </ol>
                      </div>
                      <p className="from-to-links">
                        <button
                          className="btn btn-secondary btn-sm"
                          onClick={handleFrom}
                          disabled={fromToCleasrButtonDisabled.fromButton}
                        >
                          From here
                        </button>
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={handleTo}
                          disabled={fromToCleasrButtonDisabled.toButton}
                        >
                          To here
                        </button>
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={handleClearSelection}
                          disabled={fromToCleasrButtonDisabled.clearButton}
                        >
                          Clear prior selection
                        </button>
                      </p>
                    </div>
                  </div>
                </Popup>
              </GeoJSON>
            </Pane>
          </Overlay>
        )}
      </LayersControl>
      {fromPointLocation.length > 0 && (
        <Marker position={fromPointLocation}>
          {/* <Popup>
        A pretty CSS3 popup. <br /> Easily customizable.
      </Popup> */}
        </Marker>
      )}
      {/* </LoadingOverlay> */}
      {/*<Control position="bottomleft" >
        <Accordion defaultExpanded >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>Quick Info/Hover</Typography>
          </AccordionSummary>
          <AccordionDetails >
            <div children={info} />
          </AccordionDetails>
        </Accordion>
      </Control>*/}
      <Control position="topleft">
        <button
          style={{ backgroundColor: "white" }}
          title="Return to original map zoom and center."
          onClick={() => setMapZoom(mapZoom - 1)}
          className="home-button"
        >
          {" "}
          {/*Increment of -1 used to trigger state change*/}
          <HomeIcon />
        </button>
      </Control>
      <ScaleControl position="bottomleft" />
      <Control position="bottomright">
        <MapTabs
          hoverInfo={info}
          type="rhino"
          numFeatures={rhino.features.length.toLocaleString()}
          minValue={minTrkCatValue}
          maxValue={maxTrkCatValue}
          statusGuide={statusGuide}
          utpMapLayersCount={utpMapLayersCount}
          mode={props.mode}
        />
      </Control>
      <PrintControl
        ref={printControlRef}
        position="topleft"
        sizeModes={[
          "Current",
          // "A4Portrait",
          "A4Landscape",
          a3Size,
        ]}
        hideControlContainer={false}
        spinnerBgColor="#00FF00"
        hideClasses={[
          "leaflet-control-zoom",
          "leaflet-control-layers",
          "leaflet-control-easyPrint-button-export",
          "leaflet-control-easyPrint-button",
          "home-button",
        ]}
      />
      <PrintControl
        ref={exportControlRef}
        position="topleft"
        sizeModes={[
          "Current",
          // "A4Portrait",
          "A4Landscape",
          a3Size,
        ]}
        hideControlContainer={false}
        title="Export as PNG"
        exportOnly
        spinnerBgColor="#00FF00"
        hideClasses={[
          "leaflet-control-zoom",
          "leaflet-control-layers",
          "leaflet-control-easyPrint-button-export",
          "leaflet-control-easyPrint-button",
          "home-button",
        ]}
        filename={setmapDownloadName()}
      />{" "}
      {roadGeomFeatures && <DrawPolygon
        polyRef={mapRef}
        data={roadGeomFeatures}
        statusGuide={props.statusGuide}
        onFilteredGeoJSON={handleFilteredGeoJSON} />}

      {roadGeomFeatures && 
        <Control position="topleft">
          <ClearFilter data={roadGeomFeatures}  mapType={mapType}  onFilteredGeoJSON={handleFilteredGeoJSON} />
        </Control> }
    </Map>
  );
}

export default RhinoLeafletMap;
