import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const ContactUsDialog = (props) => {
  //function ContactUsDialog(props) {
  const { openContactUs, setOpenContactUs } = props;

  const handleCloseContactUs = () => {
    setOpenContactUs(false);
  };

  return (
    <Dialog
      open={openContactUs}
      onClose={handleCloseContactUs}
      fullWidth
      maxWidth="sm"
      scroll="body"
    >
      <DialogTitle>Contact Us:</DialogTitle>
      <DialogContent dividers>
        <Grid
          container
          spacing={1}
          direction="row"
          // style={{ height: "100%" }}
        >
          {/* <Grid item xs={6}>
            <Typography variant="subtitle1">
              <strong>Casey Wells</strong>
            </Typography>
            <Typography variant="subtitle1">
              Texas Department of Transportation (TxDOT)
            </Typography>
            <a href={"mailto:Casey.Wells@txdot.gov"}>Casey.Wells@txdot.gov</a>
          </Grid> */}
          <Grid item xs={6}>
            <Typography variant="subtitle1">
              <strong>Kale Driemeier</strong>
            </Typography>
            <Typography variant="subtitle1">
              Texas Department of Transportation (TxDOT)
            </Typography>
            <a href={"mailto:kale.driemeier@txdot.gov"}>
              kale.driemeier@txdot.gov
            </a>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1">
              <strong>L.D. White</strong>
            </Typography>
            <Typography variant="subtitle1">
              Texas A&M Transportation Institute (TTI)
            </Typography>
            <a href={"mailto:l-white@tti.tamu.edu"}>l-white@tti.tamu.edu</a>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseContactUs} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ContactUsDialog;
