import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { GeoJSON, LayerGroup } from "react-leaflet";
import { RhinoQueryContext } from "../RhinoQueryContext";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
  },
  legendContainer: {
    background: "white",
    padding: "5px",
  },
  legendIcon: {
    //height: "5%",
    //width: "auto",
    //border: "1px",
    marginRight: "5px",
  },
  wrapIcon: {
    //verticalAlign: 'middle',
    display: "flex",
    alignItems: "center",
  },
  roadwayLegendLine: {
    width: "25px",
    height: "5px",
    background: "#4d4dff",
    //float: 'left',
    marginRight: "5px",
  },
}));

//props.route

export default function DistrictBoundaryLayer(props) {
  const [query, dispatch] = useContext(RhinoQueryContext);
  const classes = useStyles();
  //const { utpData, utpCategoryLookups } = props;
  const { layerRef } = props;

  const [districtBoundaries, setDistrictBoundaries] = useState({
    type: "FeatureCollection",
    features: [],
  });

  //useEffect(() => {
  //    const fetchCountyBoundaries = async () => {
  //        try {
  //            if (props.counties.length > 0) {
  //                const dataUrl = `${API_ROOT}/counties/${props.counties.join(
  //                    ","
  //                )}/boundary/`;
  //                const response = await axios.get(dataUrl);
  //                setCountyBoundaries(response.data);
  //                /* console.log(response.data);*/
  //            }
  //        } catch (e) {
  //            console.log(e);
  //        }
  //    };
  //    fetchCountyBoundaries();
  //}, [props.counties]);

  useEffect(() => {
    const fetchDistrictBoundaries = async () => {
      try {
        //http://localhost:5000/api/tcat_boundaries/findCountyBoundariesByCountyNumber/10,11,23
        // const dataUrlCnty = `${API_ROOT}/tcat_boundaries/findCountyBoundariesByCountyNumber/${countyList.join(
        //   ","
        // )}`;
        // const responseCnty = await axios.get(dataUrlCnty);
        // setCountyBoundaries(responseCnty.data.jsonb_build_object);
        if (
          query.contextLayersVisibility.districtBoundaries &&
          districtBoundaries.features.length === 0
        ) {
          const dataUrl =
            "https://services.arcgis.com/KTcxiTD9dsQw4r7Z/arcgis/rest/services/TxDOT_Districts/FeatureServer/0/query?where=1%3D1&outFields=*&outSR=4326&f=geojson";
          const response = await axios.get(dataUrl);
          setDistrictBoundaries(response.data);
        }
      } catch (e) {
        console.log(e);
      }
    };
    fetchDistrictBoundaries();
  }, [query.contextLayersVisibility.districtBoundaries]);

  const onEachFeature = (feature, layer) => {
    // let tooltipText = `
    // <div>
    //   <p><strong>${feature.properties.cnty_nm} County</strong></p>
    //   <p>FIPS: ${feature.properties.cnty_fips}</p>
    // </div>
    // `;
    let tooltipText = `
    <div>
      <div style="text-align: center;border-bottom: 2.0px solid black;">
        <h5 style="margin-bottom: 0.25rem;margin-top: 0.25rem;">TxDOT Districts Layer</h5>
      </div>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>${feature.properties.DIST_NM} District</strong></p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>District Number:</strong> ${feature.properties.DIST_NBR}</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>District Abbreviation:</strong> ${feature.properties.DIST_ABRVN_NM}</p>
    </div>
    `;
    return layer.bindTooltip(tooltipText);
    //  let popupText = `
    // <div>
    //   <div style="text-align: center;border-bottom: 2.0px solid black;">
    //     <h3 style="margin-bottom: 0.25rem;margin-top: 0.25rem;">Counties</h3>
    //   </div>
    //   <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Name:</strong> ${feature.properties.COUNTY}</p>
    //   <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>County Code:</strong> ${feature.properties.COUNTYNUM}</p>
    //   <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>County FIPS:</strong> ${feature.properties.COUNTY_FIP}</p>
    //   <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>District:</strong> ${feature.properties.DISTRICT}</p>
    // </div>
    // `;
    //   return layer.bindTooltip(tooltipText).bindPopup(popupText);
  };

  useEffect(() => {
    if (layerRef.current) {
      layerRef.current.leafletElement.clearLayers().addData(districtBoundaries);
    }
  }, [districtBoundaries]);

  return (
    <LayerGroup>
      {query.contextLayersVisibility.districtBoundaries &&
        districtBoundaries.features.length > 0 && (
          // <Overlay name="Counties" style={{ zIndex: 499 }}>
          <GeoJSON
            data={districtBoundaries}
            onEachFeature={onEachFeature}
            style={{
              color: "#90ee90",
              weight: 2,
              fillOpacity: 0.1,
            }}
          />
          // </Overlay>
        )}
    </LayerGroup>
  );
}
