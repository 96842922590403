import React, { useState, useContext, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ExportCsv, ExportPdf } from "@material-table/exporters";

export default function ExportFileNameDialog(props) {
  const {
    openExportDialog,
    setOpenExportDialog,
    csvFileNameTest,
    setCsvFileNameTest,
    exportCols,
    exportData,
    exportNewData,
    exportNewCols,
    roadLayerType
  } = props;

  const handleClose = () => {
    setOpenExportDialog(false);
  };

  const handleTextFieldChange = (e) => {
    setCsvFileNameTest(e.target.value);
  };

  const handleDownloadPDF = () => {
    setOpenExportDialog(false);
    ExportPdf(exportCols, exportData, csvFileNameTest);
  };

  const handleDownloadCSV = () => {
    setOpenExportDialog(false);
    ExportCsv(exportCols, exportData, csvFileNameTest);
  };

  //csv for custom corridors
  const handleDownloadNewCSV = () => {
    setOpenExportDialog(false);
    ExportCsv(exportNewCols, exportNewData, csvFileNameTest);
  };

  // const setExportButtonColor = () => {
  //   if (
  //     window.matchMedia &&
  //     window.matchMedia("(prefers-color-scheme: dark)").matches
  //   ) {
  //     // dark mode
  //     return "secondary";
  //   } else {
  //     return "primary";
  //   }
  // };
  function fetchExportButtonColor() {
    if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      // dark mode
      return "secondary";
    } else {
      return "primary";
    }
  }
  const exportButtonColor = fetchExportButtonColor();

  return (
    <div>
      <Dialog
        open={openExportDialog}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Download</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the filename here (no file extension, i.e. csv).
          </DialogContentText>
          <DialogContentText>
            The file will be saved in user's downloads folder.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Filename:"
            // type="email"
            fullWidth
            onChange={handleTextFieldChange}
            variant="filled"
            defaultValue="myTable"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color={exportButtonColor}>
            Cancel
          </Button>
          <Button onClick={handleDownloadPDF} color={exportButtonColor}>
            Download PDF
          </Button>
          <Button onClick={handleDownloadCSV} color={exportButtonColor}>
            Download CSV
          </Button>
          {(roadLayerType !== "TX100") &&
            <Button onClick={handleDownloadNewCSV} color={exportButtonColor}>
              Save for Later Analysis
            </Button>}
        </DialogActions>
      </Dialog>
    </div>
  );
}
