/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  //    useCallback
} from "react";
import axios from "axios";
import {
  Map,
  TileLayer,
  GeoJSON,
  LayersControl,
  ScaleControl,
  withLeaflet,
  Popup,
  Pane,
} from "react-leaflet";

import Control from "react-leaflet-control";
import {
  API_ROOT,
  COLOR_RHINO,
  MAPBOX_ACCESSTOKEN,
  MAPBOX_ATTRIBUTION,
  tx100_monitored_counties,
  baseLayers,
} from "../common/constants";
import { RhinoQueryContext } from "./RhinoQueryContext";
//import { TX100QueryContext } from "./TX100QueryContext";

// import LoadingOverlay from "react-loading-overlay";
// import BounceLoader from "react-spinners/BounceLoader";

// import { makeStyles } from "@material-ui/core/styles";

import { scaleLinear } from "d3-scale";
import { interpolateTurbo } from "d3-scale-chromatic";
import { color as colorF } from "d3-color";

import HomeIcon from "@material-ui/icons/Home";

import MapTabs from "./mapTabs";
//require('react-leaflet-markercluster/dist/styles.min.css');

import SandMinesLayer from "./layers/SandMinesLayer";
import DisposalWellsLayer from "./layers/DisposalWellsLayer";
import CountyBoundaryLayer from "./layers/CountyBoundaryLayer";
import DistrictBoundaryLayer from "./layers/DistrictBoundaryLayer";
import CommercialBorderCrossingLayer from "./layers/CommercialBorderCrossingLayer";
import NonCommercialBorderCrossingLayer from "./layers/NonCommercialBorderCrossingLayer";
import RailBorderCrossingLayer from "./layers/RailBorderCrossingLayer";
import PortsLayer from "./layers/PortsLayer";
import UTPLayer from "./layers/UTPLayer";
import CargoAirportsLayer from "./layers/CargoAirportsLayer";
import AirportsLayer from "./layers/AirportsLayer";
import TruckParkingLayer from "./layers/TruckParkingLayer";

import Utils from "../common/utils";

import { latLngBounds, latLng } from "leaflet";

import bbox from "@turf/bbox";

import PrintControlDefault from "react-leaflet-easyprint";
import DrawPolygon from "../components/draw-polygon";
import ClearFilter from "../components/clear-filter";

const PrintControl = withLeaflet(PrintControlDefault);

// const useStyles = makeStyles((theme) => ({
//   heading: {
//     fontSize: theme.typography.pxToRem(15),
//     fontWeight: theme.typography.fontWeightBold,
//   },
//   legendContainer: {
//     background: "white",
//     padding: "5px",
//   },
//   legendIcon: {
//     //height: "5%",
//     //width: "auto",
//     //border: "1px",
//     marginRight: "5px",
//   },
//   wrapIcon: {
//     //verticalAlign: 'middle',
//     display: "flex",
//     alignItems: "center",
//   },
//   roadwayLegendLine: {
//     width: "25px",
//     height: "5px",
//     background: "#4d4dff",
//     //float: 'left',
//     marginRight: "5px",
//   },
//   roadSegmentSelector: {
//     backgroundColor: theme.palette.background.paper,
//   },
// }));

/**
 * Renders the Leaflet map with the Rhino road segments
 *
 * @param {*} props React props
 */
function TX100LeafletMap(props) {

  const [homeMapBounds, setHomeMapBounds] = useState(
    latLngBounds(
      latLng(36.704958, -106.980078),
      latLng(25.78985, -93.45912),
      latLng(25.78985, -106.980078),
      latLng(36.704958, -93.45912)
    )
  );

  /* County boundaries in GeoJSON format */
  // const [countyBoundaries, setCountyBoundaries] = useState({
  //   type: "FeatureCollection",
  //   features: [],
  // });
  /* Rhino road segments in GeoJSON format */
  const [rhino, setRhino] = useState({
    type: "FeatureCollection",
    features: [],
  });

  /*loading overlay status to display spinner*/
  // const [isLoading, setIsLoading] = useState(true);

  const [geoJsonKey, setGeoJsonKey] = useState(Date.now());

  const [info, setInfo] = useState(<h4>Hover over a road</h4>);

  /* const [rhinoColor, setRhinoColor] = useState("#ffffff"); */
  const rhinoColorContainer = useRef("#ffffff");
  const rhinoWeightContainer = useRef(3);

  /* the queries set by selecting the summary table rows */
  const [query, dispatch] = useContext(RhinoQueryContext);
  // const [query, dispatch] = useContext(TX100QueryContext);
  const [countyList, setCountyList] = useState([]);

  const {
    statusGuide,
    mapType,
    roadGeomFeatures,
    // setRoadGeomFeatures,
    //isLoading,
    setIsLoading,
  } = props;

  const [filteredGeoJSON, setFilteredGeoJSON] = useState(null);
  // Function to receive the filtered GeoJSON from DrawPolygon
  const handleFilteredGeoJSON = (filteredGeoJSON) => {
    let f = filteredGeoJSON.features;
    let fc = f.map((obj) => {
      obj.color = COLOR_RHINO.normal;
      obj.color_base = COLOR_RHINO.normal;
      obj.weight = 5;
      obj.weight_base = 5;
      return obj;
    });
    setFilteredGeoJSON(fc);
    setIsLoading(false);
  };

  useEffect(() => {
    if (filteredGeoJSON) {
      if (roadsLayerRef.current) {
        const layer = roadsLayerRef.current;
        layer.leafletElement.clearLayers().addData(filteredGeoJSON);
        dispatch({
          type: "setFeatures",
          payload: filteredGeoJSON,
        });
        setIsLoading(false);
      }
    }
  }, [filteredGeoJSON])

  //useEffect(() => {
  //    const fetchCountyBoundaries = async () => {
  //        try {
  //            if (props.counties.length > 0) {
  //                const dataUrl = `${API_ROOT}/counties/${props.counties.join(
  //                    ","
  //                )}/boundary/`;
  //                const response = await axios.get(dataUrl);
  //                setCountyBoundaries(response.data);
  //                /* console.log(response.data);*/
  //            }
  //        } catch (e) {
  //            console.log(e);
  //        }
  //    };
  //    fetchCountyBoundaries();
  //}, [props.counties]);

  // change fetchRhinoNetwork
  //useEffect(() => {
  //    console.log(props.layerID)
  //    const fetchRhinoNetwork = async () => {
  //        try {
  //            if (props.quads.length > 0) {
  //                setIsLoading(true);
  //                var promises;
  //                var layerIDtemp = [props.layerID];
  //                let routes = [props.route];
  //                promises = routes.map(route => {
  //                    var dataUrl = `${API_ROOT}`+route;
  //                    //console.log(dataUrl)
  //                    return axios.get(dataUrl);
  //                });
  //                //var promises = axios.get(dataUrl)
  //                //console.log(promises)
  //                axios.all(promises).then(
  //                    //axios.get(promises).then(
  //                    axios.spread((...responses) => {
  //                        let f = [];
  //                        responses.map(r => {
  //                            f = f.concat(r.data.features);
  //                        });
  //                        let fc = f.map(obj => {
  //                            obj.color = COLOR_RHINO.normal;
  //                            return obj;
  //                        });
  //                        // without waiting (timeout), the geojson layer rendering
  //                        // finished before the data is copied to rhino state.
  //                        // this is a workaround. This should be fixed in different way.
  //                        setRhino({ type: "FeatureCollection", features: fc });
  //                        /*               setTimeout(function() {
  //                          setRhino({ type: "FeatureCollection", features: fc });
  //                        }, 500);
  //            */
  //                        setGeoJsonKey(Date.now());
  //                        setIsLoading(false);
  //                    })
  //                );
  //            }
  //        } catch (e) {
  //            console.log(e);
  //        }
  //    };
  //    fetchRhinoNetwork();
  //}, [props.quads, props.year]);

  useEffect(() => {
    const fetchRhinoNetwork = async () => {
      try {
        //if (props.quads.length > 0) {
        if (props.layerID && roadGeomFeatures && roadGeomFeatures.features.length > 0) {
          // setIsLoading(true);

          // const response = await axios.get(
          //   `${API_ROOT}` +
          //     props.route +
          //     (props.route.includes("?") ? "&" : "?") +
          //     "year=" +
          //     query.yearSelection
          // );
          // let f = response.data.features;
          let f = roadGeomFeatures.features;
         
          const mapBounds_bbox = bbox(
            { type: "FeatureCollection", features: f }
          );

          setHomeMapBounds(
            latLngBounds(
              [mapBounds_bbox[1] * 0.999, mapBounds_bbox[0] * 1.001],
              [mapBounds_bbox[3] * 1.001, mapBounds_bbox[2] * 0.999]
            )
          );

          let fc = f.map((obj) => {
            obj.color = COLOR_RHINO.normal;
            obj.color_base = COLOR_RHINO.normal;
            obj.weight = 5;
            obj.weight_base = 5;
            return obj;
          });
          //.log(fc);
          //console.log("Test f end");
          setRhino({
            type: "FeatureCollection",
            features: fc,
          });
          const layer = roadsLayerRef.current;
          if (layer) {
            layer.leafletElement.clearLayers().addData({
              type: "FeatureCollection",
              features: fc,
            });
          }

          dispatch({
            type: "setFeatures",
            payload: fc,
          });

          // setRoadGeomFeatures({
          //   features: fc,
          //   //  features: roadGeomFeatures.features,
          //   numFeatures: fc.length,
          //   type: "tx100",
          //   minValue: roadGeomFeatures.minValue,
          //   maxValue: roadGeomFeatures.maxValue,
          // });

          setGeoJsonKey(Date.now());
          // setIsLoading(false);

          dispatch({
            type: "setroadSegmentCategoryIndex",
            selection: 0,
          });

          const findUnique = (array, key) => {
            var unique = array
              .map((p) => p.properties[key])
              .filter((key, index, arr) => arr.indexOf(key) === index);
            return unique;
          };
          // const response = await axios.get(process.env.PUBLIC_URL + `/TxDOT_Projects.geojson`);
          let cntyList = findUnique(fc, "co");
          //type={props.layerID === "statewide" ? "rhino" : "tx100"}
          if (statusGuide[0] === "TX100") {
            //if (props.layerID !== "statewide") {
            cntyList = tx100_monitored_counties.map((f) => {
              var nbr = f.cnty_nbr;
              return nbr;
            });
          }
          setCountyList(cntyList);
        }
      } catch (e) {
        console.log(e);
      }
    };
    fetchRhinoNetwork();

    setInfo(<h4>Hover over a road</h4>);
  }, [roadGeomFeatures]);
  //}, [props.layerID, props.year]);

  /* Highlights selected Rhino */

  useEffect(() => {
    /* I am cloning the rhino data.
     * Is it the best way to refresh the map
     * with highlights?
     */
    // let newRhino = { ...rhino };
    const isSelected = query.highlightQuery;
    if (typeof isSelected === "function") {
      if (rhino.features.filter((f) => isSelected(f.properties)).length > 0) {
        rhino.features
          .filter((f) => isSelected(f.properties))
          .forEach((f) => {
            // f.color = COLOR_RHINO.selected;
            f.color = f.color_base;
            f.weight = f.weight_base;
          });
        rhino.features
          .filter((f) => !isSelected(f.properties))
          //.forEach(f => (f.color = COLOR_RHINO.normal));
          .forEach((f) => {
            // f.color = f.color_base;
            f.color = COLOR_RHINO.notSelected;
            f.weight = 2;
          });
        // setTimeout(() => setRhino(newRhino), 100);
      } else {
        rhino.features
          // .filter((f) => isSelected(f.properties))
          .forEach((f) => {
            // f.color = COLOR_RHINO.selected;
            f.color = f.color_base;
            f.weight = f.weight_base;
          });
      }
      const selectedFeatures = rhino.features.filter((f) => {
        // Check if feature has valid geometry
        if (isSelected(f.properties) ) {
          return true;
        }
        return false;
      });

      if (selectedFeatures.length > 0) {
        const mapBounds_bbox = bbox(
          {
            type: "FeatureCollection",
            features: selectedFeatures,
          }
          //{ rhino }
        );
        const selectedFeaturesBounds = latLngBounds(
          [mapBounds_bbox[1] * 0.999, mapBounds_bbox[0] * 1.001],
          [mapBounds_bbox[3] * 1.001, mapBounds_bbox[2] * 0.999]
        );
        mapRef.current.leafletElement.flyToBounds([selectedFeaturesBounds]);
      } else {
        mapRef.current.leafletElement.flyToBounds([homeMapBounds]);
      }
      setGeoJsonKey(Date.now());
    }
  }, [query.highlightQuery]);

  const [minTrkCatValue, setminTrkCatValue] = useState(0);
  const [maxTrkCatValue, setmaxTrkCatValue] = useState(1000000);
  // const [
  //   roadSegmentCategorySelected,
  //   setroadSegmentCategorySelected,
  // ] = useState("none");
  useEffect(() => {
    /* I am cloning the rhino data.
     * Is it the best way to refresh the map
     * with highlights?
     */
    // let newRhino = { ...rhino };

    // setroadSegmentCategorySelected(query.roadSegmentCategory);

    const isSelected = query.highlightQuery;
    if (typeof isSelected === "function") {
      let rdSeqCat = "none";
      //if (query.roadSegmentCategory === "none") return f.color_normal
      if (query.roadSegmentCategory === "tdly") rdSeqCat = "tdly";
      if (query.roadSegmentCategory === "trank") rdSeqCat = "trank";
      if (query.roadSegmentCategory === "tdmile") rdSeqCat = "tdmile";
      if (query.roadSegmentCategory === "ttci") rdSeqCat = "ttci";
      if (query.roadSegmentCategory === "tpti") rdSeqCat = "tpti";
      if (query.roadSegmentCategory === "tcost") rdSeqCat = "tcost";

      function rgbToHex(r, g, b) {
        return (
          "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)
        );
      }

      function colorScale(height, minValue, maxValue) {
        //const color = d3.color(d3.interpolateRdYlGn(linearScale(height)));
        //const color = d3.color(d3.interpolatePuRd(linearScale(height)));
        //const color = d3.color(d3.interpolateOrRd(linearScale(height)));

        const linearScale = scaleLinear()
          .domain([minValue, maxValue])
          .range([0, 1]);

        //const color = colorF(interpolateTurbo(linearScale(height)));
        let color = colorF(interpolateTurbo(linearScale(height)));
        if (height === null)
          color = colorF(interpolateTurbo(linearScale(minValue)));

        return rgbToHex(color.r, color.g, color.b);
      }
      function colorScaleInv(height, minValue, maxValue) {
        //const color = d3.color(d3.interpolateRdYlGn(linearScale(height)));
        //const color = d3.color(d3.interpolatePuRd(linearScale(height)));
        //const color = d3.color(d3.interpolateOrRd(linearScale(height)));

        const linearScale = scaleLinear()
          .domain([minValue, maxValue])
          .range([1, 0]);

        //const color = colorF(interpolateTurbo(linearScale(height)));
        let color = colorF(interpolateTurbo(linearScale(height)));
        if (height === null)
          color = colorF(interpolateTurbo(linearScale(maxValue)));

        return rgbToHex(color.r, color.g, color.b);
      }

      function weightScale(height, minValue, maxValue) {
        const linearScale = scaleLinear()
          .domain([minValue, maxValue])
          .range([0.1, 3]);

        const weight = linearScale(height);

        return weight;
      }
      function weightScaleInv(height, minValue, maxValue) {
        const linearScale = scaleLinear()
          .domain([minValue, maxValue])
          .range([3, 0.1]);

        const weight = linearScale(height);

        return weight;
      }

      let minValue = 10000000;
      let maxValue = 0;
      function maxCalc(compVal, value) {
        if (value !== null) {
          return Math.max(compVal, value);
        } else {
          return compVal;
        }
      }
      function minCalc(compVal, value) {
        if (value !== null) {
          return Math.min(compVal, value);
        } else {
          return compVal;
        }
      }
      rhino.features.forEach(
        (f) => (
          (maxValue = maxCalc(maxValue, f.properties[rdSeqCat])),
          (minValue = minCalc(minValue, f.properties[rdSeqCat]))
        )
      );

      // rhino.features.forEach(
      //   (f) => (
      //     (maxValue = Math.max(maxValue, f.properties[rdSeqCat])),
      //     (minValue = Math.min(minValue, f.properties[rdSeqCat]))
      //   )
      // );

      if (query.roadSegmentCategory === "tdly")
        setminTrkCatValue(minValue.toFixed(0));
      if (query.roadSegmentCategory === "trank")
        setminTrkCatValue(minValue.toFixed(0));
      if (query.roadSegmentCategory === "tdmile")
        setminTrkCatValue(minValue.toFixed(2));
      if (query.roadSegmentCategory === "ttci")
        setminTrkCatValue(minValue.toFixed(2));
      if (query.roadSegmentCategory === "tpti")
        setminTrkCatValue(minValue.toFixed(2));
      if (query.roadSegmentCategory === "tcost")
        setminTrkCatValue(minValue.toFixed(2));

      if (query.roadSegmentCategory === "tdly")
        setmaxTrkCatValue(maxValue.toFixed(0));
      if (query.roadSegmentCategory === "trank")
        setmaxTrkCatValue(maxValue.toFixed(0));
      if (query.roadSegmentCategory === "tdmile")
        setmaxTrkCatValue(maxValue.toFixed(2));
      if (query.roadSegmentCategory === "ttci")
        setmaxTrkCatValue(maxValue.toFixed(2));
      if (query.roadSegmentCategory === "tpti")
        setmaxTrkCatValue(maxValue.toFixed(2));
      if (query.roadSegmentCategory === "tcost")
        setmaxTrkCatValue(maxValue.toFixed(2));

      rhino.features
        .filter((f) => isSelected(f.properties))
        .forEach((f) => (f.color = COLOR_RHINO.selected));
      if (query.roadSegmentCategory === "none") {
        rhino.features
          .filter((f) => !isSelected(f.properties))
          .forEach(
            (f) => (
              (f.color_base = COLOR_RHINO.normal),
              (f.color = f.color_base),
              (f.weight_base = 3),
              (f.weight = f.weight_base)
            )
          );
      } else {
        if (query.roadSegmentCategory === "trank") {
          rhino.features
            .filter((f) => !isSelected(f.properties))
            .forEach(
              (f) => (
                (f.color_base = colorScaleInv(
                  f.properties[rdSeqCat],
                  minValue,
                  maxValue
                )),
                (f.color = f.color_base),
                (f.weight_base = Math.max(
                  0.25,
                  weightScaleInv(f.properties[rdSeqCat], minValue, maxValue) * 5
                )),
                (f.weight = f.weight_base)
              )
            );
        } else {
          rhino.features
            .filter((f) => !isSelected(f.properties))
            .forEach(
              (f) => (
                (f.color_base = colorScale(
                  f.properties[rdSeqCat],
                  minValue,
                  maxValue
                )),
                (f.color = f.color_base),
                (f.weight_base = Math.max(
                  0.25,
                  weightScale(f.properties[rdSeqCat], minValue, maxValue) * 5
                )),
                (f.weight = f.weight_base)
              )
            );
        }
      }

      setGeoJsonKey(Date.now());
    }
  }, [query.roadSegmentCategory]);

  /* Build a list of property as an unordered list in jsx
   * @param {*} rprops Rhino road segment properties
   */
  const stylesHoverBox = {
    h6Header: {
      textAlign: "left",
    },
    leftText: {
      textAlign: "left",
      marginBottom: "0.5em",
    },
    leftBullet: {
      textAlign: "left",
      marginLeft: "-25px",
    },
    rightBullet: {
      textAlign: "left",
      marginLeft: "20px",
    },
    leftHeader: {
      textAlign: "left",
      marginLeft: "-35px",
    },
    rightHeader: {
      textAlign: "left",
      marginLeft: "10px",
    },
  };
  const rhinoInfo = (rprops) => {
    return (
      <div className={props.mode} style={{ padding: "5px" }}>
        <h4>
          {/* {rprops.highway_name} ({rprops.frm_dfo} - {rprops.to_dfo}) */}
          {/* {rprops.ria_rte_id} ({rprops.frm_dfo} - {rprops.to_dfo}) */}
          {rprops.road}
        </h4>
        {/* <h6 >{rprops.from_rd} to {rprops.to_rd}</h6> */}
        <h6>From: {rprops.from_road}</h6>
        <h6>To: {rprops.to_road}</h6>
        {/* <p style={stylesHoverBox.leftText}>From/To DFO: {rprops.frm_dfo.toLocaleString()} to {rprops.to_dfo.toLocaleString()}</p> */}
        <p style={stylesHoverBox.leftText}>TX100 ID: {rprops.top100id} </p>
        {/* <p style={stylesHoverBox.leftText}>
          Congestion Stats Year: {rprops.year}{" "}
        </p> */}
        {/* <p style={stylesHoverBox.leftText}>Length of Segment: {rprops.leng.toLocaleString()} </p> */}
        <ul style={stylesHoverBox.leftText}>
          <div style={{ display: "flex" }}>
            <div>
              <b style={stylesHoverBox.leftHeader}>Truck</b>
              <li style={stylesHoverBox.leftBullet}>
                Top 100 Rank: {rprops.trank.toLocaleString()}{" "}
              </li>
              <li style={stylesHoverBox.leftBullet}>
                Hours of Delay: {rprops.tdly.toLocaleString()}{" "}
              </li>
              <li style={stylesHoverBox.leftBullet}>
                Delay Per Mile: {rprops.tdmile.toLocaleString()}{" "}
              </li>
              <li style={stylesHoverBox.leftBullet}>
                Congestion Cost: ${rprops.tcost.toLocaleString()}{" "}
              </li>
            </div>
            <div>
              <b style={stylesHoverBox.rightHeader}>All Vehicles</b>
              <li style={stylesHoverBox.rightBullet}>
                Top 100 Rank: {rprops.arank.toLocaleString()}{" "}
              </li>
              <li style={stylesHoverBox.rightBullet}>
                Hours of Delay: {rprops.dly.toLocaleString()}{" "}
              </li>
              <li style={stylesHoverBox.rightBullet}>
                Delay Per Mile: {rprops.dmile.toLocaleString()}{" "}
              </li>
              <li style={stylesHoverBox.rightBullet}>
                Congestion Cost: ${rprops.cost.toLocaleString()}{" "}
              </li>
            </div>
          </div>
        </ul>
      </div>
    );
  };
  const getRhinoStyle = (feature) => {
    //return { color: feature.color };

    return {
      color: feature.color,
      weight: feature.weight,
      fillOpacity: 0.6,
      opacity: 0.6,
    };
  };

  /* Highlights on mouseOver */
  const handleMouseOver = (feature, e) => {
    const layer = e.target;
    rhinoColorContainer.current = feature.color;
    rhinoWeightContainer.current = feature.weight;
    feature.color = COLOR_RHINO.selected;
    feature.weight = 5;
    layer.setStyle({
      weight: feature.weight,
      color: feature.color,
      dashArray: "",
      fillOpacity: 0.7,
    });
    setInfo(rhinoInfo(feature.properties));
  };

  /* Reset default style on mouseOut */
  const handleMouseOut = (feature, e) => {
    const layer = e.target;
    feature.color = rhinoColorContainer.current;
    feature.weight = rhinoWeightContainer.current;
    layer.setStyle({
      weight: feature.weight,
      color: feature.color,
      dashArray: "",
      fillOpacity: 0.6,
      opacity: 0.6,
    });
  };

  /* inside handleClick query.popUpEnabled doesn't work since it has created
   * at the time of creation and not changed. So I am using useRef and useEffect
   * to reflect the changed value of query.popUpEnabled variable.
   * see https://stackoverflow.com/questions/53845595/wrong-react-hooks-behaviour-with-event-listener
   * */
  const popUp = useRef(false);
  useEffect(() => {
    popUp.current = query.popUpEnabled;
  }, [query.popUpEnabled]);

  /* leaflet's layer work only with text not HTML element created by jsx */
  const formatPopUp = (rprops) => {
    /*
        const id = `${rprops.ria_rte_id}_${rprops.frm_dfo
            .toString()
            .replace(".", "d")}`;
        */
    const id = `${rprops.top100id}`;
    //const fromHereTooltip = "Starting segment";
    return [
      /*`
<div>
  <h5>${rprops.road}</h5>
  <h6>${rprops.from_road} to ${rprops.to_road}</h6>
  <h6>TX100 ID: ${rprops.top100id}</h6>
  
  <h7>Add to data table:
    <a href="#" id="from-here-${id}" title="Starting segment">From here</a>
    <a href="#" id="to-here-${id}" title="Starting segment to select only one segment OR 
      ending segment for multiple segments">To here</a>
  </h7>
   <button id="add-to-table-${id}" title="Add TX100 segment to data table below the map">Add Segment to Data Table</button>
</div>
`
*/
      `
<div>
  <h5>${rprops.road}</h5>
  <h6>${rprops.from_road} to ${rprops.to_road}</h6>
  <h6>TX100 ID: ${rprops.top100id}</h6>
  <div>
      <h7>Add segment to data table:</h7>
      <button id="add-to-table-${id}" title="Add TX100 segment to data table below the map">Add to Data Table</button>
      </div>
</div>
`,
      id,
    ];
  };

  /* Tracks user road selection process*/
  const defaultUserSelection = {
    /* 
        from_ria_rte_id: "",
        from_frm_dfo: Number.Nan,
        from_to_dfo: Number.NaN,
        to_frm_dfo: Number.NaN,
        to_to_dfo: Number.NaN
        */

    top100id: "",
    from_road: "",
    to_road: "",
  };
  const userSelection = useRef(defaultUserSelection);
  /* Resets the previous user selection highlights */
  /*
    const resetUserSelection = ria_rte_id => {
        // let newRhino = { ...rhino };
        rhino.features
            .filter(f => f.properties.ria_rte_id === ria_rte_id)
            .forEach(f => (f.color = COLOR_RHINO.normal));
        // setRhino(newRhino);
        setGeoJsonKey(Date.now());
        userSelection.current = defaultUserSelection;
    };
    */
  const resetUserSelection = (top100id) => {
    // let newRhino = { ...rhino };
    rhino.features
      .filter((f) => f.properties.top100id === top100id)
      .forEach((f) => (f.color = f.color_base)); //COLOR_RHINO.normal));
    // setRhino(newRhino);
    setGeoJsonKey(Date.now());
    userSelection.current = defaultUserSelection;
  };

  //    const highlightUserSelection = () => {
  //        // let newRhino = { ...rhino };
  //        const selection = userSelection.current;
  //        rhino.features
  //            .filter(f => f.properties.ria_rte_id === selection.from_ria_rte_id)
  //            .forEach(f => (f.color = COLOR_RHINO.selected));
  //        rhino.features
  //            .filter(f => f.properties.ria_rte_id !== selection.from_ria_rte_id)
  //            .forEach(f => (f.color = COLOR_RHINO.notSelected));
  //        if (!isNaN(selection.to_to_dfo)) {
  //            const fromDfo = Math.min(selection.from_frm_dfo, selection.to_frm_dfo);
  //            const toDfo = Math.max(selection.from_to_dfo, selection.to_to_dfo);
  //            rhino.features
  //                .filter(
  //                    f =>
  //                        f.properties.ria_rte_id === selection.from_ria_rte_id &&
  //                        (f.properties.frm_dfo >= fromDfo && f.properties.to_dfo <= toDfo)
  //                )
  //                .forEach(f => (f.color = COLOR_RHINO.selectedSection));
  //        } else {
  //            /* if toHere is not selected */
  //            rhino.features.filter(
  //                f =>
  //                    f.properties.ria_rte_id === selection.from_ria_rte_id &&
  //                    f.properties.frm_dfo === selection.from_frm_dfo
  //            )[0].color = COLOR_RHINO.selectedSection;
  //        }
  //        // setRhino(newRhino);
  //        setGeoJsonKey(Date.now());
  //    };

  const highlightUserSelectionTX100 = () => {
    // let newRhino = { ...rhino };
    const selection = userSelection.current;
    rhino.features
      .filter(
        (f) =>
          f.properties.top100id === selection.top100id &&
          f.properties.from_road === selection.from_road &&
          f.properties.to_road === selection.to_road
      )
      .forEach((f) => (f.color = COLOR_RHINO.selected));
    rhino.features
      .filter((f) => f.properties.top100id !== selection.top100id)
      .forEach((f) => (f.color = f.color_base)); //COLOR_RHINO.normal));

    setGeoJsonKey(Date.now());
  };

  /*
        const handleFromHereClick = (rprops, e) => {
            e.preventDefault();
            resetUserSelection(userSelection.current.from_ria_rte_id);
            userSelection.current = {
                from_ria_rte_id: rprops.ria_rte_id,
                from_frm_dfo: rprops.frm_dfo,
                from_to_dfo: rprops.to_dfo
            };
            highlightUserSelection();
        };
        const handleToHereClick = (rprops, e) => {
            e.preventDefault();
            let newSelection = { ...userSelection.current };
            newSelection.to_frm_dfo = rprops.frm_dfo;
            newSelection.to_to_dfo = rprops.to_dfo;
            userSelection.current = newSelection;
            const fromDfo = Math.min(
                newSelection.from_frm_dfo,
                newSelection.to_frm_dfo
            );
            const toDfo = Math.max(newSelection.from_to_dfo, newSelection.to_to_dfo);
            dispatch({
                type: "setUserSelection",
                selection: {
                    ria_rte_id: newSelection.from_ria_rte_id,
                    frm_dfo: fromDfo,
                    to_dfo: toDfo
                }
            });
            highlightUserSelection();
        };
    */
  const handleAddToTableClick = (rprops, e) => {
    e.preventDefault();
    /*
        let newSelection = { ...userSelection.current };
        newSelection.to_frm_dfo = rprops.frm_dfo;
        newSelection.to_to_dfo = rprops.to_dfo;
        userSelection.current = newSelection;
        const fromDfo = Math.min(
            newSelection.from_frm_dfo,
            newSelection.to_frm_dfo
        );
        const toDfo = Math.max(newSelection.from_to_dfo, newSelection.to_to_dfo);
        dispatch({
            type: "setUserSelection",
            selection: {
                ria_rte_id: newSelection.from_ria_rte_id,
                frm_dfo: fromDfo,
                to_dfo: toDfo
            }
        });
        */

    //console.log(rprops.top100id);
    resetUserSelection(userSelection.current.top100id);
    userSelection.current = {
      top100id: rprops.top100id,
      from_road: rprops.from_road,
      to_road: rprops.to_road,
      road: rprops.road,
    };
    dispatch({
      type: "setUserSelectionTX100",
      selection: {
        top100id: rprops.top100id,
        from_road: rprops.from_road,
        to_road: rprops.to_road,
        road: rprops.road,
      },
    });

    highlightUserSelectionTX100();
  };

  /* Binds a popup with the clicked rhino segment and registers event listeners'
   * for "From here" and "To here" links inside the popup.
   * Event listener registerd with classical Javascript way since the popup content
   * was created by Leaflet's bindPopup method not by jsx */
  const handleClick = (feature, layer, e) => {
    if (popUp.current) {
      /* if popUp enabled by selecting the  "My Roads" tab*/
      const props = feature.properties;
      const [text, id] = formatPopUp(props);
      layer.bindPopup(text).openPopup();
      /* 
            const fromHere = document.querySelector(`#from-here-${id}`);
            const toHere = document.querySelector(`#to-here-${id}`);
            fromHere.addEventListener("click", handleFromHereClick.bind(null, props));
            toHere.addEventListener("click", handleToHereClick.bind(null, props));
            */

      const addSegment = document.querySelector(`#add-to-table-${id}`);
      addSegment.addEventListener(
        "click",
        handleAddToTableClick.bind(null, props)
      );
    }
  };

  /* @see See https://jsfiddle.net/thbh99nu/2/ */
  const onEachFeature = (feature, layer) => {
    layer.on({
      mouseover: handleMouseOver.bind(null, feature),
      mouseout: handleMouseOut.bind(null, feature),
      // click: handleClick.bind(null, feature, layer),
    });
  };

  /* have to pass a unique key to Map to re-render leaflet map
   * when the road data is changed. Since the road data
   * changed based on regionId and year, this pass the combination of both
   * as a key. This is a bug in react-leaflet.
   * See https://stackoverflow.com/questions/44155385/rendering-geojson-with-react-leaflet
   */
  const { BaseLayer, Overlay } = LayersControl;

  const [mapZoom, setMapZoom] = useState(props.Zoom);

  const [selectedBaselayer, setselectedBaselayer] = useState(
    query.selectedBaselayer
  );

  useEffect(() => {
    if (query.roadSegmentCategory === "none") {
      setselectedBaselayer("Streets");
    } else {
      setselectedBaselayer(query.selectedBaselayer);
    }
  }, [query.roadSegmentCategory]);
  useEffect(() => {
    dispatch({
      type: "setroadSegmentCategory",
      selection: "none",
    });
  }, [props.route]);

  const createBaseLayer = (props) => {
    if (selectedBaselayer === props.name) {
      return (
        <BaseLayer
          checked
          name={props.name}
          key={props.id}
          style={{ zIndex: 500 }}
        >
          <TileLayer
            url={props.url}
            attribution={MAPBOX_ATTRIBUTION}
            id={props.id}
            access_token={MAPBOX_ACCESSTOKEN}
          />
        </BaseLayer>
      );
    } else {
      return (
        <BaseLayer name={props.name} key={props.id}>
          <TileLayer
            url={props.url}
            attribution={MAPBOX_ATTRIBUTION}
            id={props.id}
            access_token={MAPBOX_ACCESSTOKEN}
          />
        </BaseLayer>
      );
    }
  };

  const countyBoundariesRef = useRef(null);
  const mapRef = useRef(null);
  const districtBoundariesRef = useRef(null);
  const commercialBorderCrossingsRef = useRef(null);
  const nonCommercialBorderCrossingsRef = useRef(null);
  const railBorderCrossingsRef = useRef(null);
  const portsLayerRef = useRef(null);
  const utpLayerRef = useRef(null);
  const CargoAirportsLayerRef = useRef(null);
  const airportsLayerRef = useRef(null);
  const truckParkingLayerRef = useRef(null);
  const roadsLayerRef = useRef(null);

  const printControlRef = useRef();
  const exportControlRef = useRef();

  var a3Size = {
    //width: 1045,
    //height: 715,
    width: 1500,
    height: 715,
    className: "a3CssClass",
    tooltip: "A custom A3 size",
    name: "Cropped Size",
  };

  const [utpMapLayersCount, setutpMapLayersCount] = useState({
    underwayorsoon: 0,
    within4years: 0,
    fivetotenyears: 0,
    tenplusyears: 0,
  });
  //console.log("utpMapLayersCount", utpMapLayersCount);

  function setmapDownloadName(type) {
    const date = new Date();
    const mapDownloadName =
      "tx100-" +
      statusGuide[1].split(" ").join("-") +
      "-" +
      date
        .toLocaleDateString()
        .split("/")
        .join("-") +
      "-" +
      date
        .toLocaleTimeString()
        .split(" ")
        .join("-")
        .split(":")
        .join("-");

    return mapDownloadName;
  }

  const popupRef = useRef(false);
  window.popupRef = popupRef;
  function handlePopupOpen(a, b, c) {
    let featureProps =
      popupRef.current.leafletElement._source.feature.properties;
    setPopupTitle(`${featureProps.road}`);
    setPopupTitle2(`${featureProps.from_road} to ${featureProps.to_road}`);
  }
  const [popupTitle, setPopupTitle] = useState("untitled");
  const [popupTitle2, setPopupTitle2] = useState("untitled");

  function handleAddSegment() {
    let rprops = popupRef.current.leafletElement._source.feature.properties;

    resetUserSelection(userSelection.current.top100id);
    userSelection.current = {
      top100id: rprops.top100id,
      from_road: rprops.from_road,
      to_road: rprops.to_road,
      road: rprops.road,
    };
    dispatch({
      type: "setUserSelectionTX100",
      selection: {
        top100id: rprops.top100id,
        from_road: rprops.from_road,
        to_road: rprops.to_road,
        road: rprops.road,
      },
    });

    highlightUserSelectionTX100();

    popupRef.current.leafletElement.remove(); //closes the popup
  }

  return (
    <Map
      //bounds={bounds}
      bounds={homeMapBounds}
      zoom={mapZoom}
      minZoom={6}
      maxZoom={16}
      attributionContol={true}
      zoomSnap={0.25}
      zoomDelta={0.25}
      //wheelPxPerZoomLevel={30}
      ref={mapRef}
    >
      {/*<Map bounds={props.bounds} maxBounds={props.bounds} zoom={mapZoom} minZoom={6} maxZoom={16} attributionContol={true}>*/}

      {/* <LoadingOverlay
        active={isLoading}
        spinner={<BounceLoader />}
        text={
          props.layerID
            ? "Loading stats layer..."
            : "Please select a layer from the menu on the left..."
        }
        styles={{
          overlay: (base) => ({
            ...base,
            height: "650px",
          }),
        }}
      > */}
      {<SandMinesLayer />}
      {<DisposalWellsLayer />}
      {countyList.length > 0 && (
        <CountyBoundaryLayer
          countyList={countyList}
          layerRef={countyBoundariesRef}
          mapType={mapType}
        />
      )}
      {<DistrictBoundaryLayer layerRef={districtBoundariesRef} />}
      {
        <CommercialBorderCrossingLayer
          layerRef={commercialBorderCrossingsRef}
        />
      }
      {
        <NonCommercialBorderCrossingLayer
          layerRef={nonCommercialBorderCrossingsRef}
        />
      }
      {<RailBorderCrossingLayer layerRef={railBorderCrossingsRef} />}
      {<PortsLayer layerRef={portsLayerRef} />}
      {
        <UTPLayer
          layerRef={utpLayerRef}
          countyList={countyList}
          utpMapLayersCount={utpMapLayersCount}
          setutpMapLayersCount={setutpMapLayersCount}
        />
      }
      {<CargoAirportsLayer layerRef={CargoAirportsLayerRef} />}
      {<AirportsLayer layerRef={airportsLayerRef} />}
      {countyList.length > 0 && (
        <TruckParkingLayer
          layerRef={truckParkingLayerRef}
          countyList={countyList}
        />
      )}
      <DrawPolygon polyRef={mapRef}
        data={roadGeomFeatures}
        statusGuide={statusGuide}
        onFilteredGeoJSON={handleFilteredGeoJSON} />

      <Control position="topleft">
        <ClearFilter data={roadGeomFeatures} mapType={mapType} onFilteredGeoJSON={handleFilteredGeoJSON} />
      </Control>

      <LayersControl position="topleft">
        {baseLayers.map((obj) => createBaseLayer(obj))}

        {rhino.features.length > 0 && (
          <Overlay checked name="Roads" style={{ zIndex: 500 }}>
            <Pane style={{ zIndex: 500 }}>
              <GeoJSON
                /* key={geoJsonKey} */
                data={rhino}
                style={getRhinoStyle}
                onEachFeature={onEachFeature}
                ref={roadsLayerRef}
              >
                <Popup ref={popupRef} onOpen={handlePopupOpen}>
                  <div className="custom-block">
                    <p className="custom-block-layer">
                      <strong>Layer ID:</strong> {props.layerID}
                    </p>
                    <h6 className="custom-block-title">{popupTitle}</h6>
                    <p className="custom-block-text">{popupTitle2}</p>
                    <div>
                      <p className="custom-block-text">
                        To add road segment to the data table, click{" "}
                        <strong>Add segment</strong> below.
                      </p>
                      <p className="from-to-links">
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={handleAddSegment}
                        >
                          Add segment
                        </button>
                      </p>
                    </div>
                  </div>
                </Popup>
              </GeoJSON>
            </Pane>
          </Overlay>
        )}
      </LayersControl>
      {/* </LoadingOverlay> */}

      <Control position="topleft">
        <button
          title="Return to original map zoom and center."
          onClick={() => mapRef.current.leafletElement.flyToBounds([homeMapBounds])}
          className="home-button"
        >
          {" "}
          {/*Increment of -1 used to trigger state change*/}
          <HomeIcon />
        </button>
      </Control>

      <ScaleControl position="bottomleft" />

      <Control position="bottomright">
        <MapTabs
          hoverInfo={info}
          type="tx100"
          numFeatures={rhino.features.length.toLocaleString()}
          minValue={minTrkCatValue}
          maxValue={maxTrkCatValue}
          statusGuide={statusGuide}
          utpMapLayersCount={utpMapLayersCount}
          mode={props.mode}
        />
      </Control>

      <PrintControl
        ref={printControlRef}
        sizeModes={['Current', 'A4Landscape', a3Size,]}
        spinnerBgColor="#00FF00"
        customWindowTitle={"Map Test"}
        hideClasses={[
          "leaflet-control-zoom",
          "leaflet-control-layers",
          "leaflet-control-easyPrint-button-export",
          "leaflet-control-easyPrint-button",
          "home-button",
        ]}
      />
      <PrintControl
        ref={exportControlRef}
        position="topleft"
        sizeModes={[
          "Current",
          // "A4Portrait",
          "A4Landscape",
          a3Size,
        ]}
        title="Export as PNG"
        exportOnly
        hideClasses={[
          "leaflet-control-zoom",
          "leaflet-control-layers",
          "leaflet-control-easyPrint-button-export",
          "leaflet-control-easyPrint-button",
          "home-button",
        ]}
        filename={setmapDownloadName()}
      />
    </Map>
  );
}

export default TX100LeafletMap;
