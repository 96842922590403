import React from "react";

/* for nginix */
/* export const API_ROOT = "/api"; */
/* for yarn start */
// dev api server on Azure
//export const API_ROOT = "https://tti-tcat-api-dev.azurewebsites.net/api";
//export const API_ROOT = "https://tti-tcat-api-prod-dev.azurewebsites.net/api";
//export const API_ROOT = "https://tti-tcat-api-prod.azurewebsites.net/api";
//export const API_ROOT = "https://tti-txcat-api.azurewebsites.net/api";
//export const API_ROOT = "https://tti-txcat-api-dev.azurewebsites.net/api";

export const API_ROOT = "https://txcat-api.azurewebsites.net/tcat";
export const API_ROOT_COMMON = "https://txcat-api.azurewebsites.net/common";

// local api server
//export const API_ROOT = "http://localhost:8000/tcat";
//export const API_ROOT_COMMON = "http://localhost:8000/common";

// Following is test for different Azure config
// export const API_ROOT = "https://tti-tcat-api-prod.azurewebsites.net/api";

// export const whatIsTCATFile = "TCAT Desc 14Jan2021.pdf";
export const whatIsTCATFile = "TCAT_analysis.pdf";
export const whatIsTCAT_tooltip = "What is TCAT?";
// export const basicHelpFile = "TCAT V 2.0 Basic Info 4Feb2021.pdf";
// export const basicHelpFile = "TCAT V 2.1 Basic Info 30July2021.pdf";
// export const basicHelpFile = "TCAT V 2.2 Basic Info 1Dec2021.pdf";
export const basicHelpFile = "TCAT V 2.2 Basic Info 21Feb2022.pdf";
export const usersguide_tooltip = "Quick Reference/Basic Help";
// const methodHelpFile = "Methods-2019-6.pdf";
export const methodHelpFile =
  "https://static.tti.tamu.edu/tti.tamu.edu/documents/TTI-2020-8.pdf";
// const summaryReportFile = "Summary Report-2019-7.pdf";
export const summaryReportFile =
  "https://static.tti.tamu.edu/tti.tamu.edu/documents/TTI-2020-9.pdf";
export const annTruckCongRepCardsLink = "https://atcrtx.tti.tamu.edu/";
export const quarterlyTruckMobilityReportsLink = "https://qtmrtx.tti.tamu.edu/";

export const MAPBOX_ACCESSTOKEN =
  "pk.eyJ1IjoiamlzdW5nIiwiYSI6ImNqaXZ1YW9pNDJ4Nzkza3A4cGRtczhkNnUifQ.l8GpDnBNJtRoFMcId2hpSw";

export const MAPBOX_URL =
  "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={access_token}&optimize=true";
//"https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={access_token}";

export const MAPBOX_STREETS_V10_URL =
  "https://api.tiles.mapbox.com/styles/v1/mapbox/streets-v10/tiles/256/{z}/{x}/{y}?access_token={access_token}";

export const MAPBOX_ATTRIBUTION = `Map data &copy; 
<a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, 
<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>,
Imagery © <a href="https://www.mapbox.com/">Mapbox</a>`;

export const baseLayers = [
  {
    name: "Streets",
    url: MAPBOX_STREETS_V10_URL,
    id: "mapbox/streets-v11",
  },
  {
    name: "Navigation - Day",
    url: MAPBOX_URL,
    id: "mapbox/navigation-guidance-day-v4",
  },
  {
    name: "Navigation - Night",
    url: MAPBOX_URL,
    id: "mapbox/navigation-guidance-night-v4",
  },
  {
    name: "Satellite",
    url: MAPBOX_URL,
    id: "mapbox/satellite-v9",
  },
  {
    name: "Satellite-Streets",
    url: MAPBOX_URL,
    id: "mapbox/satellite-streets-v11",
  },
  {
    name: "Light",
    url: MAPBOX_URL,
    id: "mapbox/light-v10",
  },
  {
    name: "Dark",
    url: MAPBOX_URL,
    id: "mapbox/dark-v10",
  },
];

/* Colors from the "paired" and "accent" color scheme
 * at https://vega.github.io/vega/docs/schemes/#categorical.
 * normal: paired[0] light blue
 * selected: paired[1] dark blue
 * selectedSection: accent[2] light orange
 */
export const COLOR_RHINO = {
  //['#a6cee3','#1f78b4','#b2df8a','#33a02c','#fb9a99','#e31a1c','#fdbf6f','#ff7f00','#cab2d6','#6a3d9a']
  //['#a50026', '#d73027', '#f46d43', '#fdae61', '#fee090', '#e0f3f8', '#abd9e9', '#74add1', '#4575b4', '#313695']
  bucket1: "#a6cee3",
  bucket2: "#1f78b4",
  bucket3: "#b2df8a",
  bucket4: "#33a02c",
  bucket5: "#fb9a99",
  bucket6: "#e31a1c",
  bucket7: "#fdbf6f",
  bucket8: "#ff7f00",
  bucket9: "#cab2d6",
  bucket10: "#6a3d9a",
  //normal: "#1f78b4",
  //normal: "#a6cee3",
  normal: "#4d4dff",
  //normal: "#0080ff",
  //notSelected actually makes roadways disappear.
  //  notSelected: "#d3d3d3",
  notSelected: "#d4d4d4",
  //selected: "#a6cee3",
  selected: "#ff0000",
  selectedSection: "#006400",
};
export const colorContextlayers = {
  sandMines: "#ff7800",
  disposalWells: "#4B0082", //'#e31a1c',
};

export const SUMMARY_VARIABLES_RHINO = [
  {
    variable: "trkdelay",
    summaryName: "Truck Person Hours of Delay",
    definition: "Annual Hours of Truck Delay",
    explanation: `The mileage traveled by all trucks on a highway segment\
 in the peak period over an average day in a year.`,
    formatOptions: { maximumFractionDigits: 0 },
    category: "truck",
    defaultVisibility: true,
  },
  {
    variable: "trkpti80",
    summaryName: "Truck Planning Time Index 80",
    definition: "80th Percentile Truck Planning Time Index",
    explanation: `80th percentile Truck Planning Time Index (PTI)\
 is the ratio of the 80th percentile truck travel time as compared\
 to the truck free-flow travel time.`,
    formatOptions: { maximumFractionDigits: 2 },
    normalizedBy: "trkttivmt",
    category: "truck",
    defaultVisibility: true,
  },
  {
    variable: "trkpti95",
    summaryName: "Truck Planning Time Index 95",
    definition: "95th Percentile Truck Planning Time Index",
    explanation: `95th percentile Truck Planning Time Index (PTI)\
is the ratio of the 95th percentile truck travel time\
as compared to the truck free-flow travel time.`,
    formatOptions: { maximumFractionDigits: 2 },
    normalizedBy: "trkttivmt",
    category: "truck",
    defaultVisibility: true,
  },
  {
    variable: "tottrk", // derived:
    // pct_trk = pct_sadt + pct_cadt;
    // tottrk = Math.round(pct_trk === 0 ? trkttivmt/len_sec : pct_trk/100 * adt_cur)
    summaryName: "Truck AADT",
    definition: "Average Annual Daily Truck Traffic",
    explanation: `The total volume of truck traffic on a highway segment\
 for one year, divided by the number of days in the year.`,
    formatOptions: { maximumFractionDigits: 0 },
    normalizedBy: "len_sec",
    category: "truck",
    defaultVisibility: true,
  },
  {
    variable: "trkttivmt",
    summaryName: "Truck Vehicle Miles of Travel",
    definition: "Daily Truck Vehicle Miles of Travel",
    explanation: `The mileage traveled by all trucks on a highway segment\
 over an average day in a year.`,
    formatOptions: { maximumFractionDigits: 0 },
    category: "truck",
    defaultVisibility: true,
  },
  {
    variable: "trkrefspee",
    summaryName: "Truck Reference Speed",
    definition: "Average Network Truck Free Flow Speed",
    explanation: "The average speed of trucks in uncongested conditions.",
    formatOptions: { maximumFractionDigits: 1 },
    normalizedBy: "trkttivmt",
    category: "truck",
    defaultVisibility: true,
  },
  {
    variable: "trkspeed",
    summaryName: "Truck Congested Speed",
    definition: "Average Network Truck Congested Speed",
    explanation: "The average speed of trucks in congested conditions.",
    formatOptions: { maximumFractionDigits: 2 },
    normalizedBy: "trkttivmt",
    category: "truck",
    defaultVisibility: true,
  },
  {
    variable: "trktti",
    summaryName: "Truck Travel Time Index",
    definition: "Truck Travel Time Index",
    explanation: `Travel Time Index is the ratio of the peak-period travel time\
 for trucks as compared to the free-flow travel time for trucks. This measure\
 is computed for the AM peak period (6:00 a.m. to 9:00 a.m.) and PM peak period\
 (4:00 p.m. to 7:00 p.m.) on weekdays.`,
    formatOptions: { maximumFractionDigits: 2 },
    normalizedBy: "trkttivmt",
    category: "truck",
    defaultVisibility: true,
  },
  {
    variable: "delay",
    summaryName: "Person Hours of Delay",
    definition: "Annual Person Hours of Delay",
    explanation: `Person hours of delay is the difference in travel time\
 from uncogested traffic and congested traffic. This is for all persons\
 in vehicles traveling for a year.`,
    formatOptions: { maximumFractionDigits: 0 },
    category: "all",
    defaultVisibility: false,
  },
  {
    variable: "pti80",
    summaryName: "Planning Time Index 80",
    definition: "80th Percentile Planning Time Index",
    explanation: `80th percentile Planning Time Index (PTI)  is the ratio\
 of the 80th percentile travel time as compared to the free-flow travel time.`,
    formatOptions: { maximumFractionDigits: 2 },
    normalizedBy: "ttivmt",
    category: "all",
    defaultVisibility: false,
  },
  {
    variable: "pti95",
    summaryName: "Planning Time Index 95",
    definition: "95th Percentile Planning Time Index",
    explanation: `95th percentile Planning Time Index (PTI)  is the ratio of\
 the 95th percentile travel time as compared to the free-flow travel time.`,
    formatOptions: { maximumFractionDigits: 2 },
    normalizedBy: "ttivmt",
    category: "all",
    defaultVisibility: false,
  },
  {
    variable: "adt_cur",
    summaryName: "AADT",
    definition: "Average Annual Daily Traffic",
    explanation: `The total volume of traffic on a highway segment\
 for one year, divided by the number of days in the year.`,
    formatOptions: { maximumFractionDigits: 0 }, // rounded to closest integer
    normalizedBy: "len_sec",
    category: "all",
    defaultVisibility: false,
  },

  {
    variable: "vmt", // derived: Math.round(adt_cur * len_sec)
    summaryName: "Vehicle Miles of Travel",
    definition: "Daily Vehicle Miles of Travel",
    explanation: `The mileage traveled by all vehicles on a highway segment\
 over an average day in a year.`,
    formatOptions: { maximumFractionDigits: 0 },
    category: "all",
    defaultVisibility: false,
  },
  {
    variable: "ttivmt",
    summaryName: "Peak Vehicle Miles of Travel",
    definition: "Daily Peak Period Vehicle Miles of Travel",
    explanation: `The mileage traveled by all vehicles on a highway segment\
 in the peak period over an average day in a year.`,
    formatOptions: { maximumFractionDigits: 0 },
    category: "all",
    defaultVisibility: false,
  },
  {
    variable: "congcost",
    summaryName: "Congested Costs",
    definition: "Annual Costs ($) of Congestion",
    explanation: `Congested costs is the annual costs to travelers due to\
 such factors as loss productivity and extra vehicle wear`,
    formatOptions: { maximumFractionDigits: 0 },
    category: "all",
    defaultVisibility: false,
  },
  {
    variable: "ghg",
    summaryName: "Congested CO2 Lbs",
    definition: "Annual Pounds of CO2 Due to Traffic Congestion",
    explanation:
      "Congested pounds of CO2 is the extra CO2 emmitted from vehicles \
 in congested conidtions.",
    formatOptions: { maximumFractionDigits: 0 },
    category: "all",
    defaultVisibility: false,
  },
  {
    variable: "ghgbase",
    summaryName: "Normal CO2 Lbs",
    definition: "Annual Pounds of CO2 Associated with Uncongested Travel",
    explanation: `Congested pounds of CO2 is the CO2 emmitted from vehicles\
 in uncongested conditions.`,
    formatOptions: { maximumFractionDigits: 0 },
    category: "all",
    defaultVisibility: false,
  },
  {
    variable: "len_sec",
    summaryName: "Miles of Highway",
    definition: "Total Highway Miles of Analysis",
    explanation: "The total miles of highway for the roadways being analyzed.",
    formatOptions: { maximumFractionDigits: 1 },
    category: "route",
    defaultVisibility: false,
  },
  {
    variable: "lanemiles", // derived: len_sec * num_lanes
    summaryName: "Lane Miles of Highway",
    definition: "Total Lane Miles of Analysis",
    explanation: `The total lanes*miles of highway for the roadways\
 being analyzed.`,
    formatOptions: { maximumFractionDigits: 1 },
    category: "route",
    defaultVisibility: false,
  },
  {
    variable: "allrefspee",
    summaryName: "Reference Speed",
    definition: "Average Network Free Flow Speed",
    explanation: "The average speed of vehicles in uncongested conditions.",
    formatOptions: { maximumFractionDigits: 1 },
    normalizedBy: "ttivmt",
    category: "all",
    defaultVisibility: false,
  },
  {
    variable: "allspeed",
    summaryName: "Congested Speed",
    definition: "Average Network Congested Speed",
    explanation: "The average speed of vehicles in congested conditions.",
    formatOptions: { maximumFractionDigits: 1 },
    normalizedBy: "ttivmt",
    category: "all",
    defaultVisibility: false,
  },
  {
    variable: "tti",
    summaryName: "Travel Time Index",
    definition: "Travel Time Index",
    explanation: `Travel Time Index is the ratio of the peak-period travel time\
 as compared to the free-flow travel time. This measure is computed for\
 the AM peak period (6:00 a.m. to 9:00 a.m.) and PM peak period\
 (4:00 p.m. to 7:00 p.m.) on weekdays.`,
    formatOptions: { maximumFractionDigits: 2 },
    normalizedBy: "ttivmt",
    category: "all",
    defaultVisibility: false,
  },
  {
    variable: "estimated_vmt_ratio",
    summaryName: "Estimated Coverage - VMT",
    definition: "Ratio of estimated VMT over total VMT",
    formatOptions: { style: "percent", maximumFractionDigits: 2 },
    category: "estimation",
    defaultVisibility: true,
  },
  {
    variable: "estimated_lanemiles_ratio",
    summaryName: "Estimated Coverage - Miles",
    definition: "Ratio of estimated lanemiles over total lanemiles",
    formatOptions: { style: "percent", maximumFractionDigits: 2 },
    category: "estimation",
    defaultVisibility: true,
  },
  // {
  //   variable: "len_sec",
  //   summaryName: "Length of Segment",
  //   definition: "Segment Length",
  //   explanation: `Segment Length`,
  //   formatOptions: { maximumFractionDigits: 2 },
  //   category: "route",
  //   defaultVisibility: false,
  // },
];

export const CATEGORICAL_VARIABLES = [
  {
    variable: "county",
    mapped: "co_name",
    definition: "County Name",
  },
  {
    variable: "hsys_new",
    mapped: "hsys_new",
    definition: "Highway System Designation",
  },
  {
    variable: "ru",
    mapped: "ru_n",
    definition: "Rural Urban Code",
  },
  {
    variable: "sec_nhs",
    mapped: "sec_nhs_n",
    definition: "National Highway System",
  },
  // {
  //     variable: "f_system",
  //     definition: "Functional-Classification (2014)",
  //     // text: function (value) {
  //     //     var f_system_n = {
  //     //         1: 'Interstate',
  //     //         2: 'Other Freeway and Expressway',
  //     //         3: 'Other Principal Arterial',
  //     //         4: 'Minor Arterial',
  //     //         5: 'Major Collector',
  //     //         6: 'Minor Collector',
  //     //         7: 'Local',
  //     //     }
  //     //     return f_system_n[value];
  //     // },
  //     compare: function (a, b) {
  //         return b - a; // reverse order;
  //     },
  //     sortable: true,
  // },
  // {
  //     variable: "fun_sys",
  //     definition: "Functional-Classification (2009)",
  //     compare: function (a, b) {
  //         var ranks = {
  //             'Interstate': 1,
  //             'Other Principal Arterial': 2,
  //             'Minor Arterial': 3,
  //             'Major Collector': 4,
  //             'Minor Collector': 5,
  //             'Other Freeway and Expressway': 7,
  //             'Collector': 9,
  //             'Local': 10,
  //         }
  //         return ranks[b] - ranks[a];
  //     },
  // },
  // {
  //     variable: "hwy_des1",
  //     definition: "Highway Design",
  //     compare: function(a, b) {
  //         var ranks = {
  //             'Highway': 1,
  //             'Interstate': 2,
  //         }
  //         return ranks[b] - ranks[a];
  //     },
  // },
  {
    variable: "hwy",
    mapped: "highway_name",
    definition: "Major Highways",
  },
];

export const TXDOTdist_VARIABLES = [
  {
    variable: "txdot_dist_8",
    mapped: 8,
    definition: "Abilene District",
  },
  {
    variable: "txdot_dist_4",
    mapped: 4,
    definition: "Amarillo District",
  },
  {
    variable: "txdot_dist_19",
    mapped: 19,
    definition: "Atlanta District",
  },
  {
    variable: "txdot_dist_14",
    mapped: 14,
    definition: "Austin District",
  },
  {
    variable: "txdot_dist_20",
    mapped: 20,
    definition: "Beaumont District",
  },
  {
    variable: "txdot_dist_23",
    mapped: 23,
    definition: "Brownwood District",
  },
  {
    variable: "txdot_dist_17",
    mapped: 17,
    definition: "Bryan District",
  },
  {
    variable: "txdot_dist_25",
    mapped: 25,
    definition: "Childress District",
  },
  {
    variable: "txdot_dist_16",
    mapped: 16,
    definition: "Corpus Christi District",
  },
  {
    variable: "txdot_dist_18",
    mapped: 18,
    definition: "Dallas District",
  },
  {
    variable: "txdot_dist_24",
    mapped: 24,
    definition: "El Paso District",
  },
  {
    variable: "txdot_dist_2",
    mapped: 2,
    definition: "Fort Worth District",
  },
  {
    variable: "txdot_dist_12",
    mapped: 12,
    definition: "Houston District",
  },
  {
    variable: "txdot_dist_22",
    mapped: 22,
    definition: "Laredo District",
  },
  {
    variable: "txdot_dist_5",
    mapped: 5,
    definition: "Lubbock District",
  },
  {
    variable: "txdot_dist_11",
    mapped: 11,
    definition: "Lufkin District",
  },
  {
    variable: "txdot_dist_6",
    mapped: 6,
    definition: "Odessa District",
  },
  {
    variable: "txdot_dist_1",
    mapped: 1,
    definition: "Paris District",
  },
  {
    variable: "txdot_dist_21",
    mapped: 21,
    definition: "Pharr District",
  },
  {
    variable: "txdot_dist_7",
    mapped: 7,
    definition: "San Angelo District",
  },
  {
    variable: "txdot_dist_15",
    mapped: 15,
    definition: "San Antonio District",
  },
  {
    variable: "txdot_dist_10",
    mapped: 10,
    definition: "Tyler District",
  },
  {
    variable: "txdot_dist_9",
    mapped: 9,
    definition: "Waco District",
  },
  {
    variable: "txdot_dist_3",
    mapped: 3,
    definition: "Wichita Falls District",
  },
  {
    variable: "txdot_dist_13",
    mapped: 13,
    definition: "Yoakum District",
  },
];

export const CATEGORY_SUMMARY_VARIABLES = [
  {
    variable: "hrs_delay",
    summaryName: "Hours of Delay",
    definition: "Hours of Delay",
    subcategory: ["delay", "trkdelay"],
    defaultVisibility: true,
  },
  {
    variable: "plan_time_index",
    summaryName: "Planning Time Index",
    definition: "Planning Time Index",
    subcategory: ["pti80", "trkpti80", "pti95", "trkpti95"],
    defaultVisibility: false,
  },
];

export const SUMMARY_VARIABLES_TX100 = [
  {
    variable: "trank",
    summaryName: "Truck Top 100 Rank",
    definition: "Truck Top 100 Rank",
    explanation: `Truck Top 100 Rank`,
    formatOptions: { maximumFractionDigits: 0 },
    category: "truck",
    defaultVisibility: true,
  },
  {
    variable: "arank",
    summaryName: "Top 100 Rank",
    definition: "Top 100 Rank",
    explanation: `Top 100 Rank`,
    formatOptions: { maximumFractionDigits: 0 },
    category: "all",
    defaultVisibility: false,
  },
  {
    variable: "tdly",
    summaryName: "Truck Person Hours of Delay",
    definition: "Annual Hours of Truck Delay",
    explanation: `Truck hours of delay is the difference in travel time\
 from uncogested traffic and congested traffic. This is for all persons\
 in vehicles traveling for a year.`,
    formatOptions: { maximumFractionDigits: 0 },
    category: "truck",
    defaultVisibility: true,
  },
  {
    variable: "dly",
    summaryName: "Person Hours of Delay",
    definition: "Annual Person Hours of Delay",
    explanation: `Person hours of delay is the difference in travel time\
 from uncogested traffic and congested traffic. This is for all persons\
 in vehicles traveling for a year.`,
    formatOptions: { maximumFractionDigits: 0 },
    category: "all",
    defaultVisibility: false,
  },
  {
    variable: "dmile",
    summaryName: "Person Hours of Delay per Mile",
    definition: "Annual Person Hours of Delay per Mile",
    explanation: `Person hours of delay per mile.`,
    formatOptions: { maximumFractionDigits: 0 },
    category: "all",
    defaultVisibility: false,
  },
  {
    variable: "tdmile",
    summaryName: "Truck Person Hours of Delay per Mile",
    definition: "Annual Hours of Truck Delay per Mile",
    explanation: `Truck hours of delay  per Mile.`,
    formatOptions: { maximumFractionDigits: 0 },
    category: "truck",
    defaultVisibility: true,
  },
  {
    variable: "ttci",
    summaryName: "Truck Congestion Index",
    definition: "Truck Congestion Index",
    explanation: "Truck Congestion Index",
    formatOptions: { maximumFractionDigits: 2 },
    category: "truck",
    defaultVisibility: true,
  },
  {
    variable: "tpti",
    summaryName: "Truck Planning Time Index",
    definition: "Truck Planning Time Index",
    explanation: "Truck Planning Time Index",
    formatOptions: { maximumFractionDigits: 2 },
    category: "truck",
    defaultVisibility: true,
  },
  {
    variable: "tcost",
    summaryName: "Truck Congested Costs",
    definition: "Annual Costs ($) of Truck Congestion",
    explanation: `Congested costs is the annual costs to travelers due to\
 such factors as loss productivity and extra vehicle wear`,
    formatOptions: { maximumFractionDigits: 0 },
    category: "truck",
    defaultVisibility: true,
  },
  {
    variable: "tci",
    summaryName: "Congestion Index",
    definition: "Congestion Index",
    explanation: "Congestion Index",
    formatOptions: { maximumFractionDigits: 2 },
    category: "all",
    defaultVisibility: false,
  },
  {
    variable: "pti",
    summaryName: "Planning Time Index",
    definition: "Planning Time Index",
    explanation: "Planning Time Index",
    formatOptions: { maximumFractionDigits: 2 },
    category: "all",
    defaultVisibility: false,
  },
  {
    variable: "cost",
    summaryName: "Congested Costs",
    definition: "Annual Costs ($) of Congestion",
    explanation: `Congested costs is the annual costs to travelers due to\
 such factors as loss productivity and extra vehicle wear`,
    formatOptions: { maximumFractionDigits: 0 },
    category: "all",
    defaultVisibility: false,
  },
  {
    variable: "length_tx100seg",
    summaryName: "Miles of Highway",
    definition: "Segment Length",
    explanation: `Segment Length`,
    formatOptions: { maximumFractionDigits: 2 },
    category: "route",
    defaultVisibility: false,
  },
];
export const COUNTY_LIST = [
  {
    name: "Atascosa",
    id: 7,
  },
  {
    name: "Matagorda",
    id: 158,
  },
  {
    name: "Jackson",
    id: 121,
  },
  {
    name: "De Witt",
    id: 62,
  },
  {
    name: "Wilson",
    id: 247,
  },
  {
    name: "Kinney",
    id: 136,
  },
  {
    name: "Uvalde",
    id: 232,
  },
  {
    name: "Lavaca",
    id: 143,
  },
  {
    name: "Brazoria",
    id: 20,
  },
  {
    name: "Wharton",
    id: 241,
  },
  {
    name: "Presidio",
    id: 189,
  },
  {
    name: "Burleson",
    id: 26,
  },
  {
    name: "Williamson",
    id: 246,
  },
  {
    name: "Grimes",
    id: 94,
  },
  {
    name: "Llano",
    id: 150,
  },
  {
    name: "Mason",
    id: 157,
  },
  {
    name: "San Jacinto",
    id: 204,
  },
  {
    name: "Brazos",
    id: 21,
  },
  {
    name: "Burnet",
    id: 27,
  },
  {
    name: "Menard",
    id: 164,
  },
  {
    name: "Schleicher",
    id: 207,
  },
  {
    name: "Crockett",
    id: 53,
  },
  {
    name: "Walker",
    id: 236,
  },
  {
    name: "Milam",
    id: 166,
  },
  {
    name: "Medina",
    id: 163,
  },
  {
    name: "Galveston",
    id: 85,
  },
  {
    name: "Bexar",
    id: 15,
  },
  {
    name: "Gonzales",
    id: 90,
  },
  {
    name: "Fort Bend",
    id: 80,
  },
  {
    name: "Guadalupe",
    id: 95,
  },
  {
    name: "Madison",
    id: 154,
  },
  {
    name: "Jeff Davis",
    id: 123,
  },
  {
    name: "Tyler",
    id: 229,
  },
  {
    name: "Polk",
    id: 187,
  },
  {
    name: "Jasper",
    id: 122,
  },
  {
    name: "Bandera",
    id: 10,
  },
  {
    name: "Colorado",
    id: 45,
  },
  {
    name: "Chambers",
    id: 36,
  },
  {
    name: "Comal",
    id: 46,
  },
  {
    name: "Real",
    id: 193,
  },
  {
    name: "Caldwell",
    id: 28,
  },
  {
    name: "Kendall",
    id: 131,
  },
  {
    name: "Austin",
    id: 8,
  },
  {
    name: "Fayette",
    id: 76,
  },
  {
    name: "Harris",
    id: 102,
  },
  {
    name: "Edwards",
    id: 70,
  },
  {
    name: "Newton",
    id: 176,
  },
  {
    name: "Pecos",
    id: 186,
  },
  {
    name: "Kerr",
    id: 133,
  },
  {
    name: "Val Verde",
    id: 233,
  },
  {
    name: "Waller",
    id: 237,
  },
  {
    name: "Jefferson",
    id: 124,
  },
  {
    name: "Hays",
    id: 106,
  },
  {
    name: "Cameron",
    id: 31,
  },
  {
    name: "Willacy",
    id: 245,
  },
  {
    name: "Starr",
    id: 214,
  },
  {
    name: "Hidalgo",
    id: 109,
  },
  {
    name: "Brooks",
    id: 24,
  },
  {
    name: "Kenedy",
    id: 66,
  },
  {
    name: "Robertson",
    id: 198,
  },
  {
    name: "Lampasas",
    id: 141,
  },
  {
    name: "Trinity",
    id: 228,
  },
  {
    name: "San Saba",
    id: 206,
  },
  {
    name: "McCulloch",
    id: 160,
  },
  {
    name: "Zapata",
    id: 253,
  },
  {
    name: "Jim Hogg",
    id: 125,
  },
  {
    name: "Kleberg",
    id: 137,
  },
  {
    name: "Nueces",
    id: 178,
  },
  {
    name: "Duval",
    id: 67,
  },
  {
    name: "Jim Wells",
    id: 126,
  },
  {
    name: "San Patricio",
    id: 205,
  },
  {
    name: "Webb",
    id: 240,
  },
  {
    name: "Aransas",
    id: 4,
  },
  {
    name: "Refugio",
    id: 196,
  },
  {
    name: "Dimmit",
    id: 64,
  },
  {
    name: "La Salle",
    id: 142,
  },
  {
    name: "McMullen",
    id: 162,
  },
  {
    name: "Bee",
    id: 13,
  },
  {
    name: "Calhoun",
    id: 29,
  },
  {
    name: "Live Oak",
    id: 149,
  },
  {
    name: "Goliad",
    id: 89,
  },
  {
    name: "Maverick",
    id: 159,
  },
  {
    name: "Reagan",
    id: 192,
  },
  {
    name: "Zavala",
    id: 254,
  },
  {
    name: "Frio",
    id: 83,
  },
  {
    name: "Victoria",
    id: 235,
  },
  {
    name: "Karnes",
    id: 129,
  },
  {
    name: "Hudspeth",
    id: 116,
  },
  {
    name: "Anderson",
    id: 1,
  },
  {
    name: "El Paso",
    id: 72,
  },
  {
    name: "Bosque",
    id: 18,
  },
  {
    name: "Cherokee",
    id: 37,
  },
  {
    name: "Comanche",
    id: 47,
  },
  {
    name: "Hill",
    id: 110,
  },
  {
    name: "Hall",
    id: 97,
  },
  {
    name: "Somervell",
    id: 213,
  },
  {
    name: "Navarro",
    id: 175,
  },
  {
    name: "Henderson",
    id: 108,
  },
  {
    name: "Callahan",
    id: 30,
  },
  {
    name: "Rusk",
    id: 201,
  },
  {
    name: "Eastland",
    id: 68,
  },
  {
    name: "Taylor",
    id: 221,
  },
  {
    name: "Erath",
    id: 73,
  },
  {
    name: "Nolan",
    id: 177,
  },
  {
    name: "Panola",
    id: 183,
  },
  {
    name: "Mitchell",
    id: 168,
  },
  {
    name: "Howard",
    id: 115,
  },
  {
    name: "Martin",
    id: 156,
  },
  {
    name: "Upton",
    id: 231,
  },
  {
    name: "Andrews",
    id: 2,
  },
  {
    name: "Hood",
    id: 112,
  },
  {
    name: "Johnson",
    id: 127,
  },
  {
    name: "Ellis",
    id: 71,
  },
  {
    name: "Smith",
    id: 212,
  },
  {
    name: "Gregg",
    id: 93,
  },
  {
    name: "Kaufman",
    id: 130,
  },
  {
    name: "Harrison",
    id: 103,
  },
  {
    name: "Van Zandt",
    id: 234,
  },
  {
    name: "Shackelford",
    id: 209,
  },
  {
    name: "Jones",
    id: 128,
  },
  {
    name: "Stephens",
    id: 215,
  },
  {
    name: "Houston",
    id: 114,
  },
  {
    name: "Crane",
    id: 52,
  },
  {
    name: "Ward",
    id: 238,
  },
  {
    name: "Tom Green",
    id: 226,
  },
  {
    name: "Leon",
    id: 145,
  },
  {
    name: "Coryell",
    id: 50,
  },
  {
    name: "Mills",
    id: 167,
  },
  {
    name: "Sabine",
    id: 202,
  },
  {
    name: "Fisher",
    id: 77,
  },
  {
    name: "San Augustine",
    id: 203,
  },
  {
    name: "Borden",
    id: 17,
  },
  {
    name: "Scurry",
    id: 208,
  },
  {
    name: "Orange",
    id: 181,
  },
  {
    name: "Bastrop",
    id: 11,
  },
  {
    name: "Washington",
    id: 239,
  },
  {
    name: "Gillespie",
    id: 87,
  },
  {
    name: "Glasscock",
    id: 88,
  },
  {
    name: "Blanco",
    id: 16,
  },
  {
    name: "Lee",
    id: 144,
  },
  {
    name: "Liberty",
    id: 146,
  },
  {
    name: "Travis",
    id: 227,
  },
  {
    name: "Hardin",
    id: 101,
  },
  {
    name: "Terrell",
    id: 222,
  },
  {
    name: "Montgomery",
    id: 170,
  },
  {
    name: "Sutton",
    id: 218,
  },
  {
    name: "Kimble",
    id: 134,
  },
  {
    name: "Brewster",
    id: 22,
  },
  {
    name: "Bell",
    id: 14,
  },
  {
    name: "Irion",
    id: 119,
  },
  {
    name: "Falls",
    id: 74,
  },
  {
    name: "Concho",
    id: 48,
  },
  {
    name: "Angelina",
    id: 3,
  },
  {
    name: "Limestone",
    id: 147,
  },
  {
    name: "Midland",
    id: 165,
  },
  {
    name: "McLennan",
    id: 161,
  },
  {
    name: "Nacogdoches",
    id: 174,
  },
  {
    name: "Hamilton",
    id: 98,
  },
  {
    name: "Loving",
    id: 151,
  },
  {
    name: "Reeves",
    id: 195,
  },
  {
    name: "Freestone",
    id: 82,
  },
  {
    name: "Coleman",
    id: 42,
  },
  {
    name: "Runnels",
    id: 200,
  },
  {
    name: "Brown",
    id: 25,
  },
  {
    name: "Coke",
    id: 41,
  },
  {
    name: "Culberson",
    id: 55,
  },
  {
    name: "Sterling",
    id: 216,
  },
  {
    name: "Ector",
    id: 69,
  },
  {
    name: "Shelby",
    id: 210,
  },
  {
    name: "Winkler",
    id: 248,
  },
  {
    name: "Tarrant",
    id: 220,
  },
  {
    name: "Dallas",
    id: 57,
  },
  {
    name: "Crosby",
    id: 54,
  },
  {
    name: "Lubbock",
    id: 152,
  },
  {
    name: "Archer",
    id: 5,
  },
  {
    name: "Hockley",
    id: 111,
  },
  {
    name: "Cochran",
    id: 40,
  },
  {
    name: "Fannin",
    id: 75,
  },
  {
    name: "Cooke",
    id: 49,
  },
  {
    name: "Grayson",
    id: 92,
  },
  {
    name: "Montague",
    id: 169,
  },
  {
    name: "Lamar",
    id: 139,
  },
  {
    name: "Red River",
    id: 194,
  },
  {
    name: "Clay",
    id: 39,
  },
  {
    name: "Wichita",
    id: 243,
  },
  {
    name: "Foard",
    id: 79,
  },
  {
    name: "Motley",
    id: 173,
  },
  {
    name: "Cottle",
    id: 51,
  },
  {
    name: "Floyd",
    id: 78,
  },
  {
    name: "Hale",
    id: 96,
  },
  {
    name: "Rockwall",
    id: 199,
  },
  {
    name: "Rains",
    id: 190,
  },
  {
    name: "Wood",
    id: 250,
  },
  {
    name: "Camp",
    id: 32,
  },
  {
    name: "Stonewall",
    id: 217,
  },
  {
    name: "Haskell",
    id: 105,
  },
  {
    name: "Kent",
    id: 132,
  },
  {
    name: "Throckmorton",
    id: 224,
  },
  {
    name: "Collingsworth",
    id: 44,
  },
  {
    name: "Garza",
    id: 86,
  },
  {
    name: "Gray",
    id: 91,
  },
  {
    name: "Lynn",
    id: 153,
  },
  {
    name: "Young",
    id: 252,
  },
  {
    name: "Terry",
    id: 223,
  },
  {
    name: "Yoakum",
    id: 251,
  },
  {
    name: "Cass",
    id: 34,
  },
  {
    name: "Collin",
    id: 43,
  },
  {
    name: "Ochiltree",
    id: 179,
  },
  {
    name: "Denton",
    id: 61,
  },
  {
    name: "Hansford",
    id: 99,
  },
  {
    name: "Wise",
    id: 249,
  },
  {
    name: "Hopkins",
    id: 113,
  },
  {
    name: "Morris",
    id: 172,
  },
  {
    name: "Hunt",
    id: 117,
  },
  {
    name: "Franklin",
    id: 81,
  },
  {
    name: "Roberts",
    id: 197,
  },
  {
    name: "Jack",
    id: 120,
  },
  {
    name: "Titus",
    id: 225,
  },
  {
    name: "Delta",
    id: 60,
  },
  {
    name: "Bowie",
    id: 19,
  },
  {
    name: "Knox",
    id: 138,
  },
  {
    name: "King",
    id: 135,
  },
  {
    name: "Dickens",
    id: 63,
  },
  {
    name: "Baylor",
    id: 12,
  },
  {
    name: "Dawson",
    id: 58,
  },
  {
    name: "Gaines",
    id: 84,
  },
  {
    name: "Upshur",
    id: 230,
  },
  {
    name: "Marion",
    id: 155,
  },
  {
    name: "Palo Pinto",
    id: 182,
  },
  {
    name: "Parker",
    id: 184,
  },
  {
    name: "Randall",
    id: 191,
  },
  {
    name: "Deaf Smith",
    id: 59,
  },
  {
    name: "Wheeler",
    id: 242,
  },
  {
    name: "Carson",
    id: 33,
  },
  {
    name: "Potter",
    id: 188,
  },
  {
    name: "Hutchinson",
    id: 118,
  },
  {
    name: "Oldham",
    id: 180,
  },
  {
    name: "Hemphill",
    id: 107,
  },
  {
    name: "Lamb",
    id: 140,
  },
  {
    name: "Bailey",
    id: 9,
  },
  {
    name: "Wilbarger",
    id: 244,
  },
  {
    name: "Hardeman",
    id: 100,
  },
  {
    name: "Childress",
    id: 38,
  },
  {
    name: "Briscoe",
    id: 23,
  },
  {
    name: "Swisher",
    id: 219,
  },
  {
    name: "Castro",
    id: 35,
  },
  {
    name: "Parmer",
    id: 185,
  },
  {
    name: "Donley",
    id: 65,
  },
  {
    name: "Armstrong",
    id: 6,
  },
  {
    name: "Lipscomb",
    id: 148,
  },
  {
    name: "Sherman",
    id: 211,
  },
  {
    name: "Dallam",
    id: 56,
  },
  {
    name: "Moore",
    id: 171,
  },
  {
    name: "Hartley",
    id: 104,
  },
];

export const MPO_LIST = [
  {
    id: 166,
    name: "Abilene MPO",
  },
  {
    id: 120,
    name: "Amarillo MPO",
  },
  {
    id: 90,
    name: "Capital Area MPO",
  },
  {
    id: 135,
    name: "Beaumont - Port Arthur MPO",
  },
  {
    id: 230,
    name: "Brownsville MPO",
  },
  {
    id: 249,
    name: "Bryan College Station MPO",
  },
  {
    id: 96,
    name: "Corpus Christi MPO",
  },
  {
    id: 282,
    name: "North Central Texas COG",
  },
  {
    id: 66,
    name: "El Paso MPO",
  },
  {
    id: 230,
    name: "Rio Grande Valley MPO",
  },
  {
    id: 15,
    name: "Houston-Galveston Area Council",
  },
  {
    id: 277,
    name: "Killeen-Temple MPO",
  },
  {
    id: 205,
    name: "Laredo & Webb County Area MPO",
  },
  {
    id: 361,
    name: "Longview MPO",
  },
  {
    id: 122,
    name: "Lubbock MPO",
  },
  {
    id: 174,
    name: "Permian Basin MPO",
  },
  {
    id: 208,
    name: "San Angelo MPO",
  },
  {
    id: 28,
    name: "Alamo Area MPO",
  },
  {
    id: 232,
    name: "Grayson County MPO",
  },
  {
    id: 211,
    name: "Texarkana MPO",
  },
  {
    id: 213,
    name: "Tyler Area MPO",
  },
  {
    id: 363,
    name: "Victoria MPO",
  },
  {
    id: 140,
    name: "Waco MPO",
  },
  {
    id: 151,
    name: "Wichita Falls MPO",
  }, //,

  // {
  //   "id": 201,
  //   "name": "Unknown MPO 201"
  // },

  // {
  //   "id": 248,
  //   "name": "Unknown MPO 248"
  // }
];

const colourOptions = [
  { value: "ocean", label: "Ocean", color: "#00B8D9", isFixed: true },
  { value: "blue", label: "Blue", color: "#0052CC", isDisabled: true },
  { value: "purple", label: "Purple", color: "#5243AA" },
  { value: "red", label: "Red", color: "#FF5630", isFixed: true },
  { value: "orange", label: "Orange", color: "#FF8B00" },
  { value: "yellow", label: "Yellow", color: "#FFC400" },
  { value: "green", label: "Green", color: "#36B37E" },
  { value: "forest", label: "Forest", color: "#00875A" },
  { value: "slate", label: "Slate", color: "#253858" },
  { value: "silver", label: "Silver", color: "#666666" },
];
export const groupedOptions = [
  {
    label: "Colours",
    options: colourOptions,
  },
];
export const ROAD_LIST = [
  {
    value: "FM0001-KG",
    label: "FM0001",
  },
  {
    value: "FM0002-KG",
    label: "FM0002",
  },
  {
    value: "FM0003-KG",
    label: "FM0003",
  },
  {
    value: "FM0004-KG",
    label: "FM0004",
  },
  {
    value: "FM0005-KG",
    label: "FM0005",
  },
  {
    value: "FM0006-KG",
    label: "FM0006",
  },
  {
    value: "FM0008-KG",
    label: "FM0008",
  },
  {
    value: "FM0009-KG",
    label: "FM0009",
  },
  {
    value: "FM0010-KG",
    label: "FM0010",
  },
  {
    value: "FM0011-KG",
    label: "FM0011",
  },
  {
    value: "FM0013-KG",
    label: "FM0013",
  },
  {
    value: "FM0014-KG",
    label: "FM0014",
  },
  {
    value: "FM0015-KG",
    label: "FM0015",
  },
  {
    value: "FM0016-KG",
    label: "FM0016",
  },
  {
    value: "FM0017-KG",
    label: "FM0017",
  },
  {
    value: "FM0018-KG",
    label: "FM0018",
  },
  {
    value: "FM0019-KG",
    label: "FM0019",
  },
  {
    value: "FM0020-KG",
    label: "FM0020",
  },
  {
    value: "FM0021-KG",
    label: "FM0021",
  },
  {
    value: "FM0022-KG",
    label: "FM0022",
  },
  {
    value: "FM0023-KG",
    label: "FM0023",
  },
  {
    value: "FM0024-KG",
    label: "FM0024",
  },
  {
    value: "FM0026-KG",
    label: "FM0026",
  },
  {
    value: "FM0027-KG",
    label: "FM0027",
  },
  {
    value: "FM0028-KG",
    label: "FM0028",
  },
  {
    value: "FM0030-KG",
    label: "FM0030",
  },
  {
    value: "FM0031-KG",
    label: "FM0031",
  },
  {
    value: "FM0034-KG",
    label: "FM0034",
  },
  {
    value: "FM0035-KG",
    label: "FM0035",
  },
  {
    value: "FM0036-KG",
    label: "FM0036",
  },
  {
    value: "FM0037-KG",
    label: "FM0037",
  },
  {
    value: "FM0038-KG",
    label: "FM0038",
  },
  {
    value: "FM0039-KG",
    label: "FM0039",
  },
  {
    value: "FM0040-KG",
    label: "FM0040",
  },
  {
    value: "FM0041-KG",
    label: "FM0041",
  },
  {
    value: "FM0043-KG",
    label: "FM0043",
  },
  {
    value: "FM0044-KG",
    label: "FM0044",
  },
  {
    value: "FM0045-KG",
    label: "FM0045",
  },
  {
    value: "FM0046-KG",
    label: "FM0046",
  },
  {
    value: "FM0047-KG",
    label: "FM0047",
  },
  {
    value: "FM0048-KG",
    label: "FM0048",
  },
  {
    value: "FM0049-KG",
    label: "FM0049",
  },
  {
    value: "FM0050-KG",
    label: "FM0050",
  },
  {
    value: "FM0051-KG",
    label: "FM0051",
  },
  {
    value: "FM0052-KG",
    label: "FM0052",
  },
  {
    value: "FM0054-KG",
    label: "FM0054",
  },
  {
    value: "FM0055-KG",
    label: "FM0055",
  },
  {
    value: "FM0056-KG",
    label: "FM0056",
  },
  {
    value: "FM0057-KG",
    label: "FM0057",
  },
  {
    value: "FM0058-KG",
    label: "FM0058",
  },
  {
    value: "FM0059-KG",
    label: "FM0059",
  },
  {
    value: "FM0060-KG",
    label: "FM0060",
  },
  {
    value: "FM0061-KG",
    label: "FM0061",
  },
  {
    value: "FM0062-KG",
    label: "FM0062",
  },
  {
    value: "FM0064-KG",
    label: "FM0064",
  },
  {
    value: "FM0065-KG",
    label: "FM0065",
  },
  {
    value: "FM0066-KG",
    label: "FM0066",
  },
  {
    value: "FM0067-KG",
    label: "FM0067",
  },
  {
    value: "FM0068-KG",
    label: "FM0068",
  },
  {
    value: "FM0069-KG",
    label: "FM0069",
  },
  {
    value: "FM0070-KG",
    label: "FM0070",
  },
  {
    value: "FM0071-KG",
    label: "FM0071",
  },
  {
    value: "FM0072-KG",
    label: "FM0072",
  },
  {
    value: "FM0073-KG",
    label: "FM0073",
  },
  {
    value: "FM0074-KG",
    label: "FM0074",
  },
  {
    value: "FM0075-KG",
    label: "FM0075",
  },
  {
    value: "FM0076-KG",
    label: "FM0076",
  },
  {
    value: "FM0077-KG",
    label: "FM0077",
  },
  {
    value: "FM0078-KG",
    label: "FM0078",
  },
  {
    value: "FM0079-KG",
    label: "FM0079",
  },
  {
    value: "FM0080-KG",
    label: "FM0080",
  },
  {
    value: "FM0081-KG",
    label: "FM0081",
  },
  {
    value: "FM0082-KG",
    label: "FM0082",
  },
  {
    value: "FM0083-KG",
    label: "FM0083",
  },
  {
    value: "FM0084-KG",
    label: "FM0084",
  },
  {
    value: "FM0085-KG",
    label: "FM0085",
  },
  {
    value: "FM0086-KG",
    label: "FM0086",
  },
  {
    value: "FM0087-KG",
    label: "FM0087",
  },
  {
    value: "FM0088-KG",
    label: "FM0088",
  },
  {
    value: "FM0089-KG",
    label: "FM0089",
  },
  {
    value: "FM0090-KG",
    label: "FM0090",
  },
  {
    value: "FM0091-KG",
    label: "FM0091",
  },
  {
    value: "FM0092-KG",
    label: "FM0092",
  },
  {
    value: "FM0093-KG",
    label: "FM0093",
  },
  {
    value: "FM0094-KG",
    label: "FM0094",
  },
  {
    value: "FM0095-KG",
    label: "FM0095",
  },
  {
    value: "FM0096-KG",
    label: "FM0096",
  },
  {
    value: "FM0097-KG",
    label: "FM0097",
  },
  {
    value: "FM0098-KG",
    label: "FM0098",
  },
  {
    value: "FM0099-KG",
    label: "FM0099",
  },
  {
    value: "FM0100-KG",
    label: "FM0100",
  },
  {
    value: "FM0101-KG",
    label: "FM0101",
  },
  {
    value: "FM0102-KG",
    label: "FM0102",
  },
  {
    value: "FM0103-KG",
    label: "FM0103",
  },
  {
    value: "FM0104-KG",
    label: "FM0104",
  },
  {
    value: "FM0105-KG",
    label: "FM0105",
  },
  {
    value: "FM0106-KG",
    label: "FM0106",
  },
  {
    value: "FM0107-KG",
    label: "FM0107",
  },
  {
    value: "FM0108-KG",
    label: "FM0108",
  },
  {
    value: "FM0109-KG",
    label: "FM0109",
  },
  {
    value: "FM0110-KG",
    label: "FM0110",
  },
  {
    value: "FM0111-KG",
    label: "FM0111",
  },
  {
    value: "FM0112-KG",
    label: "FM0112",
  },
  {
    value: "FM0113-KG",
    label: "FM0113",
  },
  {
    value: "FM0114-KG",
    label: "FM0114",
  },
  {
    value: "FM0115-KG",
    label: "FM0115",
  },
  {
    value: "FM0116-KG",
    label: "FM0116",
  },
  {
    value: "FM0117-KG",
    label: "FM0117",
  },
  {
    value: "FM0118-KG",
    label: "FM0118",
  },
  {
    value: "FM0119-KG",
    label: "FM0119",
  },
  {
    value: "FM0120-KG",
    label: "FM0120",
  },
  {
    value: "FM0121-KG",
    label: "FM0121",
  },
  {
    value: "FM0122-KG",
    label: "FM0122",
  },
  {
    value: "FM0123-KG",
    label: "FM0123",
  },
  {
    value: "FM0124-KG",
    label: "FM0124",
  },
  {
    value: "FM0125-KG",
    label: "FM0125",
  },
  {
    value: "FM0126-KG",
    label: "FM0126",
  },
  {
    value: "FM0127-KG",
    label: "FM0127",
  },
  {
    value: "FM0128-KG",
    label: "FM0128",
  },
  {
    value: "FM0129-KG",
    label: "FM0129",
  },
  {
    value: "FM0130-KG",
    label: "FM0130",
  },
  {
    value: "FM0131-KG",
    label: "FM0131",
  },
  {
    value: "FM0132-KG",
    label: "FM0132",
  },
  {
    value: "FM0133-KG",
    label: "FM0133",
  },
  {
    value: "FM0134-KG",
    label: "FM0134",
  },
  {
    value: "FM0135-KG",
    label: "FM0135",
  },
  {
    value: "FM0136-KG",
    label: "FM0136",
  },
  {
    value: "FM0137-KG",
    label: "FM0137",
  },
  {
    value: "FM0138-KG",
    label: "FM0138",
  },
  {
    value: "FM0139-KG",
    label: "FM0139",
  },
  {
    value: "FM0140-KG",
    label: "FM0140",
  },
  {
    value: "FM0141-KG",
    label: "FM0141",
  },
  {
    value: "FM0142-KG",
    label: "FM0142",
  },
  {
    value: "FM0143-KG",
    label: "FM0143",
  },
  {
    value: "FM0144-KG",
    label: "FM0144",
  },
  {
    value: "FM0145-KG",
    label: "FM0145",
  },
  {
    value: "FM0146-KG",
    label: "FM0146",
  },
  {
    value: "FM0147-KG",
    label: "FM0147",
  },
  {
    value: "FM0148-KG",
    label: "FM0148",
  },
  {
    value: "FM0149-KG",
    label: "FM0149",
  },
  {
    value: "FM0151-KG",
    label: "FM0151",
  },
  {
    value: "FM0153-KG",
    label: "FM0153",
  },
  {
    value: "FM0154-KG",
    label: "FM0154",
  },
  {
    value: "FM0155-KG",
    label: "FM0155",
  },
  {
    value: "FM0156-KG",
    label: "FM0156",
  },
  {
    value: "FM0157-KG",
    label: "FM0157",
  },
  {
    value: "FM0158-KG",
    label: "FM0158",
  },
  {
    value: "FM0159-KG",
    label: "FM0159",
  },
  {
    value: "FM0160-KG",
    label: "FM0160",
  },
  {
    value: "FM0161-KG",
    label: "FM0161",
  },
  {
    value: "FM0162-KG",
    label: "FM0162",
  },
  {
    value: "FM0163-KG",
    label: "FM0163",
  },
  {
    value: "FM0164-KG",
    label: "FM0164",
  },
  {
    value: "FM0166-KG",
    label: "FM0166",
  },
  {
    value: "FM0167-KG",
    label: "FM0167",
  },
  {
    value: "FM0168-KG",
    label: "FM0168",
  },
  {
    value: "FM0170-KG",
    label: "FM0170",
  },
  {
    value: "FM0171-KG",
    label: "FM0171",
  },
  {
    value: "FM0172-KG",
    label: "FM0172",
  },
  {
    value: "FM0173-KG",
    label: "FM0173",
  },
  {
    value: "FM0174-KG",
    label: "FM0174",
  },
  {
    value: "FM0175-KG",
    label: "FM0175",
  },
  {
    value: "FM0176-KG",
    label: "FM0176",
  },
  {
    value: "FM0177-KG",
    label: "FM0177",
  },
  {
    value: "FM0178-KG",
    label: "FM0178",
  },
  {
    value: "FM0179-KG",
    label: "FM0179",
  },
  {
    value: "FM0180-KG",
    label: "FM0180",
  },
  {
    value: "FM0181-KG",
    label: "FM0181",
  },
  {
    value: "FM0182-KG",
    label: "FM0182",
  },
  {
    value: "FM0183-KG",
    label: "FM0183",
  },
  {
    value: "FM0184-KG",
    label: "FM0184",
  },
  {
    value: "FM0185-KG",
    label: "FM0185",
  },
  {
    value: "FM0186-KG",
    label: "FM0186",
  },
  {
    value: "FM0188-KG",
    label: "FM0188",
  },
  {
    value: "FM0190-KG",
    label: "FM0190",
  },
  {
    value: "FM0191-KG",
    label: "FM0191",
  },
  {
    value: "FM0192-KG",
    label: "FM0192",
  },
  {
    value: "FM0193-KG",
    label: "FM0193",
  },
  {
    value: "FM0194-KG",
    label: "FM0194",
  },
  {
    value: "FM0195-KG",
    label: "FM0195",
  },
  {
    value: "FM0196-KG",
    label: "FM0196",
  },
  {
    value: "FM0197-KG",
    label: "FM0197",
  },
  {
    value: "FM0198-KG",
    label: "FM0198",
  },
  {
    value: "FM0199-KG",
    label: "FM0199",
  },
  {
    value: "FM0200-KG",
    label: "FM0200",
  },
  {
    value: "FM0201-KG",
    label: "FM0201",
  },
  {
    value: "FM0202-KG",
    label: "FM0202",
  },
  {
    value: "FM0203-KG",
    label: "FM0203",
  },
  {
    value: "FM0204-KG",
    label: "FM0204",
  },
  {
    value: "FM0205-KG",
    label: "FM0205",
  },
  {
    value: "FM0206-KG",
    label: "FM0206",
  },
  {
    value: "FM0207-KG",
    label: "FM0207",
  },
  {
    value: "FM0209-KG",
    label: "FM0209",
  },
  {
    value: "FM0210-KG",
    label: "FM0210",
  },
  {
    value: "FM0211-KG",
    label: "FM0211",
  },
  {
    value: "FM0212-KG",
    label: "FM0212",
  },
  {
    value: "FM0213-KG",
    label: "FM0213",
  },
  {
    value: "FM0214-KG",
    label: "FM0214",
  },
  {
    value: "FM0215-KG",
    label: "FM0215",
  },
  {
    value: "FM0216-KG",
    label: "FM0216",
  },
  {
    value: "FM0217-KG",
    label: "FM0217",
  },
  {
    value: "FM0218-KG",
    label: "FM0218",
  },
  {
    value: "FM0219-KG",
    label: "FM0219",
  },
  {
    value: "FM0220-KG",
    label: "FM0220",
  },
  {
    value: "FM0221-KG",
    label: "FM0221",
  },
  {
    value: "FM0222-KG",
    label: "FM0222",
  },
  {
    value: "FM0223-KG",
    label: "FM0223",
  },
  {
    value: "FM0224-KG",
    label: "FM0224",
  },
  {
    value: "FM0225-KG",
    label: "FM0225",
  },
  {
    value: "FM0226-KG",
    label: "FM0226",
  },
  {
    value: "FM0227-KG",
    label: "FM0227",
  },
  {
    value: "FM0228-KG",
    label: "FM0228",
  },
  {
    value: "FM0229-KG",
    label: "FM0229",
  },
  {
    value: "FM0230-KG",
    label: "FM0230",
  },
  {
    value: "FM0231-KG",
    label: "FM0231",
  },
  {
    value: "FM0232-KG",
    label: "FM0232",
  },
  {
    value: "FM0233-KG",
    label: "FM0233",
  },
  {
    value: "FM0234-KG",
    label: "FM0234",
  },
  {
    value: "FM0235-KG",
    label: "FM0235",
  },
  {
    value: "FM0236-KG",
    label: "FM0236",
  },
  {
    value: "FM0237-KG",
    label: "FM0237",
  },
  {
    value: "FM0238-KG",
    label: "FM0238",
  },
  {
    value: "FM0239-KG",
    label: "FM0239",
  },
  {
    value: "FM0240-KG",
    label: "FM0240",
  },
  {
    value: "FM0241-KG",
    label: "FM0241",
  },
  {
    value: "FM0242-KG",
    label: "FM0242",
  },
  {
    value: "FM0244-KG",
    label: "FM0244",
  },
  {
    value: "FM0245-KG",
    label: "FM0245",
  },
  {
    value: "FM0246-KG",
    label: "FM0246",
  },
  {
    value: "FM0247-KG",
    label: "FM0247",
  },
  {
    value: "FM0248-KG",
    label: "FM0248",
  },
  {
    value: "FM0249-KG",
    label: "FM0249",
  },
  {
    value: "FM0250-KG",
    label: "FM0250",
  },
  {
    value: "FM0251-KG",
    label: "FM0251",
  },
  {
    value: "FM0252-KG",
    label: "FM0252",
  },
  {
    value: "FM0253-KG",
    label: "FM0253",
  },
  {
    value: "FM0254-KG",
    label: "FM0254",
  },
  {
    value: "FM0256-KG",
    label: "FM0256",
  },
  {
    value: "FM0257-KG",
    label: "FM0257",
  },
  {
    value: "FM0258-KG",
    label: "FM0258",
  },
  {
    value: "FM0259-KG",
    label: "FM0259",
  },
  {
    value: "FM0261-KG",
    label: "FM0261",
  },
  {
    value: "FM0262-KG",
    label: "FM0262",
  },
  {
    value: "FM0263-KG",
    label: "FM0263",
  },
  {
    value: "FM0264-KG",
    label: "FM0264",
  },
  {
    value: "FM0265-KG",
    label: "FM0265",
  },
  {
    value: "FM0266-KG",
    label: "FM0266",
  },
  {
    value: "FM0267-KG",
    label: "FM0267",
  },
  {
    value: "FM0268-KG",
    label: "FM0268",
  },
  {
    value: "FM0269-KG",
    label: "FM0269",
  },
  {
    value: "FM0270-KG",
    label: "FM0270",
  },
  {
    value: "FM0271-KG",
    label: "FM0271",
  },
  {
    value: "FM0272-KG",
    label: "FM0272",
  },
  {
    value: "FM0273-KG",
    label: "FM0273",
  },
  {
    value: "FM0274-KG",
    label: "FM0274",
  },
  {
    value: "FM0275-KG",
    label: "FM0275",
  },
  {
    value: "FM0276-KG",
    label: "FM0276",
  },
  {
    value: "FM0277-KG",
    label: "FM0277",
  },
  {
    value: "FM0278-KG",
    label: "FM0278",
  },
  {
    value: "FM0279-KG",
    label: "FM0279",
  },
  {
    value: "FM0280-KG",
    label: "FM0280",
  },
  {
    value: "FM0281-KG",
    label: "FM0281",
  },
  {
    value: "FM0282-KG",
    label: "FM0282",
  },
  {
    value: "FM0283-KG",
    label: "FM0283",
  },
  {
    value: "FM0284-KG",
    label: "FM0284",
  },
  {
    value: "FM0285-KG",
    label: "FM0285",
  },
  {
    value: "FM0287-KG",
    label: "FM0287",
  },
  {
    value: "FM0288-KG",
    label: "FM0288",
  },
  {
    value: "FM0289-KG",
    label: "FM0289",
  },
  {
    value: "FM0291-KG",
    label: "FM0291",
  },
  {
    value: "FM0292-KG",
    label: "FM0292",
  },
  {
    value: "FM0293-KG",
    label: "FM0293",
  },
  {
    value: "FM0294-KG",
    label: "FM0294",
  },
  {
    value: "FM0295-KG",
    label: "FM0295",
  },
  {
    value: "FM0296-KG",
    label: "FM0296",
  },
  {
    value: "FM0297-KG",
    label: "FM0297",
  },
  {
    value: "FM0298-KG",
    label: "FM0298",
  },
  {
    value: "FM0300-KG",
    label: "FM0300",
  },
  {
    value: "FM0301-KG",
    label: "FM0301",
  },
  {
    value: "FM0302-KG",
    label: "FM0302",
  },
  {
    value: "FM0303-KG",
    label: "FM0303",
  },
  {
    value: "FM0304-KG",
    label: "FM0304",
  },
  {
    value: "FM0305-KG",
    label: "FM0305",
  },
  {
    value: "FM0306-KG",
    label: "FM0306",
  },
  {
    value: "FM0307-KG",
    label: "FM0307",
  },
  {
    value: "FM0308-KG",
    label: "FM0308",
  },
  {
    value: "FM0309-KG",
    label: "FM0309",
  },
  {
    value: "FM0310-KG",
    label: "FM0310",
  },
  {
    value: "FM0311-KG",
    label: "FM0311",
  },
  {
    value: "FM0312-KG",
    label: "FM0312",
  },
  {
    value: "FM0313-KG",
    label: "FM0313",
  },
  {
    value: "FM0314-KG",
    label: "FM0314",
  },
  {
    value: "FM0315-KG",
    label: "FM0315",
  },
  {
    value: "FM0316-KG",
    label: "FM0316",
  },
  {
    value: "FM0317-KG",
    label: "FM0317",
  },
  {
    value: "FM0318-KG",
    label: "FM0318",
  },
  {
    value: "FM0319-KG",
    label: "FM0319",
  },
  {
    value: "FM0320-KG",
    label: "FM0320",
  },
  {
    value: "FM0321-KG",
    label: "FM0321",
  },
  {
    value: "FM0322-KG",
    label: "FM0322",
  },
  {
    value: "FM0323-KG",
    label: "FM0323",
  },
  {
    value: "FM0324-KG",
    label: "FM0324",
  },
  {
    value: "FM0325-KG",
    label: "FM0325",
  },
  {
    value: "FM0326-KG",
    label: "FM0326",
  },
  {
    value: "FM0327-KG",
    label: "FM0327",
  },
  {
    value: "FM0328-KG",
    label: "FM0328",
  },
  {
    value: "FM0329-KG",
    label: "FM0329",
  },
  {
    value: "FM0330-KG",
    label: "FM0330",
  },
  {
    value: "FM0331-KG",
    label: "FM0331",
  },
  {
    value: "FM0332-KG",
    label: "FM0332",
  },
  {
    value: "FM0333-KG",
    label: "FM0333",
  },
  {
    value: "FM0338-KG",
    label: "FM0338",
  },
  {
    value: "FM0339-KG",
    label: "FM0339",
  },
  {
    value: "FM0340-KG",
    label: "FM0340",
  },
  {
    value: "FM0341-KG",
    label: "FM0341",
  },
  {
    value: "FM0342-KG",
    label: "FM0342",
  },
  {
    value: "FM0343-KG",
    label: "FM0343",
  },
  {
    value: "FM0344-KG",
    label: "FM0344",
  },
  {
    value: "FM0345-KG",
    label: "FM0345",
  },
  {
    value: "FM0346-KG",
    label: "FM0346",
  },
  {
    value: "FM0347-KG",
    label: "FM0347",
  },
  {
    value: "FM0348-KG",
    label: "FM0348",
  },
  {
    value: "FM0349-KG",
    label: "FM0349",
  },
  {
    value: "FM0350-KG",
    label: "FM0350",
  },
  {
    value: "FM0351-KG",
    label: "FM0351",
  },
  {
    value: "FM0352-KG",
    label: "FM0352",
  },
  {
    value: "FM0353-KG",
    label: "FM0353",
  },
  {
    value: "FM0354-KG",
    label: "FM0354",
  },
  {
    value: "FM0355-KG",
    label: "FM0355",
  },
  {
    value: "FM0356-KG",
    label: "FM0356",
  },
  {
    value: "FM0357-KG",
    label: "FM0357",
  },
  {
    value: "FM0358-KG",
    label: "FM0358",
  },
  {
    value: "FM0359-KG",
    label: "FM0359",
  },
  {
    value: "FM0360-KG",
    label: "FM0360",
  },
  {
    value: "FM0361-KG",
    label: "FM0361",
  },
  {
    value: "FM0362-KG",
    label: "FM0362",
  },
  {
    value: "FM0363-KG",
    label: "FM0363",
  },
  {
    value: "FM0364-KG",
    label: "FM0364",
  },
  {
    value: "FM0365-KG",
    label: "FM0365",
  },
  {
    value: "FM0366-KG",
    label: "FM0366",
  },
  {
    value: "FM0367-KG",
    label: "FM0367",
  },
  {
    value: "FM0368-KG",
    label: "FM0368",
  },
  {
    value: "FM0369-KG",
    label: "FM0369",
  },
  {
    value: "FM0370-KG",
    label: "FM0370",
  },
  {
    value: "FM0371-KG",
    label: "FM0371",
  },
  {
    value: "FM0372-KG",
    label: "FM0372",
  },
  {
    value: "FM0373-KG",
    label: "FM0373",
  },
  {
    value: "FM0374-KG",
    label: "FM0374",
  },
  {
    value: "FM0375-KG",
    label: "FM0375",
  },
  {
    value: "FM0376-KG",
    label: "FM0376",
  },
  {
    value: "FM0377-KG",
    label: "FM0377",
  },
  {
    value: "FM0378-KG",
    label: "FM0378",
  },
  {
    value: "FM0379-KG",
    label: "FM0379",
  },
  {
    value: "FM0380-KG",
    label: "FM0380",
  },
  {
    value: "FM0381-KG",
    label: "FM0381",
  },
  {
    value: "FM0382-KG",
    label: "FM0382",
  },
  {
    value: "FM0383-KG",
    label: "FM0383",
  },
  {
    value: "FM0384-KG",
    label: "FM0384",
  },
  {
    value: "FM0387-KG",
    label: "FM0387",
  },
  {
    value: "FM0388-KG",
    label: "FM0388",
  },
  {
    value: "FM0389-KG",
    label: "FM0389",
  },
  {
    value: "FM0390-KG",
    label: "FM0390",
  },
  {
    value: "FM0391-KG",
    label: "FM0391",
  },
  {
    value: "FM0392-KG",
    label: "FM0392",
  },
  {
    value: "FM0393-KG",
    label: "FM0393",
  },
  {
    value: "FM0394-KG",
    label: "FM0394",
  },
  {
    value: "FM0395-KG",
    label: "FM0395",
  },
  {
    value: "FM0396-KG",
    label: "FM0396",
  },
  {
    value: "FM0397-KG",
    label: "FM0397",
  },
  {
    value: "FM0398-KG",
    label: "FM0398",
  },
  {
    value: "FM0399-KG",
    label: "FM0399",
  },
  {
    value: "FM0400-KG",
    label: "FM0400",
  },
  {
    value: "FM0401-KG",
    label: "FM0401",
  },
  {
    value: "FM0402-KG",
    label: "FM0402",
  },
  {
    value: "FM0403-KG",
    label: "FM0403",
  },
  {
    value: "FM0405-KG",
    label: "FM0405",
  },
  {
    value: "FM0406-KG",
    label: "FM0406",
  },
  {
    value: "FM0407-KG",
    label: "FM0407",
  },
  {
    value: "FM0408-KG",
    label: "FM0408",
  },
  {
    value: "FM0409-KG",
    label: "FM0409",
  },
  {
    value: "FM0410-KG",
    label: "FM0410",
  },
  {
    value: "FM0411-KG",
    label: "FM0411",
  },
  {
    value: "FM0412-KG",
    label: "FM0412",
  },
  {
    value: "FM0413-KG",
    label: "FM0413",
  },
  {
    value: "FM0414-KG",
    label: "FM0414",
  },
  {
    value: "FM0415-KG",
    label: "FM0415",
  },
  {
    value: "FM0416-KG",
    label: "FM0416",
  },
  {
    value: "FM0417-KG",
    label: "FM0417",
  },
  {
    value: "FM0418-KG",
    label: "FM0418",
  },
  {
    value: "FM0419-KG",
    label: "FM0419",
  },
  {
    value: "FM0420-KG",
    label: "FM0420",
  },
  {
    value: "FM0421-KG",
    label: "FM0421",
  },
  {
    value: "FM0422-KG",
    label: "FM0422",
  },
  {
    value: "FM0423-KG",
    label: "FM0423",
  },
  {
    value: "FM0424-KG",
    label: "FM0424",
  },
  {
    value: "FM0425-KG",
    label: "FM0425",
  },
  {
    value: "FM0426-KG",
    label: "FM0426",
  },
  {
    value: "FM0427-KG",
    label: "FM0427",
  },
  {
    value: "FM0428-KG",
    label: "FM0428",
  },
  {
    value: "FM0429-KG",
    label: "FM0429",
  },
  {
    value: "FM0430-KG",
    label: "FM0430",
  },
  {
    value: "FM0431-KG",
    label: "FM0431",
  },
  {
    value: "FM0432-KG",
    label: "FM0432",
  },
  {
    value: "FM0433-KG",
    label: "FM0433",
  },
  {
    value: "FM0434-KG",
    label: "FM0434",
  },
  {
    value: "FM0435-KG",
    label: "FM0435",
  },
  {
    value: "FM0436-KG",
    label: "FM0436",
  },
  {
    value: "FM0437-KG",
    label: "FM0437",
  },
  {
    value: "FM0438-KG",
    label: "FM0438",
  },
  {
    value: "FM0439-KG",
    label: "FM0439",
  },
  {
    value: "FM0440-KG",
    label: "FM0440",
  },
  {
    value: "FM0441-KG",
    label: "FM0441",
  },
  {
    value: "FM0442-KG",
    label: "FM0442",
  },
  {
    value: "FM0443-KG",
    label: "FM0443",
  },
  {
    value: "FM0444-KG",
    label: "FM0444",
  },
  {
    value: "FM0445-KG",
    label: "FM0445",
  },
  {
    value: "FM0446-KG",
    label: "FM0446",
  },
  {
    value: "FM0447-KG",
    label: "FM0447",
  },
  {
    value: "FM0448-KG",
    label: "FM0448",
  },
  {
    value: "FM0449-KG",
    label: "FM0449",
  },
  {
    value: "FM0450-KG",
    label: "FM0450",
  },
  {
    value: "FM0451-KG",
    label: "FM0451",
  },
  {
    value: "FM0452-KG",
    label: "FM0452",
  },
  {
    value: "FM0453-KG",
    label: "FM0453",
  },
  {
    value: "FM0455-KG",
    label: "FM0455",
  },
  {
    value: "FM0456-KG",
    label: "FM0456",
  },
  {
    value: "FM0457-KG",
    label: "FM0457",
  },
  {
    value: "FM0458-KG",
    label: "FM0458",
  },
  {
    value: "FM0459-KG",
    label: "FM0459",
  },
  {
    value: "FM0460-KG",
    label: "FM0460",
  },
  {
    value: "FM0461-KG",
    label: "FM0461",
  },
  {
    value: "FM0462-KG",
    label: "FM0462",
  },
  {
    value: "FM0463-KG",
    label: "FM0463",
  },
  {
    value: "FM0464-KG",
    label: "FM0464",
  },
  {
    value: "FM0465-KG",
    label: "FM0465",
  },
  {
    value: "FM0466-KG",
    label: "FM0466",
  },
  {
    value: "FM0467-KG",
    label: "FM0467",
  },
  {
    value: "FM0468-KG",
    label: "FM0468",
  },
  {
    value: "FM0469-KG",
    label: "FM0469",
  },
  {
    value: "FM0470-KG",
    label: "FM0470",
  },
  {
    value: "FM0471-KG",
    label: "FM0471",
  },
  {
    value: "FM0472-KG",
    label: "FM0472",
  },
  {
    value: "FM0475-KG",
    label: "FM0475",
  },
  {
    value: "FM0476-KG",
    label: "FM0476",
  },
  {
    value: "FM0477-KG",
    label: "FM0477",
  },
  {
    value: "FM0478-KG",
    label: "FM0478",
  },
  {
    value: "FM0481-KG",
    label: "FM0481",
  },
  {
    value: "FM0482-KG",
    label: "FM0482",
  },
  {
    value: "FM0485-KG",
    label: "FM0485",
  },
  {
    value: "FM0486-KG",
    label: "FM0486",
  },
  {
    value: "FM0487-KG",
    label: "FM0487",
  },
  {
    value: "FM0488-KG",
    label: "FM0488",
  },
  {
    value: "FM0489-KG",
    label: "FM0489",
  },
  {
    value: "FM0490-KG",
    label: "FM0490",
  },
  {
    value: "FM0491-KG",
    label: "FM0491",
  },
  {
    value: "FM0492-KG",
    label: "FM0492",
  },
  {
    value: "FM0493-KG",
    label: "FM0493",
  },
  {
    value: "FM0494-KG",
    label: "FM0494",
  },
  {
    value: "FM0496-KG",
    label: "FM0496",
  },
  {
    value: "FM0498-KG",
    label: "FM0498",
  },
  {
    value: "FM0499-KG",
    label: "FM0499",
  },
  {
    value: "FM0500-KG",
    label: "FM0500",
  },
  {
    value: "FM0502-KG",
    label: "FM0502",
  },
  {
    value: "FM0503-KG",
    label: "FM0503",
  },
  {
    value: "FM0504-KG",
    label: "FM0504",
  },
  {
    value: "FM0506-KG",
    label: "FM0506",
  },
  {
    value: "FM0507-KG",
    label: "FM0507",
  },
  {
    value: "FM0508-KG",
    label: "FM0508",
  },
  {
    value: "FM0509-KG",
    label: "FM0509",
  },
  {
    value: "FM0510-KG",
    label: "FM0510",
  },
  {
    value: "FM0511-KG",
    label: "FM0511",
  },
  {
    value: "FM0512-KG",
    label: "FM0512",
  },
  {
    value: "FM0513-KG",
    label: "FM0513",
  },
  {
    value: "FM0514-KG",
    label: "FM0514",
  },
  {
    value: "FM0515-KG",
    label: "FM0515",
  },
  {
    value: "FM0516-KG",
    label: "FM0516",
  },
  {
    value: "FM0517-KG",
    label: "FM0517",
  },
  {
    value: "FM0518-KG",
    label: "FM0518",
  },
  {
    value: "FM0519-KG",
    label: "FM0519",
  },
  {
    value: "FM0520-KG",
    label: "FM0520",
  },
  {
    value: "FM0521-KG",
    label: "FM0521",
  },
  {
    value: "FM0522-KG",
    label: "FM0522",
  },
  {
    value: "FM0523-KG",
    label: "FM0523",
  },
  {
    value: "FM0524-KG",
    label: "FM0524",
  },
  {
    value: "FM0525-KG",
    label: "FM0525",
  },
  {
    value: "FM0526-KG",
    label: "FM0526",
  },
  {
    value: "FM0528-KG",
    label: "FM0528",
  },
  {
    value: "FM0529-KG",
    label: "FM0529",
  },
  {
    value: "FM0530-KG",
    label: "FM0530",
  },
  {
    value: "FM0531-KG",
    label: "FM0531",
  },
  {
    value: "FM0532-KG",
    label: "FM0532",
  },
  {
    value: "FM0533-KG",
    label: "FM0533",
  },
  {
    value: "FM0534-KG",
    label: "FM0534",
  },
  {
    value: "FM0535-KG",
    label: "FM0535",
  },
  {
    value: "FM0536-KG",
    label: "FM0536",
  },
  {
    value: "FM0537-KG",
    label: "FM0537",
  },
  {
    value: "FM0538-KG",
    label: "FM0538",
  },
  {
    value: "FM0539-KG",
    label: "FM0539",
  },
  {
    value: "FM0540-KG",
    label: "FM0540",
  },
  {
    value: "FM0541-KG",
    label: "FM0541",
  },
  {
    value: "FM0542-KG",
    label: "FM0542",
  },
  {
    value: "FM0543-KG",
    label: "FM0543",
  },
  {
    value: "FM0544-KG",
    label: "FM0544",
  },
  {
    value: "FM0545-KG",
    label: "FM0545",
  },
  {
    value: "FM0546-KG",
    label: "FM0546",
  },
  {
    value: "FM0547-KG",
    label: "FM0547",
  },
  {
    value: "FM0548-KG",
    label: "FM0548",
  },
  {
    value: "FM0549-KG",
    label: "FM0549",
  },
  {
    value: "FM0550-KG",
    label: "FM0550",
  },
  {
    value: "FM0551-KG",
    label: "FM0551",
  },
  {
    value: "FM0552-KG",
    label: "FM0552",
  },
  {
    value: "FM0553-KG",
    label: "FM0553",
  },
  {
    value: "FM0554-KG",
    label: "FM0554",
  },
  {
    value: "FM0555-KG",
    label: "FM0555",
  },
  {
    value: "FM0556-KG",
    label: "FM0556",
  },
  {
    value: "FM0557-KG",
    label: "FM0557",
  },
  {
    value: "FM0558-KG",
    label: "FM0558",
  },
  {
    value: "FM0559-KG",
    label: "FM0559",
  },
  {
    value: "FM0560-KG",
    label: "FM0560",
  },
  {
    value: "FM0561-KG",
    label: "FM0561",
  },
  {
    value: "FM0562-KG",
    label: "FM0562",
  },
  {
    value: "FM0563-KG",
    label: "FM0563",
  },
  {
    value: "FM0565-KG",
    label: "FM0565",
  },
  {
    value: "FM0566-KG",
    label: "FM0566",
  },
  {
    value: "FM0567-KG",
    label: "FM0567",
  },
  {
    value: "FM0568-KG",
    label: "FM0568",
  },
  {
    value: "FM0569-KG",
    label: "FM0569",
  },
  {
    value: "FM0570-KG",
    label: "FM0570",
  },
  {
    value: "FM0571-KG",
    label: "FM0571",
  },
  {
    value: "FM0572-KG",
    label: "FM0572",
  },
  {
    value: "FM0573-KG",
    label: "FM0573",
  },
  {
    value: "FM0574-KG",
    label: "FM0574",
  },
  {
    value: "FM0575-KG",
    label: "FM0575",
  },
  {
    value: "FM0576-KG",
    label: "FM0576",
  },
  {
    value: "FM0577-KG",
    label: "FM0577",
  },
  {
    value: "FM0578-KG",
    label: "FM0578",
  },
  {
    value: "FM0579-KG",
    label: "FM0579",
  },
  {
    value: "FM0580-KG",
    label: "FM0580",
  },
  {
    value: "FM0581-KG",
    label: "FM0581",
  },
  {
    value: "FM0582-KG",
    label: "FM0582",
  },
  {
    value: "FM0583-KG",
    label: "FM0583",
  },
  {
    value: "FM0585-KG",
    label: "FM0585",
  },
  {
    value: "FM0586-KG",
    label: "FM0586",
  },
  {
    value: "FM0587-KG",
    label: "FM0587",
  },
  {
    value: "FM0588-KG",
    label: "FM0588",
  },
  {
    value: "FM0589-KG",
    label: "FM0589",
  },
  {
    value: "FM0590-KG",
    label: "FM0590",
  },
  {
    value: "FM0591-KG",
    label: "FM0591",
  },
  {
    value: "FM0592-KG",
    label: "FM0592",
  },
  {
    value: "FM0593-KG",
    label: "FM0593",
  },
  {
    value: "FM0594-KG",
    label: "FM0594",
  },
  {
    value: "FM0595-KG",
    label: "FM0595",
  },
  {
    value: "FM0596-KG",
    label: "FM0596",
  },
  {
    value: "FM0597-KG",
    label: "FM0597",
  },
  {
    value: "FM0598-KG",
    label: "FM0598",
  },
  {
    value: "FM0599-KG",
    label: "FM0599",
  },
  {
    value: "FM0600-KG",
    label: "FM0600",
  },
  {
    value: "FM0601-KG",
    label: "FM0601",
  },
  {
    value: "FM0602-KG",
    label: "FM0602",
  },
  {
    value: "FM0603-KG",
    label: "FM0603",
  },
  {
    value: "FM0604-KG",
    label: "FM0604",
  },
  {
    value: "FM0605-KG",
    label: "FM0605",
  },
  {
    value: "FM0606-KG",
    label: "FM0606",
  },
  {
    value: "FM0607-KG",
    label: "FM0607",
  },
  {
    value: "FM0608-KG",
    label: "FM0608",
  },
  {
    value: "FM0609-KG",
    label: "FM0609",
  },
  {
    value: "FM0610-KG",
    label: "FM0610",
  },
  {
    value: "FM0611-KG",
    label: "FM0611",
  },
  {
    value: "FM0612-KG",
    label: "FM0612",
  },
  {
    value: "FM0613-KG",
    label: "FM0613",
  },
  {
    value: "FM0614-KG",
    label: "FM0614",
  },
  {
    value: "FM0615-KG",
    label: "FM0615",
  },
  {
    value: "FM0616-KG",
    label: "FM0616",
  },
  {
    value: "FM0617-KG",
    label: "FM0617",
  },
  {
    value: "FM0618-KG",
    label: "FM0618",
  },
  {
    value: "FM0619-KG",
    label: "FM0619",
  },
  {
    value: "FM0621-KG",
    label: "FM0621",
  },
  {
    value: "FM0622-KG",
    label: "FM0622",
  },
  {
    value: "FM0623-KG",
    label: "FM0623",
  },
  {
    value: "FM0624-KG",
    label: "FM0624",
  },
  {
    value: "FM0625-KG",
    label: "FM0625",
  },
  {
    value: "FM0626-KG",
    label: "FM0626",
  },
  {
    value: "FM0627-KG",
    label: "FM0627",
  },
  {
    value: "FM0629-KG",
    label: "FM0629",
  },
  {
    value: "FM0630-KG",
    label: "FM0630",
  },
  {
    value: "FM0631-KG",
    label: "FM0631",
  },
  {
    value: "FM0632-KG",
    label: "FM0632",
  },
  {
    value: "FM0633-KG",
    label: "FM0633",
  },
  {
    value: "FM0634-KG",
    label: "FM0634",
  },
  {
    value: "FM0635-KG",
    label: "FM0635",
  },
  {
    value: "FM0636-KG",
    label: "FM0636",
  },
  {
    value: "FM0637-KG",
    label: "FM0637",
  },
  {
    value: "FM0638-KG",
    label: "FM0638",
  },
  {
    value: "FM0639-KG",
    label: "FM0639",
  },
  {
    value: "FM0640-KG",
    label: "FM0640",
  },
  {
    value: "FM0641-KG",
    label: "FM0641",
  },
  {
    value: "FM0642-KG",
    label: "FM0642",
  },
  {
    value: "FM0643-KG",
    label: "FM0643",
  },
  {
    value: "FM0644-KG",
    label: "FM0644",
  },
  {
    value: "FM0645-KG",
    label: "FM0645",
  },
  {
    value: "FM0646-KG",
    label: "FM0646",
  },
  {
    value: "FM0647-KG",
    label: "FM0647",
  },
  {
    value: "FM0649-KG",
    label: "FM0649",
  },
  {
    value: "FM0650-KG",
    label: "FM0650",
  },
  {
    value: "FM0651-KG",
    label: "FM0651",
  },
  {
    value: "FM0653-KG",
    label: "FM0653",
  },
  {
    value: "FM0654-KG",
    label: "FM0654",
  },
  {
    value: "FM0655-KG",
    label: "FM0655",
  },
  {
    value: "FM0656-KG",
    label: "FM0656",
  },
  {
    value: "FM0657-KG",
    label: "FM0657",
  },
  {
    value: "FM0658-KG",
    label: "FM0658",
  },
  {
    value: "FM0659-KG",
    label: "FM0659",
  },
  {
    value: "FM0660-KG",
    label: "FM0660",
  },
  {
    value: "FM0661-KG",
    label: "FM0661",
  },
  {
    value: "FM0662-KG",
    label: "FM0662",
  },
  {
    value: "FM0663-KG",
    label: "FM0663",
  },
  {
    value: "FM0664-KG",
    label: "FM0664",
  },
  {
    value: "FM0665-KG",
    label: "FM0665",
  },
  {
    value: "FM0666-KG",
    label: "FM0666",
  },
  {
    value: "FM0667-KG",
    label: "FM0667",
  },
  {
    value: "FM0668-KG",
    label: "FM0668",
  },
  {
    value: "FM0669-KG",
    label: "FM0669",
  },
  {
    value: "FM0670-KG",
    label: "FM0670",
  },
  {
    value: "FM0671-KG",
    label: "FM0671",
  },
  {
    value: "FM0672-KG",
    label: "FM0672",
  },
  {
    value: "FM0673-KG",
    label: "FM0673",
  },
  {
    value: "FM0675-KG",
    label: "FM0675",
  },
  {
    value: "FM0676-KG",
    label: "FM0676",
  },
  {
    value: "FM0677-KG",
    label: "FM0677",
  },
  {
    value: "FM0678-KG",
    label: "FM0678",
  },
  {
    value: "FM0679-KG",
    label: "FM0679",
  },
  {
    value: "FM0680-KG",
    label: "FM0680",
  },
  {
    value: "FM0681-KG",
    label: "FM0681",
  },
  {
    value: "FM0682-KG",
    label: "FM0682",
  },
  {
    value: "FM0683-KG",
    label: "FM0683",
  },
  {
    value: "FM0684-KG",
    label: "FM0684",
  },
  {
    value: "FM0685-KG",
    label: "FM0685",
  },
  {
    value: "FM0686-KG",
    label: "FM0686",
  },
  {
    value: "FM0688-KG",
    label: "FM0688",
  },
  {
    value: "FM0689-KG",
    label: "FM0689",
  },
  {
    value: "FM0691-KG",
    label: "FM0691",
  },
  {
    value: "FM0692-KG",
    label: "FM0692",
  },
  {
    value: "FM0694-KG",
    label: "FM0694",
  },
  {
    value: "FM0695-KG",
    label: "FM0695",
  },
  {
    value: "FM0696-KG",
    label: "FM0696",
  },
  {
    value: "FM0697-KG",
    label: "FM0697",
  },
  {
    value: "FM0698-KG",
    label: "FM0698",
  },
  {
    value: "FM0699-KG",
    label: "FM0699",
  },
  {
    value: "FM0700-KG",
    label: "FM0700",
  },
  {
    value: "FM0701-KG",
    label: "FM0701",
  },
  {
    value: "FM0702-KG",
    label: "FM0702",
  },
  {
    value: "FM0704-KG",
    label: "FM0704",
  },
  {
    value: "FM0705-KG",
    label: "FM0705",
  },
  {
    value: "FM0706-KG",
    label: "FM0706",
  },
  {
    value: "FM0707-KG",
    label: "FM0707",
  },
  {
    value: "FM0708-KG",
    label: "FM0708",
  },
  {
    value: "FM0709-KG",
    label: "FM0709",
  },
  {
    value: "FM0710-KG",
    label: "FM0710",
  },
  {
    value: "FM0711-KG",
    label: "FM0711",
  },
  {
    value: "FM0712-KG",
    label: "FM0712",
  },
  {
    value: "FM0713-KG",
    label: "FM0713",
  },
  {
    value: "FM0714-KG",
    label: "FM0714",
  },
  {
    value: "FM0715-KG",
    label: "FM0715",
  },
  {
    value: "FM0716-KG",
    label: "FM0716",
  },
  {
    value: "FM0717-KG",
    label: "FM0717",
  },
  {
    value: "FM0718-KG",
    label: "FM0718",
  },
  {
    value: "FM0719-KG",
    label: "FM0719",
  },
  {
    value: "FM0720-KG",
    label: "FM0720",
  },
  {
    value: "FM0721-KG",
    label: "FM0721",
  },
  {
    value: "FM0722-KG",
    label: "FM0722",
  },
  {
    value: "FM0723-KG",
    label: "FM0723",
  },
  {
    value: "FM0724-KG",
    label: "FM0724",
  },
  {
    value: "FM0725-KG",
    label: "FM0725",
  },
  {
    value: "FM0726-KG",
    label: "FM0726",
  },
  {
    value: "FM0727-KG",
    label: "FM0727",
  },
  {
    value: "FM0728-KG",
    label: "FM0728",
  },
  {
    value: "FM0729-KG",
    label: "FM0729",
  },
  {
    value: "FM0730-KG",
    label: "FM0730",
  },
  {
    value: "FM0731-KG",
    label: "FM0731",
  },
  {
    value: "FM0732-KG",
    label: "FM0732",
  },
  {
    value: "FM0733-KG",
    label: "FM0733",
  },
  {
    value: "FM0734-KG",
    label: "FM0734",
  },
  {
    value: "FM0735-KG",
    label: "FM0735",
  },
  {
    value: "FM0736-KG",
    label: "FM0736",
  },
  {
    value: "FM0738-KG",
    label: "FM0738",
  },
  {
    value: "FM0739-KG",
    label: "FM0739",
  },
  {
    value: "FM0740-KG",
    label: "FM0740",
  },
  {
    value: "FM0741-KG",
    label: "FM0741",
  },
  {
    value: "FM0742-KG",
    label: "FM0742",
  },
  {
    value: "FM0743-KG",
    label: "FM0743",
  },
  {
    value: "FM0744-KG",
    label: "FM0744",
  },
  {
    value: "FM0745-KG",
    label: "FM0745",
  },
  {
    value: "FM0746-KG",
    label: "FM0746",
  },
  {
    value: "FM0747-KG",
    label: "FM0747",
  },
  {
    value: "FM0748-KG",
    label: "FM0748",
  },
  {
    value: "FM0749-KG",
    label: "FM0749",
  },
  {
    value: "FM0750-KG",
    label: "FM0750",
  },
  {
    value: "FM0751-KG",
    label: "FM0751",
  },
  {
    value: "FM0752-KG",
    label: "FM0752",
  },
  {
    value: "FM0753-KG",
    label: "FM0753",
  },
  {
    value: "FM0754-KG",
    label: "FM0754",
  },
  {
    value: "FM0755-KG",
    label: "FM0755",
  },
  {
    value: "FM0756-KG",
    label: "FM0756",
  },
  {
    value: "FM0757-KG",
    label: "FM0757",
  },
  {
    value: "FM0758-KG",
    label: "FM0758",
  },
  {
    value: "FM0759-KG",
    label: "FM0759",
  },
  {
    value: "FM0760-KG",
    label: "FM0760",
  },
  {
    value: "FM0761-KG",
    label: "FM0761",
  },
  {
    value: "FM0762-KG",
    label: "FM0762",
  },
  {
    value: "FM0763-KG",
    label: "FM0763",
  },
  {
    value: "FM0764-KG",
    label: "FM0764",
  },
  {
    value: "FM0765-KG",
    label: "FM0765",
  },
  {
    value: "FM0766-KG",
    label: "FM0766",
  },
  {
    value: "FM0767-KG",
    label: "FM0767",
  },
  {
    value: "FM0768-KG",
    label: "FM0768",
  },
  {
    value: "FM0769-KG",
    label: "FM0769",
  },
  {
    value: "FM0770-KG",
    label: "FM0770",
  },
  {
    value: "FM0771-KG",
    label: "FM0771",
  },
  {
    value: "FM0772-KG",
    label: "FM0772",
  },
  {
    value: "FM0773-KG",
    label: "FM0773",
  },
  {
    value: "FM0774-KG",
    label: "FM0774",
  },
  {
    value: "FM0775-KG",
    label: "FM0775",
  },
  {
    value: "FM0776-KG",
    label: "FM0776",
  },
  {
    value: "FM0777-KG",
    label: "FM0777",
  },
  {
    value: "FM0778-KG",
    label: "FM0778",
  },
  {
    value: "FM0779-KG",
    label: "FM0779",
  },
  {
    value: "FM0780-KG",
    label: "FM0780",
  },
  {
    value: "FM0782-KG",
    label: "FM0782",
  },
  {
    value: "FM0784-KG",
    label: "FM0784",
  },
  {
    value: "FM0785-KG",
    label: "FM0785",
  },
  {
    value: "FM0786-KG",
    label: "FM0786",
  },
  {
    value: "FM0787-KG",
    label: "FM0787",
  },
  {
    value: "FM0788-KG",
    label: "FM0788",
  },
  {
    value: "FM0789-KG",
    label: "FM0789",
  },
  {
    value: "FM0790-KG",
    label: "FM0790",
  },
  {
    value: "FM0791-KG",
    label: "FM0791",
  },
  {
    value: "FM0792-KG",
    label: "FM0792",
  },
  {
    value: "FM0793-KG",
    label: "FM0793",
  },
  {
    value: "FM0794-KG",
    label: "FM0794",
  },
  {
    value: "FM0795-KG",
    label: "FM0795",
  },
  {
    value: "FM0796-KG",
    label: "FM0796",
  },
  {
    value: "FM0797-KG",
    label: "FM0797",
  },
  {
    value: "FM0798-KG",
    label: "FM0798",
  },
  {
    value: "FM0799-KG",
    label: "FM0799",
  },
  {
    value: "FM0800-KG",
    label: "FM0800",
  },
  {
    value: "FM0801-KG",
    label: "FM0801",
  },
  {
    value: "FM0802-KG",
    label: "FM0802",
  },
  {
    value: "FM0803-KG",
    label: "FM0803",
  },
  {
    value: "FM0804-KG",
    label: "FM0804",
  },
  {
    value: "FM0805-KG",
    label: "FM0805",
  },
  {
    value: "FM0806-KG",
    label: "FM0806",
  },
  {
    value: "FM0807-KG",
    label: "FM0807",
  },
  {
    value: "FM0809-KG",
    label: "FM0809",
  },
  {
    value: "FM0810-KG",
    label: "FM0810",
  },
  {
    value: "FM0811-KG",
    label: "FM0811",
  },
  {
    value: "FM0812-KG",
    label: "FM0812",
  },
  {
    value: "FM0813-KG",
    label: "FM0813",
  },
  {
    value: "FM0814-KG",
    label: "FM0814",
  },
  {
    value: "FM0815-KG",
    label: "FM0815",
  },
  {
    value: "FM0816-KG",
    label: "FM0816",
  },
  {
    value: "FM0817-KG",
    label: "FM0817",
  },
  {
    value: "FM0818-KG",
    label: "FM0818",
  },
  {
    value: "FM0819-KG",
    label: "FM0819",
  },
  {
    value: "FM0820-KG",
    label: "FM0820",
  },
  {
    value: "FM0821-KG",
    label: "FM0821",
  },
  {
    value: "FM0822-KG",
    label: "FM0822",
  },
  {
    value: "FM0824-KG",
    label: "FM0824",
  },
  {
    value: "FM0825-KG",
    label: "FM0825",
  },
  {
    value: "FM0826-KG",
    label: "FM0826",
  },
  {
    value: "FM0827-KG",
    label: "FM0827",
  },
  {
    value: "FM0828-KG",
    label: "FM0828",
  },
  {
    value: "FM0829-KG",
    label: "FM0829",
  },
  {
    value: "FM0830-KG",
    label: "FM0830",
  },
  {
    value: "FM0831-KG",
    label: "FM0831",
  },
  {
    value: "FM0832-KG",
    label: "FM0832",
  },
  {
    value: "FM0833-KG",
    label: "FM0833",
  },
  {
    value: "FM0834-KG",
    label: "FM0834",
  },
  {
    value: "FM0835-KG",
    label: "FM0835",
  },
  {
    value: "FM0836-KG",
    label: "FM0836",
  },
  {
    value: "FM0837-KG",
    label: "FM0837",
  },
  {
    value: "FM0838-KG",
    label: "FM0838",
  },
  {
    value: "FM0839-KG",
    label: "FM0839",
  },
  {
    value: "FM0840-KG",
    label: "FM0840",
  },
  {
    value: "FM0841-KG",
    label: "FM0841",
  },
  {
    value: "FM0842-KG",
    label: "FM0842",
  },
  {
    value: "FM0843-KG",
    label: "FM0843",
  },
  {
    value: "FM0844-KG",
    label: "FM0844",
  },
  {
    value: "FM0845-KG",
    label: "FM0845",
  },
  {
    value: "FM0846-KG",
    label: "FM0846",
  },
  {
    value: "FM0847-KG",
    label: "FM0847",
  },
  {
    value: "FM0848-KG",
    label: "FM0848",
  },
  {
    value: "FM0849-KG",
    label: "FM0849",
  },
  {
    value: "FM0850-KG",
    label: "FM0850",
  },
  {
    value: "FM0851-KG",
    label: "FM0851",
  },
  {
    value: "FM0852-KG",
    label: "FM0852",
  },
  {
    value: "FM0854-KG",
    label: "FM0854",
  },
  {
    value: "FM0855-KG",
    label: "FM0855",
  },
  {
    value: "FM0856-KG",
    label: "FM0856",
  },
  {
    value: "FM0857-KG",
    label: "FM0857",
  },
  {
    value: "FM0858-KG",
    label: "FM0858",
  },
  {
    value: "FM0859-KG",
    label: "FM0859",
  },
  {
    value: "FM0860-KG",
    label: "FM0860",
  },
  {
    value: "FM0861-KG",
    label: "FM0861",
  },
  {
    value: "FM0862-KG",
    label: "FM0862",
  },
  {
    value: "FM0865-KG",
    label: "FM0865",
  },
  {
    value: "FM0866-KG",
    label: "FM0866",
  },
  {
    value: "FM0867-KG",
    label: "FM0867",
  },
  {
    value: "FM0868-KG",
    label: "FM0868",
  },
  {
    value: "FM0869-KG",
    label: "FM0869",
  },
  {
    value: "FM0871-KG",
    label: "FM0871",
  },
  {
    value: "FM0872-KG",
    label: "FM0872",
  },
  {
    value: "FM0873-KG",
    label: "FM0873",
  },
  {
    value: "FM0874-KG",
    label: "FM0874",
  },
  {
    value: "FM0875-KG",
    label: "FM0875",
  },
  {
    value: "FM0876-KG",
    label: "FM0876",
  },
  {
    value: "FM0877-KG",
    label: "FM0877",
  },
  {
    value: "FM0878-KG",
    label: "FM0878",
  },
  {
    value: "FM0879-KG",
    label: "FM0879",
  },
  {
    value: "FM0880-KG",
    label: "FM0880",
  },
  {
    value: "FM0881-KG",
    label: "FM0881",
  },
  {
    value: "FM0882-KG",
    label: "FM0882",
  },
  {
    value: "FM0883-KG",
    label: "FM0883",
  },
  {
    value: "FM0884-KG",
    label: "FM0884",
  },
  {
    value: "FM0885-KG",
    label: "FM0885",
  },
  {
    value: "FM0886-KG",
    label: "FM0886",
  },
  {
    value: "FM0887-KG",
    label: "FM0887",
  },
  {
    value: "FM0888-KG",
    label: "FM0888",
  },
  {
    value: "FM0889-KG",
    label: "FM0889",
  },
  {
    value: "FM0890-KG",
    label: "FM0890",
  },
  {
    value: "FM0892-KG",
    label: "FM0892",
  },
  {
    value: "FM0893-KG",
    label: "FM0893",
  },
  {
    value: "FM0895-KG",
    label: "FM0895",
  },
  {
    value: "FM0896-KG",
    label: "FM0896",
  },
  {
    value: "FM0897-KG",
    label: "FM0897",
  },
  {
    value: "FM0898-KG",
    label: "FM0898",
  },
  {
    value: "FM0899-KG",
    label: "FM0899",
  },
  {
    value: "FM0900-KG",
    label: "FM0900",
  },
  {
    value: "FM0901-KG",
    label: "FM0901",
  },
  {
    value: "FM0902-KG",
    label: "FM0902",
  },
  {
    value: "FM0903-KG",
    label: "FM0903",
  },
  {
    value: "FM0904-KG",
    label: "FM0904",
  },
  {
    value: "FM0905-KG",
    label: "FM0905",
  },
  {
    value: "FM0906-KG",
    label: "FM0906",
  },
  {
    value: "FM0907-KG",
    label: "FM0907",
  },
  {
    value: "FM0908-KG",
    label: "FM0908",
  },
  {
    value: "FM0909-KG",
    label: "FM0909",
  },
  {
    value: "FM0910-KG",
    label: "FM0910",
  },
  {
    value: "FM0911-KG",
    label: "FM0911",
  },
  {
    value: "FM0912-KG",
    label: "FM0912",
  },
  {
    value: "FM0913-KG",
    label: "FM0913",
  },
  {
    value: "FM0914-KG",
    label: "FM0914",
  },
  {
    value: "FM0916-KG",
    label: "FM0916",
  },
  {
    value: "FM0917-KG",
    label: "FM0917",
  },
  {
    value: "FM0918-KG",
    label: "FM0918",
  },
  {
    value: "FM0919-KG",
    label: "FM0919",
  },
  {
    value: "FM0920-KG",
    label: "FM0920",
  },
  {
    value: "FM0921-KG",
    label: "FM0921",
  },
  {
    value: "FM0922-KG",
    label: "FM0922",
  },
  {
    value: "FM0923-KG",
    label: "FM0923",
  },
  {
    value: "FM0924-KG",
    label: "FM0924",
  },
  {
    value: "FM0925-KG",
    label: "FM0925",
  },
  {
    value: "FM0926-KG",
    label: "FM0926",
  },
  {
    value: "FM0927-KG",
    label: "FM0927",
  },
  {
    value: "FM0928-KG",
    label: "FM0928",
  },
  {
    value: "FM0929-KG",
    label: "FM0929",
  },
  {
    value: "FM0930-KG",
    label: "FM0930",
  },
  {
    value: "FM0931-KG",
    label: "FM0931",
  },
  {
    value: "FM0932-KG",
    label: "FM0932",
  },
  {
    value: "FM0933-KG",
    label: "FM0933",
  },
  {
    value: "FM0934-KG",
    label: "FM0934",
  },
  {
    value: "FM0935-KG",
    label: "FM0935",
  },
  {
    value: "FM0936-KG",
    label: "FM0936",
  },
  {
    value: "FM0937-KG",
    label: "FM0937",
  },
  {
    value: "FM0938-KG",
    label: "FM0938",
  },
  {
    value: "FM0939-KG",
    label: "FM0939",
  },
  {
    value: "FM0940-KG",
    label: "FM0940",
  },
  {
    value: "FM0941-KG",
    label: "FM0941",
  },
  {
    value: "FM0942-KG",
    label: "FM0942",
  },
  {
    value: "FM0943-KG",
    label: "FM0943",
  },
  {
    value: "FM0944-KG",
    label: "FM0944",
  },
  {
    value: "FM0945-KG",
    label: "FM0945",
  },
  {
    value: "FM0946-KG",
    label: "FM0946",
  },
  {
    value: "FM0947-KG",
    label: "FM0947",
  },
  {
    value: "FM0949-KG",
    label: "FM0949",
  },
  {
    value: "FM0950-KG",
    label: "FM0950",
  },
  {
    value: "FM0951-KG",
    label: "FM0951",
  },
  {
    value: "FM0952-KG",
    label: "FM0952",
  },
  {
    value: "FM0953-KG",
    label: "FM0953",
  },
  {
    value: "FM0954-KG",
    label: "FM0954",
  },
  {
    value: "FM0955-KG",
    label: "FM0955",
  },
  {
    value: "FM0956-KG",
    label: "FM0956",
  },
  {
    value: "FM0957-KG",
    label: "FM0957",
  },
  {
    value: "FM0958-KG",
    label: "FM0958",
  },
  {
    value: "FM0959-KG",
    label: "FM0959",
  },
  {
    value: "FM0960-KG",
    label: "FM0960",
  },
  {
    value: "FM0961-KG",
    label: "FM0961",
  },
  {
    value: "FM0964-KG",
    label: "FM0964",
  },
  {
    value: "FM0966-KG",
    label: "FM0966",
  },
  {
    value: "FM0968-KG",
    label: "FM0968",
  },
  {
    value: "FM0969-KG",
    label: "FM0969",
  },
  {
    value: "FM0970-KG",
    label: "FM0970",
  },
  {
    value: "FM0971-KG",
    label: "FM0971",
  },
  {
    value: "FM0972-KG",
    label: "FM0972",
  },
  {
    value: "FM0973-KG",
    label: "FM0973",
  },
  {
    value: "FM0974-KG",
    label: "FM0974",
  },
  {
    value: "FM0975-KG",
    label: "FM0975",
  },
  {
    value: "FM0976-KG",
    label: "FM0976",
  },
  {
    value: "FM0977-KG",
    label: "FM0977",
  },
  {
    value: "FM0978-KG",
    label: "FM0978",
  },
  {
    value: "FM0979-KG",
    label: "FM0979",
  },
  {
    value: "FM0980-KG",
    label: "FM0980",
  },
  {
    value: "FM0981-KG",
    label: "FM0981",
  },
  {
    value: "FM0982-KG",
    label: "FM0982",
  },
  {
    value: "FM0983-KG",
    label: "FM0983",
  },
  {
    value: "FM0984-KG",
    label: "FM0984",
  },
  {
    value: "FM0985-KG",
    label: "FM0985",
  },
  {
    value: "FM0986-KG",
    label: "FM0986",
  },
  {
    value: "FM0987-KG",
    label: "FM0987",
  },
  {
    value: "FM0988-KG",
    label: "FM0988",
  },
  {
    value: "FM0989-KG",
    label: "FM0989",
  },
  {
    value: "FM0990-KG",
    label: "FM0990",
  },
  {
    value: "FM0991-KG",
    label: "FM0991",
  },
  {
    value: "FM0992-KG",
    label: "FM0992",
  },
  {
    value: "FM0993-KG",
    label: "FM0993",
  },
  {
    value: "FM0994-KG",
    label: "FM0994",
  },
  {
    value: "FM0995-KG",
    label: "FM0995",
  },
  {
    value: "FM0996-KG",
    label: "FM0996",
  },
  {
    value: "FM0997-KG",
    label: "FM0997",
  },
  {
    value: "FM0998-KG",
    label: "FM0998",
  },
  {
    value: "FM0999-KG",
    label: "FM0999",
  },
  {
    value: "FM1000-KG",
    label: "FM1000",
  },
  {
    value: "FM1001-KG",
    label: "FM1001",
  },
  {
    value: "FM1002-KG",
    label: "FM1002",
  },
  {
    value: "FM1003-KG",
    label: "FM1003",
  },
  {
    value: "FM1004-KG",
    label: "FM1004",
  },
  {
    value: "FM1005-KG",
    label: "FM1005",
  },
  {
    value: "FM1006-KG",
    label: "FM1006",
  },
  {
    value: "FM1007-KG",
    label: "FM1007",
  },
  {
    value: "FM1008-KG",
    label: "FM1008",
  },
  {
    value: "FM1009-KG",
    label: "FM1009",
  },
  {
    value: "FM1010-KG",
    label: "FM1010",
  },
  {
    value: "FM1011-KG",
    label: "FM1011",
  },
  {
    value: "FM1012-KG",
    label: "FM1012",
  },
  {
    value: "FM1013-KG",
    label: "FM1013",
  },
  {
    value: "FM1014-KG",
    label: "FM1014",
  },
  {
    value: "FM1015-KG",
    label: "FM1015",
  },
  {
    value: "FM1016-KG",
    label: "FM1016",
  },
  {
    value: "FM1017-KG",
    label: "FM1017",
  },
  {
    value: "FM1018-KG",
    label: "FM1018",
  },
  {
    value: "FM1019-KG",
    label: "FM1019",
  },
  {
    value: "FM1020-KG",
    label: "FM1020",
  },
  {
    value: "FM1021-KG",
    label: "FM1021",
  },
  {
    value: "FM1023-KG",
    label: "FM1023",
  },
  {
    value: "FM1025-KG",
    label: "FM1025",
  },
  {
    value: "FM1026-KG",
    label: "FM1026",
  },
  {
    value: "FM1027-KG",
    label: "FM1027",
  },
  {
    value: "FM1028-KG",
    label: "FM1028",
  },
  {
    value: "FM1029-KG",
    label: "FM1029",
  },
  {
    value: "FM1030-KG",
    label: "FM1030",
  },
  {
    value: "FM1031-KG",
    label: "FM1031",
  },
  {
    value: "FM1032-KG",
    label: "FM1032",
  },
  {
    value: "FM1033-KG",
    label: "FM1033",
  },
  {
    value: "FM1034-KG",
    label: "FM1034",
  },
  {
    value: "FM1035-KG",
    label: "FM1035",
  },
  {
    value: "FM1036-KG",
    label: "FM1036",
  },
  {
    value: "FM1037-KG",
    label: "FM1037",
  },
  {
    value: "FM1038-KG",
    label: "FM1038",
  },
  {
    value: "FM1039-KG",
    label: "FM1039",
  },
  {
    value: "FM1040-KG",
    label: "FM1040",
  },
  {
    value: "FM1041-KG",
    label: "FM1041",
  },
  {
    value: "FM1042-KG",
    label: "FM1042",
  },
  {
    value: "FM1043-KG",
    label: "FM1043",
  },
  {
    value: "FM1044-KG",
    label: "FM1044",
  },
  {
    value: "FM1045-KG",
    label: "FM1045",
  },
  {
    value: "FM1046-KG",
    label: "FM1046",
  },
  {
    value: "FM1047-KG",
    label: "FM1047",
  },
  {
    value: "FM1048-KG",
    label: "FM1048",
  },
  {
    value: "FM1049-KG",
    label: "FM1049",
  },
  {
    value: "FM1051-KG",
    label: "FM1051",
  },
  {
    value: "FM1052-KG",
    label: "FM1052",
  },
  {
    value: "FM1053-KG",
    label: "FM1053",
  },
  {
    value: "FM1054-KG",
    label: "FM1054",
  },
  {
    value: "FM1055-KG",
    label: "FM1055",
  },
  {
    value: "FM1056-KG",
    label: "FM1056",
  },
  {
    value: "FM1057-KG",
    label: "FM1057",
  },
  {
    value: "FM1058-KG",
    label: "FM1058",
  },
  {
    value: "FM1059-KG",
    label: "FM1059",
  },
  {
    value: "FM1060-KG",
    label: "FM1060",
  },
  {
    value: "FM1062-KG",
    label: "FM1062",
  },
  {
    value: "FM1063-KG",
    label: "FM1063",
  },
  {
    value: "FM1064-KG",
    label: "FM1064",
  },
  {
    value: "FM1065-KG",
    label: "FM1065",
  },
  {
    value: "FM1066-KG",
    label: "FM1066",
  },
  {
    value: "FM1067-KG",
    label: "FM1067",
  },
  {
    value: "FM1068-KG",
    label: "FM1068",
  },
  {
    value: "FM1069-KG",
    label: "FM1069",
  },
  {
    value: "FM1070-KG",
    label: "FM1070",
  },
  {
    value: "FM1071-KG",
    label: "FM1071",
  },
  {
    value: "FM1072-KG",
    label: "FM1072",
  },
  {
    value: "FM1073-KG",
    label: "FM1073",
  },
  {
    value: "FM1074-KG",
    label: "FM1074",
  },
  {
    value: "FM1075-KG",
    label: "FM1075",
  },
  {
    value: "FM1076-KG",
    label: "FM1076",
  },
  {
    value: "FM1078-KG",
    label: "FM1078",
  },
  {
    value: "FM1079-KG",
    label: "FM1079",
  },
  {
    value: "FM1080-KG",
    label: "FM1080",
  },
  {
    value: "FM1081-KG",
    label: "FM1081",
  },
  {
    value: "FM1082-KG",
    label: "FM1082",
  },
  {
    value: "FM1083-KG",
    label: "FM1083",
  },
  {
    value: "FM1084-KG",
    label: "FM1084",
  },
  {
    value: "FM1085-KG",
    label: "FM1085",
  },
  {
    value: "FM1086-KG",
    label: "FM1086",
  },
  {
    value: "FM1087-KG",
    label: "FM1087",
  },
  {
    value: "FM1088-KG",
    label: "FM1088",
  },
  {
    value: "FM1089-KG",
    label: "FM1089",
  },
  {
    value: "FM1090-KG",
    label: "FM1090",
  },
  {
    value: "FM1091-KG",
    label: "FM1091",
  },
  {
    value: "FM1092-KG",
    label: "FM1092",
  },
  {
    value: "FM1093-KG",
    label: "FM1093",
  },
  {
    value: "FM1094-KG",
    label: "FM1094",
  },
  {
    value: "FM1095-KG",
    label: "FM1095",
  },
  {
    value: "FM1096-KG",
    label: "FM1096",
  },
  {
    value: "FM1097-KG",
    label: "FM1097",
  },
  {
    value: "FM1098-KG",
    label: "FM1098",
  },
  {
    value: "FM1099-KG",
    label: "FM1099",
  },
  {
    value: "FM1100-KG",
    label: "FM1100",
  },
  {
    value: "FM1101-KG",
    label: "FM1101",
  },
  {
    value: "FM1102-KG",
    label: "FM1102",
  },
  {
    value: "FM1103-KG",
    label: "FM1103",
  },
  {
    value: "FM1104-KG",
    label: "FM1104",
  },
  {
    value: "FM1105-KG",
    label: "FM1105",
  },
  {
    value: "FM1106-KG",
    label: "FM1106",
  },
  {
    value: "FM1107-KG",
    label: "FM1107",
  },
  {
    value: "FM1110-KG",
    label: "FM1110",
  },
  {
    value: "FM1112-KG",
    label: "FM1112",
  },
  {
    value: "FM1113-KG",
    label: "FM1113",
  },
  {
    value: "FM1114-KG",
    label: "FM1114",
  },
  {
    value: "FM1115-KG",
    label: "FM1115",
  },
  {
    value: "FM1116-KG",
    label: "FM1116",
  },
  {
    value: "FM1117-KG",
    label: "FM1117",
  },
  {
    value: "FM1118-KG",
    label: "FM1118",
  },
  {
    value: "FM1119-KG",
    label: "FM1119",
  },
  {
    value: "FM1120-KG",
    label: "FM1120",
  },
  {
    value: "FM1121-KG",
    label: "FM1121",
  },
  {
    value: "FM1122-KG",
    label: "FM1122",
  },
  {
    value: "FM1123-KG",
    label: "FM1123",
  },
  {
    value: "FM1124-KG",
    label: "FM1124",
  },
  {
    value: "FM1125-KG",
    label: "FM1125",
  },
  {
    value: "FM1126-KG",
    label: "FM1126",
  },
  {
    value: "FM1127-KG",
    label: "FM1127",
  },
  {
    value: "FM1128-KG",
    label: "FM1128",
  },
  {
    value: "FM1129-KG",
    label: "FM1129",
  },
  {
    value: "FM1130-KG",
    label: "FM1130",
  },
  {
    value: "FM1131-KG",
    label: "FM1131",
  },
  {
    value: "FM1132-KG",
    label: "FM1132",
  },
  {
    value: "FM1133-KG",
    label: "FM1133",
  },
  {
    value: "FM1134-KG",
    label: "FM1134",
  },
  {
    value: "FM1135-KG",
    label: "FM1135",
  },
  {
    value: "FM1136-KG",
    label: "FM1136",
  },
  {
    value: "FM1137-KG",
    label: "FM1137",
  },
  {
    value: "FM1138-KG",
    label: "FM1138",
  },
  {
    value: "FM1139-KG",
    label: "FM1139",
  },
  {
    value: "FM1140-KG",
    label: "FM1140",
  },
  {
    value: "FM1141-KG",
    label: "FM1141",
  },
  {
    value: "FM1142-KG",
    label: "FM1142",
  },
  {
    value: "FM1143-KG",
    label: "FM1143",
  },
  {
    value: "FM1144-KG",
    label: "FM1144",
  },
  {
    value: "FM1145-KG",
    label: "FM1145",
  },
  {
    value: "FM1146-KG",
    label: "FM1146",
  },
  {
    value: "FM1147-KG",
    label: "FM1147",
  },
  {
    value: "FM1148-KG",
    label: "FM1148",
  },
  {
    value: "FM1149-KG",
    label: "FM1149",
  },
  {
    value: "FM1150-KG",
    label: "FM1150",
  },
  {
    value: "FM1151-KG",
    label: "FM1151",
  },
  {
    value: "FM1152-KG",
    label: "FM1152",
  },
  {
    value: "FM1153-KG",
    label: "FM1153",
  },
  {
    value: "FM1154-KG",
    label: "FM1154",
  },
  {
    value: "FM1155-KG",
    label: "FM1155",
  },
  {
    value: "FM1156-KG",
    label: "FM1156",
  },
  {
    value: "FM1157-KG",
    label: "FM1157",
  },
  {
    value: "FM1158-KG",
    label: "FM1158",
  },
  {
    value: "FM1159-KG",
    label: "FM1159",
  },
  {
    value: "FM1160-KG",
    label: "FM1160",
  },
  {
    value: "FM1161-KG",
    label: "FM1161",
  },
  {
    value: "FM1162-KG",
    label: "FM1162",
  },
  {
    value: "FM1163-KG",
    label: "FM1163",
  },
  {
    value: "FM1164-KG",
    label: "FM1164",
  },
  {
    value: "FM1166-KG",
    label: "FM1166",
  },
  {
    value: "FM1167-KG",
    label: "FM1167",
  },
  {
    value: "FM1168-KG",
    label: "FM1168",
  },
  {
    value: "FM1169-KG",
    label: "FM1169",
  },
  {
    value: "FM1170-KG",
    label: "FM1170",
  },
  {
    value: "FM1171-KG",
    label: "FM1171",
  },
  {
    value: "FM1172-KG",
    label: "FM1172",
  },
  {
    value: "FM1173-KG",
    label: "FM1173",
  },
  {
    value: "FM1175-KG",
    label: "FM1175",
  },
  {
    value: "FM1176-KG",
    label: "FM1176",
  },
  {
    value: "FM1177-KG",
    label: "FM1177",
  },
  {
    value: "FM1178-KG",
    label: "FM1178",
  },
  {
    value: "FM1179-KG",
    label: "FM1179",
  },
  {
    value: "FM1180-KG",
    label: "FM1180",
  },
  {
    value: "FM1181-KG",
    label: "FM1181",
  },
  {
    value: "FM1182-KG",
    label: "FM1182",
  },
  {
    value: "FM1183-KG",
    label: "FM1183",
  },
  {
    value: "FM1184-KG",
    label: "FM1184",
  },
  {
    value: "FM1185-KG",
    label: "FM1185",
  },
  {
    value: "FM1186-KG",
    label: "FM1186",
  },
  {
    value: "FM1187-KG",
    label: "FM1187",
  },
  {
    value: "FM1188-KG",
    label: "FM1188",
  },
  {
    value: "FM1189-KG",
    label: "FM1189",
  },
  {
    value: "FM1190-KG",
    label: "FM1190",
  },
  {
    value: "FM1191-KG",
    label: "FM1191",
  },
  {
    value: "FM1192-KG",
    label: "FM1192",
  },
  {
    value: "FM1193-KG",
    label: "FM1193",
  },
  {
    value: "FM1194-KG",
    label: "FM1194",
  },
  {
    value: "FM1195-KG",
    label: "FM1195",
  },
  {
    value: "FM1196-KG",
    label: "FM1196",
  },
  {
    value: "FM1197-KG",
    label: "FM1197",
  },
  {
    value: "FM1198-KG",
    label: "FM1198",
  },
  {
    value: "FM1199-KG",
    label: "FM1199",
  },
  {
    value: "FM1200-KG",
    label: "FM1200",
  },
  {
    value: "FM1201-KG",
    label: "FM1201",
  },
  {
    value: "FM1202-KG",
    label: "FM1202",
  },
  {
    value: "FM1203-KG",
    label: "FM1203",
  },
  {
    value: "FM1204-KG",
    label: "FM1204",
  },
  {
    value: "FM1205-KG",
    label: "FM1205",
  },
  {
    value: "FM1206-KG",
    label: "FM1206",
  },
  {
    value: "FM1207-KG",
    label: "FM1207",
  },
  {
    value: "FM1208-KG",
    label: "FM1208",
  },
  {
    value: "FM1209-KG",
    label: "FM1209",
  },
  {
    value: "FM1210-KG",
    label: "FM1210",
  },
  {
    value: "FM1212-KG",
    label: "FM1212",
  },
  {
    value: "FM1213-KG",
    label: "FM1213",
  },
  {
    value: "FM1214-KG",
    label: "FM1214",
  },
  {
    value: "FM1215-KG",
    label: "FM1215",
  },
  {
    value: "FM1216-KG",
    label: "FM1216",
  },
  {
    value: "FM1218-KG",
    label: "FM1218",
  },
  {
    value: "FM1219-KG",
    label: "FM1219",
  },
  {
    value: "FM1220-KG",
    label: "FM1220",
  },
  {
    value: "FM1223-KG",
    label: "FM1223",
  },
  {
    value: "FM1224-KG",
    label: "FM1224",
  },
  {
    value: "FM1225-KG",
    label: "FM1225",
  },
  {
    value: "FM1226-KG",
    label: "FM1226",
  },
  {
    value: "FM1227-KG",
    label: "FM1227",
  },
  {
    value: "FM1228-KG",
    label: "FM1228",
  },
  {
    value: "FM1229-KG",
    label: "FM1229",
  },
  {
    value: "FM1230-KG",
    label: "FM1230",
  },
  {
    value: "FM1232-KG",
    label: "FM1232",
  },
  {
    value: "FM1233-KG",
    label: "FM1233",
  },
  {
    value: "FM1234-KG",
    label: "FM1234",
  },
  {
    value: "FM1235-KG",
    label: "FM1235",
  },
  {
    value: "FM1236-KG",
    label: "FM1236",
  },
  {
    value: "FM1237-KG",
    label: "FM1237",
  },
  {
    value: "FM1238-KG",
    label: "FM1238",
  },
  {
    value: "FM1239-KG",
    label: "FM1239",
  },
  {
    value: "FM1240-KG",
    label: "FM1240",
  },
  {
    value: "FM1241-KG",
    label: "FM1241",
  },
  {
    value: "FM1242-KG",
    label: "FM1242",
  },
  {
    value: "FM1243-KG",
    label: "FM1243",
  },
  {
    value: "FM1244-KG",
    label: "FM1244",
  },
  {
    value: "FM1245-KG",
    label: "FM1245",
  },
  {
    value: "FM1246-KG",
    label: "FM1246",
  },
  {
    value: "FM1247-KG",
    label: "FM1247",
  },
  {
    value: "FM1248-KG",
    label: "FM1248",
  },
  {
    value: "FM1249-KG",
    label: "FM1249",
  },
  {
    value: "FM1250-KG",
    label: "FM1250",
  },
  {
    value: "FM1251-KG",
    label: "FM1251",
  },
  {
    value: "FM1252-KG",
    label: "FM1252",
  },
  {
    value: "FM1253-KG",
    label: "FM1253",
  },
  {
    value: "FM1254-KG",
    label: "FM1254",
  },
  {
    value: "FM1255-KG",
    label: "FM1255",
  },
  {
    value: "FM1256-KG",
    label: "FM1256",
  },
  {
    value: "FM1257-KG",
    label: "FM1257",
  },
  {
    value: "FM1258-KG",
    label: "FM1258",
  },
  {
    value: "FM1259-KG",
    label: "FM1259",
  },
  {
    value: "FM1260-KG",
    label: "FM1260",
  },
  {
    value: "FM1261-KG",
    label: "FM1261",
  },
  {
    value: "FM1262-KG",
    label: "FM1262",
  },
  {
    value: "FM1263-KG",
    label: "FM1263",
  },
  {
    value: "FM1264-KG",
    label: "FM1264",
  },
  {
    value: "FM1266-KG",
    label: "FM1266",
  },
  {
    value: "FM1267-KG",
    label: "FM1267",
  },
  {
    value: "FM1268-KG",
    label: "FM1268",
  },
  {
    value: "FM1269-KG",
    label: "FM1269",
  },
  {
    value: "FM1270-KG",
    label: "FM1270",
  },
  {
    value: "FM1271-KG",
    label: "FM1271",
  },
  {
    value: "FM1272-KG",
    label: "FM1272",
  },
  {
    value: "FM1273-KG",
    label: "FM1273",
  },
  {
    value: "FM1274-KG",
    label: "FM1274",
  },
  {
    value: "FM1275-KG",
    label: "FM1275",
  },
  {
    value: "FM1276-KG",
    label: "FM1276",
  },
  {
    value: "FM1277-KG",
    label: "FM1277",
  },
  {
    value: "FM1278-KG",
    label: "FM1278",
  },
  {
    value: "FM1279-KG",
    label: "FM1279",
  },
  {
    value: "FM1280-KG",
    label: "FM1280",
  },
  {
    value: "FM1281-KG",
    label: "FM1281",
  },
  {
    value: "FM1283-KG",
    label: "FM1283",
  },
  {
    value: "FM1284-KG",
    label: "FM1284",
  },
  {
    value: "FM1285-KG",
    label: "FM1285",
  },
  {
    value: "FM1286-KG",
    label: "FM1286",
  },
  {
    value: "FM1287-KG",
    label: "FM1287",
  },
  {
    value: "FM1288-KG",
    label: "FM1288",
  },
  {
    value: "FM1289-KG",
    label: "FM1289",
  },
  {
    value: "FM1290-KG",
    label: "FM1290",
  },
  {
    value: "FM1291-KG",
    label: "FM1291",
  },
  {
    value: "FM1292-KG",
    label: "FM1292",
  },
  {
    value: "FM1293-KG",
    label: "FM1293",
  },
  {
    value: "FM1294-KG",
    label: "FM1294",
  },
  {
    value: "FM1295-KG",
    label: "FM1295",
  },
  {
    value: "FM1296-KG",
    label: "FM1296",
  },
  {
    value: "FM1297-KG",
    label: "FM1297",
  },
  {
    value: "FM1298-KG",
    label: "FM1298",
  },
  {
    value: "FM1299-KG",
    label: "FM1299",
  },
  {
    value: "FM1300-KG",
    label: "FM1300",
  },
  {
    value: "FM1301-KG",
    label: "FM1301",
  },
  {
    value: "FM1302-KG",
    label: "FM1302",
  },
  {
    value: "FM1303-KG",
    label: "FM1303",
  },
  {
    value: "FM1304-KG",
    label: "FM1304",
  },
  {
    value: "FM1305-KG",
    label: "FM1305",
  },
  {
    value: "FM1306-KG",
    label: "FM1306",
  },
  {
    value: "FM1307-KG",
    label: "FM1307",
  },
  {
    value: "FM1308-KG",
    label: "FM1308",
  },
  {
    value: "FM1309-KG",
    label: "FM1309",
  },
  {
    value: "FM1310-KG",
    label: "FM1310",
  },
  {
    value: "FM1311-KG",
    label: "FM1311",
  },
  {
    value: "FM1313-KG",
    label: "FM1313",
  },
  {
    value: "FM1314-KG",
    label: "FM1314",
  },
  {
    value: "FM1315-KG",
    label: "FM1315",
  },
  {
    value: "FM1316-KG",
    label: "FM1316",
  },
  {
    value: "FM1317-KG",
    label: "FM1317",
  },
  {
    value: "FM1318-KG",
    label: "FM1318",
  },
  {
    value: "FM1322-KG",
    label: "FM1322",
  },
  {
    value: "FM1324-KG",
    label: "FM1324",
  },
  {
    value: "FM1325-KG",
    label: "FM1325",
  },
  {
    value: "FM1326-KG",
    label: "FM1326",
  },
  {
    value: "FM1327-KG",
    label: "FM1327",
  },
  {
    value: "FM1328-KG",
    label: "FM1328",
  },
  {
    value: "FM1329-KG",
    label: "FM1329",
  },
  {
    value: "FM1330-KG",
    label: "FM1330",
  },
  {
    value: "FM1331-KG",
    label: "FM1331",
  },
  {
    value: "FM1332-KG",
    label: "FM1332",
  },
  {
    value: "FM1333-KG",
    label: "FM1333",
  },
  {
    value: "FM1334-KG",
    label: "FM1334",
  },
  {
    value: "FM1335-KG",
    label: "FM1335",
  },
  {
    value: "FM1336-KG",
    label: "FM1336",
  },
  {
    value: "FM1337-KG",
    label: "FM1337",
  },
  {
    value: "FM1338-KG",
    label: "FM1338",
  },
  {
    value: "FM1339-KG",
    label: "FM1339",
  },
  {
    value: "FM1340-KG",
    label: "FM1340",
  },
  {
    value: "FM1341-KG",
    label: "FM1341",
  },
  {
    value: "FM1342-KG",
    label: "FM1342",
  },
  {
    value: "FM1343-KG",
    label: "FM1343",
  },
  {
    value: "FM1344-KG",
    label: "FM1344",
  },
  {
    value: "FM1346-KG",
    label: "FM1346",
  },
  {
    value: "FM1347-KG",
    label: "FM1347",
  },
  {
    value: "FM1348-KG",
    label: "FM1348",
  },
  {
    value: "FM1349-KG",
    label: "FM1349",
  },
  {
    value: "FM1350-KG",
    label: "FM1350",
  },
  {
    value: "FM1351-KG",
    label: "FM1351",
  },
  {
    value: "FM1352-KG",
    label: "FM1352",
  },
  {
    value: "FM1353-KG",
    label: "FM1353",
  },
  {
    value: "FM1354-KG",
    label: "FM1354",
  },
  {
    value: "FM1355-KG",
    label: "FM1355",
  },
  {
    value: "FM1356-KG",
    label: "FM1356",
  },
  {
    value: "FM1358-KG",
    label: "FM1358",
  },
  {
    value: "FM1359-KG",
    label: "FM1359",
  },
  {
    value: "FM1360-KG",
    label: "FM1360",
  },
  {
    value: "FM1361-KG",
    label: "FM1361",
  },
  {
    value: "FM1362-KG",
    label: "FM1362",
  },
  {
    value: "FM1363-KG",
    label: "FM1363",
  },
  {
    value: "FM1364-KG",
    label: "FM1364",
  },
  {
    value: "FM1365-KG",
    label: "FM1365",
  },
  {
    value: "FM1366-KG",
    label: "FM1366",
  },
  {
    value: "FM1367-KG",
    label: "FM1367",
  },
  {
    value: "FM1368-KG",
    label: "FM1368",
  },
  {
    value: "FM1370-KG",
    label: "FM1370",
  },
  {
    value: "FM1371-KG",
    label: "FM1371",
  },
  {
    value: "FM1372-KG",
    label: "FM1372",
  },
  {
    value: "FM1373-KG",
    label: "FM1373",
  },
  {
    value: "FM1374-KG",
    label: "FM1374",
  },
  {
    value: "FM1375-KG",
    label: "FM1375",
  },
  {
    value: "FM1377-KG",
    label: "FM1377",
  },
  {
    value: "FM1378-KG",
    label: "FM1378",
  },
  {
    value: "FM1379-KG",
    label: "FM1379",
  },
  {
    value: "FM1380-KG",
    label: "FM1380",
  },
  {
    value: "FM1381-KG",
    label: "FM1381",
  },
  {
    value: "FM1382-KG",
    label: "FM1382",
  },
  {
    value: "FM1383-KG",
    label: "FM1383",
  },
  {
    value: "FM1384-KG",
    label: "FM1384",
  },
  {
    value: "FM1385-KG",
    label: "FM1385",
  },
  {
    value: "FM1386-KG",
    label: "FM1386",
  },
  {
    value: "FM1387-KG",
    label: "FM1387",
  },
  {
    value: "FM1388-KG",
    label: "FM1388",
  },
  {
    value: "FM1389-KG",
    label: "FM1389",
  },
  {
    value: "FM1390-KG",
    label: "FM1390",
  },
  {
    value: "FM1391-KG",
    label: "FM1391",
  },
  {
    value: "FM1392-KG",
    label: "FM1392",
  },
  {
    value: "FM1393-KG",
    label: "FM1393",
  },
  {
    value: "FM1394-KG",
    label: "FM1394",
  },
  {
    value: "FM1395-KG",
    label: "FM1395",
  },
  {
    value: "FM1396-KG",
    label: "FM1396",
  },
  {
    value: "FM1397-KG",
    label: "FM1397",
  },
  {
    value: "FM1398-KG",
    label: "FM1398",
  },
  {
    value: "FM1399-KG",
    label: "FM1399",
  },
  {
    value: "FM1400-KG",
    label: "FM1400",
  },
  {
    value: "FM1402-KG",
    label: "FM1402",
  },
  {
    value: "FM1403-KG",
    label: "FM1403",
  },
  {
    value: "FM1404-KG",
    label: "FM1404",
  },
  {
    value: "FM1405-KG",
    label: "FM1405",
  },
  {
    value: "FM1406-KG",
    label: "FM1406",
  },
  {
    value: "FM1407-KG",
    label: "FM1407",
  },
  {
    value: "FM1408-KG",
    label: "FM1408",
  },
  {
    value: "FM1409-KG",
    label: "FM1409",
  },
  {
    value: "FM1410-KG",
    label: "FM1410",
  },
  {
    value: "FM1411-KG",
    label: "FM1411",
  },
  {
    value: "FM1412-KG",
    label: "FM1412",
  },
  {
    value: "FM1413-KG",
    label: "FM1413",
  },
  {
    value: "FM1414-KG",
    label: "FM1414",
  },
  {
    value: "FM1415-KG",
    label: "FM1415",
  },
  {
    value: "FM1416-KG",
    label: "FM1416",
  },
  {
    value: "FM1417-KG",
    label: "FM1417",
  },
  {
    value: "FM1418-KG",
    label: "FM1418",
  },
  {
    value: "FM1419-KG",
    label: "FM1419",
  },
  {
    value: "FM1420-KG",
    label: "FM1420",
  },
  {
    value: "FM1421-KG",
    label: "FM1421",
  },
  {
    value: "FM1422-KG",
    label: "FM1422",
  },
  {
    value: "FM1423-KG",
    label: "FM1423",
  },
  {
    value: "FM1424-KG",
    label: "FM1424",
  },
  {
    value: "FM1425-KG",
    label: "FM1425",
  },
  {
    value: "FM1426-KG",
    label: "FM1426",
  },
  {
    value: "FM1427-KG",
    label: "FM1427",
  },
  {
    value: "FM1428-KG",
    label: "FM1428",
  },
  {
    value: "FM1429-KG",
    label: "FM1429",
  },
  {
    value: "FM1430-KG",
    label: "FM1430",
  },
  {
    value: "FM1432-KG",
    label: "FM1432",
  },
  {
    value: "FM1433-KG",
    label: "FM1433",
  },
  {
    value: "FM1434-KG",
    label: "FM1434",
  },
  {
    value: "FM1435-KG",
    label: "FM1435",
  },
  {
    value: "FM1436-KG",
    label: "FM1436",
  },
  {
    value: "FM1437-KG",
    label: "FM1437",
  },
  {
    value: "FM1438-KG",
    label: "FM1438",
  },
  {
    value: "FM1439-KG",
    label: "FM1439",
  },
  {
    value: "FM1440-KG",
    label: "FM1440",
  },
  {
    value: "FM1441-KG",
    label: "FM1441",
  },
  {
    value: "FM1442-KG",
    label: "FM1442",
  },
  {
    value: "FM1443-KG",
    label: "FM1443",
  },
  {
    value: "FM1444-KG",
    label: "FM1444",
  },
  {
    value: "FM1445-KG",
    label: "FM1445",
  },
  {
    value: "FM1446-KG",
    label: "FM1446",
  },
  {
    value: "FM1447-KG",
    label: "FM1447",
  },
  {
    value: "FM1448-KG",
    label: "FM1448",
  },
  {
    value: "FM1449-KG",
    label: "FM1449",
  },
  {
    value: "FM1450-KG",
    label: "FM1450",
  },
  {
    value: "FM1451-KG",
    label: "FM1451",
  },
  {
    value: "FM1452-KG",
    label: "FM1452",
  },
  {
    value: "FM1453-KG",
    label: "FM1453",
  },
  {
    value: "FM1454-KG",
    label: "FM1454",
  },
  {
    value: "FM1455-KG",
    label: "FM1455",
  },
  {
    value: "FM1456-KG",
    label: "FM1456",
  },
  {
    value: "FM1457-KG",
    label: "FM1457",
  },
  {
    value: "FM1458-KG",
    label: "FM1458",
  },
  {
    value: "FM1459-KG",
    label: "FM1459",
  },
  {
    value: "FM1460-KG",
    label: "FM1460",
  },
  {
    value: "FM1461-KG",
    label: "FM1461",
  },
  {
    value: "FM1462-KG",
    label: "FM1462",
  },
  {
    value: "FM1463-KG",
    label: "FM1463",
  },
  {
    value: "FM1464-KG",
    label: "FM1464",
  },
  {
    value: "FM1465-KG",
    label: "FM1465",
  },
  {
    value: "FM1466-KG",
    label: "FM1466",
  },
  {
    value: "FM1467-KG",
    label: "FM1467",
  },
  {
    value: "FM1468-KG",
    label: "FM1468",
  },
  {
    value: "FM1469-KG",
    label: "FM1469",
  },
  {
    value: "FM1470-KG",
    label: "FM1470",
  },
  {
    value: "FM1471-KG",
    label: "FM1471",
  },
  {
    value: "FM1472-KG",
    label: "FM1472",
  },
  {
    value: "FM1473-KG",
    label: "FM1473",
  },
  {
    value: "FM1475-KG",
    label: "FM1475",
  },
  {
    value: "FM1476-KG",
    label: "FM1476",
  },
  {
    value: "FM1477-KG",
    label: "FM1477",
  },
  {
    value: "FM1478-KG",
    label: "FM1478",
  },
  {
    value: "FM1479-KG",
    label: "FM1479",
  },
  {
    value: "FM1480-KG",
    label: "FM1480",
  },
  {
    value: "FM1481-KG",
    label: "FM1481",
  },
  {
    value: "FM1482-KG",
    label: "FM1482",
  },
  {
    value: "FM1483-KG",
    label: "FM1483",
  },
  {
    value: "FM1484-KG",
    label: "FM1484",
  },
  {
    value: "FM1485-KG",
    label: "FM1485",
  },
  {
    value: "FM1486-KG",
    label: "FM1486",
  },
  {
    value: "FM1487-KG",
    label: "FM1487",
  },
  {
    value: "FM1488-KG",
    label: "FM1488",
  },
  {
    value: "FM1489-KG",
    label: "FM1489",
  },
  {
    value: "FM1490-KG",
    label: "FM1490",
  },
  {
    value: "FM1491-KG",
    label: "FM1491",
  },
  {
    value: "FM1493-KG",
    label: "FM1493",
  },
  {
    value: "FM1494-KG",
    label: "FM1494",
  },
  {
    value: "FM1495-KG",
    label: "FM1495",
  },
  {
    value: "FM1496-KG",
    label: "FM1496",
  },
  {
    value: "FM1497-KG",
    label: "FM1497",
  },
  {
    value: "FM1498-KG",
    label: "FM1498",
  },
  {
    value: "FM1499-KG",
    label: "FM1499",
  },
  {
    value: "FM1500-KG",
    label: "FM1500",
  },
  {
    value: "FM1501-KG",
    label: "FM1501",
  },
  {
    value: "FM1502-KG",
    label: "FM1502",
  },
  {
    value: "FM1503-KG",
    label: "FM1503",
  },
  {
    value: "FM1504-KG",
    label: "FM1504",
  },
  {
    value: "FM1505-KG",
    label: "FM1505",
  },
  {
    value: "FM1506-KG",
    label: "FM1506",
  },
  {
    value: "FM1507-KG",
    label: "FM1507",
  },
  {
    value: "FM1508-KG",
    label: "FM1508",
  },
  {
    value: "FM1509-KG",
    label: "FM1509",
  },
  {
    value: "FM1510-KG",
    label: "FM1510",
  },
  {
    value: "FM1511-KG",
    label: "FM1511",
  },
  {
    value: "FM1512-KG",
    label: "FM1512",
  },
  {
    value: "FM1513-KG",
    label: "FM1513",
  },
  {
    value: "FM1514-KG",
    label: "FM1514",
  },
  {
    value: "FM1515-KG",
    label: "FM1515",
  },
  {
    value: "FM1516-KG",
    label: "FM1516",
  },
  {
    value: "FM1518-KG",
    label: "FM1518",
  },
  {
    value: "FM1519-KG",
    label: "FM1519",
  },
  {
    value: "FM1520-KG",
    label: "FM1520",
  },
  {
    value: "FM1521-KG",
    label: "FM1521",
  },
  {
    value: "FM1522-KG",
    label: "FM1522",
  },
  {
    value: "FM1523-KG",
    label: "FM1523",
  },
  {
    value: "FM1524-KG",
    label: "FM1524",
  },
  {
    value: "FM1525-KG",
    label: "FM1525",
  },
  {
    value: "FM1527-KG",
    label: "FM1527",
  },
  {
    value: "FM1528-KG",
    label: "FM1528",
  },
  {
    value: "FM1529-KG",
    label: "FM1529",
  },
  {
    value: "FM1530-KG",
    label: "FM1530",
  },
  {
    value: "FM1531-KG",
    label: "FM1531",
  },
  {
    value: "FM1532-KG",
    label: "FM1532",
  },
  {
    value: "FM1533-KG",
    label: "FM1533",
  },
  {
    value: "FM1534-KG",
    label: "FM1534",
  },
  {
    value: "FM1535-KG",
    label: "FM1535",
  },
  {
    value: "FM1536-KG",
    label: "FM1536",
  },
  {
    value: "FM1537-KG",
    label: "FM1537",
  },
  {
    value: "FM1538-KG",
    label: "FM1538",
  },
  {
    value: "FM1539-KG",
    label: "FM1539",
  },
  {
    value: "FM1540-KG",
    label: "FM1540",
  },
  {
    value: "FM1541-KG",
    label: "FM1541",
  },
  {
    value: "FM1542-KG",
    label: "FM1542",
  },
  {
    value: "FM1543-KG",
    label: "FM1543",
  },
  {
    value: "FM1544-KG",
    label: "FM1544",
  },
  {
    value: "FM1545-KG",
    label: "FM1545",
  },
  {
    value: "FM1546-KG",
    label: "FM1546",
  },
  {
    value: "FM1547-KG",
    label: "FM1547",
  },
  {
    value: "FM1548-KG",
    label: "FM1548",
  },
  {
    value: "FM1549-KG",
    label: "FM1549",
  },
  {
    value: "FM1550-KG",
    label: "FM1550",
  },
  {
    value: "FM1551-KG",
    label: "FM1551",
  },
  {
    value: "FM1552-KG",
    label: "FM1552",
  },
  {
    value: "FM1553-KG",
    label: "FM1553",
  },
  {
    value: "FM1554-KG",
    label: "FM1554",
  },
  {
    value: "FM1556-KG",
    label: "FM1556",
  },
  {
    value: "FM1557-KG",
    label: "FM1557",
  },
  {
    value: "FM1558-KG",
    label: "FM1558",
  },
  {
    value: "FM1559-KG",
    label: "FM1559",
  },
  {
    value: "FM1560-KG",
    label: "FM1560",
  },
  {
    value: "FM1561-KG",
    label: "FM1561",
  },
  {
    value: "FM1562-KG",
    label: "FM1562",
  },
  {
    value: "FM1563-KG",
    label: "FM1563",
  },
  {
    value: "FM1564-KG",
    label: "FM1564",
  },
  {
    value: "FM1565-KG",
    label: "FM1565",
  },
  {
    value: "FM1566-KG",
    label: "FM1566",
  },
  {
    value: "FM1567-KG",
    label: "FM1567",
  },
  {
    value: "FM1568-KG",
    label: "FM1568",
  },
  {
    value: "FM1569-KG",
    label: "FM1569",
  },
  {
    value: "FM1570-KG",
    label: "FM1570",
  },
  {
    value: "FM1571-KG",
    label: "FM1571",
  },
  {
    value: "FM1572-KG",
    label: "FM1572",
  },
  {
    value: "FM1573-KG",
    label: "FM1573",
  },
  {
    value: "FM1574-KG",
    label: "FM1574",
  },
  {
    value: "FM1575-KG",
    label: "FM1575",
  },
  {
    value: "FM1576-KG",
    label: "FM1576",
  },
  {
    value: "FM1577-KG",
    label: "FM1577",
  },
  {
    value: "FM1578-KG",
    label: "FM1578",
  },
  {
    value: "FM1579-KG",
    label: "FM1579",
  },
  {
    value: "FM1580-KG",
    label: "FM1580",
  },
  {
    value: "FM1581-KG",
    label: "FM1581",
  },
  {
    value: "FM1582-KG",
    label: "FM1582",
  },
  {
    value: "FM1583-KG",
    label: "FM1583",
  },
  {
    value: "FM1584-KG",
    label: "FM1584",
  },
  {
    value: "FM1585-KG",
    label: "FM1585",
  },
  {
    value: "FM1586-KG",
    label: "FM1586",
  },
  {
    value: "FM1588-KG",
    label: "FM1588",
  },
  {
    value: "FM1589-KG",
    label: "FM1589",
  },
  {
    value: "FM1590-KG",
    label: "FM1590",
  },
  {
    value: "FM1591-KG",
    label: "FM1591",
  },
  {
    value: "FM1592-KG",
    label: "FM1592",
  },
  {
    value: "FM1593-KG",
    label: "FM1593",
  },
  {
    value: "FM1594-KG",
    label: "FM1594",
  },
  {
    value: "FM1595-KG",
    label: "FM1595",
  },
  {
    value: "FM1596-KG",
    label: "FM1596",
  },
  {
    value: "FM1597-KG",
    label: "FM1597",
  },
  {
    value: "FM1598-KG",
    label: "FM1598",
  },
  {
    value: "FM1599-KG",
    label: "FM1599",
  },
  {
    value: "FM1600-KG",
    label: "FM1600",
  },
  {
    value: "FM1601-KG",
    label: "FM1601",
  },
  {
    value: "FM1602-KG",
    label: "FM1602",
  },
  {
    value: "FM1603-KG",
    label: "FM1603",
  },
  {
    value: "FM1604-KG",
    label: "FM1604",
  },
  {
    value: "FM1605-KG",
    label: "FM1605",
  },
  {
    value: "FM1606-KG",
    label: "FM1606",
  },
  {
    value: "FM1607-KG",
    label: "FM1607",
  },
  {
    value: "FM1608-KG",
    label: "FM1608",
  },
  {
    value: "FM1609-KG",
    label: "FM1609",
  },
  {
    value: "FM1610-KG",
    label: "FM1610",
  },
  {
    value: "FM1611-KG",
    label: "FM1611",
  },
  {
    value: "FM1612-KG",
    label: "FM1612",
  },
  {
    value: "FM1613-KG",
    label: "FM1613",
  },
  {
    value: "FM1614-KG",
    label: "FM1614",
  },
  {
    value: "FM1615-KG",
    label: "FM1615",
  },
  {
    value: "FM1616-KG",
    label: "FM1616",
  },
  {
    value: "FM1617-KG",
    label: "FM1617",
  },
  {
    value: "FM1618-KG",
    label: "FM1618",
  },
  {
    value: "FM1619-KG",
    label: "FM1619",
  },
  {
    value: "FM1620-KG",
    label: "FM1620",
  },
  {
    value: "FM1621-KG",
    label: "FM1621",
  },
  {
    value: "FM1622-KG",
    label: "FM1622",
  },
  {
    value: "FM1624-KG",
    label: "FM1624",
  },
  {
    value: "FM1625-KG",
    label: "FM1625",
  },
  {
    value: "FM1626-KG",
    label: "FM1626",
  },
  {
    value: "FM1628-KG",
    label: "FM1628",
  },
  {
    value: "FM1629-KG",
    label: "FM1629",
  },
  {
    value: "FM1630-KG",
    label: "FM1630",
  },
  {
    value: "FM1632-KG",
    label: "FM1632",
  },
  {
    value: "FM1633-KG",
    label: "FM1633",
  },
  {
    value: "FM1634-KG",
    label: "FM1634",
  },
  {
    value: "FM1635-KG",
    label: "FM1635",
  },
  {
    value: "FM1636-KG",
    label: "FM1636",
  },
  {
    value: "FM1637-KG",
    label: "FM1637",
  },
  {
    value: "FM1638-KG",
    label: "FM1638",
  },
  {
    value: "FM1639-KG",
    label: "FM1639",
  },
  {
    value: "FM1640-KG",
    label: "FM1640",
  },
  {
    value: "FM1641-KG",
    label: "FM1641",
  },
  {
    value: "FM1642-KG",
    label: "FM1642",
  },
  {
    value: "FM1643-KG",
    label: "FM1643",
  },
  {
    value: "FM1644-KG",
    label: "FM1644",
  },
  {
    value: "FM1645-KG",
    label: "FM1645",
  },
  {
    value: "FM1646-KG",
    label: "FM1646",
  },
  {
    value: "FM1647-KG",
    label: "FM1647",
  },
  {
    value: "FM1648-KG",
    label: "FM1648",
  },
  {
    value: "FM1649-KG",
    label: "FM1649",
  },
  {
    value: "FM1650-KG",
    label: "FM1650",
  },
  {
    value: "FM1651-KG",
    label: "FM1651",
  },
  {
    value: "FM1652-KG",
    label: "FM1652",
  },
  {
    value: "FM1653-KG",
    label: "FM1653",
  },
  {
    value: "FM1655-KG",
    label: "FM1655",
  },
  {
    value: "FM1656-KG",
    label: "FM1656",
  },
  {
    value: "FM1657-KG",
    label: "FM1657",
  },
  {
    value: "FM1658-KG",
    label: "FM1658",
  },
  {
    value: "FM1660-KG",
    label: "FM1660",
  },
  {
    value: "FM1661-KG",
    label: "FM1661",
  },
  {
    value: "FM1662-KG",
    label: "FM1662",
  },
  {
    value: "FM1663-KG",
    label: "FM1663",
  },
  {
    value: "FM1664-KG",
    label: "FM1664",
  },
  {
    value: "FM1665-KG",
    label: "FM1665",
  },
  {
    value: "FM1666-KG",
    label: "FM1666",
  },
  {
    value: "FM1667-KG",
    label: "FM1667",
  },
  {
    value: "FM1668-KG",
    label: "FM1668",
  },
  {
    value: "FM1669-KG",
    label: "FM1669",
  },
  {
    value: "FM1670-KG",
    label: "FM1670",
  },
  {
    value: "FM1671-KG",
    label: "FM1671",
  },
  {
    value: "FM1673-KG",
    label: "FM1673",
  },
  {
    value: "FM1677-KG",
    label: "FM1677",
  },
  {
    value: "FM1678-KG",
    label: "FM1678",
  },
  {
    value: "FM1679-KG",
    label: "FM1679",
  },
  {
    value: "FM1680-KG",
    label: "FM1680",
  },
  {
    value: "FM1681-KG",
    label: "FM1681",
  },
  {
    value: "FM1682-KG",
    label: "FM1682",
  },
  {
    value: "FM1683-KG",
    label: "FM1683",
  },
  {
    value: "FM1684-KG",
    label: "FM1684",
  },
  {
    value: "FM1685-KG",
    label: "FM1685",
  },
  {
    value: "FM1686-KG",
    label: "FM1686",
  },
  {
    value: "FM1687-KG",
    label: "FM1687",
  },
  {
    value: "FM1688-KG",
    label: "FM1688",
  },
  {
    value: "FM1689-KG",
    label: "FM1689",
  },
  {
    value: "FM1690-KG",
    label: "FM1690",
  },
  {
    value: "FM1692-KG",
    label: "FM1692",
  },
  {
    value: "FM1693-KG",
    label: "FM1693",
  },
  {
    value: "FM1694-KG",
    label: "FM1694",
  },
  {
    value: "FM1695-KG",
    label: "FM1695",
  },
  {
    value: "FM1696-KG",
    label: "FM1696",
  },
  {
    value: "FM1697-KG",
    label: "FM1697",
  },
  {
    value: "FM1698-KG",
    label: "FM1698",
  },
  {
    value: "FM1699-KG",
    label: "FM1699",
  },
  {
    value: "FM1700-KG",
    label: "FM1700",
  },
  {
    value: "FM1701-KG",
    label: "FM1701",
  },
  {
    value: "FM1702-KG",
    label: "FM1702",
  },
  {
    value: "FM1703-KG",
    label: "FM1703",
  },
  {
    value: "FM1704-KG",
    label: "FM1704",
  },
  {
    value: "FM1705-KG",
    label: "FM1705",
  },
  {
    value: "FM1706-KG",
    label: "FM1706",
  },
  {
    value: "FM1707-KG",
    label: "FM1707",
  },
  {
    value: "FM1708-KG",
    label: "FM1708",
  },
  {
    value: "FM1709-KG",
    label: "FM1709",
  },
  {
    value: "FM1710-KG",
    label: "FM1710",
  },
  {
    value: "FM1711-KG",
    label: "FM1711",
  },
  {
    value: "FM1712-KG",
    label: "FM1712",
  },
  {
    value: "FM1713-KG",
    label: "FM1713",
  },
  {
    value: "FM1714-KG",
    label: "FM1714",
  },
  {
    value: "FM1715-KG",
    label: "FM1715",
  },
  {
    value: "FM1716-KG",
    label: "FM1716",
  },
  {
    value: "FM1717-KG",
    label: "FM1717",
  },
  {
    value: "FM1718-KG",
    label: "FM1718",
  },
  {
    value: "FM1719-KG",
    label: "FM1719",
  },
  {
    value: "FM1720-KG",
    label: "FM1720",
  },
  {
    value: "FM1721-KG",
    label: "FM1721",
  },
  {
    value: "FM1722-KG",
    label: "FM1722",
  },
  {
    value: "FM1724-KG",
    label: "FM1724",
  },
  {
    value: "FM1725-KG",
    label: "FM1725",
  },
  {
    value: "FM1726-KG",
    label: "FM1726",
  },
  {
    value: "FM1727-KG",
    label: "FM1727",
  },
  {
    value: "FM1728-KG",
    label: "FM1728",
  },
  {
    value: "FM1729-KG",
    label: "FM1729",
  },
  {
    value: "FM1730-KG",
    label: "FM1730",
  },
  {
    value: "FM1731-KG",
    label: "FM1731",
  },
  {
    value: "FM1732-KG",
    label: "FM1732",
  },
  {
    value: "FM1733-KG",
    label: "FM1733",
  },
  {
    value: "FM1734-KG",
    label: "FM1734",
  },
  {
    value: "FM1735-KG",
    label: "FM1735",
  },
  {
    value: "FM1736-KG",
    label: "FM1736",
  },
  {
    value: "FM1737-KG",
    label: "FM1737",
  },
  {
    value: "FM1738-KG",
    label: "FM1738",
  },
  {
    value: "FM1739-KG",
    label: "FM1739",
  },
  {
    value: "FM1740-KG",
    label: "FM1740",
  },
  {
    value: "FM1741-KG",
    label: "FM1741",
  },
  {
    value: "FM1742-KG",
    label: "FM1742",
  },
  {
    value: "FM1743-KG",
    label: "FM1743",
  },
  {
    value: "FM1744-KG",
    label: "FM1744",
  },
  {
    value: "FM1745-KG",
    label: "FM1745",
  },
  {
    value: "FM1746-KG",
    label: "FM1746",
  },
  {
    value: "FM1747-KG",
    label: "FM1747",
  },
  {
    value: "FM1748-KG",
    label: "FM1748",
  },
  {
    value: "FM1749-KG",
    label: "FM1749",
  },
  {
    value: "FM1750-KG",
    label: "FM1750",
  },
  {
    value: "FM1751-KG",
    label: "FM1751",
  },
  {
    value: "FM1752-KG",
    label: "FM1752",
  },
  {
    value: "FM1753-KG",
    label: "FM1753",
  },
  {
    value: "FM1754-KG",
    label: "FM1754",
  },
  {
    value: "FM1755-KG",
    label: "FM1755",
  },
  {
    value: "FM1756-KG",
    label: "FM1756",
  },
  {
    value: "FM1757-KG",
    label: "FM1757",
  },
  {
    value: "FM1758-KG",
    label: "FM1758",
  },
  {
    value: "FM1759-KG",
    label: "FM1759",
  },
  {
    value: "FM1760-KG",
    label: "FM1760",
  },
  {
    value: "FM1761-KG",
    label: "FM1761",
  },
  {
    value: "FM1762-KG",
    label: "FM1762",
  },
  {
    value: "FM1763-KG",
    label: "FM1763",
  },
  {
    value: "FM1764-KG",
    label: "FM1764",
  },
  {
    value: "FM1765-KG",
    label: "FM1765",
  },
  {
    value: "FM1766-KG",
    label: "FM1766",
  },
  {
    value: "FM1767-KG",
    label: "FM1767",
  },
  {
    value: "FM1768-KG",
    label: "FM1768",
  },
  {
    value: "FM1769-KG",
    label: "FM1769",
  },
  {
    value: "FM1770-KG",
    label: "FM1770",
  },
  {
    value: "FM1771-KG",
    label: "FM1771",
  },
  {
    value: "FM1772-KG",
    label: "FM1772",
  },
  {
    value: "FM1774-KG",
    label: "FM1774",
  },
  {
    value: "FM1775-KG",
    label: "FM1775",
  },
  {
    value: "FM1776-KG",
    label: "FM1776",
  },
  {
    value: "FM1777-KG",
    label: "FM1777",
  },
  {
    value: "FM1778-KG",
    label: "FM1778",
  },
  {
    value: "FM1779-KG",
    label: "FM1779",
  },
  {
    value: "FM1780-KG",
    label: "FM1780",
  },
  {
    value: "FM1781-KG",
    label: "FM1781",
  },
  {
    value: "FM1782-KG",
    label: "FM1782",
  },
  {
    value: "FM1783-KG",
    label: "FM1783",
  },
  {
    value: "FM1784-KG",
    label: "FM1784",
  },
  {
    value: "FM1785-KG",
    label: "FM1785",
  },
  {
    value: "FM1786-KG",
    label: "FM1786",
  },
  {
    value: "FM1787-KG",
    label: "FM1787",
  },
  {
    value: "FM1788-KG",
    label: "FM1788",
  },
  {
    value: "FM1789-KG",
    label: "FM1789",
  },
  {
    value: "FM1790-KG",
    label: "FM1790",
  },
  {
    value: "FM1791-KG",
    label: "FM1791",
  },
  {
    value: "FM1793-KG",
    label: "FM1793",
  },
  {
    value: "FM1794-KG",
    label: "FM1794",
  },
  {
    value: "FM1795-KG",
    label: "FM1795",
  },
  {
    value: "FM1796-KG",
    label: "FM1796",
  },
  {
    value: "FM1797-KG",
    label: "FM1797",
  },
  {
    value: "FM1798-KG",
    label: "FM1798",
  },
  {
    value: "FM1799-KG",
    label: "FM1799",
  },
  {
    value: "FM1800-KG",
    label: "FM1800",
  },
  {
    value: "FM1801-KG",
    label: "FM1801",
  },
  {
    value: "FM1802-KG",
    label: "FM1802",
  },
  {
    value: "FM1803-KG",
    label: "FM1803",
  },
  {
    value: "FM1804-KG",
    label: "FM1804",
  },
  {
    value: "FM1805-KG",
    label: "FM1805",
  },
  {
    value: "FM1806-KG",
    label: "FM1806",
  },
  {
    value: "FM1807-KG",
    label: "FM1807",
  },
  {
    value: "FM1808-KG",
    label: "FM1808",
  },
  {
    value: "FM1809-KG",
    label: "FM1809",
  },
  {
    value: "FM1810-KG",
    label: "FM1810",
  },
  {
    value: "FM1811-KG",
    label: "FM1811",
  },
  {
    value: "FM1812-KG",
    label: "FM1812",
  },
  {
    value: "FM1813-KG",
    label: "FM1813",
  },
  {
    value: "FM1814-KG",
    label: "FM1814",
  },
  {
    value: "FM1815-KG",
    label: "FM1815",
  },
  {
    value: "FM1816-KG",
    label: "FM1816",
  },
  {
    value: "FM1817-KG",
    label: "FM1817",
  },
  {
    value: "FM1818-KG",
    label: "FM1818",
  },
  {
    value: "FM1819-KG",
    label: "FM1819",
  },
  {
    value: "FM1821-KG",
    label: "FM1821",
  },
  {
    value: "FM1822-KG",
    label: "FM1822",
  },
  {
    value: "FM1823-KG",
    label: "FM1823",
  },
  {
    value: "FM1824-KG",
    label: "FM1824",
  },
  {
    value: "FM1825-KG",
    label: "FM1825",
  },
  {
    value: "FM1827-KG",
    label: "FM1827",
  },
  {
    value: "FM1828-KG",
    label: "FM1828",
  },
  {
    value: "FM1829-KG",
    label: "FM1829",
  },
  {
    value: "FM1830-KG",
    label: "FM1830",
  },
  {
    value: "FM1831-KG",
    label: "FM1831",
  },
  {
    value: "FM1833-KG",
    label: "FM1833",
  },
  {
    value: "FM1834-KG",
    label: "FM1834",
  },
  {
    value: "FM1835-KG",
    label: "FM1835",
  },
  {
    value: "FM1836-KG",
    label: "FM1836",
  },
  {
    value: "FM1838-KG",
    label: "FM1838",
  },
  {
    value: "FM1839-KG",
    label: "FM1839",
  },
  {
    value: "FM1840-KG",
    label: "FM1840",
  },
  {
    value: "FM1841-KG",
    label: "FM1841",
  },
  {
    value: "FM1842-KG",
    label: "FM1842",
  },
  {
    value: "FM1843-KG",
    label: "FM1843",
  },
  {
    value: "FM1844-KG",
    label: "FM1844",
  },
  {
    value: "FM1845-KG",
    label: "FM1845",
  },
  {
    value: "FM1846-KG",
    label: "FM1846",
  },
  {
    value: "FM1847-KG",
    label: "FM1847",
  },
  {
    value: "FM1848-KG",
    label: "FM1848",
  },
  {
    value: "FM1849-KG",
    label: "FM1849",
  },
  {
    value: "FM1850-KG",
    label: "FM1850",
  },
  {
    value: "FM1851-KG",
    label: "FM1851",
  },
  {
    value: "FM1852-KG",
    label: "FM1852",
  },
  {
    value: "FM1853-KG",
    label: "FM1853",
  },
  {
    value: "FM1854-KG",
    label: "FM1854",
  },
  {
    value: "FM1856-KG",
    label: "FM1856",
  },
  {
    value: "FM1857-KG",
    label: "FM1857",
  },
  {
    value: "FM1858-KG",
    label: "FM1858",
  },
  {
    value: "FM1859-KG",
    label: "FM1859",
  },
  {
    value: "FM1860-KG",
    label: "FM1860",
  },
  {
    value: "FM1861-KG",
    label: "FM1861",
  },
  {
    value: "FM1862-KG",
    label: "FM1862",
  },
  {
    value: "FM1863-KG",
    label: "FM1863",
  },
  {
    value: "FM1864-KG",
    label: "FM1864",
  },
  {
    value: "FM1866-KG",
    label: "FM1866",
  },
  {
    value: "FM1867-KG",
    label: "FM1867",
  },
  {
    value: "FM1868-KG",
    label: "FM1868",
  },
  {
    value: "FM1870-KG",
    label: "FM1870",
  },
  {
    value: "FM1872-KG",
    label: "FM1872",
  },
  {
    value: "FM1873-KG",
    label: "FM1873",
  },
  {
    value: "FM1874-KG",
    label: "FM1874",
  },
  {
    value: "FM1875-KG",
    label: "FM1875",
  },
  {
    value: "FM1876-KG",
    label: "FM1876",
  },
  {
    value: "FM1877-KG",
    label: "FM1877",
  },
  {
    value: "FM1878-KG",
    label: "FM1878",
  },
  {
    value: "FM1879-KG",
    label: "FM1879",
  },
  {
    value: "FM1880-KG",
    label: "FM1880",
  },
  {
    value: "FM1881-KG",
    label: "FM1881",
  },
  {
    value: "FM1882-KG",
    label: "FM1882",
  },
  {
    value: "FM1883-KG",
    label: "FM1883",
  },
  {
    value: "FM1884-KG",
    label: "FM1884",
  },
  {
    value: "FM1885-KG",
    label: "FM1885",
  },
  {
    value: "FM1886-KG",
    label: "FM1886",
  },
  {
    value: "FM1887-KG",
    label: "FM1887",
  },
  {
    value: "FM1889-KG",
    label: "FM1889",
  },
  {
    value: "FM1890-KG",
    label: "FM1890",
  },
  {
    value: "FM1891-KG",
    label: "FM1891",
  },
  {
    value: "FM1892-KG",
    label: "FM1892",
  },
  {
    value: "FM1893-KG",
    label: "FM1893",
  },
  {
    value: "FM1894-KG",
    label: "FM1894",
  },
  {
    value: "FM1895-KG",
    label: "FM1895",
  },
  {
    value: "FM1896-KG",
    label: "FM1896",
  },
  {
    value: "FM1897-KG",
    label: "FM1897",
  },
  {
    value: "FM1898-KG",
    label: "FM1898",
  },
  {
    value: "FM1899-KG",
    label: "FM1899",
  },
  {
    value: "FM1901-KG",
    label: "FM1901",
  },
  {
    value: "FM1902-KG",
    label: "FM1902",
  },
  {
    value: "FM1903-KG",
    label: "FM1903",
  },
  {
    value: "FM1904-KG",
    label: "FM1904",
  },
  {
    value: "FM1905-KG",
    label: "FM1905",
  },
  {
    value: "FM1906-KG",
    label: "FM1906",
  },
  {
    value: "FM1907-KG",
    label: "FM1907",
  },
  {
    value: "FM1908-KG",
    label: "FM1908",
  },
  {
    value: "FM1909-KG",
    label: "FM1909",
  },
  {
    value: "FM1910-KG",
    label: "FM1910",
  },
  {
    value: "FM1911-KG",
    label: "FM1911",
  },
  {
    value: "FM1912-KG",
    label: "FM1912",
  },
  {
    value: "FM1913-KG",
    label: "FM1913",
  },
  {
    value: "FM1914-KG",
    label: "FM1914",
  },
  {
    value: "FM1915-KG",
    label: "FM1915",
  },
  {
    value: "FM1916-KG",
    label: "FM1916",
  },
  {
    value: "FM1917-KG",
    label: "FM1917",
  },
  {
    value: "FM1918-KG",
    label: "FM1918",
  },
  {
    value: "FM1920-KG",
    label: "FM1920",
  },
  {
    value: "FM1921-KG",
    label: "FM1921",
  },
  {
    value: "FM1922-KG",
    label: "FM1922",
  },
  {
    value: "FM1923-KG",
    label: "FM1923",
  },
  {
    value: "FM1924-KG",
    label: "FM1924",
  },
  {
    value: "FM1925-KG",
    label: "FM1925",
  },
  {
    value: "FM1926-KG",
    label: "FM1926",
  },
  {
    value: "FM1927-KG",
    label: "FM1927",
  },
  {
    value: "FM1929-KG",
    label: "FM1929",
  },
  {
    value: "FM1930-KG",
    label: "FM1930",
  },
  {
    value: "FM1931-KG",
    label: "FM1931",
  },
  {
    value: "FM1932-KG",
    label: "FM1932",
  },
  {
    value: "FM1933-KG",
    label: "FM1933",
  },
  {
    value: "FM1934-KG",
    label: "FM1934",
  },
  {
    value: "FM1935-KG",
    label: "FM1935",
  },
  {
    value: "FM1936-KG",
    label: "FM1936",
  },
  {
    value: "FM1937-KG",
    label: "FM1937",
  },
  {
    value: "FM1938-KG",
    label: "FM1938",
  },
  {
    value: "FM1939-KG",
    label: "FM1939",
  },
  {
    value: "FM1940-KG",
    label: "FM1940",
  },
  {
    value: "FM1941-KG",
    label: "FM1941",
  },
  {
    value: "FM1942-KG",
    label: "FM1942",
  },
  {
    value: "FM1943-KG",
    label: "FM1943",
  },
  {
    value: "FM1944-KG",
    label: "FM1944",
  },
  {
    value: "FM1945-KG",
    label: "FM1945",
  },
  {
    value: "FM1946-KG",
    label: "FM1946",
  },
  {
    value: "FM1947-KG",
    label: "FM1947",
  },
  {
    value: "FM1948-KG",
    label: "FM1948",
  },
  {
    value: "FM1949-KG",
    label: "FM1949",
  },
  {
    value: "FM1950-KG",
    label: "FM1950",
  },
  {
    value: "FM1951-KG",
    label: "FM1951",
  },
  {
    value: "FM1952-KG",
    label: "FM1952",
  },
  {
    value: "FM1953-KG",
    label: "FM1953",
  },
  {
    value: "FM1954-KG",
    label: "FM1954",
  },
  {
    value: "FM1955-KG",
    label: "FM1955",
  },
  {
    value: "FM1956-KG",
    label: "FM1956",
  },
  {
    value: "FM1957-KG",
    label: "FM1957",
  },
  {
    value: "FM1958-KG",
    label: "FM1958",
  },
  {
    value: "FM1959-KG",
    label: "FM1959",
  },
  {
    value: "FM1960-KG",
    label: "FM1960",
  },
  {
    value: "FM1961-KG",
    label: "FM1961",
  },
  {
    value: "FM1962-KG",
    label: "FM1962",
  },
  {
    value: "FM1963-KG",
    label: "FM1963",
  },
  {
    value: "FM1965-KG",
    label: "FM1965",
  },
  {
    value: "FM1966-KG",
    label: "FM1966",
  },
  {
    value: "FM1967-KG",
    label: "FM1967",
  },
  {
    value: "FM1968-KG",
    label: "FM1968",
  },
  {
    value: "FM1969-KG",
    label: "FM1969",
  },
  {
    value: "FM1970-KG",
    label: "FM1970",
  },
  {
    value: "FM1971-KG",
    label: "FM1971",
  },
  {
    value: "FM1972-KG",
    label: "FM1972",
  },
  {
    value: "FM1974-KG",
    label: "FM1974",
  },
  {
    value: "FM1975-KG",
    label: "FM1975",
  },
  {
    value: "FM1976-KG",
    label: "FM1976",
  },
  {
    value: "FM1977-KG",
    label: "FM1977",
  },
  {
    value: "FM1978-KG",
    label: "FM1978",
  },
  {
    value: "FM1979-KG",
    label: "FM1979",
  },
  {
    value: "FM1980-KG",
    label: "FM1980",
  },
  {
    value: "FM1981-KG",
    label: "FM1981",
  },
  {
    value: "FM1982-KG",
    label: "FM1982",
  },
  {
    value: "FM1983-KG",
    label: "FM1983",
  },
  {
    value: "FM1984-KG",
    label: "FM1984",
  },
  {
    value: "FM1985-KG",
    label: "FM1985",
  },
  {
    value: "FM1986-KG",
    label: "FM1986",
  },
  {
    value: "FM1987-KG",
    label: "FM1987",
  },
  {
    value: "FM1988-KG",
    label: "FM1988",
  },
  {
    value: "FM1990-KG",
    label: "FM1990",
  },
  {
    value: "FM1991-KG",
    label: "FM1991",
  },
  {
    value: "FM1992-KG",
    label: "FM1992",
  },
  {
    value: "FM1993-KG",
    label: "FM1993",
  },
  {
    value: "FM1994-KG",
    label: "FM1994",
  },
  {
    value: "FM1995-KG",
    label: "FM1995",
  },
  {
    value: "FM1996-KG",
    label: "FM1996",
  },
  {
    value: "FM1997-KG",
    label: "FM1997",
  },
  {
    value: "FM1998-KG",
    label: "FM1998",
  },
  {
    value: "FM1999-KG",
    label: "FM1999",
  },
  {
    value: "FM2000-KG",
    label: "FM2000",
  },
  {
    value: "FM2001-KG",
    label: "FM2001",
  },
  {
    value: "FM2002-KG",
    label: "FM2002",
  },
  {
    value: "FM2003-KG",
    label: "FM2003",
  },
  {
    value: "FM2004-KG",
    label: "FM2004",
  },
  {
    value: "FM2005-KG",
    label: "FM2005",
  },
  {
    value: "FM2006-KG",
    label: "FM2006",
  },
  {
    value: "FM2007-KG",
    label: "FM2007",
  },
  {
    value: "FM2008-KG",
    label: "FM2008",
  },
  {
    value: "FM2009-KG",
    label: "FM2009",
  },
  {
    value: "FM2010-KG",
    label: "FM2010",
  },
  {
    value: "FM2011-KG",
    label: "FM2011",
  },
  {
    value: "FM2012-KG",
    label: "FM2012",
  },
  {
    value: "FM2013-KG",
    label: "FM2013",
  },
  {
    value: "FM2014-KG",
    label: "FM2014",
  },
  {
    value: "FM2015-KG",
    label: "FM2015",
  },
  {
    value: "FM2016-KG",
    label: "FM2016",
  },
  {
    value: "FM2017-KG",
    label: "FM2017",
  },
  {
    value: "FM2018-KG",
    label: "FM2018",
  },
  {
    value: "FM2019-KG",
    label: "FM2019",
  },
  {
    value: "FM2020-KG",
    label: "FM2020",
  },
  {
    value: "FM2021-KG",
    label: "FM2021",
  },
  {
    value: "FM2022-KG",
    label: "FM2022",
  },
  {
    value: "FM2024-KG",
    label: "FM2024",
  },
  {
    value: "FM2025-KG",
    label: "FM2025",
  },
  {
    value: "FM2026-KG",
    label: "FM2026",
  },
  {
    value: "FM2027-KG",
    label: "FM2027",
  },
  {
    value: "FM2028-KG",
    label: "FM2028",
  },
  {
    value: "FM2029-KG",
    label: "FM2029",
  },
  {
    value: "FM2030-KG",
    label: "FM2030",
  },
  {
    value: "FM2031-KG",
    label: "FM2031",
  },
  {
    value: "FM2033-KG",
    label: "FM2033",
  },
  {
    value: "FM2035-KG",
    label: "FM2035",
  },
  {
    value: "FM2036-KG",
    label: "FM2036",
  },
  {
    value: "FM2037-KG",
    label: "FM2037",
  },
  {
    value: "FM2038-KG",
    label: "FM2038",
  },
  {
    value: "FM2039-KG",
    label: "FM2039",
  },
  {
    value: "FM2040-KG",
    label: "FM2040",
  },
  {
    value: "FM2041-KG",
    label: "FM2041",
  },
  {
    value: "FM2042-KG",
    label: "FM2042",
  },
  {
    value: "FM2043-KG",
    label: "FM2043",
  },
  {
    value: "FM2044-KG",
    label: "FM2044",
  },
  {
    value: "FM2045-KG",
    label: "FM2045",
  },
  {
    value: "FM2046-KG",
    label: "FM2046",
  },
  {
    value: "FM2047-KG",
    label: "FM2047",
  },
  {
    value: "FM2048-KG",
    label: "FM2048",
  },
  {
    value: "FM2049-KG",
    label: "FM2049",
  },
  {
    value: "FM2050-KG",
    label: "FM2050",
  },
  {
    value: "FM2051-KG",
    label: "FM2051",
  },
  {
    value: "FM2052-KG",
    label: "FM2052",
  },
  {
    value: "FM2053-KG",
    label: "FM2053",
  },
  {
    value: "FM2054-KG",
    label: "FM2054",
  },
  {
    value: "FM2055-KG",
    label: "FM2055",
  },
  {
    value: "FM2056-KG",
    label: "FM2056",
  },
  {
    value: "FM2057-KG",
    label: "FM2057",
  },
  {
    value: "FM2058-KG",
    label: "FM2058",
  },
  {
    value: "FM2060-KG",
    label: "FM2060",
  },
  {
    value: "FM2061-KG",
    label: "FM2061",
  },
  {
    value: "FM2062-KG",
    label: "FM2062",
  },
  {
    value: "FM2063-KG",
    label: "FM2063",
  },
  {
    value: "FM2064-KG",
    label: "FM2064",
  },
  {
    value: "FM2065-KG",
    label: "FM2065",
  },
  {
    value: "FM2066-KG",
    label: "FM2066",
  },
  {
    value: "FM2067-KG",
    label: "FM2067",
  },
  {
    value: "FM2068-KG",
    label: "FM2068",
  },
  {
    value: "FM2069-KG",
    label: "FM2069",
  },
  {
    value: "FM2070-KG",
    label: "FM2070",
  },
  {
    value: "FM2071-KG",
    label: "FM2071",
  },
  {
    value: "FM2072-KG",
    label: "FM2072",
  },
  {
    value: "FM2073-KG",
    label: "FM2073",
  },
  {
    value: "FM2074-KG",
    label: "FM2074",
  },
  {
    value: "FM2075-KG",
    label: "FM2075",
  },
  {
    value: "FM2076-KG",
    label: "FM2076",
  },
  {
    value: "FM2077-KG",
    label: "FM2077",
  },
  {
    value: "FM2078-KG",
    label: "FM2078",
  },
  {
    value: "FM2079-KG",
    label: "FM2079",
  },
  {
    value: "FM2080-KG",
    label: "FM2080",
  },
  {
    value: "FM2081-KG",
    label: "FM2081",
  },
  {
    value: "FM2082-KG",
    label: "FM2082",
  },
  {
    value: "FM2085-KG",
    label: "FM2085",
  },
  {
    value: "FM2086-KG",
    label: "FM2086",
  },
  {
    value: "FM2087-KG",
    label: "FM2087",
  },
  {
    value: "FM2088-KG",
    label: "FM2088",
  },
  {
    value: "FM2089-KG",
    label: "FM2089",
  },
  {
    value: "FM2090-KG",
    label: "FM2090",
  },
  {
    value: "FM2091-KG",
    label: "FM2091",
  },
  {
    value: "FM2092-KG",
    label: "FM2092",
  },
  {
    value: "FM2093-KG",
    label: "FM2093",
  },
  {
    value: "FM2094-KG",
    label: "FM2094",
  },
  {
    value: "FM2095-KG",
    label: "FM2095",
  },
  {
    value: "FM2096-KG",
    label: "FM2096",
  },
  {
    value: "FM2097-KG",
    label: "FM2097",
  },
  {
    value: "FM2098-KG",
    label: "FM2098",
  },
  {
    value: "FM2099-KG",
    label: "FM2099",
  },
  {
    value: "FM2100-KG",
    label: "FM2100",
  },
  {
    value: "FM2101-KG",
    label: "FM2101",
  },
  {
    value: "FM2102-KG",
    label: "FM2102",
  },
  {
    value: "FM2103-KG",
    label: "FM2103",
  },
  {
    value: "FM2104-KG",
    label: "FM2104",
  },
  {
    value: "FM2105-KG",
    label: "FM2105",
  },
  {
    value: "FM2106-KG",
    label: "FM2106",
  },
  {
    value: "FM2107-KG",
    label: "FM2107",
  },
  {
    value: "FM2108-KG",
    label: "FM2108",
  },
  {
    value: "FM2109-KG",
    label: "FM2109",
  },
  {
    value: "FM2110-KG",
    label: "FM2110",
  },
  {
    value: "FM2111-KG",
    label: "FM2111",
  },
  {
    value: "FM2112-KG",
    label: "FM2112",
  },
  {
    value: "FM2113-KG",
    label: "FM2113",
  },
  {
    value: "FM2114-KG",
    label: "FM2114",
  },
  {
    value: "FM2115-KG",
    label: "FM2115",
  },
  {
    value: "FM2116-KG",
    label: "FM2116",
  },
  {
    value: "FM2117-KG",
    label: "FM2117",
  },
  {
    value: "FM2118-KG",
    label: "FM2118",
  },
  {
    value: "FM2119-KG",
    label: "FM2119",
  },
  {
    value: "FM2120-KG",
    label: "FM2120",
  },
  {
    value: "FM2121-KG",
    label: "FM2121",
  },
  {
    value: "FM2122-KG",
    label: "FM2122",
  },
  {
    value: "FM2123-KG",
    label: "FM2123",
  },
  {
    value: "FM2124-KG",
    label: "FM2124",
  },
  {
    value: "FM2125-KG",
    label: "FM2125",
  },
  {
    value: "FM2126-KG",
    label: "FM2126",
  },
  {
    value: "FM2127-KG",
    label: "FM2127",
  },
  {
    value: "FM2128-KG",
    label: "FM2128",
  },
  {
    value: "FM2129-KG",
    label: "FM2129",
  },
  {
    value: "FM2130-KG",
    label: "FM2130",
  },
  {
    value: "FM2131-KG",
    label: "FM2131",
  },
  {
    value: "FM2132-KG",
    label: "FM2132",
  },
  {
    value: "FM2133-KG",
    label: "FM2133",
  },
  {
    value: "FM2134-KG",
    label: "FM2134",
  },
  {
    value: "FM2135-KG",
    label: "FM2135",
  },
  {
    value: "FM2136-KG",
    label: "FM2136",
  },
  {
    value: "FM2137-KG",
    label: "FM2137",
  },
  {
    value: "FM2138-KG",
    label: "FM2138",
  },
  {
    value: "FM2140-KG",
    label: "FM2140",
  },
  {
    value: "FM2141-KG",
    label: "FM2141",
  },
  {
    value: "FM2142-KG",
    label: "FM2142",
  },
  {
    value: "FM2143-KG",
    label: "FM2143",
  },
  {
    value: "FM2144-KG",
    label: "FM2144",
  },
  {
    value: "FM2145-KG",
    label: "FM2145",
  },
  {
    value: "FM2146-KG",
    label: "FM2146",
  },
  {
    value: "FM2148-KG",
    label: "FM2148",
  },
  {
    value: "FM2149-KG",
    label: "FM2149",
  },
  {
    value: "FM2150-KG",
    label: "FM2150",
  },
  {
    value: "FM2152-KG",
    label: "FM2152",
  },
  {
    value: "FM2153-KG",
    label: "FM2153",
  },
  {
    value: "FM2154-KG",
    label: "FM2154",
  },
  {
    value: "FM2155-KG",
    label: "FM2155",
  },
  {
    value: "FM2156-KG",
    label: "FM2156",
  },
  {
    value: "FM2158-KG",
    label: "FM2158",
  },
  {
    value: "FM2159-KG",
    label: "FM2159",
  },
  {
    value: "FM2160-KG",
    label: "FM2160",
  },
  {
    value: "FM2161-KG",
    label: "FM2161",
  },
  {
    value: "FM2162-KG",
    label: "FM2162",
  },
  {
    value: "FM2163-KG",
    label: "FM2163",
  },
  {
    value: "FM2164-KG",
    label: "FM2164",
  },
  {
    value: "FM2165-KG",
    label: "FM2165",
  },
  {
    value: "FM2168-KG",
    label: "FM2168",
  },
  {
    value: "FM2169-KG",
    label: "FM2169",
  },
  {
    value: "FM2170-KG",
    label: "FM2170",
  },
  {
    value: "FM2171-KG",
    label: "FM2171",
  },
  {
    value: "FM2172-KG",
    label: "FM2172",
  },
  {
    value: "FM2174-KG",
    label: "FM2174",
  },
  {
    value: "FM2175-KG",
    label: "FM2175",
  },
  {
    value: "FM2176-KG",
    label: "FM2176",
  },
  {
    value: "FM2177-KG",
    label: "FM2177",
  },
  {
    value: "FM2178-KG",
    label: "FM2178",
  },
  {
    value: "FM2179-KG",
    label: "FM2179",
  },
  {
    value: "FM2180-KG",
    label: "FM2180",
  },
  {
    value: "FM2181-KG",
    label: "FM2181",
  },
  {
    value: "FM2182-KG",
    label: "FM2182",
  },
  {
    value: "FM2183-KG",
    label: "FM2183",
  },
  {
    value: "FM2184-KG",
    label: "FM2184",
  },
  {
    value: "FM2185-KG",
    label: "FM2185",
  },
  {
    value: "FM2186-KG",
    label: "FM2186",
  },
  {
    value: "FM2187-KG",
    label: "FM2187",
  },
  {
    value: "FM2188-KG",
    label: "FM2188",
  },
  {
    value: "FM2189-KG",
    label: "FM2189",
  },
  {
    value: "FM2190-KG",
    label: "FM2190",
  },
  {
    value: "FM2191-KG",
    label: "FM2191",
  },
  {
    value: "FM2192-KG",
    label: "FM2192",
  },
  {
    value: "FM2193-KG",
    label: "FM2193",
  },
  {
    value: "FM2194-KG",
    label: "FM2194",
  },
  {
    value: "FM2195-KG",
    label: "FM2195",
  },
  {
    value: "FM2196-KG",
    label: "FM2196",
  },
  {
    value: "FM2197-KG",
    label: "FM2197",
  },
  {
    value: "FM2198-KG",
    label: "FM2198",
  },
  {
    value: "FM2199-KG",
    label: "FM2199",
  },
  {
    value: "FM2200-KG",
    label: "FM2200",
  },
  {
    value: "FM2201-KG",
    label: "FM2201",
  },
  {
    value: "FM2202-KG",
    label: "FM2202",
  },
  {
    value: "FM2203-KG",
    label: "FM2203",
  },
  {
    value: "FM2204-KG",
    label: "FM2204",
  },
  {
    value: "FM2205-KG",
    label: "FM2205",
  },
  {
    value: "FM2206-KG",
    label: "FM2206",
  },
  {
    value: "FM2207-KG",
    label: "FM2207",
  },
  {
    value: "FM2208-KG",
    label: "FM2208",
  },
  {
    value: "FM2209-KG",
    label: "FM2209",
  },
  {
    value: "FM2210-KG",
    label: "FM2210",
  },
  {
    value: "FM2211-KG",
    label: "FM2211",
  },
  {
    value: "FM2212-KG",
    label: "FM2212",
  },
  {
    value: "FM2213-KG",
    label: "FM2213",
  },
  {
    value: "FM2214-KG",
    label: "FM2214",
  },
  {
    value: "FM2215-KG",
    label: "FM2215",
  },
  {
    value: "FM2216-KG",
    label: "FM2216",
  },
  {
    value: "FM2217-KG",
    label: "FM2217",
  },
  {
    value: "FM2218-KG",
    label: "FM2218",
  },
  {
    value: "FM2219-KG",
    label: "FM2219",
  },
  {
    value: "FM2220-KG",
    label: "FM2220",
  },
  {
    value: "FM2221-KG",
    label: "FM2221",
  },
  {
    value: "FM2223-KG",
    label: "FM2223",
  },
  {
    value: "FM2224-KG",
    label: "FM2224",
  },
  {
    value: "FM2225-KG",
    label: "FM2225",
  },
  {
    value: "FM2226-KG",
    label: "FM2226",
  },
  {
    value: "FM2228-KG",
    label: "FM2228",
  },
  {
    value: "FM2229-KG",
    label: "FM2229",
  },
  {
    value: "FM2230-KG",
    label: "FM2230",
  },
  {
    value: "FM2231-KG",
    label: "FM2231",
  },
  {
    value: "FM2232-KG",
    label: "FM2232",
  },
  {
    value: "FM2234-KG",
    label: "FM2234",
  },
  {
    value: "FM2235-KG",
    label: "FM2235",
  },
  {
    value: "FM2236-KG",
    label: "FM2236",
  },
  {
    value: "FM2237-KG",
    label: "FM2237",
  },
  {
    value: "FM2238-KG",
    label: "FM2238",
  },
  {
    value: "FM2239-KG",
    label: "FM2239",
  },
  {
    value: "FM2240-KG",
    label: "FM2240",
  },
  {
    value: "FM2245-KG",
    label: "FM2245",
  },
  {
    value: "FM2246-KG",
    label: "FM2246",
  },
  {
    value: "FM2247-KG",
    label: "FM2247",
  },
  {
    value: "FM2249-KG",
    label: "FM2249",
  },
  {
    value: "FM2250-KG",
    label: "FM2250",
  },
  {
    value: "FM2251-KG",
    label: "FM2251",
  },
  {
    value: "FM2252-KG",
    label: "FM2252",
  },
  {
    value: "FM2253-KG",
    label: "FM2253",
  },
  {
    value: "FM2254-KG",
    label: "FM2254",
  },
  {
    value: "FM2255-KG",
    label: "FM2255",
  },
  {
    value: "FM2256-KG",
    label: "FM2256",
  },
  {
    value: "FM2257-KG",
    label: "FM2257",
  },
  {
    value: "FM2258-KG",
    label: "FM2258",
  },
  {
    value: "FM2259-KG",
    label: "FM2259",
  },
  {
    value: "FM2260-KG",
    label: "FM2260",
  },
  {
    value: "FM2261-KG",
    label: "FM2261",
  },
  {
    value: "FM2262-KG",
    label: "FM2262",
  },
  {
    value: "FM2263-KG",
    label: "FM2263",
  },
  {
    value: "FM2264-KG",
    label: "FM2264",
  },
  {
    value: "FM2265-KG",
    label: "FM2265",
  },
  {
    value: "FM2266-KG",
    label: "FM2266",
  },
  {
    value: "FM2267-KG",
    label: "FM2267",
  },
  {
    value: "FM2268-KG",
    label: "FM2268",
  },
  {
    value: "FM2269-KG",
    label: "FM2269",
  },
  {
    value: "FM2270-KG",
    label: "FM2270",
  },
  {
    value: "FM2271-KG",
    label: "FM2271",
  },
  {
    value: "FM2272-KG",
    label: "FM2272",
  },
  {
    value: "FM2273-KG",
    label: "FM2273",
  },
  {
    value: "FM2274-KG",
    label: "FM2274",
  },
  {
    value: "FM2275-KG",
    label: "FM2275",
  },
  {
    value: "FM2276-KG",
    label: "FM2276",
  },
  {
    value: "FM2278-KG",
    label: "FM2278",
  },
  {
    value: "FM2279-KG",
    label: "FM2279",
  },
  {
    value: "FM2280-KG",
    label: "FM2280",
  },
  {
    value: "FM2281-KG",
    label: "FM2281",
  },
  {
    value: "FM2282-KG",
    label: "FM2282",
  },
  {
    value: "FM2283-KG",
    label: "FM2283",
  },
  {
    value: "FM2284-KG",
    label: "FM2284",
  },
  {
    value: "FM2285-KG",
    label: "FM2285",
  },
  {
    value: "FM2286-KG",
    label: "FM2286",
  },
  {
    value: "FM2287-KG",
    label: "FM2287",
  },
  {
    value: "FM2288-KG",
    label: "FM2288",
  },
  {
    value: "FM2289-KG",
    label: "FM2289",
  },
  {
    value: "FM2290-KG",
    label: "FM2290",
  },
  {
    value: "FM2292-KG",
    label: "FM2292",
  },
  {
    value: "FM2293-KG",
    label: "FM2293",
  },
  {
    value: "FM2294-KG",
    label: "FM2294",
  },
  {
    value: "FM2295-KG",
    label: "FM2295",
  },
  {
    value: "FM2296-KG",
    label: "FM2296",
  },
  {
    value: "FM2297-KG",
    label: "FM2297",
  },
  {
    value: "FM2298-KG",
    label: "FM2298",
  },
  {
    value: "FM2299-KG",
    label: "FM2299",
  },
  {
    value: "FM2300-KG",
    label: "FM2300",
  },
  {
    value: "FM2301-KG",
    label: "FM2301",
  },
  {
    value: "FM2302-KG",
    label: "FM2302",
  },
  {
    value: "FM2303-KG",
    label: "FM2303",
  },
  {
    value: "FM2304-KG",
    label: "FM2304",
  },
  {
    value: "FM2305-KG",
    label: "FM2305",
  },
  {
    value: "FM2306-KG",
    label: "FM2306",
  },
  {
    value: "FM2307-KG",
    label: "FM2307",
  },
  {
    value: "FM2308-KG",
    label: "FM2308",
  },
  {
    value: "FM2309-KG",
    label: "FM2309",
  },
  {
    value: "FM2310-KG",
    label: "FM2310",
  },
  {
    value: "FM2311-KG",
    label: "FM2311",
  },
  {
    value: "FM2312-KG",
    label: "FM2312",
  },
  {
    value: "FM2313-KG",
    label: "FM2313",
  },
  {
    value: "FM2314-KG",
    label: "FM2314",
  },
  {
    value: "FM2315-KG",
    label: "FM2315",
  },
  {
    value: "FM2316-KG",
    label: "FM2316",
  },
  {
    value: "FM2318-KG",
    label: "FM2318",
  },
  {
    value: "FM2319-KG",
    label: "FM2319",
  },
  {
    value: "FM2320-KG",
    label: "FM2320",
  },
  {
    value: "FM2321-KG",
    label: "FM2321",
  },
  {
    value: "FM2324-KG",
    label: "FM2324",
  },
  {
    value: "FM2326-KG",
    label: "FM2326",
  },
  {
    value: "FM2327-KG",
    label: "FM2327",
  },
  {
    value: "FM2328-KG",
    label: "FM2328",
  },
  {
    value: "FM2330-KG",
    label: "FM2330",
  },
  {
    value: "FM2331-KG",
    label: "FM2331",
  },
  {
    value: "FM2332-KG",
    label: "FM2332",
  },
  {
    value: "FM2333-KG",
    label: "FM2333",
  },
  {
    value: "FM2334-KG",
    label: "FM2334",
  },
  {
    value: "FM2335-KG",
    label: "FM2335",
  },
  {
    value: "FM2336-KG",
    label: "FM2336",
  },
  {
    value: "FM2337-KG",
    label: "FM2337",
  },
  {
    value: "FM2339-KG",
    label: "FM2339",
  },
  {
    value: "FM2343-KG",
    label: "FM2343",
  },
  {
    value: "FM2344-KG",
    label: "FM2344",
  },
  {
    value: "FM2345-KG",
    label: "FM2345",
  },
  {
    value: "FM2346-KG",
    label: "FM2346",
  },
  {
    value: "FM2347-KG",
    label: "FM2347",
  },
  {
    value: "FM2348-KG",
    label: "FM2348",
  },
  {
    value: "FM2349-KG",
    label: "FM2349",
  },
  {
    value: "FM2350-KG",
    label: "FM2350",
  },
  {
    value: "FM2351-KG",
    label: "FM2351",
  },
  {
    value: "FM2352-KG",
    label: "FM2352",
  },
  {
    value: "FM2353-KG",
    label: "FM2353",
  },
  {
    value: "FM2354-KG",
    label: "FM2354",
  },
  {
    value: "FM2356-KG",
    label: "FM2356",
  },
  {
    value: "FM2357-KG",
    label: "FM2357",
  },
  {
    value: "FM2358-KG",
    label: "FM2358",
  },
  {
    value: "FM2359-KG",
    label: "FM2359",
  },
  {
    value: "FM2360-KG",
    label: "FM2360",
  },
  {
    value: "FM2361-KG",
    label: "FM2361",
  },
  {
    value: "FM2362-KG",
    label: "FM2362",
  },
  {
    value: "FM2363-KG",
    label: "FM2363",
  },
  {
    value: "FM2364-KG",
    label: "FM2364",
  },
  {
    value: "FM2365-KG",
    label: "FM2365",
  },
  {
    value: "FM2367-KG",
    label: "FM2367",
  },
  {
    value: "FM2368-KG",
    label: "FM2368",
  },
  {
    value: "FM2369-KG",
    label: "FM2369",
  },
  {
    value: "FM2370-KG",
    label: "FM2370",
  },
  {
    value: "FM2371-KG",
    label: "FM2371",
  },
  {
    value: "FM2372-KG",
    label: "FM2372",
  },
  {
    value: "FM2373-KG",
    label: "FM2373",
  },
  {
    value: "FM2374-KG",
    label: "FM2374",
  },
  {
    value: "FM2376-KG",
    label: "FM2376",
  },
  {
    value: "FM2377-KG",
    label: "FM2377",
  },
  {
    value: "FM2378-KG",
    label: "FM2378",
  },
  {
    value: "FM2379-KG",
    label: "FM2379",
  },
  {
    value: "FM2380-KG",
    label: "FM2380",
  },
  {
    value: "FM2382-KG",
    label: "FM2382",
  },
  {
    value: "FM2383-KG",
    label: "FM2383",
  },
  {
    value: "FM2384-KG",
    label: "FM2384",
  },
  {
    value: "FM2385-KG",
    label: "FM2385",
  },
  {
    value: "FM2386-KG",
    label: "FM2386",
  },
  {
    value: "FM2387-KG",
    label: "FM2387",
  },
  {
    value: "FM2388-KG",
    label: "FM2388",
  },
  {
    value: "FM2390-KG",
    label: "FM2390",
  },
  {
    value: "FM2391-KG",
    label: "FM2391",
  },
  {
    value: "FM2392-KG",
    label: "FM2392",
  },
  {
    value: "FM2393-KG",
    label: "FM2393",
  },
  {
    value: "FM2394-KG",
    label: "FM2394",
  },
  {
    value: "FM2395-KG",
    label: "FM2395",
  },
  {
    value: "FM2396-KG",
    label: "FM2396",
  },
  {
    value: "FM2397-KG",
    label: "FM2397",
  },
  {
    value: "FM2402-KG",
    label: "FM2402",
  },
  {
    value: "FM2403-KG",
    label: "FM2403",
  },
  {
    value: "FM2404-KG",
    label: "FM2404",
  },
  {
    value: "FM2405-KG",
    label: "FM2405",
  },
  {
    value: "FM2406-KG",
    label: "FM2406",
  },
  {
    value: "FM2407-KG",
    label: "FM2407",
  },
  {
    value: "FM2408-KG",
    label: "FM2408",
  },
  {
    value: "FM2409-KG",
    label: "FM2409",
  },
  {
    value: "FM2410-KG",
    label: "FM2410",
  },
  {
    value: "FM2411-KG",
    label: "FM2411",
  },
  {
    value: "FM2412-KG",
    label: "FM2412",
  },
  {
    value: "FM2413-KG",
    label: "FM2413",
  },
  {
    value: "FM2414-KG",
    label: "FM2414",
  },
  {
    value: "FM2415-KG",
    label: "FM2415",
  },
  {
    value: "FM2416-KG",
    label: "FM2416",
  },
  {
    value: "FM2417-KG",
    label: "FM2417",
  },
  {
    value: "FM2418-KG",
    label: "FM2418",
  },
  {
    value: "FM2419-KG",
    label: "FM2419",
  },
  {
    value: "FM2420-KG",
    label: "FM2420",
  },
  {
    value: "FM2421-KG",
    label: "FM2421",
  },
  {
    value: "FM2422-KG",
    label: "FM2422",
  },
  {
    value: "FM2423-KG",
    label: "FM2423",
  },
  {
    value: "FM2425-KG",
    label: "FM2425",
  },
  {
    value: "FM2426-KG",
    label: "FM2426",
  },
  {
    value: "FM2427-KG",
    label: "FM2427",
  },
  {
    value: "FM2428-KG",
    label: "FM2428",
  },
  {
    value: "FM2429-KG",
    label: "FM2429",
  },
  {
    value: "FM2430-KG",
    label: "FM2430",
  },
  {
    value: "FM2431-KG",
    label: "FM2431",
  },
  {
    value: "FM2432-KG",
    label: "FM2432",
  },
  {
    value: "FM2433-KG",
    label: "FM2433",
  },
  {
    value: "FM2434-KG",
    label: "FM2434",
  },
  {
    value: "FM2435-KG",
    label: "FM2435",
  },
  {
    value: "FM2436-KG",
    label: "FM2436",
  },
  {
    value: "FM2437-KG",
    label: "FM2437",
  },
  {
    value: "FM2438-KG",
    label: "FM2438",
  },
  {
    value: "FM2439-KG",
    label: "FM2439",
  },
  {
    value: "FM2440-KG",
    label: "FM2440",
  },
  {
    value: "FM2441-KG",
    label: "FM2441",
  },
  {
    value: "FM2442-KG",
    label: "FM2442",
  },
  {
    value: "FM2443-KG",
    label: "FM2443",
  },
  {
    value: "FM2444-KG",
    label: "FM2444",
  },
  {
    value: "FM2445-KG",
    label: "FM2445",
  },
  {
    value: "FM2446-KG",
    label: "FM2446",
  },
  {
    value: "FM2447-KG",
    label: "FM2447",
  },
  {
    value: "FM2448-KG",
    label: "FM2448",
  },
  {
    value: "FM2449-KG",
    label: "FM2449",
  },
  {
    value: "FM2450-KG",
    label: "FM2450",
  },
  {
    value: "FM2451-KG",
    label: "FM2451",
  },
  {
    value: "FM2452-KG",
    label: "FM2452",
  },
  {
    value: "FM2453-KG",
    label: "FM2453",
  },
  {
    value: "FM2454-KG",
    label: "FM2454",
  },
  {
    value: "FM2455-KG",
    label: "FM2455",
  },
  {
    value: "FM2456-KG",
    label: "FM2456",
  },
  {
    value: "FM2457-KG",
    label: "FM2457",
  },
  {
    value: "FM2458-KG",
    label: "FM2458",
  },
  {
    value: "FM2459-KG",
    label: "FM2459",
  },
  {
    value: "FM2460-KG",
    label: "FM2460",
  },
  {
    value: "FM2461-KG",
    label: "FM2461",
  },
  {
    value: "FM2462-KG",
    label: "FM2462",
  },
  {
    value: "FM2464-KG",
    label: "FM2464",
  },
  {
    value: "FM2465-KG",
    label: "FM2465",
  },
  {
    value: "FM2466-KG",
    label: "FM2466",
  },
  {
    value: "FM2467-KG",
    label: "FM2467",
  },
  {
    value: "FM2468-KG",
    label: "FM2468",
  },
  {
    value: "FM2470-KG",
    label: "FM2470",
  },
  {
    value: "FM2471-KG",
    label: "FM2471",
  },
  {
    value: "FM2472-KG",
    label: "FM2472",
  },
  {
    value: "FM2473-KG",
    label: "FM2473",
  },
  {
    value: "FM2474-KG",
    label: "FM2474",
  },
  {
    value: "FM2475-KG",
    label: "FM2475",
  },
  {
    value: "FM2476-KG",
    label: "FM2476",
  },
  {
    value: "FM2477-KG",
    label: "FM2477",
  },
  {
    value: "FM2478-KG",
    label: "FM2478",
  },
  {
    value: "FM2479-KG",
    label: "FM2479",
  },
  {
    value: "FM2480-KG",
    label: "FM2480",
  },
  {
    value: "FM2481-KG",
    label: "FM2481",
  },
  {
    value: "FM2482-KG",
    label: "FM2482",
  },
  {
    value: "FM2483-KG",
    label: "FM2483",
  },
  {
    value: "FM2484-KG",
    label: "FM2484",
  },
  {
    value: "FM2485-KG",
    label: "FM2485",
  },
  {
    value: "FM2486-KG",
    label: "FM2486",
  },
  {
    value: "FM2487-KG",
    label: "FM2487",
  },
  {
    value: "FM2488-KG",
    label: "FM2488",
  },
  {
    value: "FM2489-KG",
    label: "FM2489",
  },
  {
    value: "FM2490-KG",
    label: "FM2490",
  },
  {
    value: "FM2491-KG",
    label: "FM2491",
  },
  {
    value: "FM2492-KG",
    label: "FM2492",
  },
  {
    value: "FM2493-KG",
    label: "FM2493",
  },
  {
    value: "FM2494-KG",
    label: "FM2494",
  },
  {
    value: "FM2495-KG",
    label: "FM2495",
  },
  {
    value: "FM2496-KG",
    label: "FM2496",
  },
  {
    value: "FM2497-KG",
    label: "FM2497",
  },
  {
    value: "FM2498-KG",
    label: "FM2498",
  },
  {
    value: "FM2499-KG",
    label: "FM2499",
  },
  {
    value: "FM2500-KG",
    label: "FM2500",
  },
  {
    value: "FM2501-KG",
    label: "FM2501",
  },
  {
    value: "FM2502-KG",
    label: "FM2502",
  },
  {
    value: "FM2503-KG",
    label: "FM2503",
  },
  {
    value: "FM2504-KG",
    label: "FM2504",
  },
  {
    value: "FM2505-KG",
    label: "FM2505",
  },
  {
    value: "FM2506-KG",
    label: "FM2506",
  },
  {
    value: "FM2507-KG",
    label: "FM2507",
  },
  {
    value: "FM2508-KG",
    label: "FM2508",
  },
  {
    value: "FM2509-KG",
    label: "FM2509",
  },
  {
    value: "FM2510-KG",
    label: "FM2510",
  },
  {
    value: "FM2511-KG",
    label: "FM2511",
  },
  {
    value: "FM2512-KG",
    label: "FM2512",
  },
  {
    value: "FM2514-KG",
    label: "FM2514",
  },
  {
    value: "FM2515-KG",
    label: "FM2515",
  },
  {
    value: "FM2516-KG",
    label: "FM2516",
  },
  {
    value: "FM2517-KG",
    label: "FM2517",
  },
  {
    value: "FM2518-KG",
    label: "FM2518",
  },
  {
    value: "FM2519-KG",
    label: "FM2519",
  },
  {
    value: "FM2520-KG",
    label: "FM2520",
  },
  {
    value: "FM2521-KG",
    label: "FM2521",
  },
  {
    value: "FM2522-KG",
    label: "FM2522",
  },
  {
    value: "FM2524-KG",
    label: "FM2524",
  },
  {
    value: "FM2525-KG",
    label: "FM2525",
  },
  {
    value: "FM2526-KG",
    label: "FM2526",
  },
  {
    value: "FM2527-KG",
    label: "FM2527",
  },
  {
    value: "FM2528-KG",
    label: "FM2528",
  },
  {
    value: "FM2529-KG",
    label: "FM2529",
  },
  {
    value: "FM2530-KG",
    label: "FM2530",
  },
  {
    value: "FM2531-KG",
    label: "FM2531",
  },
  {
    value: "FM2532-KG",
    label: "FM2532",
  },
  {
    value: "FM2533-KG",
    label: "FM2533",
  },
  {
    value: "FM2534-KG",
    label: "FM2534",
  },
  {
    value: "FM2535-KG",
    label: "FM2535",
  },
  {
    value: "FM2536-KG",
    label: "FM2536",
  },
  {
    value: "FM2537-KG",
    label: "FM2537",
  },
  {
    value: "FM2538-KG",
    label: "FM2538",
  },
  {
    value: "FM2539-KG",
    label: "FM2539",
  },
  {
    value: "FM2540-KG",
    label: "FM2540",
  },
  {
    value: "FM2541-KG",
    label: "FM2541",
  },
  {
    value: "FM2542-KG",
    label: "FM2542",
  },
  {
    value: "FM2543-KG",
    label: "FM2543",
  },
  {
    value: "FM2544-KG",
    label: "FM2544",
  },
  {
    value: "FM2546-KG",
    label: "FM2546",
  },
  {
    value: "FM2547-KG",
    label: "FM2547",
  },
  {
    value: "FM2548-KG",
    label: "FM2548",
  },
  {
    value: "FM2549-KG",
    label: "FM2549",
  },
  {
    value: "FM2550-KG",
    label: "FM2550",
  },
  {
    value: "FM2551-KG",
    label: "FM2551",
  },
  {
    value: "FM2552-KG",
    label: "FM2552",
  },
  {
    value: "FM2553-KG",
    label: "FM2553",
  },
  {
    value: "FM2554-KG",
    label: "FM2554",
  },
  {
    value: "FM2555-KG",
    label: "FM2555",
  },
  {
    value: "FM2556-KG",
    label: "FM2556",
  },
  {
    value: "FM2557-KG",
    label: "FM2557",
  },
  {
    value: "FM2558-KG",
    label: "FM2558",
  },
  {
    value: "FM2559-KG",
    label: "FM2559",
  },
  {
    value: "FM2560-KG",
    label: "FM2560",
  },
  {
    value: "FM2561-KG",
    label: "FM2561",
  },
  {
    value: "FM2562-KG",
    label: "FM2562",
  },
  {
    value: "FM2563-KG",
    label: "FM2563",
  },
  {
    value: "FM2564-KG",
    label: "FM2564",
  },
  {
    value: "FM2565-KG",
    label: "FM2565",
  },
  {
    value: "FM2566-KG",
    label: "FM2566",
  },
  {
    value: "FM2567-KG",
    label: "FM2567",
  },
  {
    value: "FM2568-KG",
    label: "FM2568",
  },
  {
    value: "FM2569-KG",
    label: "FM2569",
  },
  {
    value: "FM2570-KG",
    label: "FM2570",
  },
  {
    value: "FM2571-KG",
    label: "FM2571",
  },
  {
    value: "FM2572-KG",
    label: "FM2572",
  },
  {
    value: "FM2573-KG",
    label: "FM2573",
  },
  {
    value: "FM2574-KG",
    label: "FM2574",
  },
  {
    value: "FM2575-KG",
    label: "FM2575",
  },
  {
    value: "FM2576-KG",
    label: "FM2576",
  },
  {
    value: "FM2577-KG",
    label: "FM2577",
  },
  {
    value: "FM2578-KG",
    label: "FM2578",
  },
  {
    value: "FM2579-KG",
    label: "FM2579",
  },
  {
    value: "FM2580-KG",
    label: "FM2580",
  },
  {
    value: "FM2581-KG",
    label: "FM2581",
  },
  {
    value: "FM2582-KG",
    label: "FM2582",
  },
  {
    value: "FM2583-KG",
    label: "FM2583",
  },
  {
    value: "FM2584-KG",
    label: "FM2584",
  },
  {
    value: "FM2585-KG",
    label: "FM2585",
  },
  {
    value: "FM2586-KG",
    label: "FM2586",
  },
  {
    value: "FM2587-KG",
    label: "FM2587",
  },
  {
    value: "FM2589-KG",
    label: "FM2589",
  },
  {
    value: "FM2590-KG",
    label: "FM2590",
  },
  {
    value: "FM2591-KG",
    label: "FM2591",
  },
  {
    value: "FM2592-KG",
    label: "FM2592",
  },
  {
    value: "FM2595-KG",
    label: "FM2595",
  },
  {
    value: "FM2598-KG",
    label: "FM2598",
  },
  {
    value: "FM2599-KG",
    label: "FM2599",
  },
  {
    value: "FM2601-KG",
    label: "FM2601",
  },
  {
    value: "FM2602-KG",
    label: "FM2602",
  },
  {
    value: "FM2603-KG",
    label: "FM2603",
  },
  {
    value: "FM2604-KG",
    label: "FM2604",
  },
  {
    value: "FM2605-KG",
    label: "FM2605",
  },
  {
    value: "FM2606-KG",
    label: "FM2606",
  },
  {
    value: "FM2607-KG",
    label: "FM2607",
  },
  {
    value: "FM2608-KG",
    label: "FM2608",
  },
  {
    value: "FM2609-KG",
    label: "FM2609",
  },
  {
    value: "FM2610-KG",
    label: "FM2610",
  },
  {
    value: "FM2611-KG",
    label: "FM2611",
  },
  {
    value: "FM2612-KG",
    label: "FM2612",
  },
  {
    value: "FM2613-KG",
    label: "FM2613",
  },
  {
    value: "FM2614-KG",
    label: "FM2614",
  },
  {
    value: "FM2615-KG",
    label: "FM2615",
  },
  {
    value: "FM2616-KG",
    label: "FM2616",
  },
  {
    value: "FM2617-KG",
    label: "FM2617",
  },
  {
    value: "FM2619-KG",
    label: "FM2619",
  },
  {
    value: "FM2620-KG",
    label: "FM2620",
  },
  {
    value: "FM2621-KG",
    label: "FM2621",
  },
  {
    value: "FM2622-KG",
    label: "FM2622",
  },
  {
    value: "FM2623-KG",
    label: "FM2623",
  },
  {
    value: "FM2624-KG",
    label: "FM2624",
  },
  {
    value: "FM2625-KG",
    label: "FM2625",
  },
  {
    value: "FM2626-KG",
    label: "FM2626",
  },
  {
    value: "FM2628-KG",
    label: "FM2628",
  },
  {
    value: "FM2629-KG",
    label: "FM2629",
  },
  {
    value: "FM2632-KG",
    label: "FM2632",
  },
  {
    value: "FM2633-KG",
    label: "FM2633",
  },
  {
    value: "FM2634-KG",
    label: "FM2634",
  },
  {
    value: "FM2635-KG",
    label: "FM2635",
  },
  {
    value: "FM2636-KG",
    label: "FM2636",
  },
  {
    value: "FM2637-KG",
    label: "FM2637",
  },
  {
    value: "FM2638-KG",
    label: "FM2638",
  },
  {
    value: "FM2639-KG",
    label: "FM2639",
  },
  {
    value: "FM2640-KG",
    label: "FM2640",
  },
  {
    value: "FM2641-KG",
    label: "FM2641",
  },
  {
    value: "FM2642-KG",
    label: "FM2642",
  },
  {
    value: "FM2643-KG",
    label: "FM2643",
  },
  {
    value: "FM2644-KG",
    label: "FM2644",
  },
  {
    value: "FM2645-KG",
    label: "FM2645",
  },
  {
    value: "FM2646-KG",
    label: "FM2646",
  },
  {
    value: "FM2647-KG",
    label: "FM2647",
  },
  {
    value: "FM2648-KG",
    label: "FM2648",
  },
  {
    value: "FM2649-KG",
    label: "FM2649",
  },
  {
    value: "FM2650-KG",
    label: "FM2650",
  },
  {
    value: "FM2651-KG",
    label: "FM2651",
  },
  {
    value: "FM2652-KG",
    label: "FM2652",
  },
  {
    value: "FM2653-KG",
    label: "FM2653",
  },
  {
    value: "FM2655-KG",
    label: "FM2655",
  },
  {
    value: "FM2656-KG",
    label: "FM2656",
  },
  {
    value: "FM2657-KG",
    label: "FM2657",
  },
  {
    value: "FM2658-KG",
    label: "FM2658",
  },
  {
    value: "FM2659-KG",
    label: "FM2659",
  },
  {
    value: "FM2660-KG",
    label: "FM2660",
  },
  {
    value: "FM2661-KG",
    label: "FM2661",
  },
  {
    value: "FM2662-KG",
    label: "FM2662",
  },
  {
    value: "FM2663-KG",
    label: "FM2663",
  },
  {
    value: "FM2664-KG",
    label: "FM2664",
  },
  {
    value: "FM2665-KG",
    label: "FM2665",
  },
  {
    value: "FM2666-KG",
    label: "FM2666",
  },
  {
    value: "FM2667-KG",
    label: "FM2667",
  },
  {
    value: "FM2668-KG",
    label: "FM2668",
  },
  {
    value: "FM2669-KG",
    label: "FM2669",
  },
  {
    value: "FM2670-KG",
    label: "FM2670",
  },
  {
    value: "FM2671-KG",
    label: "FM2671",
  },
  {
    value: "FM2672-KG",
    label: "FM2672",
  },
  {
    value: "FM2673-KG",
    label: "FM2673",
  },
  {
    value: "FM2674-KG",
    label: "FM2674",
  },
  {
    value: "FM2675-KG",
    label: "FM2675",
  },
  {
    value: "FM2676-KG",
    label: "FM2676",
  },
  {
    value: "FM2677-KG",
    label: "FM2677",
  },
  {
    value: "FM2678-KG",
    label: "FM2678",
  },
  {
    value: "FM2679-KG",
    label: "FM2679",
  },
  {
    value: "FM2680-KG",
    label: "FM2680",
  },
  {
    value: "FM2681-KG",
    label: "FM2681",
  },
  {
    value: "FM2682-KG",
    label: "FM2682",
  },
  {
    value: "FM2683-KG",
    label: "FM2683",
  },
  {
    value: "FM2684-KG",
    label: "FM2684",
  },
  {
    value: "FM2685-KG",
    label: "FM2685",
  },
  {
    value: "FM2686-KG",
    label: "FM2686",
  },
  {
    value: "FM2687-KG",
    label: "FM2687",
  },
  {
    value: "FM2688-KG",
    label: "FM2688",
  },
  {
    value: "FM2689-KG",
    label: "FM2689",
  },
  {
    value: "FM2691-KG",
    label: "FM2691",
  },
  {
    value: "FM2693-KG",
    label: "FM2693",
  },
  {
    value: "FM2694-KG",
    label: "FM2694",
  },
  {
    value: "FM2695-KG",
    label: "FM2695",
  },
  {
    value: "FM2696-KG",
    label: "FM2696",
  },
  {
    value: "FM2697-KG",
    label: "FM2697",
  },
  {
    value: "FM2698-KG",
    label: "FM2698",
  },
  {
    value: "FM2700-KG",
    label: "FM2700",
  },
  {
    value: "FM2701-KG",
    label: "FM2701",
  },
  {
    value: "FM2702-KG",
    label: "FM2702",
  },
  {
    value: "FM2703-KG",
    label: "FM2703",
  },
  {
    value: "FM2705-KG",
    label: "FM2705",
  },
  {
    value: "FM2706-KG",
    label: "FM2706",
  },
  {
    value: "FM2707-KG",
    label: "FM2707",
  },
  {
    value: "FM2708-KG",
    label: "FM2708",
  },
  {
    value: "FM2709-KG",
    label: "FM2709",
  },
  {
    value: "FM2710-KG",
    label: "FM2710",
  },
  {
    value: "FM2711-KG",
    label: "FM2711",
  },
  {
    value: "FM2712-KG",
    label: "FM2712",
  },
  {
    value: "FM2713-KG",
    label: "FM2713",
  },
  {
    value: "FM2714-KG",
    label: "FM2714",
  },
  {
    value: "FM2716-KG",
    label: "FM2716",
  },
  {
    value: "FM2717-KG",
    label: "FM2717",
  },
  {
    value: "FM2718-KG",
    label: "FM2718",
  },
  {
    value: "FM2719-KG",
    label: "FM2719",
  },
  {
    value: "FM2720-KG",
    label: "FM2720",
  },
  {
    value: "FM2723-KG",
    label: "FM2723",
  },
  {
    value: "FM2724-KG",
    label: "FM2724",
  },
  {
    value: "FM2725-KG",
    label: "FM2725",
  },
  {
    value: "FM2726-KG",
    label: "FM2726",
  },
  {
    value: "FM2727-KG",
    label: "FM2727",
  },
  {
    value: "FM2728-KG",
    label: "FM2728",
  },
  {
    value: "FM2729-KG",
    label: "FM2729",
  },
  {
    value: "FM2730-KG",
    label: "FM2730",
  },
  {
    value: "FM2731-KG",
    label: "FM2731",
  },
  {
    value: "FM2732-KG",
    label: "FM2732",
  },
  {
    value: "FM2733-KG",
    label: "FM2733",
  },
  {
    value: "FM2734-KG",
    label: "FM2734",
  },
  {
    value: "FM2735-KG",
    label: "FM2735",
  },
  {
    value: "FM2736-KG",
    label: "FM2736",
  },
  {
    value: "FM2737-KG",
    label: "FM2737",
  },
  {
    value: "FM2738-KG",
    label: "FM2738",
  },
  {
    value: "FM2739-KG",
    label: "FM2739",
  },
  {
    value: "FM2741-KG",
    label: "FM2741",
  },
  {
    value: "FM2742-KG",
    label: "FM2742",
  },
  {
    value: "FM2743-KG",
    label: "FM2743",
  },
  {
    value: "FM2744-KG",
    label: "FM2744",
  },
  {
    value: "FM2745-KG",
    label: "FM2745",
  },
  {
    value: "FM2746-KG",
    label: "FM2746",
  },
  {
    value: "FM2747-KG",
    label: "FM2747",
  },
  {
    value: "FM2749-KG",
    label: "FM2749",
  },
  {
    value: "FM2750-KG",
    label: "FM2750",
  },
  {
    value: "FM2751-KG",
    label: "FM2751",
  },
  {
    value: "FM2752-KG",
    label: "FM2752",
  },
  {
    value: "FM2753-KG",
    label: "FM2753",
  },
  {
    value: "FM2754-KG",
    label: "FM2754",
  },
  {
    value: "FM2755-KG",
    label: "FM2755",
  },
  {
    value: "FM2756-KG",
    label: "FM2756",
  },
  {
    value: "FM2757-KG",
    label: "FM2757",
  },
  {
    value: "FM2759-KG",
    label: "FM2759",
  },
  {
    value: "FM2760-KG",
    label: "FM2760",
  },
  {
    value: "FM2761-KG",
    label: "FM2761",
  },
  {
    value: "FM2762-KG",
    label: "FM2762",
  },
  {
    value: "FM2763-KG",
    label: "FM2763",
  },
  {
    value: "FM2764-KG",
    label: "FM2764",
  },
  {
    value: "FM2765-KG",
    label: "FM2765",
  },
  {
    value: "FM2767-KG",
    label: "FM2767",
  },
  {
    value: "FM2770-KG",
    label: "FM2770",
  },
  {
    value: "FM2771-KG",
    label: "FM2771",
  },
  {
    value: "FM2772-KG",
    label: "FM2772",
  },
  {
    value: "FM2773-KG",
    label: "FM2773",
  },
  {
    value: "FM2774-KG",
    label: "FM2774",
  },
  {
    value: "FM2776-KG",
    label: "FM2776",
  },
  {
    value: "FM2777-KG",
    label: "FM2777",
  },
  {
    value: "FM2779-KG",
    label: "FM2779",
  },
  {
    value: "FM2780-KG",
    label: "FM2780",
  },
  {
    value: "FM2781-KG",
    label: "FM2781",
  },
  {
    value: "FM2782-KG",
    label: "FM2782",
  },
  {
    value: "FM2783-KG",
    label: "FM2783",
  },
  {
    value: "FM2784-KG",
    label: "FM2784",
  },
  {
    value: "FM2785-KG",
    label: "FM2785",
  },
  {
    value: "FM2786-KG",
    label: "FM2786",
  },
  {
    value: "FM2787-KG",
    label: "FM2787",
  },
  {
    value: "FM2788-KG",
    label: "FM2788",
  },
  {
    value: "FM2789-KG",
    label: "FM2789",
  },
  {
    value: "FM2790-KG",
    label: "FM2790",
  },
  {
    value: "FM2791-KG",
    label: "FM2791",
  },
  {
    value: "FM2792-KG",
    label: "FM2792",
  },
  {
    value: "FM2793-KG",
    label: "FM2793",
  },
  {
    value: "FM2794-KG",
    label: "FM2794",
  },
  {
    value: "FM2795-KG",
    label: "FM2795",
  },
  {
    value: "FM2796-KG",
    label: "FM2796",
  },
  {
    value: "FM2797-KG",
    label: "FM2797",
  },
  {
    value: "FM2798-KG",
    label: "FM2798",
  },
  {
    value: "FM2799-KG",
    label: "FM2799",
  },
  {
    value: "FM2800-KG",
    label: "FM2800",
  },
  {
    value: "FM2801-KG",
    label: "FM2801",
  },
  {
    value: "FM2802-KG",
    label: "FM2802",
  },
  {
    value: "FM2803-KG",
    label: "FM2803",
  },
  {
    value: "FM2805-KG",
    label: "FM2805",
  },
  {
    value: "FM2806-KG",
    label: "FM2806",
  },
  {
    value: "FM2807-KG",
    label: "FM2807",
  },
  {
    value: "FM2808-KG",
    label: "FM2808",
  },
  {
    value: "FM2809-KG",
    label: "FM2809",
  },
  {
    value: "FM2811-KG",
    label: "FM2811",
  },
  {
    value: "FM2812-KG",
    label: "FM2812",
  },
  {
    value: "FM2813-KG",
    label: "FM2813",
  },
  {
    value: "FM2814-KG",
    label: "FM2814",
  },
  {
    value: "FM2815-KG",
    label: "FM2815",
  },
  {
    value: "FM2816-KG",
    label: "FM2816",
  },
  {
    value: "FM2817-KG",
    label: "FM2817",
  },
  {
    value: "FM2818-KG",
    label: "FM2818",
  },
  {
    value: "FM2819-KG",
    label: "FM2819",
  },
  {
    value: "FM2820-KG",
    label: "FM2820",
  },
  {
    value: "FM2821-KG",
    label: "FM2821",
  },
  {
    value: "FM2822-KG",
    label: "FM2822",
  },
  {
    value: "FM2823-KG",
    label: "FM2823",
  },
  {
    value: "FM2824-KG",
    label: "FM2824",
  },
  {
    value: "FM2825-KG",
    label: "FM2825",
  },
  {
    value: "FM2826-KG",
    label: "FM2826",
  },
  {
    value: "FM2827-KG",
    label: "FM2827",
  },
  {
    value: "FM2829-KG",
    label: "FM2829",
  },
  {
    value: "FM2830-KG",
    label: "FM2830",
  },
  {
    value: "FM2832-KG",
    label: "FM2832",
  },
  {
    value: "FM2833-KG",
    label: "FM2833",
  },
  {
    value: "FM2834-KG",
    label: "FM2834",
  },
  {
    value: "FM2835-KG",
    label: "FM2835",
  },
  {
    value: "FM2836-KG",
    label: "FM2836",
  },
  {
    value: "FM2837-KG",
    label: "FM2837",
  },
  {
    value: "FM2838-KG",
    label: "FM2838",
  },
  {
    value: "FM2839-KG",
    label: "FM2839",
  },
  {
    value: "FM2840-KG",
    label: "FM2840",
  },
  {
    value: "FM2841-KG",
    label: "FM2841",
  },
  {
    value: "FM2842-KG",
    label: "FM2842",
  },
  {
    value: "FM2843-KG",
    label: "FM2843",
  },
  {
    value: "FM2844-KG",
    label: "FM2844",
  },
  {
    value: "FM2845-KG",
    label: "FM2845",
  },
  {
    value: "FM2846-KG",
    label: "FM2846",
  },
  {
    value: "FM2847-KG",
    label: "FM2847",
  },
  {
    value: "FM2848-KG",
    label: "FM2848",
  },
  {
    value: "FM2849-KG",
    label: "FM2849",
  },
  {
    value: "FM2850-KG",
    label: "FM2850",
  },
  {
    value: "FM2851-KG",
    label: "FM2851",
  },
  {
    value: "FM2852-KG",
    label: "FM2852",
  },
  {
    value: "FM2853-KG",
    label: "FM2853",
  },
  {
    value: "FM2854-KG",
    label: "FM2854",
  },
  {
    value: "FM2855-KG",
    label: "FM2855",
  },
  {
    value: "FM2856-KG",
    label: "FM2856",
  },
  {
    value: "FM2858-KG",
    label: "FM2858",
  },
  {
    value: "FM2859-KG",
    label: "FM2859",
  },
  {
    value: "FM2860-KG",
    label: "FM2860",
  },
  {
    value: "FM2861-KG",
    label: "FM2861",
  },
  {
    value: "FM2862-KG",
    label: "FM2862",
  },
  {
    value: "FM2863-KG",
    label: "FM2863",
  },
  {
    value: "FM2864-KG",
    label: "FM2864",
  },
  {
    value: "FM2865-KG",
    label: "FM2865",
  },
  {
    value: "FM2866-KG",
    label: "FM2866",
  },
  {
    value: "FM2867-KG",
    label: "FM2867",
  },
  {
    value: "FM2868-KG",
    label: "FM2868",
  },
  {
    value: "FM2869-KG",
    label: "FM2869",
  },
  {
    value: "FM2870-KG",
    label: "FM2870",
  },
  {
    value: "FM2872-KG",
    label: "FM2872",
  },
  {
    value: "FM2873-KG",
    label: "FM2873",
  },
  {
    value: "FM2874-KG",
    label: "FM2874",
  },
  {
    value: "FM2875-KG",
    label: "FM2875",
  },
  {
    value: "FM2876-KG",
    label: "FM2876",
  },
  {
    value: "FM2877-KG",
    label: "FM2877",
  },
  {
    value: "FM2878-KG",
    label: "FM2878",
  },
  {
    value: "FM2879-KG",
    label: "FM2879",
  },
  {
    value: "FM2880-KG",
    label: "FM2880",
  },
  {
    value: "FM2881-KG",
    label: "FM2881",
  },
  {
    value: "FM2882-KG",
    label: "FM2882",
  },
  {
    value: "FM2883-KG",
    label: "FM2883",
  },
  {
    value: "FM2884-KG",
    label: "FM2884",
  },
  {
    value: "FM2885-KG",
    label: "FM2885",
  },
  {
    value: "FM2887-KG",
    label: "FM2887",
  },
  {
    value: "FM2888-KG",
    label: "FM2888",
  },
  {
    value: "FM2889-KG",
    label: "FM2889",
  },
  {
    value: "FM2890-KG",
    label: "FM2890",
  },
  {
    value: "FM2891-KG",
    label: "FM2891",
  },
  {
    value: "FM2892-KG",
    label: "FM2892",
  },
  {
    value: "FM2893-KG",
    label: "FM2893",
  },
  {
    value: "FM2895-KG",
    label: "FM2895",
  },
  {
    value: "FM2896-KG",
    label: "FM2896",
  },
  {
    value: "FM2897-KG",
    label: "FM2897",
  },
  {
    value: "FM2898-KG",
    label: "FM2898",
  },
  {
    value: "FM2899-KG",
    label: "FM2899",
  },
  {
    value: "FM2901-KG",
    label: "FM2901",
  },
  {
    value: "FM2902-KG",
    label: "FM2902",
  },
  {
    value: "FM2903-KG",
    label: "FM2903",
  },
  {
    value: "FM2904-KG",
    label: "FM2904",
  },
  {
    value: "FM2905-KG",
    label: "FM2905",
  },
  {
    value: "FM2906-KG",
    label: "FM2906",
  },
  {
    value: "FM2907-KG",
    label: "FM2907",
  },
  {
    value: "FM2908-KG",
    label: "FM2908",
  },
  {
    value: "FM2910-KG",
    label: "FM2910",
  },
  {
    value: "FM2911-KG",
    label: "FM2911",
  },
  {
    value: "FM2912-KG",
    label: "FM2912",
  },
  {
    value: "FM2913-KG",
    label: "FM2913",
  },
  {
    value: "FM2914-KG",
    label: "FM2914",
  },
  {
    value: "FM2915-KG",
    label: "FM2915",
  },
  {
    value: "FM2916-KG",
    label: "FM2916",
  },
  {
    value: "FM2917-KG",
    label: "FM2917",
  },
  {
    value: "FM2918-KG",
    label: "FM2918",
  },
  {
    value: "FM2919-KG",
    label: "FM2919",
  },
  {
    value: "FM2920-KG",
    label: "FM2920",
  },
  {
    value: "FM2921-KG",
    label: "FM2921",
  },
  {
    value: "FM2922-KG",
    label: "FM2922",
  },
  {
    value: "FM2923-KG",
    label: "FM2923",
  },
  {
    value: "FM2924-KG",
    label: "FM2924",
  },
  {
    value: "FM2925-KG",
    label: "FM2925",
  },
  {
    value: "FM2926-KG",
    label: "FM2926",
  },
  {
    value: "FM2928-KG",
    label: "FM2928",
  },
  {
    value: "FM2929-KG",
    label: "FM2929",
  },
  {
    value: "FM2930-KG",
    label: "FM2930",
  },
  {
    value: "FM2931-KG",
    label: "FM2931",
  },
  {
    value: "FM2932-KG",
    label: "FM2932",
  },
  {
    value: "FM2933-KG",
    label: "FM2933",
  },
  {
    value: "FM2934-KG",
    label: "FM2934",
  },
  {
    value: "FM2935-KG",
    label: "FM2935",
  },
  {
    value: "FM2936-KG",
    label: "FM2936",
  },
  {
    value: "FM2937-KG",
    label: "FM2937",
  },
  {
    value: "FM2938-KG",
    label: "FM2938",
  },
  {
    value: "FM2939-KG",
    label: "FM2939",
  },
  {
    value: "FM2940-KG",
    label: "FM2940",
  },
  {
    value: "FM2941-KG",
    label: "FM2941",
  },
  {
    value: "FM2942-KG",
    label: "FM2942",
  },
  {
    value: "FM2943-KG",
    label: "FM2943",
  },
  {
    value: "FM2944-KG",
    label: "FM2944",
  },
  {
    value: "FM2945-KG",
    label: "FM2945",
  },
  {
    value: "FM2946-KG",
    label: "FM2946",
  },
  {
    value: "FM2947-KG",
    label: "FM2947",
  },
  {
    value: "FM2948-KG",
    label: "FM2948",
  },
  {
    value: "FM2949-KG",
    label: "FM2949",
  },
  {
    value: "FM2950-KG",
    label: "FM2950",
  },
  {
    value: "FM2951-KG",
    label: "FM2951",
  },
  {
    value: "FM2952-KG",
    label: "FM2952",
  },
  {
    value: "FM2953-KG",
    label: "FM2953",
  },
  {
    value: "FM2954-KG",
    label: "FM2954",
  },
  {
    value: "FM2955-KG",
    label: "FM2955",
  },
  {
    value: "FM2956-KG",
    label: "FM2956",
  },
  {
    value: "FM2957-KG",
    label: "FM2957",
  },
  {
    value: "FM2958-KG",
    label: "FM2958",
  },
  {
    value: "FM2959-KG",
    label: "FM2959",
  },
  {
    value: "FM2960-KG",
    label: "FM2960",
  },
  {
    value: "FM2961-KG",
    label: "FM2961",
  },
  {
    value: "FM2962-KG",
    label: "FM2962",
  },
  {
    value: "FM2963-KG",
    label: "FM2963",
  },
  {
    value: "FM2964-KG",
    label: "FM2964",
  },
  {
    value: "FM2965-KG",
    label: "FM2965",
  },
  {
    value: "FM2966-KG",
    label: "FM2966",
  },
  {
    value: "FM2967-KG",
    label: "FM2967",
  },
  {
    value: "FM2968-KG",
    label: "FM2968",
  },
  {
    value: "FM2969-KG",
    label: "FM2969",
  },
  {
    value: "FM2971-KG",
    label: "FM2971",
  },
  {
    value: "FM2972-KG",
    label: "FM2972",
  },
  {
    value: "FM2973-KG",
    label: "FM2973",
  },
  {
    value: "FM2974-KG",
    label: "FM2974",
  },
  {
    value: "FM2975-KG",
    label: "FM2975",
  },
  {
    value: "FM2976-KG",
    label: "FM2976",
  },
  {
    value: "FM2977-KG",
    label: "FM2977",
  },
  {
    value: "FM2978-KG",
    label: "FM2978",
  },
  {
    value: "FM2979-KG",
    label: "FM2979",
  },
  {
    value: "FM2980-KG",
    label: "FM2980",
  },
  {
    value: "FM2981-KG",
    label: "FM2981",
  },
  {
    value: "FM2982-KG",
    label: "FM2982",
  },
  {
    value: "FM2983-KG",
    label: "FM2983",
  },
  {
    value: "FM2984-KG",
    label: "FM2984",
  },
  {
    value: "FM2985-KG",
    label: "FM2985",
  },
  {
    value: "FM2986-KG",
    label: "FM2986",
  },
  {
    value: "FM2987-KG",
    label: "FM2987",
  },
  {
    value: "FM2988-KG",
    label: "FM2988",
  },
  {
    value: "FM2989-KG",
    label: "FM2989",
  },
  {
    value: "FM2990-KG",
    label: "FM2990",
  },
  {
    value: "FM2991-KG",
    label: "FM2991",
  },
  {
    value: "FM2992-KG",
    label: "FM2992",
  },
  {
    value: "FM2993-KG",
    label: "FM2993",
  },
  {
    value: "FM2994-KG",
    label: "FM2994",
  },
  {
    value: "FM2996-KG",
    label: "FM2996",
  },
  {
    value: "FM2997-KG",
    label: "FM2997",
  },
  {
    value: "FM2998-KG",
    label: "FM2998",
  },
  {
    value: "FM2999-KG",
    label: "FM2999",
  },
  {
    value: "FM3000-KG",
    label: "FM3000",
  },
  {
    value: "FM3001-KG",
    label: "FM3001",
  },
  {
    value: "FM3002-KG",
    label: "FM3002",
  },
  {
    value: "FM3003-KG",
    label: "FM3003",
  },
  {
    value: "FM3004-KG",
    label: "FM3004",
  },
  {
    value: "FM3005-KG",
    label: "FM3005",
  },
  {
    value: "FM3006-KG",
    label: "FM3006",
  },
  {
    value: "FM3007-KG",
    label: "FM3007",
  },
  {
    value: "FM3009-KG",
    label: "FM3009",
  },
  {
    value: "FM3010-KG",
    label: "FM3010",
  },
  {
    value: "FM3011-KG",
    label: "FM3011",
  },
  {
    value: "FM3012-KG",
    label: "FM3012",
  },
  {
    value: "FM3013-KG",
    label: "FM3013",
  },
  {
    value: "FM3016-KG",
    label: "FM3016",
  },
  {
    value: "FM3017-KG",
    label: "FM3017",
  },
  {
    value: "FM3018-KG",
    label: "FM3018",
  },
  {
    value: "FM3019-KG",
    label: "FM3019",
  },
  {
    value: "FM3020-KG",
    label: "FM3020",
  },
  {
    value: "FM3021-KG",
    label: "FM3021",
  },
  {
    value: "FM3022-KG",
    label: "FM3022",
  },
  {
    value: "FM3023-KG",
    label: "FM3023",
  },
  {
    value: "FM3024-KG",
    label: "FM3024",
  },
  {
    value: "FM3025-KG",
    label: "FM3025",
  },
  {
    value: "FM3026-KG",
    label: "FM3026",
  },
  {
    value: "FM3027-KG",
    label: "FM3027",
  },
  {
    value: "FM3028-KG",
    label: "FM3028",
  },
  {
    value: "FM3029-KG",
    label: "FM3029",
  },
  {
    value: "FM3030-KG",
    label: "FM3030",
  },
  {
    value: "FM3031-KG",
    label: "FM3031",
  },
  {
    value: "FM3032-KG",
    label: "FM3032",
  },
  {
    value: "FM3033-KG",
    label: "FM3033",
  },
  {
    value: "FM3034-KG",
    label: "FM3034",
  },
  {
    value: "FM3035-KG",
    label: "FM3035",
  },
  {
    value: "FM3036-KG",
    label: "FM3036",
  },
  {
    value: "FM3037-KG",
    label: "FM3037",
  },
  {
    value: "FM3038-KG",
    label: "FM3038",
  },
  {
    value: "FM3039-KG",
    label: "FM3039",
  },
  {
    value: "FM3040-KG",
    label: "FM3040",
  },
  {
    value: "FM3041-KG",
    label: "FM3041",
  },
  {
    value: "FM3042-KG",
    label: "FM3042",
  },
  {
    value: "FM3043-KG",
    label: "FM3043",
  },
  {
    value: "FM3044-KG",
    label: "FM3044",
  },
  {
    value: "FM3045-KG",
    label: "FM3045",
  },
  {
    value: "FM3046-KG",
    label: "FM3046",
  },
  {
    value: "FM3047-KG",
    label: "FM3047",
  },
  {
    value: "FM3048-KG",
    label: "FM3048",
  },
  {
    value: "FM3049-KG",
    label: "FM3049",
  },
  {
    value: "FM3050-KG",
    label: "FM3050",
  },
  {
    value: "FM3051-KG",
    label: "FM3051",
  },
  {
    value: "FM3052-KG",
    label: "FM3052",
  },
  {
    value: "FM3053-KG",
    label: "FM3053",
  },
  {
    value: "FM3055-KG",
    label: "FM3055",
  },
  {
    value: "FM3056-KG",
    label: "FM3056",
  },
  {
    value: "FM3057-KG",
    label: "FM3057",
  },
  {
    value: "FM3058-KG",
    label: "FM3058",
  },
  {
    value: "FM3059-KG",
    label: "FM3059",
  },
  {
    value: "FM3060-KG",
    label: "FM3060",
  },
  {
    value: "FM3061-KG",
    label: "FM3061",
  },
  {
    value: "FM3062-KG",
    label: "FM3062",
  },
  {
    value: "FM3063-KG",
    label: "FM3063",
  },
  {
    value: "FM3064-KG",
    label: "FM3064",
  },
  {
    value: "FM3065-KG",
    label: "FM3065",
  },
  {
    value: "FM3066-KG",
    label: "FM3066",
  },
  {
    value: "FM3067-KG",
    label: "FM3067",
  },
  {
    value: "FM3068-KG",
    label: "FM3068",
  },
  {
    value: "FM3069-KG",
    label: "FM3069",
  },
  {
    value: "FM3071-KG",
    label: "FM3071",
  },
  {
    value: "FM3072-KG",
    label: "FM3072",
  },
  {
    value: "FM3073-KG",
    label: "FM3073",
  },
  {
    value: "FM3074-KG",
    label: "FM3074",
  },
  {
    value: "FM3075-KG",
    label: "FM3075",
  },
  {
    value: "FM3077-KG",
    label: "FM3077",
  },
  {
    value: "FM3078-KG",
    label: "FM3078",
  },
  {
    value: "FM3079-KG",
    label: "FM3079",
  },
  {
    value: "FM3080-KG",
    label: "FM3080",
  },
  {
    value: "FM3081-KG",
    label: "FM3081",
  },
  {
    value: "FM3082-KG",
    label: "FM3082",
  },
  {
    value: "FM3083-KG",
    label: "FM3083",
  },
  {
    value: "FM3084-KG",
    label: "FM3084",
  },
  {
    value: "FM3085-KG",
    label: "FM3085",
  },
  {
    value: "FM3086-KG",
    label: "FM3086",
  },
  {
    value: "FM3087-KG",
    label: "FM3087",
  },
  {
    value: "FM3088-KG",
    label: "FM3088",
  },
  {
    value: "FM3089-KG",
    label: "FM3089",
  },
  {
    value: "FM3090-KG",
    label: "FM3090",
  },
  {
    value: "FM3091-KG",
    label: "FM3091",
  },
  {
    value: "FM3092-KG",
    label: "FM3092",
  },
  {
    value: "FM3094-KG",
    label: "FM3094",
  },
  {
    value: "FM3095-KG",
    label: "FM3095",
  },
  {
    value: "FM3096-KG",
    label: "FM3096",
  },
  {
    value: "FM3097-KG",
    label: "FM3097",
  },
  {
    value: "FM3098-KG",
    label: "FM3098",
  },
  {
    value: "FM3099-KG",
    label: "FM3099",
  },
  {
    value: "FM3100-KG",
    label: "FM3100",
  },
  {
    value: "FM3101-KG",
    label: "FM3101",
  },
  {
    value: "FM3102-KG",
    label: "FM3102",
  },
  {
    value: "FM3103-KG",
    label: "FM3103",
  },
  {
    value: "FM3104-KG",
    label: "FM3104",
  },
  {
    value: "FM3105-KG",
    label: "FM3105",
  },
  {
    value: "FM3107-KG",
    label: "FM3107",
  },
  {
    value: "FM3108-KG",
    label: "FM3108",
  },
  {
    value: "FM3109-KG",
    label: "FM3109",
  },
  {
    value: "FM3110-KG",
    label: "FM3110",
  },
  {
    value: "FM3111-KG",
    label: "FM3111",
  },
  {
    value: "FM3112-KG",
    label: "FM3112",
  },
  {
    value: "FM3113-KG",
    label: "FM3113",
  },
  {
    value: "FM3115-KG",
    label: "FM3115",
  },
  {
    value: "FM3116-KG",
    label: "FM3116",
  },
  {
    value: "FM3117-KG",
    label: "FM3117",
  },
  {
    value: "FM3118-KG",
    label: "FM3118",
  },
  {
    value: "FM3119-KG",
    label: "FM3119",
  },
  {
    value: "FM3121-KG",
    label: "FM3121",
  },
  {
    value: "FM3122-KG",
    label: "FM3122",
  },
  {
    value: "FM3123-KG",
    label: "FM3123",
  },
  {
    value: "FM3124-KG",
    label: "FM3124",
  },
  {
    value: "FM3125-KG",
    label: "FM3125",
  },
  {
    value: "FM3126-KG",
    label: "FM3126",
  },
  {
    value: "FM3127-KG",
    label: "FM3127",
  },
  {
    value: "FM3128-KG",
    label: "FM3128",
  },
  {
    value: "FM3129-KG",
    label: "FM3129",
  },
  {
    value: "FM3131-KG",
    label: "FM3131",
  },
  {
    value: "FM3132-KG",
    label: "FM3132",
  },
  {
    value: "FM3133-KG",
    label: "FM3133",
  },
  {
    value: "FM3134-KG",
    label: "FM3134",
  },
  {
    value: "FM3135-KG",
    label: "FM3135",
  },
  {
    value: "FM3136-KG",
    label: "FM3136",
  },
  {
    value: "FM3138-KG",
    label: "FM3138",
  },
  {
    value: "FM3139-KG",
    label: "FM3139",
  },
  {
    value: "FM3140-KG",
    label: "FM3140",
  },
  {
    value: "FM3141-KG",
    label: "FM3141",
  },
  {
    value: "FM3142-KG",
    label: "FM3142",
  },
  {
    value: "FM3143-KG",
    label: "FM3143",
  },
  {
    value: "FM3144-KG",
    label: "FM3144",
  },
  {
    value: "FM3145-KG",
    label: "FM3145",
  },
  {
    value: "FM3147-KG",
    label: "FM3147",
  },
  {
    value: "FM3148-KG",
    label: "FM3148",
  },
  {
    value: "FM3149-KG",
    label: "FM3149",
  },
  {
    value: "FM3150-KG",
    label: "FM3150",
  },
  {
    value: "FM3151-KG",
    label: "FM3151",
  },
  {
    value: "FM3152-KG",
    label: "FM3152",
  },
  {
    value: "FM3153-KG",
    label: "FM3153",
  },
  {
    value: "FM3154-KG",
    label: "FM3154",
  },
  {
    value: "FM3155-KG",
    label: "FM3155",
  },
  {
    value: "FM3156-KG",
    label: "FM3156",
  },
  {
    value: "FM3157-KG",
    label: "FM3157",
  },
  {
    value: "FM3158-KG",
    label: "FM3158",
  },
  {
    value: "FM3159-KG",
    label: "FM3159",
  },
  {
    value: "FM3161-KG",
    label: "FM3161",
  },
  {
    value: "FM3162-KG",
    label: "FM3162",
  },
  {
    value: "FM3163-KG",
    label: "FM3163",
  },
  {
    value: "FM3164-KG",
    label: "FM3164",
  },
  {
    value: "FM3165-KG",
    label: "FM3165",
  },
  {
    value: "FM3167-KG",
    label: "FM3167",
  },
  {
    value: "FM3168-KG",
    label: "FM3168",
  },
  {
    value: "FM3169-KG",
    label: "FM3169",
  },
  {
    value: "FM3170-KG",
    label: "FM3170",
  },
  {
    value: "FM3171-KG",
    label: "FM3171",
  },
  {
    value: "FM3172-KG",
    label: "FM3172",
  },
  {
    value: "FM3173-KG",
    label: "FM3173",
  },
  {
    value: "FM3174-KG",
    label: "FM3174",
  },
  {
    value: "FM3175-KG",
    label: "FM3175",
  },
  {
    value: "FM3176-KG",
    label: "FM3176",
  },
  {
    value: "FM3177-KG",
    label: "FM3177",
  },
  {
    value: "FM3178-KG",
    label: "FM3178",
  },
  {
    value: "FM3179-KG",
    label: "FM3179",
  },
  {
    value: "FM3180-KG",
    label: "FM3180",
  },
  {
    value: "FM3181-KG",
    label: "FM3181",
  },
  {
    value: "FM3182-KG",
    label: "FM3182",
  },
  {
    value: "FM3183-KG",
    label: "FM3183",
  },
  {
    value: "FM3184-KG",
    label: "FM3184",
  },
  {
    value: "FM3185-KG",
    label: "FM3185",
  },
  {
    value: "FM3186-KG",
    label: "FM3186",
  },
  {
    value: "FM3187-KG",
    label: "FM3187",
  },
  {
    value: "FM3188-KG",
    label: "FM3188",
  },
  {
    value: "FM3190-KG",
    label: "FM3190",
  },
  {
    value: "FM3191-KG",
    label: "FM3191",
  },
  {
    value: "FM3192-KG",
    label: "FM3192",
  },
  {
    value: "FM3194-KG",
    label: "FM3194",
  },
  {
    value: "FM3195-KG",
    label: "FM3195",
  },
  {
    value: "FM3196-KG",
    label: "FM3196",
  },
  {
    value: "FM3197-KG",
    label: "FM3197",
  },
  {
    value: "FM3198-KG",
    label: "FM3198",
  },
  {
    value: "FM3199-KG",
    label: "FM3199",
  },
  {
    value: "FM3200-KG",
    label: "FM3200",
  },
  {
    value: "FM3201-KG",
    label: "FM3201",
  },
  {
    value: "FM3202-KG",
    label: "FM3202",
  },
  {
    value: "FM3203-KG",
    label: "FM3203",
  },
  {
    value: "FM3204-KG",
    label: "FM3204",
  },
  {
    value: "FM3205-KG",
    label: "FM3205",
  },
  {
    value: "FM3206-KG",
    label: "FM3206",
  },
  {
    value: "FM3207-KG",
    label: "FM3207",
  },
  {
    value: "FM3208-KG",
    label: "FM3208",
  },
  {
    value: "FM3209-KG",
    label: "FM3209",
  },
  {
    value: "FM3210-KG",
    label: "FM3210",
  },
  {
    value: "FM3211-KG",
    label: "FM3211",
  },
  {
    value: "FM3212-KG",
    label: "FM3212",
  },
  {
    value: "FM3213-KG",
    label: "FM3213",
  },
  {
    value: "FM3214-KG",
    label: "FM3214",
  },
  {
    value: "FM3215-KG",
    label: "FM3215",
  },
  {
    value: "FM3216-KG",
    label: "FM3216",
  },
  {
    value: "FM3217-KG",
    label: "FM3217",
  },
  {
    value: "FM3218-KG",
    label: "FM3218",
  },
  {
    value: "FM3219-KG",
    label: "FM3219",
  },
  {
    value: "FM3220-KG",
    label: "FM3220",
  },
  {
    value: "FM3221-KG",
    label: "FM3221",
  },
  {
    value: "FM3222-KG",
    label: "FM3222",
  },
  {
    value: "FM3223-KG",
    label: "FM3223",
  },
  {
    value: "FM3224-KG",
    label: "FM3224",
  },
  {
    value: "FM3225-KG",
    label: "FM3225",
  },
  {
    value: "FM3226-KG",
    label: "FM3226",
  },
  {
    value: "FM3227-KG",
    label: "FM3227",
  },
  {
    value: "FM3228-KG",
    label: "FM3228",
  },
  {
    value: "FM3229-KG",
    label: "FM3229",
  },
  {
    value: "FM3230-KG",
    label: "FM3230",
  },
  {
    value: "FM3231-KG",
    label: "FM3231",
  },
  {
    value: "FM3233-KG",
    label: "FM3233",
  },
  {
    value: "FM3234-KG",
    label: "FM3234",
  },
  {
    value: "FM3236-KG",
    label: "FM3236",
  },
  {
    value: "FM3239-KG",
    label: "FM3239",
  },
  {
    value: "FM3240-KG",
    label: "FM3240",
  },
  {
    value: "FM3242-KG",
    label: "FM3242",
  },
  {
    value: "FM3243-KG",
    label: "FM3243",
  },
  {
    value: "FM3244-KG",
    label: "FM3244",
  },
  {
    value: "FM3245-KG",
    label: "FM3245",
  },
  {
    value: "FM3246-KG",
    label: "FM3246",
  },
  {
    value: "FM3247-KG",
    label: "FM3247",
  },
  {
    value: "FM3248-KG",
    label: "FM3248",
  },
  {
    value: "FM3249-KG",
    label: "FM3249",
  },
  {
    value: "FM3250-KG",
    label: "FM3250",
  },
  {
    value: "FM3251-KG",
    label: "FM3251",
  },
  {
    value: "FM3252-KG",
    label: "FM3252",
  },
  {
    value: "FM3253-KG",
    label: "FM3253",
  },
  {
    value: "FM3254-KG",
    label: "FM3254",
  },
  {
    value: "FM3255-KG",
    label: "FM3255",
  },
  {
    value: "FM3256-KG",
    label: "FM3256",
  },
  {
    value: "FM3257-KG",
    label: "FM3257",
  },
  {
    value: "FM3258-KG",
    label: "FM3258",
  },
  {
    value: "FM3259-KG",
    label: "FM3259",
  },
  {
    value: "FM3261-KG",
    label: "FM3261",
  },
  {
    value: "FM3262-KG",
    label: "FM3262",
  },
  {
    value: "FM3263-KG",
    label: "FM3263",
  },
  {
    value: "FM3264-KG",
    label: "FM3264",
  },
  {
    value: "FM3265-KG",
    label: "FM3265",
  },
  {
    value: "FM3266-KG",
    label: "FM3266",
  },
  {
    value: "FM3267-KG",
    label: "FM3267",
  },
  {
    value: "FM3268-KG",
    label: "FM3268",
  },
  {
    value: "FM3269-KG",
    label: "FM3269",
  },
  {
    value: "FM3270-KG",
    label: "FM3270",
  },
  {
    value: "FM3271-KG",
    label: "FM3271",
  },
  {
    value: "FM3272-KG",
    label: "FM3272",
  },
  {
    value: "FM3274-KG",
    label: "FM3274",
  },
  {
    value: "FM3275-KG",
    label: "FM3275",
  },
  {
    value: "FM3276-KG",
    label: "FM3276",
  },
  {
    value: "FM3277-KG",
    label: "FM3277",
  },
  {
    value: "FM3278-KG",
    label: "FM3278",
  },
  {
    value: "FM3279-KG",
    label: "FM3279",
  },
  {
    value: "FM3280-KG",
    label: "FM3280",
  },
  {
    value: "FM3281-KG",
    label: "FM3281",
  },
  {
    value: "FM3282-KG",
    label: "FM3282",
  },
  {
    value: "FM3283-KG",
    label: "FM3283",
  },
  {
    value: "FM3284-KG",
    label: "FM3284",
  },
  {
    value: "FM3285-KG",
    label: "FM3285",
  },
  {
    value: "FM3286-KG",
    label: "FM3286",
  },
  {
    value: "FM3287-KG",
    label: "FM3287",
  },
  {
    value: "FM3288-KG",
    label: "FM3288",
  },
  {
    value: "FM3290-KG",
    label: "FM3290",
  },
  {
    value: "FM3292-KG",
    label: "FM3292",
  },
  {
    value: "FM3293-KG",
    label: "FM3293",
  },
  {
    value: "FM3294-KG",
    label: "FM3294",
  },
  {
    value: "FM3295-KG",
    label: "FM3295",
  },
  {
    value: "FM3297-KG",
    label: "FM3297",
  },
  {
    value: "FM3298-KG",
    label: "FM3298",
  },
  {
    value: "FM3299-KG",
    label: "FM3299",
  },
  {
    value: "FM3300-KG",
    label: "FM3300",
  },
  {
    value: "FM3301-KG",
    label: "FM3301",
  },
  {
    value: "FM3302-KG",
    label: "FM3302",
  },
  {
    value: "FM3303-KG",
    label: "FM3303",
  },
  {
    value: "FM3304-KG",
    label: "FM3304",
  },
  {
    value: "FM3305-KG",
    label: "FM3305",
  },
  {
    value: "FM3306-KG",
    label: "FM3306",
  },
  {
    value: "FM3308-KG",
    label: "FM3308",
  },
  {
    value: "FM3309-KG",
    label: "FM3309",
  },
  {
    value: "FM3310-KG",
    label: "FM3310",
  },
  {
    value: "FM3311-KG",
    label: "FM3311",
  },
  {
    value: "FM3313-KG",
    label: "FM3313",
  },
  {
    value: "FM3314-KG",
    label: "FM3314",
  },
  {
    value: "FM3315-KG",
    label: "FM3315",
  },
  {
    value: "FM3316-KG",
    label: "FM3316",
  },
  {
    value: "FM3317-KG",
    label: "FM3317",
  },
  {
    value: "FM3318-KG",
    label: "FM3318",
  },
  {
    value: "FM3319-KG",
    label: "FM3319",
  },
  {
    value: "FM3320-KG",
    label: "FM3320",
  },
  {
    value: "FM3321-KG",
    label: "FM3321",
  },
  {
    value: "FM3322-KG",
    label: "FM3322",
  },
  {
    value: "FM3323-KG",
    label: "FM3323",
  },
  {
    value: "FM3324-KG",
    label: "FM3324",
  },
  {
    value: "FM3325-KG",
    label: "FM3325",
  },
  {
    value: "FM3326-KG",
    label: "FM3326",
  },
  {
    value: "FM3327-KG",
    label: "FM3327",
  },
  {
    value: "FM3328-KG",
    label: "FM3328",
  },
  {
    value: "FM3329-KG",
    label: "FM3329",
  },
  {
    value: "FM3330-KG",
    label: "FM3330",
  },
  {
    value: "FM3331-KG",
    label: "FM3331",
  },
  {
    value: "FM3332-KG",
    label: "FM3332",
  },
  {
    value: "FM3333-KG",
    label: "FM3333",
  },
  {
    value: "FM3334-KG",
    label: "FM3334",
  },
  {
    value: "FM3335-KG",
    label: "FM3335",
  },
  {
    value: "FM3336-KG",
    label: "FM3336",
  },
  {
    value: "FM3337-KG",
    label: "FM3337",
  },
  {
    value: "FM3338-KG",
    label: "FM3338",
  },
  {
    value: "FM3339-KG",
    label: "FM3339",
  },
  {
    value: "FM3340-KG",
    label: "FM3340",
  },
  {
    value: "FM3341-KG",
    label: "FM3341",
  },
  {
    value: "FM3342-KG",
    label: "FM3342",
  },
  {
    value: "FM3343-KG",
    label: "FM3343",
  },
  {
    value: "FM3344-KG",
    label: "FM3344",
  },
  {
    value: "FM3345-KG",
    label: "FM3345",
  },
  {
    value: "FM3346-KG",
    label: "FM3346",
  },
  {
    value: "FM3349-KG",
    label: "FM3349",
  },
  {
    value: "FM3350-KG",
    label: "FM3350",
  },
  {
    value: "FM3351-KG",
    label: "FM3351",
  },
  {
    value: "FM3352-KG",
    label: "FM3352",
  },
  {
    value: "FM3353-KG",
    label: "FM3353",
  },
  {
    value: "FM3354-KG",
    label: "FM3354",
  },
  {
    value: "FM3355-KG",
    label: "FM3355",
  },
  {
    value: "FM3356-KG",
    label: "FM3356",
  },
  {
    value: "FM3357-KG",
    label: "FM3357",
  },
  {
    value: "FM3358-KG",
    label: "FM3358",
  },
  {
    value: "FM3359-KG",
    label: "FM3359",
  },
  {
    value: "FM3360-KG",
    label: "FM3360",
  },
  {
    value: "FM3361-KG",
    label: "FM3361",
  },
  {
    value: "FM3362-KG",
    label: "FM3362",
  },
  {
    value: "FM3363-KG",
    label: "FM3363",
  },
  {
    value: "FM3364-KG",
    label: "FM3364",
  },
  {
    value: "FM3365-KG",
    label: "FM3365",
  },
  {
    value: "FM3366-KG",
    label: "FM3366",
  },
  {
    value: "FM3367-KG",
    label: "FM3367",
  },
  {
    value: "FM3368-KG",
    label: "FM3368",
  },
  {
    value: "FM3369-KG",
    label: "FM3369",
  },
  {
    value: "FM3370-KG",
    label: "FM3370",
  },
  {
    value: "FM3371-KG",
    label: "FM3371",
  },
  {
    value: "FM3372-KG",
    label: "FM3372",
  },
  {
    value: "FM3373-KG",
    label: "FM3373",
  },
  {
    value: "FM3375-KG",
    label: "FM3375",
  },
  {
    value: "FM3376-KG",
    label: "FM3376",
  },
  {
    value: "FM3377-KG",
    label: "FM3377",
  },
  {
    value: "FM3378-KG",
    label: "FM3378",
  },
  {
    value: "FM3379-KG",
    label: "FM3379",
  },
  {
    value: "FM3380-KG",
    label: "FM3380",
  },
  {
    value: "FM3381-KG",
    label: "FM3381",
  },
  {
    value: "FM3382-KG",
    label: "FM3382",
  },
  {
    value: "FM3383-KG",
    label: "FM3383",
  },
  {
    value: "FM3384-KG",
    label: "FM3384",
  },
  {
    value: "FM3385-KG",
    label: "FM3385",
  },
  {
    value: "FM3386-KG",
    label: "FM3386",
  },
  {
    value: "FM3387-KG",
    label: "FM3387",
  },
  {
    value: "FM3388-KG",
    label: "FM3388",
  },
  {
    value: "FM3389-KG",
    label: "FM3389",
  },
  {
    value: "FM3390-KG",
    label: "FM3390",
  },
  {
    value: "FM3391-KG",
    label: "FM3391",
  },
  {
    value: "FM3392-KG",
    label: "FM3392",
  },
  {
    value: "FM3393-KG",
    label: "FM3393",
  },
  {
    value: "FM3394-KG",
    label: "FM3394",
  },
  {
    value: "FM3395-KG",
    label: "FM3395",
  },
  {
    value: "FM3396-KG",
    label: "FM3396",
  },
  {
    value: "FM3397-KG",
    label: "FM3397",
  },
  {
    value: "FM3398-KG",
    label: "FM3398",
  },
  {
    value: "FM3399-KG",
    label: "FM3399",
  },
  {
    value: "FM3400-KG",
    label: "FM3400",
  },
  {
    value: "FM3401-KG",
    label: "FM3401",
  },
  {
    value: "FM3403-KG",
    label: "FM3403",
  },
  {
    value: "FM3405-KG",
    label: "FM3405",
  },
  {
    value: "FM3406-KG",
    label: "FM3406",
  },
  {
    value: "FM3408-KG",
    label: "FM3408",
  },
  {
    value: "FM3409-KG",
    label: "FM3409",
  },
  {
    value: "FM3410-KG",
    label: "FM3410",
  },
  {
    value: "FM3411-KG",
    label: "FM3411",
  },
  {
    value: "FM3413-KG",
    label: "FM3413",
  },
  {
    value: "FM3414-KG",
    label: "FM3414",
  },
  {
    value: "FM3415-KG",
    label: "FM3415",
  },
  {
    value: "FM3416-KG",
    label: "FM3416",
  },
  {
    value: "FM3417-KG",
    label: "FM3417",
  },
  {
    value: "FM3418-KG",
    label: "FM3418",
  },
  {
    value: "FM3419-KG",
    label: "FM3419",
  },
  {
    value: "FM3421-KG",
    label: "FM3421",
  },
  {
    value: "FM3422-KG",
    label: "FM3422",
  },
  {
    value: "FM3423-KG",
    label: "FM3423",
  },
  {
    value: "FM3424-KG",
    label: "FM3424",
  },
  {
    value: "FM3425-KG",
    label: "FM3425",
  },
  {
    value: "FM3426-KG",
    label: "FM3426",
  },
  {
    value: "FM3427-KG",
    label: "FM3427",
  },
  {
    value: "FM3428-KG",
    label: "FM3428",
  },
  {
    value: "FM3429-KG",
    label: "FM3429",
  },
  {
    value: "FM3430-KG",
    label: "FM3430",
  },
  {
    value: "FM3431-KG",
    label: "FM3431",
  },
  {
    value: "FM3432-KG",
    label: "FM3432",
  },
  {
    value: "FM3433-KG",
    label: "FM3433",
  },
  {
    value: "FM3434-KG",
    label: "FM3434",
  },
  {
    value: "FM3435-KG",
    label: "FM3435",
  },
  {
    value: "FM3436-KG",
    label: "FM3436",
  },
  {
    value: "FM3437-KG",
    label: "FM3437",
  },
  {
    value: "FM3438-KG",
    label: "FM3438",
  },
  {
    value: "FM3439-KG",
    label: "FM3439",
  },
  {
    value: "FM3440-KG",
    label: "FM3440",
  },
  {
    value: "FM3441-KG",
    label: "FM3441",
  },
  {
    value: "FM3442-KG",
    label: "FM3442",
  },
  {
    value: "FM3443-KG",
    label: "FM3443",
  },
  {
    value: "FM3445-KG",
    label: "FM3445",
  },
  {
    value: "FM3446-KG",
    label: "FM3446",
  },
  {
    value: "FM3447-KG",
    label: "FM3447",
  },
  {
    value: "FM3448-KG",
    label: "FM3448",
  },
  {
    value: "FM3449-KG",
    label: "FM3449",
  },
  {
    value: "FM3450-KG",
    label: "FM3450",
  },
  {
    value: "FM3451-KG",
    label: "FM3451",
  },
  {
    value: "FM3452-KG",
    label: "FM3452",
  },
  {
    value: "FM3453-KG",
    label: "FM3453",
  },
  {
    value: "FM3454-KG",
    label: "FM3454",
  },
  {
    value: "FM3455-KG",
    label: "FM3455",
  },
  {
    value: "FM3456-KG",
    label: "FM3456",
  },
  {
    value: "FM3457-KG",
    label: "FM3457",
  },
  {
    value: "FM3458-KG",
    label: "FM3458",
  },
  {
    value: "FM3459-KG",
    label: "FM3459",
  },
  {
    value: "FM3460-KG",
    label: "FM3460",
  },
  {
    value: "FM3461-KG",
    label: "FM3461",
  },
  {
    value: "FM3462-KG",
    label: "FM3462",
  },
  {
    value: "FM3463-KG",
    label: "FM3463",
  },
  {
    value: "FM3465-KG",
    label: "FM3465",
  },
  {
    value: "FM3466-KG",
    label: "FM3466",
  },
  {
    value: "FM3468-KG",
    label: "FM3468",
  },
  {
    value: "FM3469-KG",
    label: "FM3469",
  },
  {
    value: "FM3470-KG",
    label: "FM3470",
  },
  {
    value: "FM3471-KG",
    label: "FM3471",
  },
  {
    value: "FM3472-KG",
    label: "FM3472",
  },
  {
    value: "FM3475-KG",
    label: "FM3475",
  },
  {
    value: "FM3476-KG",
    label: "FM3476",
  },
  {
    value: "FM3478-KG",
    label: "FM3478",
  },
  {
    value: "FM3479-KG",
    label: "FM3479",
  },
  {
    value: "FM3480-KG",
    label: "FM3480",
  },
  {
    value: "FM3481-KG",
    label: "FM3481",
  },
  {
    value: "FM3482-KG",
    label: "FM3482",
  },
  {
    value: "FM3483-KG",
    label: "FM3483",
  },
  {
    value: "FM3484-KG",
    label: "FM3484",
  },
  {
    value: "FM3486-KG",
    label: "FM3486",
  },
  {
    value: "FM3489-KG",
    label: "FM3489",
  },
  {
    value: "FM3490-KG",
    label: "FM3490",
  },
  {
    value: "FM3491-KG",
    label: "FM3491",
  },
  {
    value: "FM3492-KG",
    label: "FM3492",
  },
  {
    value: "FM3495-KG",
    label: "FM3495",
  },
  {
    value: "FM3496-KG",
    label: "FM3496",
  },
  {
    value: "FM3497-KG",
    label: "FM3497",
  },
  {
    value: "FM3498-KG",
    label: "FM3498",
  },
  {
    value: "FM3499-KG",
    label: "FM3499",
  },
  {
    value: "FM3501-KG",
    label: "FM3501",
  },
  {
    value: "FM3502-KG",
    label: "FM3502",
  },
  {
    value: "FM3503-KG",
    label: "FM3503",
  },
  {
    value: "FM3505-KG",
    label: "FM3505",
  },
  {
    value: "FM3506-KG",
    label: "FM3506",
  },
  {
    value: "FM3509-KG",
    label: "FM3509",
  },
  {
    value: "FM3510-KG",
    label: "FM3510",
  },
  {
    value: "FM3512-KG",
    label: "FM3512",
  },
  {
    value: "FM3513-KG",
    label: "FM3513",
  },
  {
    value: "FM3514-KG",
    label: "FM3514",
  },
  {
    value: "FM3516-KG",
    label: "FM3516",
  },
  {
    value: "FM3517-KG",
    label: "FM3517",
  },
  {
    value: "FM3519-KG",
    label: "FM3519",
  },
  {
    value: "FM3520-KG",
    label: "FM3520",
  },
  {
    value: "FM3521-KG",
    label: "FM3521",
  },
  {
    value: "FM3522-KG",
    label: "FM3522",
  },
  {
    value: "FM3523-KG",
    label: "FM3523",
  },
  {
    value: "FM3524-KG",
    label: "FM3524",
  },
  {
    value: "FM3525-KG",
    label: "FM3525",
  },
  {
    value: "FM3527-KG",
    label: "FM3527",
  },
  {
    value: "FM3529-KG",
    label: "FM3529",
  },
  {
    value: "FM3530-KG",
    label: "FM3530",
  },
  {
    value: "FM3533-KG",
    label: "FM3533",
  },
  {
    value: "FM3535-KG",
    label: "FM3535",
  },
  {
    value: "FM3537-KG",
    label: "FM3537",
  },
  {
    value: "FM3538-KG",
    label: "FM3538",
  },
  {
    value: "FM3541-KG",
    label: "FM3541",
  },
  {
    value: "FM3549-KG",
    label: "FM3549",
  },
  {
    value: "FM4000-KG",
    label: "FM4000",
  },
  {
    value: "IH0002-KG",
    label: "IH0002",
  },
  {
    value: "IH0010-KG",
    label: "IH0010",
  },
  {
    value: "IH0014-KG",
    label: "IH0014",
  },
  {
    value: "IH0020-KG",
    label: "IH0020",
  },
  {
    value: "IH0027-KG",
    label: "IH0027",
  },
  {
    value: "IH0030-KG",
    label: "IH0030",
  },
  {
    value: "IH0035E-KG",
    label: "IH0035E",
  },
  {
    value: "IH0035-KG",
    label: "IH0035",
  },
  {
    value: "IH0035W-KG",
    label: "IH0035W",
  },
  {
    value: "IH0037-KG",
    label: "IH0037",
  },
  {
    value: "IH0040-KG",
    label: "IH0040",
  },
  {
    value: "IH0044-KG",
    label: "IH0044",
  },
  {
    value: "IH0045-KG",
    label: "IH0045",
  },
  {
    value: "IH0069C-KG",
    label: "IH0069C",
  },
  {
    value: "IH0069E-KG",
    label: "IH0069E",
  },
  {
    value: "IH0069-KG",
    label: "IH0069",
  },
  {
    value: "IH0069W-KG",
    label: "IH0069W",
  },
  {
    value: "IH0110-KG",
    label: "IH0110",
  },
  {
    value: "IH0169-KG",
    label: "IH0169",
  },
  {
    value: "IH0345-KG",
    label: "IH0345",
  },
  {
    value: "IH0369-KG",
    label: "IH0369",
  },
  {
    value: "IH0410-KG",
    label: "IH0410",
  },
  {
    value: "IH0610-KG",
    label: "IH0610",
  },
  {
    value: "IH0635-KG",
    label: "IH0635",
  },
  {
    value: "IH0820-KG",
    label: "IH0820",
  },
  {
    value: "RM0012-KG",
    label: "RM0012",
  },
  {
    value: "RM0032-KG",
    label: "RM0032",
  },
  {
    value: "RM0033-KG",
    label: "RM0033",
  },
  {
    value: "RM0150-KG",
    label: "RM0150",
  },
  {
    value: "RM0152-KG",
    label: "RM0152",
  },
  {
    value: "RM0165-KG",
    label: "RM0165",
  },
  {
    value: "RM0169-KG",
    label: "RM0169",
  },
  {
    value: "RM0187-KG",
    label: "RM0187",
  },
  {
    value: "RM0189-KG",
    label: "RM0189",
  },
  {
    value: "RM0243-KG",
    label: "RM0243",
  },
  {
    value: "RM0334-KG",
    label: "RM0334",
  },
  {
    value: "RM0335-KG",
    label: "RM0335",
  },
  {
    value: "RM0336-KG",
    label: "RM0336",
  },
  {
    value: "RM0337-KG",
    label: "RM0337",
  },
  {
    value: "RM0385-KG",
    label: "RM0385",
  },
  {
    value: "RM0386-KG",
    label: "RM0386",
  },
  {
    value: "RM0473-KG",
    label: "RM0473",
  },
  {
    value: "RM0474-KG",
    label: "RM0474",
  },
  {
    value: "RM0479-KG",
    label: "RM0479",
  },
  {
    value: "RM0480-KG",
    label: "RM0480",
  },
  {
    value: "RM0484-KG",
    label: "RM0484",
  },
  {
    value: "RM0501-KG",
    label: "RM0501",
  },
  {
    value: "RM0505-KG",
    label: "RM0505",
  },
  {
    value: "RM0584-KG",
    label: "RM0584",
  },
  {
    value: "RM0620-KG",
    label: "RM0620",
  },
  {
    value: "RM0628-KG",
    label: "RM0628",
  },
  {
    value: "RM0648-KG",
    label: "RM0648",
  },
  {
    value: "RM0652-KG",
    label: "RM0652",
  },
  {
    value: "RM0674-KG",
    label: "RM0674",
  },
  {
    value: "RM0687-KG",
    label: "RM0687",
  },
  {
    value: "RM0690-KG",
    label: "RM0690",
  },
  {
    value: "RM0693-KG",
    label: "RM0693",
  },
  {
    value: "RM0783-KG",
    label: "RM0783",
  },
  {
    value: "RM0853-KG",
    label: "RM0853",
  },
  {
    value: "RM0864-KG",
    label: "RM0864",
  },
  {
    value: "RM0915-KG",
    label: "RM0915",
  },
  {
    value: "RM0962-KG",
    label: "RM0962",
  },
  {
    value: "RM0963-KG",
    label: "RM0963",
  },
  {
    value: "RM0965-KG",
    label: "RM0965",
  },
  {
    value: "RM0967-KG",
    label: "RM0967",
  },
  {
    value: "RM1022-KG",
    label: "RM1022",
  },
  {
    value: "RM1024-KG",
    label: "RM1024",
  },
  {
    value: "RM1050-KG",
    label: "RM1050",
  },
  {
    value: "RM1061-KG",
    label: "RM1061",
  },
  {
    value: "RM1077-KG",
    label: "RM1077",
  },
  {
    value: "RM1108-KG",
    label: "RM1108",
  },
  {
    value: "RM1111-KG",
    label: "RM1111",
  },
  {
    value: "RM1165-KG",
    label: "RM1165",
  },
  {
    value: "RM1174-KG",
    label: "RM1174",
  },
  {
    value: "RM1221-KG",
    label: "RM1221",
  },
  {
    value: "RM1222-KG",
    label: "RM1222",
  },
  {
    value: "RM1282-KG",
    label: "RM1282",
  },
  {
    value: "RM1312-KG",
    label: "RM1312",
  },
  {
    value: "RM1319-KG",
    label: "RM1319",
  },
  {
    value: "RM1320-KG",
    label: "RM1320",
  },
  {
    value: "RM1321-KG",
    label: "RM1321",
  },
  {
    value: "RM1323-KG",
    label: "RM1323",
  },
  {
    value: "RM1357-KG",
    label: "RM1357",
  },
  {
    value: "RM1376-KG",
    label: "RM1376",
  },
  {
    value: "RM1431-KG",
    label: "RM1431",
  },
  {
    value: "RM1474-KG",
    label: "RM1474",
  },
  {
    value: "RM1492-KG",
    label: "RM1492",
  },
  {
    value: "RM1526-KG",
    label: "RM1526",
  },
  {
    value: "RM1555-KG",
    label: "RM1555",
  },
  {
    value: "RM1623-KG",
    label: "RM1623",
  },
  {
    value: "RM1631-KG",
    label: "RM1631",
  },
  {
    value: "RM1672-KG",
    label: "RM1672",
  },
  {
    value: "RM1674-KG",
    label: "RM1674",
  },
  {
    value: "RM1675-KG",
    label: "RM1675",
  },
  {
    value: "RM1676-KG",
    label: "RM1676",
  },
  {
    value: "RM1691-KG",
    label: "RM1691",
  },
  {
    value: "RM1723-KG",
    label: "RM1723",
  },
  {
    value: "RM1773-KG",
    label: "RM1773",
  },
  {
    value: "RM1826-KG",
    label: "RM1826",
  },
  {
    value: "RM1832-KG",
    label: "RM1832",
  },
  {
    value: "RM1837-KG",
    label: "RM1837",
  },
  {
    value: "RM1855-KG",
    label: "RM1855",
  },
  {
    value: "RM1865-KG",
    label: "RM1865",
  },
  {
    value: "RM1869-KG",
    label: "RM1869",
  },
  {
    value: "RM1871-KG",
    label: "RM1871",
  },
  {
    value: "RM1888-KG",
    label: "RM1888",
  },
  {
    value: "RM1900-KG",
    label: "RM1900",
  },
  {
    value: "RM1919-KG",
    label: "RM1919",
  },
  {
    value: "RM1964-KG",
    label: "RM1964",
  },
  {
    value: "RM1973-KG",
    label: "RM1973",
  },
  {
    value: "RM1989-KG",
    label: "RM1989",
  },
  {
    value: "RM2023-KG",
    label: "RM2023",
  },
  {
    value: "RM2034-KG",
    label: "RM2034",
  },
  {
    value: "RM2059-KG",
    label: "RM2059",
  },
  {
    value: "RM2083-KG",
    label: "RM2083",
  },
  {
    value: "RM2084-KG",
    label: "RM2084",
  },
  {
    value: "RM2139-KG",
    label: "RM2139",
  },
  {
    value: "RM2147-KG",
    label: "RM2147",
  },
  {
    value: "RM2166-KG",
    label: "RM2166",
  },
  {
    value: "RM2222-KG",
    label: "RM2222",
  },
  {
    value: "RM2233-KG",
    label: "RM2233",
  },
  {
    value: "RM2241-KG",
    label: "RM2241",
  },
  {
    value: "RM2242-KG",
    label: "RM2242",
  },
  {
    value: "RM2243-KG",
    label: "RM2243",
  },
  {
    value: "RM2244-KG",
    label: "RM2244",
  },
  {
    value: "RM2248-KG",
    label: "RM2248",
  },
  {
    value: "RM2277-KG",
    label: "RM2277",
  },
  {
    value: "RM2291-KG",
    label: "RM2291",
  },
  {
    value: "RM2317-KG",
    label: "RM2317",
  },
  {
    value: "RM2322-KG",
    label: "RM2322",
  },
  {
    value: "RM2323-KG",
    label: "RM2323",
  },
  {
    value: "RM2325-KG",
    label: "RM2325",
  },
  {
    value: "RM2329-KG",
    label: "RM2329",
  },
  {
    value: "RM2338-KG",
    label: "RM2338",
  },
  {
    value: "RM2340-KG",
    label: "RM2340",
  },
  {
    value: "RM2341-KG",
    label: "RM2341",
  },
  {
    value: "RM2342-KG",
    label: "RM2342",
  },
  {
    value: "RM2355-KG",
    label: "RM2355",
  },
  {
    value: "RM2375-KG",
    label: "RM2375",
  },
  {
    value: "RM2381-KG",
    label: "RM2381",
  },
  {
    value: "RM2389-KG",
    label: "RM2389",
  },
  {
    value: "RM2398-KG",
    label: "RM2398",
  },
  {
    value: "RM2400-KG",
    label: "RM2400",
  },
  {
    value: "RM2401-KG",
    label: "RM2401",
  },
  {
    value: "RM2424-KG",
    label: "RM2424",
  },
  {
    value: "RM2463-KG",
    label: "RM2463",
  },
  {
    value: "RM2469-KG",
    label: "RM2469",
  },
  {
    value: "RM2523-KG",
    label: "RM2523",
  },
  {
    value: "RM2545-KG",
    label: "RM2545",
  },
  {
    value: "RM2588-KG",
    label: "RM2588",
  },
  {
    value: "RM2593-KG",
    label: "RM2593",
  },
  {
    value: "RM2594-KG",
    label: "RM2594",
  },
  {
    value: "RM2596-KG",
    label: "RM2596",
  },
  {
    value: "RM2597-KG",
    label: "RM2597",
  },
  {
    value: "RM2600-KG",
    label: "RM2600",
  },
  {
    value: "RM2618-KG",
    label: "RM2618",
  },
  {
    value: "RM2627-KG",
    label: "RM2627",
  },
  {
    value: "RM2630-KG",
    label: "RM2630",
  },
  {
    value: "RM2631-KG",
    label: "RM2631",
  },
  {
    value: "RM2654-KG",
    label: "RM2654",
  },
  {
    value: "RM2690-KG",
    label: "RM2690",
  },
  {
    value: "RM2692-KG",
    label: "RM2692",
  },
  {
    value: "RM2699-KG",
    label: "RM2699",
  },
  {
    value: "RM2721-KG",
    label: "RM2721",
  },
  {
    value: "RM2722-KG",
    label: "RM2722",
  },
  {
    value: "RM2748-KG",
    label: "RM2748",
  },
  {
    value: "RM2758-KG",
    label: "RM2758",
  },
  {
    value: "RM2766-KG",
    label: "RM2766",
  },
  {
    value: "RM2768-KG",
    label: "RM2768",
  },
  {
    value: "RM2769-KG",
    label: "RM2769",
  },
  {
    value: "RM2775-KG",
    label: "RM2775",
  },
  {
    value: "RM2804-KG",
    label: "RM2804",
  },
  {
    value: "RM2810-KG",
    label: "RM2810",
  },
  {
    value: "RM2828-KG",
    label: "RM2828",
  },
  {
    value: "RM2831-KG",
    label: "RM2831",
  },
  {
    value: "RM2857-KG",
    label: "RM2857",
  },
  {
    value: "RM2871-KG",
    label: "RM2871",
  },
  {
    value: "RM2886-KG",
    label: "RM2886",
  },
  {
    value: "RM2900-KG",
    label: "RM2900",
  },
  {
    value: "RM2909-KG",
    label: "RM2909",
  },
  {
    value: "RM2970-KG",
    label: "RM2970",
  },
  {
    value: "RM2995-KG",
    label: "RM2995",
  },
  {
    value: "RM3008-KG",
    label: "RM3008",
  },
  {
    value: "RM3014-KG",
    label: "RM3014",
  },
  {
    value: "RM3054-KG",
    label: "RM3054",
  },
  {
    value: "RM3093-KG",
    label: "RM3093",
  },
  {
    value: "RM3130-KG",
    label: "RM3130",
  },
  {
    value: "RM3137-KG",
    label: "RM3137",
  },
  {
    value: "RM3166-KG",
    label: "RM3166",
  },
  {
    value: "RM3232-KG",
    label: "RM3232",
  },
  {
    value: "RM3235-KG",
    label: "RM3235",
  },
  {
    value: "RM3237-KG",
    label: "RM3237",
  },
  {
    value: "RM3238-KG",
    label: "RM3238",
  },
  {
    value: "RM3260-KG",
    label: "RM3260",
  },
  {
    value: "RM3273-KG",
    label: "RM3273",
  },
  {
    value: "RM3296-KG",
    label: "RM3296",
  },
  {
    value: "RM3307-KG",
    label: "RM3307",
  },
  {
    value: "RM3347-KG",
    label: "RM3347",
  },
  {
    value: "RM3348-KG",
    label: "RM3348",
  },
  {
    value: "RM3404-KG",
    label: "RM3404",
  },
  {
    value: "RM3474-KG",
    label: "RM3474",
  },
  {
    value: "SH0003-KG",
    label: "SH0003",
  },
  {
    value: "SH0004-KG",
    label: "SH0004",
  },
  {
    value: "SH0005-KG",
    label: "SH0005",
  },
  {
    value: "SH0006-KG",
    label: "SH0006",
  },
  {
    value: "SH0007-KG",
    label: "SH0007",
  },
  {
    value: "SH0008-KG",
    label: "SH0008",
  },
  {
    value: "SH0009-KG",
    label: "SH0009",
  },
  {
    value: "SH0010-KG",
    label: "SH0010",
  },
  {
    value: "SH0011-KG",
    label: "SH0011",
  },
  {
    value: "SH0012-KG",
    label: "SH0012",
  },
  {
    value: "SH0014-KG",
    label: "SH0014",
  },
  {
    value: "SH0015-KG",
    label: "SH0015",
  },
  {
    value: "SH0016-KG",
    label: "SH0016",
  },
  {
    value: "SH0017-KG",
    label: "SH0017",
  },
  {
    value: "SH0018-KG",
    label: "SH0018",
  },
  {
    value: "SH0019-KG",
    label: "SH0019",
  },
  {
    value: "SH0020-KG",
    label: "SH0020",
  },
  {
    value: "SH0021-KG",
    label: "SH0021",
  },
  {
    value: "SH0022-KG",
    label: "SH0022",
  },
  {
    value: "SH0023-KG",
    label: "SH0023",
  },
  {
    value: "SH0024-KG",
    label: "SH0024",
  },
  {
    value: "SH0025-KG",
    label: "SH0025",
  },
  {
    value: "SH0026-KG",
    label: "SH0026",
  },
  {
    value: "SH0027-KG",
    label: "SH0027",
  },
  {
    value: "SH0029-KG",
    label: "SH0029",
  },
  {
    value: "SH0030-KG",
    label: "SH0030",
  },
  {
    value: "SH0031-KG",
    label: "SH0031",
  },
  {
    value: "SH0033-KG",
    label: "SH0033",
  },
  {
    value: "SH0034-KG",
    label: "SH0034",
  },
  {
    value: "SH0035-KG",
    label: "SH0035",
  },
  {
    value: "SH0036-KG",
    label: "SH0036",
  },
  {
    value: "SH0037-KG",
    label: "SH0037",
  },
  {
    value: "SH0039-KG",
    label: "SH0039",
  },
  {
    value: "SH0040-KG",
    label: "SH0040",
  },
  {
    value: "SH0041-KG",
    label: "SH0041",
  },
  {
    value: "SH0042-KG",
    label: "SH0042",
  },
  {
    value: "SH0043-KG",
    label: "SH0043",
  },
  {
    value: "SH0044-KG",
    label: "SH0044",
  },
  {
    value: "SH0045-KG",
    label: "SH0045",
  },
  {
    value: "SH0046-KG",
    label: "SH0046",
  },
  {
    value: "SH0047-KG",
    label: "SH0047",
  },
  {
    value: "SH0048-KG",
    label: "SH0048",
  },
  {
    value: "SH0049-KG",
    label: "SH0049",
  },
  {
    value: "SH0050-KG",
    label: "SH0050",
  },
  {
    value: "SH0051-KG",
    label: "SH0051",
  },
  {
    value: "SH0053-KG",
    label: "SH0053",
  },
  {
    value: "SH0054-KG",
    label: "SH0054",
  },
  {
    value: "SH0055-KG",
    label: "SH0055",
  },
  {
    value: "SH0056-KG",
    label: "SH0056",
  },
  {
    value: "SH0057-KG",
    label: "SH0057",
  },
  {
    value: "SH0059-KG",
    label: "SH0059",
  },
  {
    value: "SH0060-KG",
    label: "SH0060",
  },
  {
    value: "SH0061-KG",
    label: "SH0061",
  },
  {
    value: "SH0062-KG",
    label: "SH0062",
  },
  {
    value: "SH0063-KG",
    label: "SH0063",
  },
  {
    value: "SH0064-KG",
    label: "SH0064",
  },
  {
    value: "SH0065-KG",
    label: "SH0065",
  },
  {
    value: "SH0066-KG",
    label: "SH0066",
  },
  {
    value: "SH0067-KG",
    label: "SH0067",
  },
  {
    value: "SH0070-KG",
    label: "SH0070",
  },
  {
    value: "SH0071-KG",
    label: "SH0071",
  },
  {
    value: "SH0072-KG",
    label: "SH0072",
  },
  {
    value: "SH0073-KG",
    label: "SH0073",
  },
  {
    value: "SH0075-KG",
    label: "SH0075",
  },
  {
    value: "SH0077-KG",
    label: "SH0077",
  },
  {
    value: "SH0078-KG",
    label: "SH0078",
  },
  {
    value: "SH0079-KG",
    label: "SH0079",
  },
  {
    value: "SH0080-KG",
    label: "SH0080",
  },
  {
    value: "SH0081-KG",
    label: "SH0081",
  },
  {
    value: "SH0082-KG",
    label: "SH0082",
  },
  {
    value: "SH0083-KG",
    label: "SH0083",
  },
  {
    value: "SH0085-KG",
    label: "SH0085",
  },
  {
    value: "SH0086-KG",
    label: "SH0086",
  },
  {
    value: "SH0087-KG",
    label: "SH0087",
  },
  {
    value: "SH0089-KG",
    label: "SH0089",
  },
  {
    value: "SH0090-KG",
    label: "SH0090",
  },
  {
    value: "SH0091-KG",
    label: "SH0091",
  },
  {
    value: "SH0092-KG",
    label: "SH0092",
  },
  {
    value: "SH0093-KG",
    label: "SH0093",
  },
  {
    value: "SH0094-KG",
    label: "SH0094",
  },
  {
    value: "SH0095-KG",
    label: "SH0095",
  },
  {
    value: "SH0096-KG",
    label: "SH0096",
  },
  {
    value: "SH0097-KG",
    label: "SH0097",
  },
  {
    value: "SH0098-KG",
    label: "SH0098",
  },
  {
    value: "SH0099-KG",
    label: "SH0099",
  },
  {
    value: "SH0100-KG",
    label: "SH0100",
  },
  {
    value: "SH0101-KG",
    label: "SH0101",
  },
  {
    value: "SH0102-KG",
    label: "SH0102",
  },
  {
    value: "SH0103-KG",
    label: "SH0103",
  },
  {
    value: "SH0105-KG",
    label: "SH0105",
  },
  {
    value: "SH0107-KG",
    label: "SH0107",
  },
  {
    value: "SH0108-KG",
    label: "SH0108",
  },
  {
    value: "SH0110-KG",
    label: "SH0110",
  },
  {
    value: "SH0111-KG",
    label: "SH0111",
  },
  {
    value: "SH0112-KG",
    label: "SH0112",
  },
  {
    value: "SH0114-KG",
    label: "SH0114",
  },
  {
    value: "SH0115-KG",
    label: "SH0115",
  },
  {
    value: "SH0118-KG",
    label: "SH0118",
  },
  {
    value: "SH0119-KG",
    label: "SH0119",
  },
  {
    value: "SH0121-KG",
    label: "SH0121",
  },
  {
    value: "SH0123-KG",
    label: "SH0123",
  },
  {
    value: "SH0124-KG",
    label: "SH0124",
  },
  {
    value: "SH0125-KG",
    label: "SH0125",
  },
  {
    value: "SH0127-KG",
    label: "SH0127",
  },
  {
    value: "SH0128-KG",
    label: "SH0128",
  },
  {
    value: "SH0130-KG",
    label: "SH0130",
  },
  {
    value: "SH0131-KG",
    label: "SH0131",
  },
  {
    value: "SH0132-KG",
    label: "SH0132",
  },
  {
    value: "SH0135-KG",
    label: "SH0135",
  },
  {
    value: "SH0136-KG",
    label: "SH0136",
  },
  {
    value: "SH0137-KG",
    label: "SH0137",
  },
  {
    value: "SH0138-KG",
    label: "SH0138",
  },
  {
    value: "SH0140-KG",
    label: "SH0140",
  },
  {
    value: "SH0141-KG",
    label: "SH0141",
  },
  {
    value: "SH0142-KG",
    label: "SH0142",
  },
  {
    value: "SH0144-KG",
    label: "SH0144",
  },
  {
    value: "SH0146-KG",
    label: "SH0146",
  },
  {
    value: "SH0147-KG",
    label: "SH0147",
  },
  {
    value: "SH0148-KG",
    label: "SH0148",
  },
  {
    value: "SH0149-KG",
    label: "SH0149",
  },
  {
    value: "SH0150-KG",
    label: "SH0150",
  },
  {
    value: "SH0151-KG",
    label: "SH0151",
  },
  {
    value: "SH0152-KG",
    label: "SH0152",
  },
  {
    value: "SH0153-KG",
    label: "SH0153",
  },
  {
    value: "SH0154-KG",
    label: "SH0154",
  },
  {
    value: "SH0155-KG",
    label: "SH0155",
  },
  {
    value: "SH0156-KG",
    label: "SH0156",
  },
  {
    value: "SH0158-KG",
    label: "SH0158",
  },
  {
    value: "SH0159-KG",
    label: "SH0159",
  },
  {
    value: "SH0160-KG",
    label: "SH0160",
  },
  {
    value: "SH0161-KG",
    label: "SH0161",
  },
  {
    value: "SH0163-KG",
    label: "SH0163",
  },
  {
    value: "SH0164-KG",
    label: "SH0164",
  },
  {
    value: "SH0165-KG",
    label: "SH0165",
  },
  {
    value: "SH0166-KG",
    label: "SH0166",
  },
  {
    value: "SH0167-KG",
    label: "SH0167",
  },
  {
    value: "SH0168-KG",
    label: "SH0168",
  },
  {
    value: "SH0170-KG",
    label: "SH0170",
  },
  {
    value: "SH0171-KG",
    label: "SH0171",
  },
  {
    value: "SH0172-KG",
    label: "SH0172",
  },
  {
    value: "SH0173-KG",
    label: "SH0173",
  },
  {
    value: "SH0174-KG",
    label: "SH0174",
  },
  {
    value: "SH0175-KG",
    label: "SH0175",
  },
  {
    value: "SH0176-KG",
    label: "SH0176",
  },
  {
    value: "SH0178-KG",
    label: "SH0178",
  },
  {
    value: "SH0179-KG",
    label: "SH0179",
  },
  {
    value: "SH0180-KG",
    label: "SH0180",
  },
  {
    value: "SH0182-KG",
    label: "SH0182",
  },
  {
    value: "SH0183-KG",
    label: "SH0183",
  },
  {
    value: "SH0184-KG",
    label: "SH0184",
  },
  {
    value: "SH0185-KG",
    label: "SH0185",
  },
  {
    value: "SH0186-KG",
    label: "SH0186",
  },
  {
    value: "SH0188-KG",
    label: "SH0188",
  },
  {
    value: "SH0191-KG",
    label: "SH0191",
  },
  {
    value: "SH0193-KG",
    label: "SH0193",
  },
  {
    value: "SH0194-KG",
    label: "SH0194",
  },
  {
    value: "SH0195-KG",
    label: "SH0195",
  },
  {
    value: "SH0198-KG",
    label: "SH0198",
  },
  {
    value: "SH0199-KG",
    label: "SH0199",
  },
  {
    value: "SH0201-KG",
    label: "SH0201",
  },
  {
    value: "SH0202-KG",
    label: "SH0202",
  },
  {
    value: "SH0203-KG",
    label: "SH0203",
  },
  {
    value: "SH0204-KG",
    label: "SH0204",
  },
  {
    value: "SH0205-KG",
    label: "SH0205",
  },
  {
    value: "SH0206-KG",
    label: "SH0206",
  },
  {
    value: "SH0207-KG",
    label: "SH0207",
  },
  {
    value: "SH0208-KG",
    label: "SH0208",
  },
  {
    value: "SH0211-KG",
    label: "SH0211",
  },
  {
    value: "SH0213-KG",
    label: "SH0213",
  },
  {
    value: "SH0214-KG",
    label: "SH0214",
  },
  {
    value: "SH0217-KG",
    label: "SH0217",
  },
  {
    value: "SH0218-KG",
    label: "SH0218",
  },
  {
    value: "SH0220-KG",
    label: "SH0220",
  },
  {
    value: "SH0222-KG",
    label: "SH0222",
  },
  {
    value: "SH0223-KG",
    label: "SH0223",
  },
  {
    value: "SH0224-KG",
    label: "SH0224",
  },
  {
    value: "SH0225-KG",
    label: "SH0225",
  },
  {
    value: "SH0234-KG",
    label: "SH0234",
  },
  {
    value: "SH0236-KG",
    label: "SH0236",
  },
  {
    value: "SH0237-KG",
    label: "SH0237",
  },
  {
    value: "SH0238-KG",
    label: "SH0238",
  },
  {
    value: "SH0239-KG",
    label: "SH0239",
  },
  {
    value: "SH0240-KG",
    label: "SH0240",
  },
  {
    value: "SH0242-KG",
    label: "SH0242",
  },
  {
    value: "SH0243-KG",
    label: "SH0243",
  },
  {
    value: "SH0249-KG",
    label: "SH0249",
  },
  {
    value: "SH0251-KG",
    label: "SH0251",
  },
  {
    value: "SH0254-KG",
    label: "SH0254",
  },
  {
    value: "SH0255-KG",
    label: "SH0255",
  },
  {
    value: "SH0256-KG",
    label: "SH0256",
  },
  {
    value: "SH0258-KG",
    label: "SH0258",
  },
  {
    value: "SH0261-KG",
    label: "SH0261",
  },
  {
    value: "SH0267-KG",
    label: "SH0267",
  },
  {
    value: "SH0273-KG",
    label: "SH0273",
  },
  {
    value: "SH0274-KG",
    label: "SH0274",
  },
  {
    value: "SH0275-KG",
    label: "SH0275",
  },
  {
    value: "SH0276-KG",
    label: "SH0276",
  },
  {
    value: "SH0279-KG",
    label: "SH0279",
  },
  {
    value: "SH0283-KG",
    label: "SH0283",
  },
  {
    value: "SH0285-KG",
    label: "SH0285",
  },
  {
    value: "SH0286-KG",
    label: "SH0286",
  },
  {
    value: "SH0288-KG",
    label: "SH0288",
  },
  {
    value: "SH0289-KG",
    label: "SH0289",
  },
  {
    value: "SH0290-KG",
    label: "SH0290",
  },
  {
    value: "SH0294-KG",
    label: "SH0294",
  },
  {
    value: "SH0300-KG",
    label: "SH0300",
  },
  {
    value: "SH0302-KG",
    label: "SH0302",
  },
  {
    value: "SH0304-KG",
    label: "SH0304",
  },
  {
    value: "SH0305-KG",
    label: "SH0305",
  },
  {
    value: "SH0308-KG",
    label: "SH0308",
  },
  {
    value: "SH0309-KG",
    label: "SH0309",
  },
  {
    value: "SH0310-KG",
    label: "SH0310",
  },
  {
    value: "SH0315-KG",
    label: "SH0315",
  },
  {
    value: "SH0316-KG",
    label: "SH0316",
  },
  {
    value: "SH0317-KG",
    label: "SH0317",
  },
  {
    value: "SH0320-KG",
    label: "SH0320",
  },
  {
    value: "SH0321-KG",
    label: "SH0321",
  },
  {
    value: "SH0322-KG",
    label: "SH0322",
  },
  {
    value: "SH0323-KG",
    label: "SH0323",
  },
  {
    value: "SH0326-KG",
    label: "SH0326",
  },
  {
    value: "SH0327-KG",
    label: "SH0327",
  },
  {
    value: "SH0329-KG",
    label: "SH0329",
  },
  {
    value: "SH0332-KG",
    label: "SH0332",
  },
  {
    value: "SH0334-KG",
    label: "SH0334",
  },
  {
    value: "SH0336-KG",
    label: "SH0336",
  },
  {
    value: "SH0337-KG",
    label: "SH0337",
  },
  {
    value: "SH0338-KG",
    label: "SH0338",
  },
  {
    value: "SH0339-KG",
    label: "SH0339",
  },
  {
    value: "SH0342-KG",
    label: "SH0342",
  },
  {
    value: "SH0345-KG",
    label: "SH0345",
  },
  {
    value: "SH0347-KG",
    label: "SH0347",
  },
  {
    value: "SH0349-KG",
    label: "SH0349",
  },
  {
    value: "SH0350-KG",
    label: "SH0350",
  },
  {
    value: "SH0351-KG",
    label: "SH0351",
  },
  {
    value: "SH0352-KG",
    label: "SH0352",
  },
  {
    value: "SH0354-KG",
    label: "SH0354",
  },
  {
    value: "SH0356-KG",
    label: "SH0356",
  },
  {
    value: "SH0357-KG",
    label: "SH0357",
  },
  {
    value: "SH0358-KG",
    label: "SH0358",
  },
  {
    value: "SH0359-KG",
    label: "SH0359",
  },
  {
    value: "SH0360-KG",
    label: "SH0360",
  },
  {
    value: "SH0361-KG",
    label: "SH0361",
  },
  {
    value: "SH0364-KG",
    label: "SH0364",
  },
  {
    value: "SH0495-KG",
    label: "SH0495",
  },
  {
    value: "SH0550-KG",
    label: "SH0550",
  },
  {
    value: "SHNASA-KG",
    label: "SHNASA",
  },
  {
    value: "SL0001-KG",
    label: "SL0001",
  },
  {
    value: "SL0002-KG",
    label: "SL0002",
  },
  {
    value: "SL0007-KG",
    label: "SL0007",
  },
  {
    value: "SL0011-KG",
    label: "SL0011",
  },
  {
    value: "SL0012-KG",
    label: "SL0012",
  },
  {
    value: "SL0013-KG",
    label: "SL0013",
  },
  {
    value: "SL0014-KG",
    label: "SL0014",
  },
  {
    value: "SL0015-KG",
    label: "SL0015",
  },
  {
    value: "SL0019-KG",
    label: "SL0019",
  },
  {
    value: "SL0020-KG",
    label: "SL0020",
  },
  {
    value: "SL0021-KG",
    label: "SL0021",
  },
  {
    value: "SL0025-KG",
    label: "SL0025",
  },
  {
    value: "SL0034-KG",
    label: "SL0034",
  },
  {
    value: "SL0036-KG",
    label: "SL0036",
  },
  {
    value: "SL0040-KG",
    label: "SL0040",
  },
  {
    value: "SL0042-KG",
    label: "SL0042",
  },
  {
    value: "SL0043-KG",
    label: "SL0043",
  },
  {
    value: "SL0046-KG",
    label: "SL0046",
  },
  {
    value: "SL0047-KG",
    label: "SL0047",
  },
  {
    value: "SL0048-KG",
    label: "SL0048",
  },
  {
    value: "SL0060-KG",
    label: "SL0060",
  },
  {
    value: "SL0062-KG",
    label: "SL0062",
  },
  {
    value: "SL0070-KG",
    label: "SL0070",
  },
  {
    value: "SL0071-KG",
    label: "SL0071",
  },
  {
    value: "SL0075-KG",
    label: "SL0075",
  },
  {
    value: "SL0076-KG",
    label: "SL0076",
  },
  {
    value: "SL0079-KG",
    label: "SL0079",
  },
  {
    value: "SL0082-KG",
    label: "SL0082",
  },
  {
    value: "SL0083-KG",
    label: "SL0083",
  },
  {
    value: "SL0084-KG",
    label: "SL0084",
  },
  {
    value: "SL0090-KG",
    label: "SL0090",
  },
  {
    value: "SL0096-KG",
    label: "SL0096",
  },
  {
    value: "SL0098-KG",
    label: "SL0098",
  },
  {
    value: "SL0105-KG",
    label: "SL0105",
  },
  {
    value: "SL0106-KG",
    label: "SL0106",
  },
  {
    value: "SL0107-KG",
    label: "SL0107",
  },
  {
    value: "SL0108-KG",
    label: "SL0108",
  },
  {
    value: "SL0109-KG",
    label: "SL0109",
  },
  {
    value: "SL0110-KG",
    label: "SL0110",
  },
  {
    value: "SL0111-KG",
    label: "SL0111",
  },
  {
    value: "SL0116-KG",
    label: "SL0116",
  },
  {
    value: "SL0118-KG",
    label: "SL0118",
  },
  {
    value: "SL0120-KG",
    label: "SL0120",
  },
  {
    value: "SL0121-KG",
    label: "SL0121",
  },
  {
    value: "SL0123-KG",
    label: "SL0123",
  },
  {
    value: "SL0124-KG",
    label: "SL0124",
  },
  {
    value: "SL0127-KG",
    label: "SL0127",
  },
  {
    value: "SL0128-KG",
    label: "SL0128",
  },
  {
    value: "SL0130-KG",
    label: "SL0130",
  },
  {
    value: "SL0132-KG",
    label: "SL0132",
  },
  {
    value: "SL0142-KG",
    label: "SL0142",
  },
  {
    value: "SL0143-KG",
    label: "SL0143",
  },
  {
    value: "SL0145-KG",
    label: "SL0145",
  },
  {
    value: "SL0149-KG",
    label: "SL0149",
  },
  {
    value: "SL0150-KG",
    label: "SL0150",
  },
  {
    value: "SL0151-KG",
    label: "SL0151",
  },
  {
    value: "SL0155-KG",
    label: "SL0155",
  },
  {
    value: "SL0157-KG",
    label: "SL0157",
  },
  {
    value: "SL0160-KG",
    label: "SL0160",
  },
  {
    value: "SL0163-KG",
    label: "SL0163",
  },
  {
    value: "SL0166-KG",
    label: "SL0166",
  },
  {
    value: "SL0168-KG",
    label: "SL0168",
  },
  {
    value: "SL0170-KG",
    label: "SL0170",
  },
  {
    value: "SL0171-KG",
    label: "SL0171",
  },
  {
    value: "SL0173-KG",
    label: "SL0173",
  },
  {
    value: "SL0177-KG",
    label: "SL0177",
  },
  {
    value: "SL0178-KG",
    label: "SL0178",
  },
  {
    value: "SL0179-KG",
    label: "SL0179",
  },
  {
    value: "SL0181-KG",
    label: "SL0181",
  },
  {
    value: "SL0182-KG",
    label: "SL0182",
  },
  {
    value: "SL0187-KG",
    label: "SL0187",
  },
  {
    value: "SL0193-KG",
    label: "SL0193",
  },
  {
    value: "SL0197-KG",
    label: "SL0197",
  },
  {
    value: "SL0204-KG",
    label: "SL0204",
  },
  {
    value: "SL0205-KG",
    label: "SL0205",
  },
  {
    value: "SL0207-KG",
    label: "SL0207",
  },
  {
    value: "SL0208-KG",
    label: "SL0208",
  },
  {
    value: "SL0210-KG",
    label: "SL0210",
  },
  {
    value: "SL0211-KG",
    label: "SL0211",
  },
  {
    value: "SL0212-KG",
    label: "SL0212",
  },
  {
    value: "SL0220-KG",
    label: "SL0220",
  },
  {
    value: "SL0222-KG",
    label: "SL0222",
  },
  {
    value: "SL0223-KG",
    label: "SL0223",
  },
  {
    value: "SL0224-KG",
    label: "SL0224",
  },
  {
    value: "SL0225-KG",
    label: "SL0225",
  },
  {
    value: "SL0227-KG",
    label: "SL0227",
  },
  {
    value: "SL0229-KG",
    label: "SL0229",
  },
  {
    value: "SL0230-KG",
    label: "SL0230",
  },
  {
    value: "SL0235-KG",
    label: "SL0235",
  },
  {
    value: "SL0236-KG",
    label: "SL0236",
  },
  {
    value: "SL0238-KG",
    label: "SL0238",
  },
  {
    value: "SL0250-KG",
    label: "SL0250",
  },
  {
    value: "SL0252-KG",
    label: "SL0252",
  },
  {
    value: "SL0254-KG",
    label: "SL0254",
  },
  {
    value: "SL0255-KG",
    label: "SL0255",
  },
  {
    value: "SL0256-KG",
    label: "SL0256",
  },
  {
    value: "SL0257-KG",
    label: "SL0257",
  },
  {
    value: "SL0262-KG",
    label: "SL0262",
  },
  {
    value: "SL0265-KG",
    label: "SL0265",
  },
  {
    value: "SL0266-KG",
    label: "SL0266",
  },
  {
    value: "SL0267-KG",
    label: "SL0267",
  },
  {
    value: "SL0268-KG",
    label: "SL0268",
  },
  {
    value: "SL0271-KG",
    label: "SL0271",
  },
  {
    value: "SL0274-KG",
    label: "SL0274",
  },
  {
    value: "SL0275-KG",
    label: "SL0275",
  },
  {
    value: "SL0279-KG",
    label: "SL0279",
  },
  {
    value: "SL0281-KG",
    label: "SL0281",
  },
  {
    value: "SL0282-KG",
    label: "SL0282",
  },
  {
    value: "SL0284-KG",
    label: "SL0284",
  },
  {
    value: "SL0285-KG",
    label: "SL0285",
  },
  {
    value: "SL0286-KG",
    label: "SL0286",
  },
  {
    value: "SL0287-KG",
    label: "SL0287",
  },
  {
    value: "SL0288-KG",
    label: "SL0288",
  },
  {
    value: "SL0289-KG",
    label: "SL0289",
  },
  {
    value: "SL0291-KG",
    label: "SL0291",
  },
  {
    value: "SL0292-KG",
    label: "SL0292",
  },
  {
    value: "SL0293-KG",
    label: "SL0293",
  },
  {
    value: "SL0301-KG",
    label: "SL0301",
  },
  {
    value: "SL0304-KG",
    label: "SL0304",
  },
  {
    value: "SL0305-KG",
    label: "SL0305",
  },
  {
    value: "SL0306-KG",
    label: "SL0306",
  },
  {
    value: "SL0308-KG",
    label: "SL0308",
  },
  {
    value: "SL0321-KG",
    label: "SL0321",
  },
  {
    value: "SL0322-KG",
    label: "SL0322",
  },
  {
    value: "SL0323-KG",
    label: "SL0323",
  },
  {
    value: "SL0328-KG",
    label: "SL0328",
  },
  {
    value: "SL0332-KG",
    label: "SL0332",
  },
  {
    value: "SL0335-KG",
    label: "SL0335",
  },
  {
    value: "SL0336-KG",
    label: "SL0336",
  },
  {
    value: "SL0337-KG",
    label: "SL0337",
  },
  {
    value: "SL0338-KG",
    label: "SL0338",
  },
  {
    value: "SL0340-KG",
    label: "SL0340",
  },
  {
    value: "SL0343-KG",
    label: "SL0343",
  },
  {
    value: "SL0344-KG",
    label: "SL0344",
  },
  {
    value: "SL0345-KG",
    label: "SL0345",
  },
  {
    value: "SL0350-KG",
    label: "SL0350",
  },
  {
    value: "SL0353-KG",
    label: "SL0353",
  },
  {
    value: "SL0354-KG",
    label: "SL0354",
  },
  {
    value: "SL0360-KG",
    label: "SL0360",
  },
  {
    value: "SL0361-KG",
    label: "SL0361",
  },
  {
    value: "SL0362-KG",
    label: "SL0362",
  },
  {
    value: "SL0363-KG",
    label: "SL0363",
  },
  {
    value: "SL0368-KG",
    label: "SL0368",
  },
  {
    value: "SL0369-KG",
    label: "SL0369",
  },
  {
    value: "SL0373-KG",
    label: "SL0373",
  },
  {
    value: "SL0375-KG",
    label: "SL0375",
  },
  {
    value: "SL0378-KG",
    label: "SL0378",
  },
  {
    value: "SL0385-KG",
    label: "SL0385",
  },
  {
    value: "SL0388-KG",
    label: "SL0388",
  },
  {
    value: "SL0389-KG",
    label: "SL0389",
  },
  {
    value: "SL0390-KG",
    label: "SL0390",
  },
  {
    value: "SL0391-KG",
    label: "SL0391",
  },
  {
    value: "SL0393-KG",
    label: "SL0393",
  },
  {
    value: "SL0395-KG",
    label: "SL0395",
  },
  {
    value: "SL0396-KG",
    label: "SL0396",
  },
  {
    value: "SL0403-KG",
    label: "SL0403",
  },
  {
    value: "SL0404-KG",
    label: "SL0404",
  },
  {
    value: "SL0424-KG",
    label: "SL0424",
  },
  {
    value: "SL0429-KG",
    label: "SL0429",
  },
  {
    value: "SL0430-KG",
    label: "SL0430",
  },
  {
    value: "SL0434-KG",
    label: "SL0434",
  },
  {
    value: "SL0438-KG",
    label: "SL0438",
  },
  {
    value: "SL0442-KG",
    label: "SL0442",
  },
  {
    value: "SL0444-KG",
    label: "SL0444",
  },
  {
    value: "SL0456-KG",
    label: "SL0456",
  },
  {
    value: "SL0461-KG",
    label: "SL0461",
  },
  {
    value: "SL0463-KG",
    label: "SL0463",
  },
  {
    value: "SL0464-KG",
    label: "SL0464",
  },
  {
    value: "SL0466-KG",
    label: "SL0466",
  },
  {
    value: "SL0467-KG",
    label: "SL0467",
  },
  {
    value: "SL0472-KG",
    label: "SL0472",
  },
  {
    value: "SL0473-KG",
    label: "SL0473",
  },
  {
    value: "SL0478-KG",
    label: "SL0478",
  },
  {
    value: "SL0480-KG",
    label: "SL0480",
  },
  {
    value: "SL0481-KG",
    label: "SL0481",
  },
  {
    value: "SL0484-KG",
    label: "SL0484",
  },
  {
    value: "SL0485-KG",
    label: "SL0485",
  },
  {
    value: "SL0488-KG",
    label: "SL0488",
  },
  {
    value: "SL0493-KG",
    label: "SL0493",
  },
  {
    value: "SL0494-KG",
    label: "SL0494",
  },
  {
    value: "SL0497-KG",
    label: "SL0497",
  },
  {
    value: "SL0499-KG",
    label: "SL0499",
  },
  {
    value: "SL0500-KG",
    label: "SL0500",
  },
  {
    value: "SL0505-KG",
    label: "SL0505",
  },
  {
    value: "SL0517-KG",
    label: "SL0517",
  },
  {
    value: "SL0521-KG",
    label: "SL0521",
  },
  {
    value: "SL0522-KG",
    label: "SL0522",
  },
  {
    value: "SL0523-KG",
    label: "SL0523",
  },
  {
    value: "SL0524-KG",
    label: "SL0524",
  },
  {
    value: "SL0526-KG",
    label: "SL0526",
  },
  {
    value: "SL0533-KG",
    label: "SL0533",
  },
  {
    value: "SL0534-KG",
    label: "SL0534",
  },
  {
    value: "SL0540-KG",
    label: "SL0540",
  },
  {
    value: "SL0541-KG",
    label: "SL0541",
  },
  {
    value: "SL0543-KG",
    label: "SL0543",
  },
  {
    value: "SL0547-KG",
    label: "SL0547",
  },
  {
    value: "SL0561-KG",
    label: "SL0561",
  },
  {
    value: "SL0564-KG",
    label: "SL0564",
  },
  {
    value: "SL0567-KG",
    label: "SL0567",
  },
  {
    value: "SL0570-KG",
    label: "SL0570",
  },
  {
    value: "SL0571-KG",
    label: "SL0571",
  },
  {
    value: "SL0573-KG",
    label: "SL0573",
  },
  {
    value: "SL0574-KG",
    label: "SL0574",
  },
  {
    value: "SL0577-KG",
    label: "SL0577",
  },
  {
    value: "SL1604-KG",
    label: "SL1604",
  },
  {
    value: "US0054-KG",
    label: "US0054",
  },
  {
    value: "US0057-KG",
    label: "US0057",
  },
  {
    value: "US0059-KG",
    label: "US0059",
  },
  {
    value: "US0060-KG",
    label: "US0060",
  },
  {
    value: "US0062-KG",
    label: "US0062",
  },
  {
    value: "US0067-KG",
    label: "US0067",
  },
  {
    value: "US0069-KG",
    label: "US0069",
  },
  {
    value: "US0070-KG",
    label: "US0070",
  },
  {
    value: "US0071-KG",
    label: "US0071",
  },
  {
    value: "US0075-KG",
    label: "US0075",
  },
  {
    value: "US0077-KG",
    label: "US0077",
  },
  {
    value: "US0079-KG",
    label: "US0079",
  },
  {
    value: "US0080-KG",
    label: "US0080",
  },
  {
    value: "US0081-KG",
    label: "US0081",
  },
  {
    value: "US0082-KG",
    label: "US0082",
  },
  {
    value: "US0083-KG",
    label: "US0083",
  },
  {
    value: "US0084-KG",
    label: "US0084",
  },
  {
    value: "US0085-KG",
    label: "US0085",
  },
  {
    value: "US0087-KG",
    label: "US0087",
  },
  {
    value: "US0090-KG",
    label: "US0090",
  },
  {
    value: "US0096-KG",
    label: "US0096",
  },
  {
    value: "US0175-KG",
    label: "US0175",
  },
  {
    value: "US0180-KG",
    label: "US0180",
  },
  {
    value: "US0181-KG",
    label: "US0181",
  },
  {
    value: "US0183-KG",
    label: "US0183",
  },
  {
    value: "US0190-KG",
    label: "US0190",
  },
  {
    value: "US0259-KG",
    label: "US0259",
  },
  {
    value: "US0271-KG",
    label: "US0271",
  },
  {
    value: "US0277-KG",
    label: "US0277",
  },
  {
    value: "US0281-KG",
    label: "US0281",
  },
  {
    value: "US0283-KG",
    label: "US0283",
  },
  {
    value: "US0285-KG",
    label: "US0285",
  },
  {
    value: "US0287-KG",
    label: "US0287",
  },
  {
    value: "US0290-KG",
    label: "US0290",
  },
  {
    value: "US0377-KG",
    label: "US0377",
  },
  {
    value: "US0380-KG",
    label: "US0380",
  },
  {
    value: "US0385-KG",
    label: "US0385",
  },
];
export const REGION_LIST = [
  {
    name: "texas-triangle",
    description: "Texas Triangle Region",
    statsLayer: "statewide",
    route: "/triangleregion=1",
  },
  {
    name: "border",
    description: "Border Region",
    statsLayer: "statewide",
    route: "/borderregion=1",
  },
  {
    name: "permian-basin",
    description: "Permian Basin Region",
    statsLayer: "statewide",
    route: "/permianbasin=1",
  },
  {
    name: "houston-area",
    description: "Houston Region",
    statsLayer: "statewide",
    route: "/houston=1",
  },
  {
    name: "dfw-area",
    description: "DFW Region",
    statsLayer: "statewide",
    route: "/dfw=1",
  },
  {
    name: "rio-grande-valley-area",
    description: "Rio Grande Valley Region",
    statsLayer: "statewide",
    route: "/riograndevalley=1",
  },
];
export const OTHER_GROUP = [
  {
    name: "interstates",
    description: "Interstate Corridors (including loops)",
    statsLayer: "statewide",
    route: "/interstatesloops=1",
  },
  {
    name: "port-to-plains-area",
    description: "Port-to-Plains Corridor",
    statsLayer: "statewide",
    route: "/portstoplains=1",
  },
];

export const FUNCTION_CLASS2014 = [
  { id: 1, label: "Interstate" },
  { id: 2, label: "Other Freeway and Expressway" },
  { id: 3, label: "Other Principal Arterial" },
  { id: 4, label: "Minor Arterial" },
  { id: 5, label: "Major Collector" },
  { id: 6, label: "Minor Collector" },
  { id: 7, label: "Local" },
];

export const FUNCTION_CLASS2009 = [
  { id: 1, label: "Interstate" },
  { id: 2, label: "Other Principal Arterial" },
  { id: 3, label: "Minor Arterial" },
  { id: 4, label: "Major Collector" },
  { id: 5, label: "Minor Collector" },
  { id: 7, label: "Other Freeway and Expressway" },
  { id: 9, label: "Collector" },
  { id: 10, label: "Local" },
];

export const RURAL_URBAN_CODE = [
  { id: 0, label: "Unknown" },
  { id: 1, label: "Rural (<5000)" },
  { id: 2, label: "Small Urban (5000-49,999)" },
  { id: 3, label: "Large Urban (50,000-199,999)" },
  { id: 4, label: "Urbanized (200,000+)" },
];

export const tx100_monitored_counties = [
  {
    cnty_nm: "Palo Pinto",
    cnty_nbr: 182,
  },
  {
    cnty_nm: "Freestone",
    cnty_nbr: 82,
  },
  {
    cnty_nm: "Bosque",
    cnty_nbr: 18,
  },
  {
    cnty_nm: "Galveston",
    cnty_nbr: 85,
  },
  {
    cnty_nm: "Taylor",
    cnty_nbr: 221,
  },
  {
    cnty_nm: "Wichita",
    cnty_nbr: 243,
  },
  {
    cnty_nm: "Hardin",
    cnty_nbr: 101,
  },
  {
    cnty_nm: "Travis",
    cnty_nbr: 227,
  },
  {
    cnty_nm: "Cherokee",
    cnty_nbr: 37,
  },
  {
    cnty_nm: "Victoria",
    cnty_nbr: 235,
  },
  {
    cnty_nm: "Brazoria",
    cnty_nbr: 20,
  },
  {
    cnty_nm: "Coryell",
    cnty_nbr: 50,
  },
  {
    cnty_nm: "Lampasas",
    cnty_nbr: 141,
  },
  {
    cnty_nm: "Williamson",
    cnty_nbr: 246,
  },
  {
    cnty_nm: "Bastrop",
    cnty_nbr: 11,
  },
  {
    cnty_nm: "San Jacinto",
    cnty_nbr: 204,
  },
  {
    cnty_nm: "McLennan",
    cnty_nbr: 161,
  },
  {
    cnty_nm: "Atascosa",
    cnty_nbr: 7,
  },
  {
    cnty_nm: "Callahan",
    cnty_nbr: 30,
  },
  {
    cnty_nm: "Tom Green",
    cnty_nbr: 226,
  },
  {
    cnty_nm: "Clay",
    cnty_nbr: 39,
  },
  {
    cnty_nm: "Orange",
    cnty_nbr: 181,
  },
  {
    cnty_nm: "Ellis",
    cnty_nbr: 71,
  },
  {
    cnty_nm: "Blanco",
    cnty_nbr: 16,
  },
  {
    cnty_nm: "Hunt",
    cnty_nbr: 117,
  },
  {
    cnty_nm: "Kendall",
    cnty_nbr: 131,
  },
  {
    cnty_nm: "Fort Bend",
    cnty_nbr: 80,
  },
  {
    cnty_nm: "Smith",
    cnty_nbr: 212,
  },
  {
    cnty_nm: "Austin",
    cnty_nbr: 8,
  },
  {
    cnty_nm: "Harrison",
    cnty_nbr: 103,
  },
  {
    cnty_nm: "Caldwell",
    cnty_nbr: 28,
  },
  {
    cnty_nm: "Liberty",
    cnty_nbr: 146,
  },
  {
    cnty_nm: "Parker",
    cnty_nbr: 184,
  },
  {
    cnty_nm: "Webb",
    cnty_nbr: 240,
  },
  {
    cnty_nm: "Colorado",
    cnty_nbr: 45,
  },
  {
    cnty_nm: "Midland",
    cnty_nbr: 165,
  },
  {
    cnty_nm: "Waller",
    cnty_nbr: 237,
  },
  {
    cnty_nm: "Kaufman",
    cnty_nbr: 130,
  },
  {
    cnty_nm: "El Paso",
    cnty_nbr: 72,
  },
  {
    cnty_nm: "Bexar",
    cnty_nbr: 15,
  },
  {
    cnty_nm: "Cameron",
    cnty_nbr: 31,
  },
  {
    cnty_nm: "Guadalupe",
    cnty_nbr: 95,
  },
  {
    cnty_nm: "Chambers",
    cnty_nbr: 36,
  },
  {
    cnty_nm: "Nueces",
    cnty_nbr: 178,
  },
  {
    cnty_nm: "Hays",
    cnty_nbr: 106,
  },
  {
    cnty_nm: "Comal",
    cnty_nbr: 46,
  },
  {
    cnty_nm: "Gregg",
    cnty_nbr: 93,
  },
  {
    cnty_nm: "Tarrant",
    cnty_nbr: 220,
  },
  {
    cnty_nm: "Jefferson",
    cnty_nbr: 124,
  },
  {
    cnty_nm: "Collin",
    cnty_nbr: 43,
  },
  {
    cnty_nm: "Lubbock",
    cnty_nbr: 152,
  },
  {
    cnty_nm: "Ector",
    cnty_nbr: 69,
  },
  {
    cnty_nm: "Navarro",
    cnty_nbr: 175,
  },
  {
    cnty_nm: "Hood",
    cnty_nbr: 112,
  },
  {
    cnty_nm: "Wise",
    cnty_nbr: 249,
  },
  {
    cnty_nm: "Jones",
    cnty_nbr: 128,
  },
  {
    cnty_nm: "Falls",
    cnty_nbr: 74,
  },
  {
    cnty_nm: "Rockwall",
    cnty_nbr: 199,
  },
  {
    cnty_nm: "Harris",
    cnty_nbr: 102,
  },
  {
    cnty_nm: "Dallas",
    cnty_nbr: 57,
  },
  {
    cnty_nm: "Polk",
    cnty_nbr: 187,
  },
  {
    cnty_nm: "Bell",
    cnty_nbr: 14,
  },
  {
    cnty_nm: "Burnet",
    cnty_nbr: 27,
  },
  {
    cnty_nm: "Van Zandt",
    cnty_nbr: 234,
  },
  {
    cnty_nm: "Montgomery",
    cnty_nbr: 170,
  },
  {
    cnty_nm: "Randall",
    cnty_nbr: 191,
  },
  {
    cnty_nm: "San Patricio",
    cnty_nbr: 205,
  },
  {
    cnty_nm: "Johnson",
    cnty_nbr: 127,
  },
  {
    cnty_nm: "Bowie",
    cnty_nbr: 19,
  },
  {
    cnty_nm: "Brazos",
    cnty_nbr: 21,
  },
  {
    cnty_nm: "Upshur",
    cnty_nbr: 230,
  },
  {
    cnty_nm: "Wharton",
    cnty_nbr: 241,
  },
  {
    cnty_nm: "Hidalgo",
    cnty_nbr: 109,
  },
  {
    cnty_nm: "Walker",
    cnty_nbr: 236,
  },
  {
    cnty_nm: "Grayson",
    cnty_nbr: 92,
  },
  {
    cnty_nm: "Denton",
    cnty_nbr: 61,
  },
  {
    cnty_nm: "Potter",
    cnty_nbr: 188,
  },
  {
    cnty_nm: "Cooke",
    cnty_nbr: 49,
  },
];

export const utpCategoryLookups = [
  {
    category: "category0",
    checkedVariable: "underwayorsoon",
    markerIcon:
      "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png",
    nameDesc: "UTP-Underway or begins soon",
  },
  {
    category: "category1",
    checkedVariable: "within4years",
    markerIcon:
      "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-violet.png",
    nameDesc: "UTP-Begins in 4 years",
  },
  {
    category: "category2",
    checkedVariable: "fivetotenyears",
    markerIcon:
      "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-blue.png",
    nameDesc: "UTP-Begins in 5-10 years",
  },
  {
    category: "category3",
    checkedVariable: "tenplusyears",
    markerIcon:
      "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-green.png",
    nameDesc: "UTP-Begins in 10+ years",
  },
];

// Note: segmentsTable and selection only used for geoRoute = 'hpms_segments/geojson'
export const geographic_options_tx100 = [
  {
    text: "Texas 100 Monitored Roads",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads_tx100",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "TX100",
    statusGuide: ["TX100", "Monitored Roads"],
  },
  {
    text: "Top 100 Truck Segments",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads_tx100/findByTrank?min=0&max=100",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "TX100",
    statusGuide: ["TX100", "Top100 Truck Segments"],
  },
  {
    text: "Top 100 All-Vehicle Segments",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads_tx100/findByArank?min=0&max=100",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "TX100",
    statusGuide: ["TX100", "Top100 Segments"],
  },
];

export const geographic_options_TXDOTdist = [
  {
    variable: "txdot_dist_8",
    mapped: 8,
    definition: "Abilene District",
    text: "Abilene District",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByDistrict?district=8",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["TxDOT District", "Abilene District"],
    counties: [17, 30, 77, 105, 115, 128, 132, 168, 177, 208, 209, 217, 221],
  },
  {
    variable: "txdot_dist_4",
    mapped: 4,
    definition: "Amarillo District",
    text: "Amarillo District",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByDistrict?district=4",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["TxDOT District", "Amarillo District"],
    counties: [
      6,
      33,
      56,
      59,
      91,
      99,
      104,
      107,
      118,
      148,
      171,
      179,
      180,
      188,
      191,
      197,
      211,
    ],
  },
  {
    variable: "txdot_dist_19",
    mapped: 19,
    definition: "Atlanta District",
    text: "Atlanta District",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByDistrict?district=19",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["TxDOT District", "Atlanta District"],
    counties: [19, 32, 34, 103, 155, 172, 183, 225, 230],
  },
  {
    variable: "txdot_dist_14",
    mapped: 14,
    definition: "Austin District",
    text: "Austin District",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByDistrict?district=14",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["TxDOT District", "Austin District"],
    counties: [11, 16, 27, 28, 87, 106, 144, 150, 157, 227, 246],
  },
  {
    variable: "txdot_dist_20",
    mapped: 20,
    definition: "Beaumont District",
    text: "Beaumont District",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByDistrict?district=20",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["TxDOT District", "Beaumont District"],
    counties: [36, 101, 122, 124, 146, 176, 181, 229],
  },
  {
    variable: "txdot_dist_23",
    mapped: 23,
    definition: "Brownwood District",
    text: "Brownwood District",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByDistrict?district=23",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["TxDOT District", "Brownwood District"],
    counties: [25, 42, 47, 68, 141, 160, 167, 206, 215],
  },
  {
    variable: "txdot_dist_17",
    mapped: 17,
    definition: "Bryan District",
    text: "Bryan District",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByDistrict?district=17",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["TxDOT District", "Bryan District"],
    counties: [21, 26, 82, 94, 145, 154, 166, 198, 236, 239],
  },
  {
    variable: "txdot_dist_25",
    mapped: 25,
    definition: "Childress District",
    text: "Childress District",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByDistrict?district=25",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["TxDOT District", "Childress District"],
    counties: [23, 38, 44, 51, 63, 65, 79, 97, 100, 135, 138, 173, 242],
  },
  {
    variable: "txdot_dist_16",
    mapped: 16,
    definition: "Corpus Christi District",
    text: "Corpus Christi District",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByDistrict?district=16",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["TxDOT District", "Corpus Christi District"],
    counties: [4, 13, 89, 126, 129, 137, 149, 178, 196, 205],
  },
  {
    variable: "txdot_dist_18",
    mapped: 18,
    definition: "Dallas District",
    text: "Dallas District",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByDistrict?district=18",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["TxDOT District", "Dallas District"],
    counties: [43, 57, 61, 71, 130, 175, 199],
  },
  {
    variable: "txdot_dist_24",
    mapped: 24,
    definition: "El Paso District",
    text: "El Paso District",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByDistrict?district=24",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["TxDOT District", "El Paso District"],
    counties: [22, 55, 72, 116, 123, 189],
  },
  {
    variable: "txdot_dist_2",
    mapped: 2,
    definition: "Fort Worth District",
    text: "Fort Worth District",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByDistrict?district=2",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["TxDOT District", "Fort Worth District"],
    counties: [73, 112, 120, 127, 182, 184, 213, 220, 249],
  },
  {
    variable: "txdot_dist_12",
    mapped: 12,
    definition: "Houston District",
    text: "Houston District",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByDistrict?district=12",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["TxDOT District", "Houston District"],
    counties: [20, 80, 85, 102, 170, 237],
  },
  {
    variable: "txdot_dist_22",
    mapped: 22,
    definition: "Laredo District",
    text: "Laredo District",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByDistrict?district=22",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["TxDOT District", "Laredo District"],
    counties: [64, 67, 136, 142, 159, 233, 240, 254],
  },
  {
    variable: "txdot_dist_5",
    mapped: 5,
    definition: "Lubbock District",
    text: "Lubbock District",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByDistrict?district=5",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["TxDOT District", "Lubbock District"],
    counties: [
      9,
      35,
      40,
      54,
      58,
      78,
      84,
      86,
      96,
      111,
      140,
      152,
      153,
      185,
      219,
      223,
      251,
    ],
  },
  {
    variable: "txdot_dist_11",
    mapped: 11,
    definition: "Lufkin District",
    text: "Lufkin District",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByDistrict?district=11",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["TxDOT District", "Lufkin District"],
    counties: [3, 114, 174, 187, 202, 203, 204, 210, 228],
  },
  {
    variable: "txdot_dist_6",
    mapped: 6,
    definition: "Odessa District",
    text: "Odessa District",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByDistrict?district=6",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["TxDOT District", "Odessa District"],
    counties: [2, 52, 69, 151, 156, 165, 186, 195, 222, 231, 238, 248],
  },
  {
    variable: "txdot_dist_1",
    mapped: 1,
    definition: "Paris District",
    text: "Paris District",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByDistrict?district=1",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["TxDOT District", "Paris District"],
    counties: [60, 75, 81, 92, 113, 117, 139, 190, 194],
  },
  {
    variable: "txdot_dist_21",
    mapped: 21,
    definition: "Pharr District",
    text: "Pharr District",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByDistrict?district=21",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["TxDOT District", "Pharr District"],
    counties: [24, 31, 66, 109, 125, 214, 245, 253],
  },
  {
    variable: "txdot_dist_7",
    mapped: 7,
    definition: "San Angelo District",
    text: "San Angelo District",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByDistrict?district=7",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["TxDOT District", "San Angelo District"],
    counties: [
      41,
      48,
      53,
      70,
      88,
      119,
      134,
      164,
      192,
      193,
      200,
      207,
      216,
      218,
      226,
    ],
  },
  {
    variable: "txdot_dist_15",
    mapped: 15,
    definition: "San Antonio District",
    text: "San Antonio District",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByDistrict?district=15",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["TxDOT District", "San Antonio District"],
    counties: [7, 10, 15, 46, 83, 95, 131, 133, 162, 163, 232, 247],
  },
  {
    variable: "txdot_dist_10",
    mapped: 10,
    definition: "Tyler District",
    text: "Tyler District",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByDistrict?district=10",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["TxDOT District", "Tyler District"],
    counties: [1, 37, 93, 108, 201, 212, 234, 250],
  },
  {
    variable: "txdot_dist_9",
    mapped: 9,
    definition: "Waco District",
    text: "Waco District",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByDistrict?district=9",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["TxDOT District", "Waco District"],
    counties: [14, 18, 50, 74, 98, 110, 147, 161],
  },
  {
    variable: "txdot_dist_3",
    mapped: 3,
    definition: "Wichita Falls District",
    text: "Wichita Falls District",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByDistrict?district=3",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["TxDOT District", "Wichita Falls District"],
    counties: [5, 12, 39, 49, 169, 224, 243, 244, 252],
  },
  {
    variable: "txdot_dist_13",
    mapped: 13,
    definition: "Yoakum District",
    text: "Yoakum District",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByDistrict?district=13",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["TxDOT District", "Yoakum District"],
    counties: [8, 29, 45, 62, 76, 90, 121, 143, 158, 235, 241],
  },
];

export const geographic_options_MPO = [
  {
    id: 0,
    name: "Not in MPO",
    text: "Not in MPO",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByMpo?mpo=0",
    geoRoute2021: "/roads/findByMpo?mpo=0",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["MPO", "Not in MPO"],
  },
  {
    id: 166,
    name: "Abilene MPO",
    text: "Abilene MPO",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByMpo?mpo=166",
    geoRoute2021: "/roads/findByMpo?mpo=4",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["MPO", "Abilene MPO"],
  },
  {
    id: 120,
    name: "Amarillo MPO",
    text: "Amarillo MPO",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByMpo?mpo=120",
    geoRoute2021: "/roads/findByMpo?mpo=2",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["MPO", "Amarillo MPO"],
  },
  {
    id: 90,
    name: "Capital Area MPO",
    text: "Capital Area MPO",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByMpo?mpo=90",
    geoRoute2021: "/roads/findByMpo?mpo=20",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["MPO", "Capital Area MPO"],
  },
  {
    id: 135,
    name: "Beaumont - Port Arthur MPO",
    text: "Beaumont - Port Arthur MPO",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByMpo?mpo=135",
    geoRoute2021: "/roads/findByMpo?mpo=17",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["MPO", "Beaumont - Port Arthur MPO"],
  },
  {
    id: 230,
    name: "Brownsville MPO",
    text: "Brownsville MPO",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByMpo?mpo=230",
    geoRoute2021: "/roads/findByMpo?mpo=25",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["MPO", "Brownsville MPO"],
  },
  {
    id: 249,
    name: "Bryan College Station MPO",
    text: "Bryan College Station MPO",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByMpo?mpo=249",
    geoRoute2021: "/roads/findByMpo?mpo=19",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["MPO", "Bryan College Station MPO"],
  },
  {
    id: 96,
    name: "Corpus Christi MPO",
    text: "Corpus Christi MPO",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByMpo?mpo=96",
    geoRoute2021: "/roads/findByMpo?mpo=9",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["MPO", "Corpus Christi MPO"],
  },
  {
    id: 282,
    name: "North Central Texas COG",
    text: "North Central Texas COG",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByMpo?mpo=282",
    geoRoute2021: "/roads/findByMpo?mpo=8",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["MPO", "North Central Texas COG"],
  },
  {
    id: 66,
    name: "El Paso MPO",
    text: "El Paso MPO",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByMpo?mpo=66",
    geoRoute2021: "/roads/findByMpo?mpo=15",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["MPO", "El Paso MPO"],
  },
  {
    id: 230,
    name: "Rio Grande Valley MPO",
    text: "Rio Grande Valley MPO",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByMpo?mpo=230",
    geoRoute2021: "/roads/findByMpo?mpo=25",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["MPO", "Rio Grande Valley MPO"],
  },
  {
    id: 15,
    name: "Houston-Galveston Area Council",
    text: "Houston-Galveston Area Council",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByMpo?mpo=15",
    geoRoute2021: "/roads/findByMpo?mpo=11",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["MPO", "Houston-Galveston Area Council"],
  },
  {
    id: 277,
    name: "Killeen-Temple MPO",
    text: "Killeen-Temple MPO",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByMpo?mpo=277",
    geoRoute2021: "/roads/findByMpo?mpo=7",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["MPO", "Killeen-Temple MPO"],
  },
  {
    id: 205,
    name: "Laredo & Webb County Area MPO",
    text: "Laredo & Webb County Area MPO",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByMpo?mpo=205",
    geoRoute2021: "/roads/findByMpo?mpo=16",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["MPO", "Laredo & Webb County Area MPO"],
  },
  {
    id: 361,
    name: "Longview MPO",
    text: "Longview MPO",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByMpo?mpo=361",
    geoRoute2021: "/roads/findByMpo?mpo=13",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["MPO", "Longview MPO"],
  },
  {
    id: 122,
    name: "Lubbock MPO",
    text: "Lubbock MPO",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByMpo?mpo=122",
    geoRoute2021: "/roads/findByMpo?mpo=6",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["MPO", "Lubbock MPO"],
  },
  {
    id: 174,
    name: "Permian Basin MPO",
    text: "Permian Basin MPO",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByMpo?mpo=174",
    geoRoute2021: "/roads/findByMpo?mpo=1",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["MPO", "Permian Basin MPO"],
  },
  {
    id: 208,
    name: "San Angelo MPO",
    text: "San Angelo MPO",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByMpo?mpo=208",
    geoRoute2021: "/roads/findByMpo?mpo=3",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["MPO", "San Angelo MPO"],
  },
  {
    id: 28,
    name: "Alamo Area MPO",
    text: "Alamo Area MPO",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByMpo?mpo=28",
    geoRoute2021: "/roads/findByMpo?mpo=21",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["MPO", "Alamo Area MPO"],
  },
  {
    id: 232,
    name: "Grayson County MPO",
    text: "Grayson County MPO",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByMpo?mpo=232",
    geoRoute2021: "/roads/findByMpo?mpo=24",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["MPO", "Grayson County MPO"],
  },
  {
    id: 211,
    name: "Texarkana MPO",
    text: "Texarkana MPO",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByMpo?mpo=211",
    geoRoute2021: "/roads/findByMpo?mpo=14",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["MPO", "Texarkana MPO"],
  },
  {
    id: 213,
    name: "Tyler Area MPO",
    text: "Tyler Area MPO",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByMpo?mpo=213",
    geoRoute2021: "/roads/findByMpo?mpo=12",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["MPO", "Tyler Area MPO"],
  },
  {
    id: 363,
    name: "Victoria MPO",
    text: "Victoria MPO",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByMpo?mpo=363",
    geoRoute2021: "/roads/findByMpo?mpo=18",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["MPO", "Victoria MPO"],
  },
  {
    id: 140,
    name: "Waco MPO",
    text: "Waco MPO",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByMpo?mpo=140",
    geoRoute2021: "/roads/findByMpo?mpo=22",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["MPO", "Waco MPO"],
  },
  {
    id: 151,
    name: "Wichita Falls MPO",
    text: "Wichita Falls MPO",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByMpo?mpo=151",
    geoRoute2021: "/roads/findByMpo?mpo=5",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["MPO", "Wichita Falls MPO"],
  }, //,

  // {
  //   "id": 201,
  //   "name": "Unknown MPO 201"
  // },

  // {
  //   "id": 248,
  //   "name": "Unknown MPO 248"
  // }
  {
    id: 0,
    name: "Not in MPO",
    text: "Not in MPO",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByMpo?mpo=0",
    geoRoute2021: "/roads/findByMpo?mpo=0",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["MPO", "Not in MPO"],
  },
];

export const geographic_options_Networks = [
  {
    name: "freight-network-texas",
    description: "Texas Freight Network",
    route: "/frght_ntwk_st=1",
    text: "Texas Freight Network",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findTxFreightNetworkByDistrict",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["Networks", "Texas Freight Network"],
  },
  {
    name: "freight-network-national",
    description: "National Freight Network",
    route: "/frght_ntwk_nl=1",
    text: "National Freight Network",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findNationalFreightNetworkByDistrict",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["Networks", "National Freight Network"],
  },
  {
    name: "national-highway-system",
    description: "National Highway System (NHS)",
    route: "/sec_nhs>0",
    text: "National Highway System (NHS)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findNhsByDistrict",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["Networks", "National Highway System (NHS)"],
  },
];
export const geographic_options_REGIONS = [
  {
    name: "texas-triangle",
    description: "Texas Triangle Region",
    // statsLayer: "statewide",
    route: "/triangleregion=1",
    text: "Texas Triangle Region",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByTcatRegion?region_id=1",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["Regions", "Texas Triangle Region"],
    counties: [
      8,
      14,
      15,
      18,
      28,
      43,
      45,
      46,
      50,
      57,
      61,
      71,
      74,
      76,
      80,
      82,
      90,
      95,
      98,
      102,
      106,
      110,
      127,
      145,
      147,
      154,
      161,
      170,
      175,
      220,
      227,
      236,
      237,
      246,
    ],
  },
  {
    name: "border",
    description: "Border Region",
    // statsLayer: "statewide",
    route: "/borderregion=1",
    text: "Border Region",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByTcatRegion?region_id=2",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["Regions", "Border Region"],
    counties: [
      22,
      31,
      55,
      72,
      109,
      116,
      123,
      136,
      159,
      189,
      214,
      222,
      233,
      240,
      253,
    ],
  },
  {
    name: "permian-basin",
    description: "Permian Basin Region",
    // statsLayer: "statewide",
    route: "/permianbasin=1",
    text: "Permian Basin Region",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByTcatRegion?region_id=3",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["Regions", "Permian Basin Region"],
    counties: [
      2,
      17,
      22,
      40,
      41,
      48,
      51,
      52,
      53,
      54,
      55,
      58,
      63,
      69,
      70,
      77,
      78,
      84,
      86,
      88,
      96,
      111,
      115,
      116,
      119,
      123,
      132,
      134,
      135,
      138,
      140,
      151,
      152,
      153,
      156,
      160,
      164,
      165,
      168,
      173,
      177,
      186,
      189,
      192,
      195,
      200,
      207,
      208,
      216,
      217,
      218,
      221,
      222,
      223,
      226,
      231,
      233,
      238,
      248,
      251,
    ],
  },
  {
    name: "houston-area",
    description: "Houston Region",
    // statsLayer: "statewide",
    route: "/houston=1",
    text: "Houston Region",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByTcatRegion?region_id=4",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["Regions", "Houston Region"],
    counties: [8, 20, 36, 45, 80, 102, 146, 158, 170, 236, 237, 241],
  },
  {
    name: "dfw-area",
    description: "DFW Region",
    // statsLayer: "statewide",
    route: "/dfw=1",
    text: "DFW Region",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByTcatRegion?region_id=5",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["Regions", "DFW Region"],
    counties: [
      43,
      57,
      61,
      71,
      73,
      112,
      117,
      127,
      130,
      175,
      182,
      184,
      199,
      213,
      220,
      249,
    ],
  },
  {
    name: "rio-grande-valley-area",
    description: "Rio Grande Valley Region",
    // statsLayer: "statewide",
    route: "/riograndevalley=1",
    text: "Rio Grande Valley Region",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByTcatRegion?region_id=6",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["Regions", "Rio Grande Valley Region"],
    counties: [24, 31, 66, 109, 125, 214, 245, 253],
  },
];

export const geographic_options_corridors = [
  {
    name: "interstates",
    description: "Interstate Corridors (including loops)",
    // statsLayer: "statewide",
    route: "/interstatesloops=1",
    text: "Interstate Corridors (including loops)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByFSystem?fsystem=1",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["Corridors", "Interstate Corridors (including loops)"],
  },
  {
    name: "port-to-plains-area",
    description: "Port-to-Plains Corridor",
    // statsLayer: "statewide",
    route: "/portstoplains=1",
    text: "Port-to-Plains Corridor",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCorridor?name=Ports-to-Plains",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["Corridors", "Port-to-Plains Corridor"],
  },
  {
    name: "US277_US83",
    description: "US277 US83 Corridor",
    statsLayer: "statewide",
    text: "US277 US83 Corridor",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCorridor?name=US277_US83",
    segmentsTable: "roads",
    segmentSelection: "all",
    statusGuide: ["Corridors", "US277_US83"],
    routeIds:['US0277-KG','US0083-KG']
  },
  {
    name: "US87_US83",
    description: "US87 US83 Corridor",
    text: "US87 US83 Corridor",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCorridor?name=US87_US83",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["Corridors", "US87_US83"],
    routeIds:['US0087-KG','US0083-KG']
  },
  {
    name: "US69_US175",
    description: "US69 US175 Corridor",
    text: "US69 US175 Corridor",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCorridor?name=US69_US175",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["Corridors", "US69_US175"],
    routeIds:['US0175-KG','US0069-KG']
  },
  {
    name: "US59",
    description: "US59 Corridor",
    statsLayer: "statewide",
    geoRoute: "/roads/findByCorridor?name=US59",
    text: "US59 Corridor",
    icon: "emoji_transportation",
    active: true,
    segmentsTable: "roads",
    segmentSelection: "all",
    statusGuide: ["Corridors", "US59"],
    routeIds:['US0059-KG',]
  },
  {
    name: "US281",
    description: "US281 Corridor",
    statsLayer: "statewide",
    geoRoute: "/roads/findByCorridor?name=US281",
    text: "US281 Corridor",
    icon: "emoji_transportation",
    active: true,
    segmentsTable: "roads",
    segmentSelection: "all",
    statusGuide: ["Corridors", "US281"],
    routeIds:['US0281-KG',]
  }, 
  {
    name: "IH0002",
    description: "IH0002 Corridor",
    statsLayer: "statewide",
    geoRoute: "/roads/findByRouteId?route_id=IH0002-KG",
    text: "I-2 Corridor",
    icon: "emoji_transportation",
    active: true,
    segmentsTable: "roads",
    segmentSelection: "all",
    statusGuide: ["Corridors", "IH0002"],
  },
  {
    name: "IH0010",
    description: "IH0010 Corridor",
    statsLayer: "statewide",
    geoRoute: "/roads/findByRouteId?route_id=IH0010-KG",
    text: "I-10 Corridor",
    icon: "emoji_transportation",
    active: true,
    segmentsTable: "roads",
    segmentSelection: "all",
    statusGuide: ["Corridors", "IH0010"],
  },
  {
    name: "IH0014",
    description: "IH0014 Corridor",
    statsLayer: "statewide",
    geoRoute: "/roads/findByRouteId?route_id=IH0014-KG",
    text: "I-14 Corridor",
    icon: "emoji_transportation",
    active: true,
    segmentsTable: "roads",
    segmentSelection: "all",
    statusGuide: ["Corridors", "IH0014"],
  },
  {
    name: "IH0020",
    description: "IH0020 Corridor",
    statsLayer: "statewide",
    geoRoute: "/roads/findByRouteId?route_id=IH0020-KG",
    text: "I-20 Corridor",
    icon: "emoji_transportation",
    active: true,
    segmentsTable: "roads",
    segmentSelection: "all",
    statusGuide: ["Corridors", "IH0020"],
  },
  {
    name: "IH0027",
    description: "I27 Corridor",
    statsLayer: "statewide",
    geoRoute: "/roads/findByRouteId?route_id=IH0027-KG",
    text: "I-27 Corridor",
    icon: "emoji_transportation",
    active: true,
    segmentsTable: "roads",
    segmentSelection: "all",
    statusGuide: ["Corridors", "I27"],
    routeIds:['IH0027-KG',]
  },
  {
    name: "IH0030",
    description: "IH0030 Corridor",
    statsLayer: "statewide",
    geoRoute: "/roads/findByRouteId?route_id=IH0030-KG",
    text: "I-30 Corridor",
    icon: "emoji_transportation",
    active: true,
    segmentsTable: "roads",
    segmentSelection: "all",
    statusGuide: ["Corridors", "IH0030"],
  },
  {
    name: "IH0035",
    description: "IH0035 Corridor",
    statsLayer: "statewide",
    geoRoute: "/roads/findByRouteId?route_id=IH0035-KG",
    text: "I-35 Corridor",
    icon: "emoji_transportation",
    active: true,
    segmentsTable: "roads",
    segmentSelection: "all",
    statusGuide: ["Corridors", "IH0035"],
  },
  {
    name: "IH0035E",
    description: "IH0035E Corridor",
    statsLayer: "statewide",
    geoRoute: "/roads/findByRouteId?route_id=IH0035E-KG",
    text: "I-35E Corridor",
    icon: "emoji_transportation",
    active: true,
    segmentsTable: "roads",
    segmentSelection: "all",
    statusGuide: ["Corridors", "IH0035E"],
  },
  {
    name: "IH0035W",
    description: "IH0035W Corridor",
    statsLayer: "statewide",
    geoRoute: "/roads/findByRouteId?route_id=IH0035W-KG",
    text: "I-35W Corridor",
    icon: "emoji_transportation",
    active: true,
    segmentsTable: "roads",
    segmentSelection: "all",
    statusGuide: ["Corridors", "IH0035W"],
  },
  {
    name: "IH0037",
    description: "IH0037 Corridor",
    statsLayer: "statewide",
    geoRoute: "/roads/findByRouteId?route_id=IH0037-KG",
    text: "I-37 Corridor",
    icon: "emoji_transportation",
    active: true,
    segmentsTable: "roads",
    segmentSelection: "all",
    statusGuide: ["Corridors", "IH0037"],
  },
  {
    name: "IH0040",
    description: "IH0040 Corridor",
    statsLayer: "statewide",
    geoRoute: "/roads/findByRouteId?route_id=IH0040-KG",
    text: "I-40 Corridor",
    icon: "emoji_transportation",
    active: true,
    segmentsTable: "roads",
    segmentSelection: "all",
    statusGuide: ["Corridors", "IH0040"],
  },
  {
    name: "IH0044",
    description: "IH0044 Corridor",
    statsLayer: "statewide",
    geoRoute: "/roads/findByRouteId?route_id=IH0044-KG",
    text: "I-44 Corridor",
    icon: "emoji_transportation",
    active: true,
    segmentsTable: "roads",
    segmentSelection: "all",
    statusGuide: ["Corridors", "IH0044"],
  },
  {
    name: "IH0045",
    description: "IH0045 Corridor",
    statsLayer: "statewide",
    geoRoute: "/roads/findByRouteId?route_id=IH0045-KG",
    text: "I-45 Corridor",
    icon: "emoji_transportation",
    active: true,
    segmentsTable: "roads",
    segmentSelection: "all",
    statusGuide: ["Corridors", "IH0045"],
  },
  {
    name: "IH0069C",
    description: "IH0069C Corridor",
    statsLayer: "statewide",
    geoRoute: "/roads/findByRouteId?route_id=IH0069C-KG",
    text: "I-69C Corridor",
    icon: "emoji_transportation",
    active: true,
    segmentsTable: "roads",
    segmentSelection: "all",
    statusGuide: ["Corridors", "IH0069C"],
  },
  {
    name: "IH0069E",
    description: "IH0069E Corridor",
    statsLayer: "statewide",
    geoRoute: "/roads/findByRouteId?route_id=IH0069E-KG",
    text: "I-69E Corridor",
    icon: "emoji_transportation",
    active: true,
    segmentsTable: "roads",
    segmentSelection: "all",
    statusGuide: ["Corridors", "IH0069E"],
  },
  {
    name: "IH0069",
    description: "IH0069 Corridor",
    statsLayer: "statewide",
    geoRoute: "/roads/findByRouteId?route_id=IH0069-KG",
    text: "I-69 Corridor",
    icon: "emoji_transportation",
    active: true,
    segmentsTable: "roads",
    segmentSelection: "all",
    statusGuide: ["Corridors", "IH0069"],
  },
  {
    name: "IH0069W",
    description: "IH0069W Corridor",
    statsLayer: "statewide",
    geoRoute: "/roads/findByRouteId?route_id=IH0069W-KG",
    text: "I-69W Corridor",
    icon: "emoji_transportation",
    active: true,
    segmentsTable: "roads",
    segmentSelection: "all",
    statusGuide: ["Corridors", "IH0069W"],
  },
];

export const geographic_options_COUNTY = [
  {
    name: "Anderson",
    id: 1,
    text: "Anderson County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=1",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Anderson County"],
  },
  {
    name: "Andrews",
    id: 2,
    text: "Andrews County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=2",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Andrews County"],
  },
  {
    name: "Angelina",
    id: 3,
    text: "Angelina County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=3",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Angelina County"],
  },
  {
    name: "Aransas",
    id: 4,
    text: "Aransas County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=4",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Aransas County"],
  },
  {
    name: "Archer",
    id: 5,
    text: "Archer County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=5",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Archer County"],
  },
  {
    name: "Armstrong",
    id: 6,
    text: "Armstrong County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=6",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Armstrong County"],
  },
  {
    name: "Atascosa",
    id: 7,
    text: "Atascosa County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=7",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Atascosa County"],
  },
  {
    name: "Austin",
    id: 8,
    text: "Austin County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=8",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Austin County"],
  },
  {
    name: "Bailey",
    id: 9,
    text: "Bailey County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=9",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Bailey County"],
  },
  {
    name: "Bandera",
    id: 10,
    text: "Bandera County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=10",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Bandera County"],
  },
  {
    name: "Bastrop",
    id: 11,
    text: "Bastrop County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=11",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Bastrop County"],
  },
  {
    name: "Baylor",
    id: 12,
    text: "Baylor County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=12",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Baylor County"],
  },
  {
    name: "Bee",
    id: 13,
    text: "Bee County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=13",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Bee County"],
  },
  {
    name: "Bell",
    id: 14,
    text: "Bell County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=14",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Bell County"],
  },
  {
    name: "Bexar",
    id: 15,
    text: "Bexar County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=15",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Bexar County"],
  },
  {
    name: "Blanco",
    id: 16,
    text: "Blanco County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=16",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Blanco County"],
  },
  {
    name: "Borden",
    id: 17,
    text: "Borden County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=17",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Borden County"],
  },
  {
    name: "Bosque",
    id: 18,
    text: "Bosque County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=18",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Bosque County"],
  },
  {
    name: "Bowie",
    id: 19,
    text: "Bowie County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=19",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Bowie County"],
  },
  {
    name: "Brazoria",
    id: 20,
    text: "Brazoria County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=20",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Brazoria County"],
  },
  {
    name: "Brazos",
    id: 21,
    text: "Brazos County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=21",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Brazos County"],
  },
  {
    name: "Brewster",
    id: 22,
    text: "Brewster County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=22",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Brewster County"],
  },
  {
    name: "Briscoe",
    id: 23,
    text: "Briscoe County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=23",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Briscoe County"],
  },
  {
    name: "Brooks",
    id: 24,
    text: "Brooks County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=24",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Brooks County"],
  },
  {
    name: "Brown",
    id: 25,
    text: "Brown County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=25",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Brown County"],
  },
  {
    name: "Burleson",
    id: 26,
    text: "Burleson County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=26",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Burleson County"],
  },
  {
    name: "Burnet",
    id: 27,
    text: "Burnet County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=27",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Burnet County"],
  },
  {
    name: "Caldwell",
    id: 28,
    text: "Caldwell County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=28",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Caldwell County"],
  },
  {
    name: "Calhoun",
    id: 29,
    text: "Calhoun County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=29",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Calhoun County"],
  },
  {
    name: "Callahan",
    id: 30,
    text: "Callahan County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=30",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Callahan County"],
  },
  {
    name: "Cameron",
    id: 31,
    text: "Cameron County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=31",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Cameron County"],
  },
  {
    name: "Camp",
    id: 32,
    text: "Camp County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=32",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Camp County"],
  },
  {
    name: "Carson",
    id: 33,
    text: "Carson County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=33",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Carson County"],
  },
  {
    name: "Cass",
    id: 34,
    text: "Cass County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=34",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Cass County"],
  },
  {
    name: "Castro",
    id: 35,
    text: "Castro County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=35",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Castro County"],
  },
  {
    name: "Chambers",
    id: 36,
    text: "Chambers County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=36",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Chambers County"],
  },
  {
    name: "Cherokee",
    id: 37,
    text: "Cherokee County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=37",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Cherokee County"],
  },
  {
    name: "Childress",
    id: 38,
    text: "Childress County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=38",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Childress County"],
  },
  {
    name: "Clay",
    id: 39,
    text: "Clay County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=39",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Clay County"],
  },
  {
    name: "Cochran",
    id: 40,
    text: "Cochran County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=40",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Cochran County"],
  },
  {
    name: "Coke",
    id: 41,
    text: "Coke County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=41",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Coke County"],
  },
  {
    name: "Coleman",
    id: 42,
    text: "Coleman County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=42",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Coleman County"],
  },
  {
    name: "Collin",
    id: 43,
    text: "Collin County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=43",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Collin County"],
  },
  {
    name: "Collingsworth",
    id: 44,
    text: "Collingsworth County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=44",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Collingsworth County"],
  },
  {
    name: "Colorado",
    id: 45,
    text: "Colorado County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=45",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Colorado County"],
  },
  {
    name: "Comal",
    id: 46,
    text: "Comal County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=46",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Comal County"],
  },
  {
    name: "Comanche",
    id: 47,
    text: "Comanche County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=47",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Comanche County"],
  },
  {
    name: "Concho",
    id: 48,
    text: "Concho County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=48",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Concho County"],
  },
  {
    name: "Cooke",
    id: 49,
    text: "Cooke County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=49",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Cooke County"],
  },
  {
    name: "Coryell",
    id: 50,
    text: "Coryell County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=50",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Coryell County"],
  },
  {
    name: "Cottle",
    id: 51,
    text: "Cottle County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=51",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Cottle County"],
  },
  {
    name: "Crane",
    id: 52,
    text: "Crane County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=52",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Crane County"],
  },
  {
    name: "Crockett",
    id: 53,
    text: "Crockett County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=53",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Crockett County"],
  },
  {
    name: "Crosby",
    id: 54,
    text: "Crosby County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=54",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Crosby County"],
  },
  {
    name: "Culberson",
    id: 55,
    text: "Culberson County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=55",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Culberson County"],
  },
  {
    name: "Dallam",
    id: 56,
    text: "Dallam County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=56",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Dallam County"],
  },
  {
    name: "Dallas",
    id: 57,
    text: "Dallas County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=57",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Dallas County"],
  },
  {
    name: "Dawson",
    id: 58,
    text: "Dawson County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=58",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Dawson County"],
  },
  {
    name: "De Witt",
    id: 62,
    text: "De Witt County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=62",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "De Witt County"],
  },
  {
    name: "Deaf Smith",
    id: 59,
    text: "Deaf Smith County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=59",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Deaf Smith County"],
  },
  {
    name: "Delta",
    id: 60,
    text: "Delta County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=60",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Delta County"],
  },
  {
    name: "Denton",
    id: 61,
    text: "Denton County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=61",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Denton County"],
  },
  {
    name: "Dickens",
    id: 63,
    text: "Dickens County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=63",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Dickens County"],
  },
  {
    name: "Dimmit",
    id: 64,
    text: "Dimmit County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=64",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Dimmit County"],
  },
  {
    name: "Donley",
    id: 65,
    text: "Donley County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=65",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Donley County"],
  },
  {
    name: "Duval",
    id: 67,
    text: "Duval County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=67",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Duval County"],
  },
  {
    name: "Eastland",
    id: 68,
    text: "Eastland County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=68",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Eastland County"],
  },
  {
    name: "Ector",
    id: 69,
    text: "Ector County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=69",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Ector County"],
  },
  {
    name: "Edwards",
    id: 70,
    text: "Edwards County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=70",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Edwards County"],
  },
  {
    name: "El Paso",
    id: 72,
    text: "El Paso County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=72",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "El Paso County"],
  },
  {
    name: "Ellis",
    id: 71,
    text: "Ellis County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=71",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Ellis County"],
  },
  {
    name: "Erath",
    id: 73,
    text: "Erath County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=73",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Erath County"],
  },
  {
    name: "Falls",
    id: 74,
    text: "Falls County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=74",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Falls County"],
  },
  {
    name: "Fannin",
    id: 75,
    text: "Fannin County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=75",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Fannin County"],
  },
  {
    name: "Fayette",
    id: 76,
    text: "Fayette County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=76",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Fayette County"],
  },
  {
    name: "Fisher",
    id: 77,
    text: "Fisher County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=77",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Fisher County"],
  },
  {
    name: "Floyd",
    id: 78,
    text: "Floyd County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=78",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Floyd County"],
  },
  {
    name: "Foard",
    id: 79,
    text: "Foard County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=79",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Foard County"],
  },
  {
    name: "Fort Bend",
    id: 80,
    text: "Fort Bend County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=80",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Fort Bend County"],
  },
  {
    name: "Franklin",
    id: 81,
    text: "Franklin County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=81",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Franklin County"],
  },
  {
    name: "Freestone",
    id: 82,
    text: "Freestone County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=82",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Freestone County"],
  },
  {
    name: "Frio",
    id: 83,
    text: "Frio County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=83",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Frio County"],
  },
  {
    name: "Gaines",
    id: 84,
    text: "Gaines County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=84",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Gaines County"],
  },
  {
    name: "Galveston",
    id: 85,
    text: "Galveston County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=85",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Galveston County"],
  },
  {
    name: "Garza",
    id: 86,
    text: "Garza County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=86",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Garza County"],
  },
  {
    name: "Gillespie",
    id: 87,
    text: "Gillespie County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=87",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Gillespie County"],
  },
  {
    name: "Glasscock",
    id: 88,
    text: "Glasscock County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=88",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Glasscock County"],
  },
  {
    name: "Goliad",
    id: 89,
    text: "Goliad County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=89",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Goliad County"],
  },
  {
    name: "Gonzales",
    id: 90,
    text: "Gonzales County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=90",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Gonzales County"],
  },
  {
    name: "Gray",
    id: 91,
    text: "Gray County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=91",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Gray County"],
  },
  {
    name: "Grayson",
    id: 92,
    text: "Grayson County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=92",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Grayson County"],
  },
  {
    name: "Gregg",
    id: 93,
    text: "Gregg County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=93",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Gregg County"],
  },
  {
    name: "Grimes",
    id: 94,
    text: "Grimes County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=94",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Grimes County"],
  },
  {
    name: "Guadalupe",
    id: 95,
    text: "Guadalupe County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=95",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Guadalupe County"],
  },
  {
    name: "Hale",
    id: 96,
    text: "Hale County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=96",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Hale County"],
  },
  {
    name: "Hall",
    id: 97,
    text: "Hall County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=97",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Hall County"],
  },
  {
    name: "Hamilton",
    id: 98,
    text: "Hamilton County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=98",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Hamilton County"],
  },
  {
    name: "Hansford",
    id: 99,
    text: "Hansford County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=99",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Hansford County"],
  },
  {
    name: "Hardeman",
    id: 100,
    text: "Hardeman County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=100",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Hardeman County"],
  },
  {
    name: "Hardin",
    id: 101,
    text: "Hardin County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=101",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Hardin County"],
  },
  {
    name: "Harris",
    id: 102,
    text: "Harris County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=102",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Harris County"],
  },
  {
    name: "Harrison",
    id: 103,
    text: "Harrison County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=103",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Harrison County"],
  },
  {
    name: "Hartley",
    id: 104,
    text: "Hartley County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=104",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Hartley County"],
  },
  {
    name: "Haskell",
    id: 105,
    text: "Haskell County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=105",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Haskell County"],
  },
  {
    name: "Hays",
    id: 106,
    text: "Hays County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=106",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Hays County"],
  },
  {
    name: "Hemphill",
    id: 107,
    text: "Hemphill County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=107",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Hemphill County"],
  },
  {
    name: "Henderson",
    id: 108,
    text: "Henderson County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=108",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Henderson County"],
  },
  {
    name: "Hidalgo",
    id: 109,
    text: "Hidalgo County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=109",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Hidalgo County"],
  },
  {
    name: "Hill",
    id: 110,
    text: "Hill County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=110",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Hill County"],
  },
  {
    name: "Hockley",
    id: 111,
    text: "Hockley County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=111",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Hockley County"],
  },
  {
    name: "Hood",
    id: 112,
    text: "Hood County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=112",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Hood County"],
  },
  {
    name: "Hopkins",
    id: 113,
    text: "Hopkins County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=113",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Hopkins County"],
  },
  {
    name: "Houston",
    id: 114,
    text: "Houston County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=114",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Houston County"],
  },
  {
    name: "Howard",
    id: 115,
    text: "Howard County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=115",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Howard County"],
  },
  {
    name: "Hudspeth",
    id: 116,
    text: "Hudspeth County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=116",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Hudspeth County"],
  },
  {
    name: "Hunt",
    id: 117,
    text: "Hunt County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=117",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Hunt County"],
  },
  {
    name: "Hutchinson",
    id: 118,
    text: "Hutchinson County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=118",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Hutchinson County"],
  },
  {
    name: "Irion",
    id: 119,
    text: "Irion County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=119",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Irion County"],
  },
  {
    name: "Jack",
    id: 120,
    text: "Jack County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=120",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Jack County"],
  },
  {
    name: "Jackson",
    id: 121,
    text: "Jackson County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=121",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Jackson County"],
  },
  {
    name: "Jasper",
    id: 122,
    text: "Jasper County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=122",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Jasper County"],
  },
  {
    name: "Jeff Davis",
    id: 123,
    text: "Jeff Davis County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=123",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Jeff Davis County"],
  },
  {
    name: "Jefferson",
    id: 124,
    text: "Jefferson County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=124",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Jefferson County"],
  },
  {
    name: "Jim Hogg",
    id: 125,
    text: "Jim Hogg County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=125",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Jim Hogg County"],
  },
  {
    name: "Jim Wells",
    id: 126,
    text: "Jim Wells County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=126",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Jim Wells County"],
  },
  {
    name: "Johnson",
    id: 127,
    text: "Johnson County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=127",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Johnson County"],
  },
  {
    name: "Jones",
    id: 128,
    text: "Jones County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=128",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Jones County"],
  },
  {
    name: "Karnes",
    id: 129,
    text: "Karnes County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=129",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Karnes County"],
  },
  {
    name: "Kaufman",
    id: 130,
    text: "Kaufman County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=130",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Kaufman County"],
  },
  {
    name: "Kendall",
    id: 131,
    text: "Kendall County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=131",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Kendall County"],
  },
  {
    name: "Kenedy",
    id: 66,
    text: "Kenedy County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=66",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Kenedy County"],
  },
  {
    name: "Kent",
    id: 132,
    text: "Kent County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=132",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Kent County"],
  },
  {
    name: "Kerr",
    id: 133,
    text: "Kerr County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=133",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Kerr County"],
  },
  {
    name: "Kimble",
    id: 134,
    text: "Kimble County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=134",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Kimble County"],
  },
  {
    name: "King",
    id: 135,
    text: "King County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=135",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "King County"],
  },
  {
    name: "Kinney",
    id: 136,
    text: "Kinney County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=136",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Kinney County"],
  },
  {
    name: "Kleberg",
    id: 137,
    text: "Kleberg County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=137",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Kleberg County"],
  },
  {
    name: "Knox",
    id: 138,
    text: "Knox County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=138",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Knox County"],
  },
  {
    name: "La Salle",
    id: 142,
    text: "La Salle County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=142",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "La Salle County"],
  },
  {
    name: "Lamar",
    id: 139,
    text: "Lamar County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=139",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Lamar County"],
  },
  {
    name: "Lamb",
    id: 140,
    text: "Lamb County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=140",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Lamb County"],
  },
  {
    name: "Lampasas",
    id: 141,
    text: "Lampasas County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=141",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Lampasas County"],
  },
  {
    name: "Lavaca",
    id: 143,
    text: "Lavaca County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=143",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Lavaca County"],
  },
  {
    name: "Lee",
    id: 144,
    text: "Lee County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=144",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Lee County"],
  },
  {
    name: "Leon",
    id: 145,
    text: "Leon County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=145",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Leon County"],
  },
  {
    name: "Liberty",
    id: 146,
    text: "Liberty County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=146",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Liberty County"],
  },
  {
    name: "Limestone",
    id: 147,
    text: "Limestone County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=147",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Limestone County"],
  },
  {
    name: "Lipscomb",
    id: 148,
    text: "Lipscomb County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=148",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Lipscomb County"],
  },
  {
    name: "Live Oak",
    id: 149,
    text: "Live Oak County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=149",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Live Oak County"],
  },
  {
    name: "Llano",
    id: 150,
    text: "Llano County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=150",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Llano County"],
  },
  {
    name: "Loving",
    id: 151,
    text: "Loving County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=151",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Loving County"],
  },
  {
    name: "Lubbock",
    id: 152,
    text: "Lubbock County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=152",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Lubbock County"],
  },
  {
    name: "Lynn",
    id: 153,
    text: "Lynn County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=153",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Lynn County"],
  },
  {
    name: "Madison",
    id: 154,
    text: "Madison County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=154",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Madison County"],
  },
  {
    name: "Marion",
    id: 155,
    text: "Marion County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=155",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Marion County"],
  },
  {
    name: "Martin",
    id: 156,
    text: "Martin County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=156",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Martin County"],
  },
  {
    name: "Mason",
    id: 157,
    text: "Mason County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=157",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Mason County"],
  },
  {
    name: "Matagorda",
    id: 158,
    text: "Matagorda County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=158",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Matagorda County"],
  },
  {
    name: "Maverick",
    id: 159,
    text: "Maverick County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=159",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Maverick County"],
  },
  {
    name: "McCulloch",
    id: 160,
    text: "McCulloch County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=160",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "McCulloch County"],
  },
  {
    name: "McLennan",
    id: 161,
    text: "McLennan County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=161",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "McLennan County"],
  },
  {
    name: "McMullen",
    id: 162,
    text: "McMullen County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=162",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "McMullen County"],
  },
  {
    name: "Medina",
    id: 163,
    text: "Medina County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=163",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Medina County"],
  },
  {
    name: "Menard",
    id: 164,
    text: "Menard County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=164",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Menard County"],
  },
  {
    name: "Midland",
    id: 165,
    text: "Midland County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=165",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Midland County"],
  },
  {
    name: "Milam",
    id: 166,
    text: "Milam County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=166",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Milam County"],
  },
  {
    name: "Mills",
    id: 167,
    text: "Mills County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=167",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Mills County"],
  },
  {
    name: "Mitchell",
    id: 168,
    text: "Mitchell County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=168",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Mitchell County"],
  },
  {
    name: "Montague",
    id: 169,
    text: "Montague County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=169",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Montague County"],
  },
  {
    name: "Montgomery",
    id: 170,
    text: "Montgomery County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=170",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Montgomery County"],
  },
  {
    name: "Moore",
    id: 171,
    text: "Moore County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=171",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Moore County"],
  },
  {
    name: "Morris",
    id: 172,
    text: "Morris County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=172",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Morris County"],
  },
  {
    name: "Motley",
    id: 173,
    text: "Motley County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=173",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Motley County"],
  },
  {
    name: "Nacogdoches",
    id: 174,
    text: "Nacogdoches County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=174",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Nacogdoches County"],
  },
  {
    name: "Navarro",
    id: 175,
    text: "Navarro County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=175",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Navarro County"],
  },
  {
    name: "Newton",
    id: 176,
    text: "Newton County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=176",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Newton County"],
  },
  {
    name: "Nolan",
    id: 177,
    text: "Nolan County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=177",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Nolan County"],
  },
  {
    name: "Nueces",
    id: 178,
    text: "Nueces County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=178",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Nueces County"],
  },
  {
    name: "Ochiltree",
    id: 179,
    text: "Ochiltree County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=179",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Ochiltree County"],
  },
  {
    name: "Oldham",
    id: 180,
    text: "Oldham County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=180",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Oldham County"],
  },
  {
    name: "Orange",
    id: 181,
    text: "Orange County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=181",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Orange County"],
  },
  {
    name: "Palo Pinto",
    id: 182,
    text: "Palo Pinto County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=182",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Palo Pinto County"],
  },
  {
    name: "Panola",
    id: 183,
    text: "Panola County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=183",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Panola County"],
  },
  {
    name: "Parker",
    id: 184,
    text: "Parker County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=184",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Parker County"],
  },
  {
    name: "Parmer",
    id: 185,
    text: "Parmer County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=185",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Parmer County"],
  },
  {
    name: "Pecos",
    id: 186,
    text: "Pecos County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=186",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Pecos County"],
  },
  {
    name: "Polk",
    id: 187,
    text: "Polk County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=187",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Polk County"],
  },
  {
    name: "Potter",
    id: 188,
    text: "Potter County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=188",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Potter County"],
  },
  {
    name: "Presidio",
    id: 189,
    text: "Presidio County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=189",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Presidio County"],
  },
  {
    name: "Rains",
    id: 190,
    text: "Rains County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=190",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Rains County"],
  },
  {
    name: "Randall",
    id: 191,
    text: "Randall County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=191",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Randall County"],
  },
  {
    name: "Reagan",
    id: 192,
    text: "Reagan County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=192",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Reagan County"],
  },
  {
    name: "Real",
    id: 193,
    text: "Real County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=193",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Real County"],
  },
  {
    name: "Red River",
    id: 194,
    text: "Red River County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=194",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Red River County"],
  },
  {
    name: "Reeves",
    id: 195,
    text: "Reeves County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=195",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Reeves County"],
  },
  {
    name: "Refugio",
    id: 196,
    text: "Refugio County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=196",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Refugio County"],
  },
  {
    name: "Roberts",
    id: 197,
    text: "Roberts County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=197",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Roberts County"],
  },
  {
    name: "Robertson",
    id: 198,
    text: "Robertson County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=198",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Robertson County"],
  },
  {
    name: "Rockwall",
    id: 199,
    text: "Rockwall County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=199",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Rockwall County"],
  },
  {
    name: "Runnels",
    id: 200,
    text: "Runnels County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=200",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Runnels County"],
  },
  {
    name: "Rusk",
    id: 201,
    text: "Rusk County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=201",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Rusk County"],
  },
  {
    name: "Sabine",
    id: 202,
    text: "Sabine County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=202",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Sabine County"],
  },
  {
    name: "San Augustine",
    id: 203,
    text: "San Augustine County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=203",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "San Augustine County"],
  },
  {
    name: "San Jacinto",
    id: 204,
    text: "San Jacinto County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=204",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "San Jacinto County"],
  },
  {
    name: "San Patricio",
    id: 205,
    text: "San Patricio County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=205",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "San Patricio County"],
  },
  {
    name: "San Saba",
    id: 206,
    text: "San Saba County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=206",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "San Saba County"],
  },
  {
    name: "Schleicher",
    id: 207,
    text: "Schleicher County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=207",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Schleicher County"],
  },
  {
    name: "Scurry",
    id: 208,
    text: "Scurry County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=208",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Scurry County"],
  },
  {
    name: "Shackelford",
    id: 209,
    text: "Shackelford County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=209",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Shackelford County"],
  },
  {
    name: "Shelby",
    id: 210,
    text: "Shelby County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=210",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Shelby County"],
  },
  {
    name: "Sherman",
    id: 211,
    text: "Sherman County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=211",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Sherman County"],
  },
  {
    name: "Smith",
    id: 212,
    text: "Smith County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=212",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Smith County"],
  },
  {
    name: "Somervell",
    id: 213,
    text: "Somervell County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=213",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Somervell County"],
  },
  {
    name: "Starr",
    id: 214,
    text: "Starr County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=214",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Starr County"],
  },
  {
    name: "Stephens",
    id: 215,
    text: "Stephens County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=215",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Stephens County"],
  },
  {
    name: "Sterling",
    id: 216,
    text: "Sterling County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=216",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Sterling County"],
  },
  {
    name: "Stonewall",
    id: 217,
    text: "Stonewall County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=217",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Stonewall County"],
  },
  {
    name: "Sutton",
    id: 218,
    text: "Sutton County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=218",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Sutton County"],
  },
  {
    name: "Swisher",
    id: 219,
    text: "Swisher County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=219",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Swisher County"],
  },
  {
    name: "Tarrant",
    id: 220,
    text: "Tarrant County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=220",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Tarrant County"],
  },
  {
    name: "Taylor",
    id: 221,
    text: "Taylor County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=221",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Taylor County"],
  },
  {
    name: "Terrell",
    id: 222,
    text: "Terrell County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=222",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Terrell County"],
  },
  {
    name: "Terry",
    id: 223,
    text: "Terry County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=223",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Terry County"],
  },
  {
    name: "Throckmorton",
    id: 224,
    text: "Throckmorton County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=224",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Throckmorton County"],
  },
  {
    name: "Titus",
    id: 225,
    text: "Titus County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=225",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Titus County"],
  },
  {
    name: "Tom Green",
    id: 226,
    text: "Tom Green County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=226",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Tom Green County"],
  },
  {
    name: "Travis",
    id: 227,
    text: "Travis County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=227",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Travis County"],
  },
  {
    name: "Trinity",
    id: 228,
    text: "Trinity County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=228",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Trinity County"],
  },
  {
    name: "Tyler",
    id: 229,
    text: "Tyler County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=229",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Tyler County"],
  },
  {
    name: "Upshur",
    id: 230,
    text: "Upshur County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=230",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Upshur County"],
  },
  {
    name: "Upton",
    id: 231,
    text: "Upton County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=231",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Upton County"],
  },
  {
    name: "Uvalde",
    id: 232,
    text: "Uvalde County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=232",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Uvalde County"],
  },
  {
    name: "Val Verde",
    id: 233,
    text: "Val Verde County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=233",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Val Verde County"],
  },
  {
    name: "Van Zandt",
    id: 234,
    text: "Van Zandt County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=234",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Van Zandt County"],
  },
  {
    name: "Victoria",
    id: 235,
    text: "Victoria County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=235",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Victoria County"],
  },
  {
    name: "Walker",
    id: 236,
    text: "Walker County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=236",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Walker County"],
  },
  {
    name: "Waller",
    id: 237,
    text: "Waller County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=237",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Waller County"],
  },
  {
    name: "Ward",
    id: 238,
    text: "Ward County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=238",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Ward County"],
  },
  {
    name: "Washington",
    id: 239,
    text: "Washington County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=239",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Washington County"],
  },
  {
    name: "Webb",
    id: 240,
    text: "Webb County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=240",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Webb County"],
  },
  {
    name: "Wharton",
    id: 241,
    text: "Wharton County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=241",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Wharton County"],
  },
  {
    name: "Wheeler",
    id: 242,
    text: "Wheeler County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=242",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Wheeler County"],
  },
  {
    name: "Wichita",
    id: 243,
    text: "Wichita County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=243",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Wichita County"],
  },
  {
    name: "Wilbarger",
    id: 244,
    text: "Wilbarger County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=244",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Wilbarger County"],
  },
  {
    name: "Willacy",
    id: 245,
    text: "Willacy County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=245",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Willacy County"],
  },
  {
    name: "Williamson",
    id: 246,
    text: "Williamson County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=246",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Williamson County"],
  },
  {
    name: "Wilson",
    id: 247,
    text: "Wilson County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=247",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Wilson County"],
  },
  {
    name: "Winkler",
    id: 248,
    text: "Winkler County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=248",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Winkler County"],
  },
  {
    name: "Wise",
    id: 249,
    text: "Wise County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=249",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Wise County"],
  },
  {
    name: "Wood",
    id: 250,
    text: "Wood County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=250",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Wood County"],
  },
  {
    name: "Yoakum",
    id: 251,
    text: "Yoakum County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=251",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Yoakum County"],
  },
  {
    name: "Young",
    id: 252,
    text: "Young County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=252",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Young County"],
  },
  {
    name: "Zapata",
    id: 253,
    text: "Zapata County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=253",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Zapata County"],
  },
  {
    name: "Zavala",
    id: 254,
    text: "Zavala County",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "/roads/findByCounty?county=254",
    segmentsTable: "roads",
    segmentSelection: "all",
    statsLayer: "statewide",
    statusGuide: ["County", "Zavala County"],
  },
];

export const SUMMARY_VARIABLES_ROUTE_100 = [
  {
    id: "road",
    numeric: false,
    disablePadding: true,
    label: "Name",
    checked: true,
    category: "route",
    // tooltip: <div style={{ fontSize: 12, maxWidth: 400 }}>Top 100 Bottlenecks road name</div>
  },
  {
    id: "from_road",
    numeric: true,
    disablePadding: false,
    label: "From",
    checked: true,
    category: "route",
    // tooltip: <div style={{ fontSize: 12, maxWidth: 400 }}>Starting road name</div>
  },
  {
    id: "to_road",
    numeric: true,
    disablePadding: false,
    label: "To",
    checked: true,
    category: "route",
    // tooltip: <div style={{ fontSize: 12, maxWidth: 400 }}>Ending road name</div>
  },
];

export const SUMMARY_VARIABLES_ROUTE = [
  {
    id: "ria_rte_id",
    numeric: false,
    disablePadding: true,
    label: "Route ID",
    checked: true,
    category: "route",
    // tooltip: <div style={{ fontSize: 12, maxWidth: 400 }}> HPMS route identifier/road name</div>
  },
  {
    id: "frm_dfo",
    numeric: true,
    disablePadding: false,
    label: "From",
    checked: true,
    category: "route",
    // tooltip: <div style={{ fontSize: 12, maxWidth: 400 }}>Starting milemarker</div>
  },
  {
    id: "to_dfo",
    numeric: true,
    disablePadding: false,
    label: "To",
    checked: true,
    category: "route",
    // tooltip: <div style={{ fontSize: 12, maxWidth: 400 }}>Ending milemarker</div>
  },
];

export const SUMMARY_VARIABLES_GROUPING = [
  {
    id: "ria_rte_id",
    numeric: false,
    disablePadding: true,
    label: "Route ID",
    checked: true,
    category: "route",
    // tooltip: <div style={{ fontSize: 12, maxWidth: 400 }}>HPMS route identifier/road name</div>
  },
  {
    id: "frght_ntwk_st",
    numeric: false,
    disablePadding: true,
    label: "Texas Freight Network",
    checked: true,
    category: "route",
    // tooltip: <div style={{ fontSize: 12, maxWidth: 400 }}>The entity that has legal ownership of a roadway</div>,
    queryVariable: "frghtNtwkTxFilters",
  },
  {
    id: "frght_ntwk_nl",
    numeric: false,
    disablePadding: true,
    label: "National Freight Network",
    checked: true,
    category: "route",
    // tooltip: <div style={{ fontSize: 12, maxWidth: 400 }}>The entity that has legal ownership of a roadway</div>,
    queryVariable: "frghtNtwkNationFilters",
  },
  {
    //id: "hsys_new",
    id: "hsys",
    numeric: false,
    disablePadding: true,
    label: "Highway System Designation",
    checked: true,
    category: "route",
    // tooltip: <div style={{ fontSize: 12, maxWidth: 400 }}>The entity that has legal ownership of a roadway</div>,
    queryVariable: "hsysFilters",
  },
  {
    id: "f_system",
    numeric: false,
    disablePadding: true,
    label: "Functional Classification",
    checked: true,
    category: "route",
    // tooltip: <div style={{ fontSize: 12, maxWidth: 400 }}>The FHWA approved Functional Classification System</div>,
    queryVariable: "functionalClassFilters",
  },
  {
    id: "ru",
    numeric: false,
    disablePadding: true,
    label: "Rural/Urban Code",
    checked: true,
    category: "route",
    // tooltip: <div style={{ fontSize: 12, maxWidth: 400 }}>The entity that has legal ownership of a roadway</div>,
    queryVariable: "ruralUrbanFilters",
  },
  {
    id: "co",
    numeric: false,
    disablePadding: true,
    label: "County",
    checked: true,
    category: "route",
    // tooltip: <div style={{ fontSize: 12, maxWidth: 400 }}>The entity that has legal ownership of a roadway</div>,
    queryVariable: "countyFilters",
  },
  {
    id: "di",
    numeric: false,
    disablePadding: true,
    label: "TxDOT District",
    checked: true,
    category: "route",
    // tooltip: <div style={{ fontSize: 12, maxWidth: 400 }}>The entity that has legal ownership of a roadway</div>,
    queryVariable: "districtFilters",
  },
  {
    id: "mpa",
    numeric: false,
    disablePadding: true,
    label: "MPO",
    checked: true,
    category: "route",
    // tooltip: <div style={{ fontSize: 12, maxWidth: 400 }}>The entity that has legal ownership of a roadway</div>,
    queryVariable: "mpoFilters",
  },
  {
    id: "sec_nhs",
    numeric: false,
    disablePadding: true,
    label: "NHS",
    checked: true,
    category: "route",
    //  tooltip: (
    //    <div style={{ fontSize: 12, maxWidth: 400 }}>
    //      A Roadway that is a component of the National Highway System
    //      (NHS)
    //    </div>
    //  ),
    queryVariable: "nhsFilters",
  },
  {
    id: "estimated",
    numeric: false,
    disablePadding: true,
    label: "Estimated",
    checked: true,
    category: "route",
    //  tooltip: (
    //    <div style={{ fontSize: 12, maxWidth: 400 }}>
    //      A Roadway that is a component of the National Highway System
    //      (NHS)
    //    </div>
    //  ),
    queryVariable: "estimatedFilters",
  },
  {
    id: "ownership",
    numeric: false,
    disablePadding: true,
    label: "Ownership",
    checked: true,
    category: "route",
    // tooltip: <div style={{ fontSize: 12, maxWidth: 400 }}>The entity that has legal ownership of a roadway</div>,
    queryVariable: "ownershipFilters",
  },
  {
    id: "urban_code",
    numeric: false,
    disablePadding: true,
    label: "Urban Code",
    checked: true,
    category: "route",
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        The U.S. Census Urban Area Code
      </div>
    ),
    queryVariable: "urbanCodeFilters",
  },
  {
    id: "nhs",
    numeric: false,
    disablePadding: true,
    label: "NHS",
    checked: true,
    category: "route",
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        A Roadway that is a component of the National Highway System (NHS)
      </div>
    ),
    queryVariable: "nhsFilters",
  },
  {
    id: "strahnet_t",
    numeric: false,
    disablePadding: true,
    label: "STRAHNET",
    checked: true,
    category: "route",
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Roadway section that is a component of the Strategic Highway Network
        (STRAHNET)
      </div>
    ),
    queryVariable: "strahnetFilters",
  },
  {
    id: "truck",
    numeric: false,
    disablePadding: true,
    label: "NN",
    checked: true,
    category: "route",
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Roadway section that is a component of the National Truck Network (NN)
        as defined by 23 CFR 658
      </div>
    ),
    queryVariable: "truckNetworkFilters",
  },
  {
    id: "total",
    numeric: false,
    disablePadding: true,
    label: "Total",
    checked: true,
    category: "route",
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>Total of each column</div>
    ),
  },
  {
    id: "county_cod",
    numeric: false,
    disablePadding: true,
    label: "County",
    checked: true,
    category: "route",
    tooltip: <div style={{ fontSize: 12, maxWidth: 400 }}>Counties</div>,
    queryVariable: "countyFilters",
  },
];

export const tx100_plot_vars = [
  { year: "2014", trank: "trank2014", arank: "arank2014" },
  { year: "2015", trank: "trank2015", arank: "arank2015" },
  { year: "2016", trank: "trank2016", arank: "arank2016" },
  { year: "2017", trank: "trank2017", arank: "arank2017" },
  { year: "2018", trank: "trank2018", arank: "arank2018" },
  { year: "2019", trank: "trank2019", arank: "arank2019" },
  { year: "2020", trank: "trank2020", arank: "arank2020" },
  { year: "2021", trank: "trank2021", arank: "arank2021" },
  { year: "2022", trank: "trank2022", arank: "arank2022" },
  { year: "2023", trank: "trank2023", arank: "arank2023" },
];

export const databaseVariablesHPMS = [
  {
    //  variable: "aadt",
    variable: "adt_cur",
    condition: "normalized",
    normalizedBy: "ttivmt",
  },
  {
    variable: "aadt_combi",
    condition: "normalized",
    normalizedBy: "trkttivmt",
  },
  {
    variable: "aadt_sgl_u",
    condition: "normalized",
    normalizedBy: "trkttivmt",
  },
  {
    variable: "allfuel",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "allfuel1",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "allfuel2",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "allrefspe1",
    condition: "normalized",
    normalizedBy: "ttivmt1",
  },
  {
    variable: "allrefspe4",
    condition: "normalized",
    normalizedBy: "ttivmt2",
  },
  {
    variable: "allrefspee",
    condition: "normalized",
    normalizedBy: "ttivmt",
  },
  {
    variable: "allspeed",
    condition: "normalized",
    normalizedBy: "ttivmt",
  },
  {
    variable: "allspeed1",
    condition: "normalized",
    normalizedBy: "ttivmt1",
  },
  {
    variable: "allspeed2",
    condition: "normalized",
    normalizedBy: "ttivmt2",
  },
  {
    variable: "beg_point",
    condition: "none",
    normalizedBy: "",
  },
  {
    variable: "commcost",
    condition: "sum",
    normalizedBy: "trkttivmt",
  },
  {
    variable: "commcost1",
    condition: "sum",
    normalizedBy: "trkttivmt1",
  },
  {
    variable: "commcost2",
    condition: "sum",
    normalizedBy: "trkttivmt2",
  },
  {
    variable: "congcost",
    condition: "sum",
    normalizedBy: "ttivmt",
  },
  {
    variable: "congcost1",
    condition: "sum",
    normalizedBy: "ttivmt1",
  },
  {
    variable: "congcost2",
    condition: "sum",
    normalizedBy: "ttivmt2",
  },
  {
    variable: "delay",
    condition: "sum",
    normalizedBy: "ttivmt",
  },
  {
    variable: "delay1",
    condition: "sum",
    normalizedBy: "ttivmt1",
  },
  {
    variable: "delay2",
    condition: "sum",
    normalizedBy: "ttivmt2",
  },
  {
    variable: "direction1",
    condition: "none",
    normalizedBy: "",
  },
  {
    variable: "direction2",
    condition: "none",
    normalizedBy: "",
  },
  {
    variable: "end_point",
    condition: "none",
    normalizedBy: "",
  },
  {
    variable: "estimated",
    condition: "none",
    normalizedBy: "",
  },
  {
    variable: "ghg",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "ghg1",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "ghg2",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "ghgbase",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "ghgbase1",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "ghgbase2",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "ghgtrk",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "ghgtrk1",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "ghgtrk2",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "ghgtrkbas0",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "ghgtrkbas3",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "ghgtrkbase",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "id",
    condition: "none",
    normalizedBy: "",
  },
  {
    variable: "opdly",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "opdly1",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "opdly2",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "optrkdly",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "optrkdly1",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "optrkdly2",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "pkdly",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "pkdly1",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "pkdly2",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "pktrkdly",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "pktrkdly1",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "pktrkdly2",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "pti80",
    condition: "normalized",
    normalizedBy: "ttivmt",
  },
  {
    variable: "pti801",
    condition: "normalized",
    normalizedBy: "ttivmt1",
  },
  {
    variable: "pti802",
    condition: "normalized",
    normalizedBy: "ttivmt2",
  },
  {
    variable: "pti95",
    condition: "normalized",
    normalizedBy: "ttivmt",
  },
  {
    variable: "pti951",
    condition: "normalized",
    normalizedBy: "ttivmt1",
  },
  {
    variable: "pti952",
    condition: "normalized",
    normalizedBy: "ttivmt2",
  },
  {
    variable: "route_id",
    condition: "none",
    normalizedBy: "",
  },
  {
    variable: "state_code",
    condition: "none",
    normalizedBy: "",
  },
  {
    // variable: "trkaadt",
    variable: "tottrk",
    condition: "normalized",
    normalizedBy: "trkttivmt",
  },
  {
    variable: "trkdelay",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "trkdelay1",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "trkdelay2",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "trkfuel",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "trkfuel1",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "trkfuel2",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "trkpti80",
    condition: "normalized",
    normalizedBy: "trkttivmt",
  },
  {
    variable: "trkpti801",
    condition: "normalized",
    normalizedBy: "trkttivmt1",
  },
  {
    variable: "trkpti802",
    condition: "normalized",
    normalizedBy: "trkttivmt2",
  },
  {
    variable: "trkpti95",
    condition: "normalized",
    normalizedBy: "trkttivmt",
  },
  {
    variable: "trkpti951",
    condition: "normalized",
    normalizedBy: "trkttivmt1",
  },
  {
    variable: "trkpti952",
    condition: "normalized",
    normalizedBy: "trkttivmt2",
  },
  {
    variable: "trkrefspee",
    condition: "normalized",
    normalizedBy: "trkttivmt",
  },
  {
    variable: "trkrefspe2",
    condition: "normalized",
    normalizedBy: "trkttivmt1",
  },
  {
    variable: "trkrefspe5",
    condition: "normalized",
    normalizedBy: "trkttivmt2",
  },
  {
    variable: "trkspeed",
    condition: "normalized",
    normalizedBy: "trkttivmt",
  },
  {
    variable: "trkspeed1",
    condition: "normalized",
    normalizedBy: "trkttivmt1",
  },
  {
    variable: "trkspeed2",
    condition: "normalized",
    normalizedBy: "trkttivmt2",
  },
  {
    variable: "trktti",
    condition: "normalized",
    normalizedBy: "trkttivmt",
  },
  {
    variable: "trktti1",
    condition: "normalized",
    normalizedBy: "trkttivmt`",
  },
  {
    variable: "trktti2",
    condition: "normalized",
    normalizedBy: "trkttivmt2",
  },
  {
    variable: "trkttivmt",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "trkttivmt1",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "trkttivmt2",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "truck",
    condition: "none",
    normalizedBy: "",
  },
  {
    variable: "tti",
    condition: "normalized",
    normalizedBy: "ttivmt",
  },
  {
    variable: "tti1",
    condition: "normalized",
    normalizedBy: "ttivmt1",
  },
  {
    variable: "tti2",
    condition: "normalized",
    normalizedBy: "ttivmt2",
  },
  {
    variable: "ttivmt",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "ttivmt1",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "ttivmt2",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "volume_gro",
    condition: "none",
    normalizedBy: "",
  },
  {
    variable: "wedly",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "wedly1",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "wedly2",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "wetrkdly",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "wetrkdly1",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "wetrkdly2",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "year_recor",
    condition: "none",
    normalizedBy: "",
  },
  //variable: "vmt",
  {
    variable: "vmt",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "trkvmt",
    condition: "sum",
    normalizedBy: "",
  },
];
