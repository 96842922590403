import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import FileUpload from "../components/FileUpload";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Link from "@material-ui/core/Link";
import Icon from "@material-ui/core/Icon";

const CustomDialog = (props) => {
  const {
    openCustom,
    setOpenCustom,
    statusGuide,
    setStatusGuide,
    setRoadGeomFeatures,
    setMapType,
    setStatsLayer,
    setGeoRoute,
    setSegmentsTable,
    setSegmentSelection,
    file,
    setFile,
    setYear,
  } = props;

  const handleCloseCustom = () => {
    setOpenCustom(false);
  };

  return (
    <Dialog
      open={openCustom}
      onClose={handleCloseCustom}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>Add Custom Layers:</DialogTitle>
      <DialogContent>
        <Link
          key="Draw Polygon"
          style={{textDecoration:'none', color:"inherit"}}
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setStatusGuide(["Custom", "Polygon"]);
            setMapType("deckGL-lines");
            setStatsLayer("statewide");
            setSegmentsTable("roads");
            setSegmentSelection("all");
            setGeoRoute("");
            setRoadGeomFeatures({
              features: [],
              numFeatures: 0,
              type: "rhino",
              minValue: 0,
              maxValue: 1000000,
            });
          }}
        >
          <ListItem button onClick={handleCloseCustom}>
            <ListItemIcon>
              <Icon>{"emoji_transportation"}</Icon>
            </ListItemIcon>
            <ListItemText
              primary="Draw Polygon"
              secondary={
                <span>
                  Start by clicking on the map to select custom road segements.
                  <br />
                  Select polygon icon from the map if not selected
                </span>
              }
            />
          </ListItem>
        </Link>
        <ListItem button>
          <ListItemIcon>
            <Icon>{"emoji_transportation"}</Icon>
          </ListItemIcon>
          <ListItemText
            primary="Upload CSV"
            secondary={<span>
              Download sample file here
              <br/>
              <a
              href={process.env.PUBLIC_URL + "/sample.csv"}
              download="tcat_sample_upload.csv" // The name of the file when downloaded
            >
              tcat_sample_upload.csv
            </a>
            </span>}
          />
        </ListItem>
        <FileUpload
          setStatusGuide={setStatusGuide}
          setMapType={setMapType}
          setStatsLayer={setStatsLayer}
          setGeoRoute={setGeoRoute}
          setSegmentsTable={setSegmentsTable}
          setSegmentSelection={setSegmentSelection}
          setFile={setFile}
          setYear={setYear}
          handleCloseCustom={handleCloseCustom}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseCustom} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialog;
