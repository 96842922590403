import rgbToHex from '../utils/rgbtohex'
import { scaleLinear } from "d3-scale";
import { interpolateTurbo } from "d3-scale-chromatic";
import { color as colorF } from "d3-color";

export default function colorScale(height, minValue, maxValue) {
    //const color = d3.color(d3.interpolateRdYlGn(linearScale(height)));
    //const color = d3.color(d3.interpolatePuRd(linearScale(height)));
    //const color = d3.color(d3.interpolateOrRd(linearScale(height)));

    const linearScale = scaleLinear()
        //.domain([minTempTPTI, maxTempTPTI])
        .domain([minValue, maxValue])
        .range([0, 1]);

    const color = colorF(interpolateTurbo(linearScale(height)));

    if (color) return rgbToHex(color.r, color.g, color.b);
}
