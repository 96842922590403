import { API_ROOT_COMMON, API_ROOT, COLOR_RHINO } from "../common/constants";
import axios from "axios";

export const fetchCountyBoundaryData = async (countyList) => {
  try {
    // const dataUrl = `${API_ROOT}/boundaries/findByCounty?counties=${countyList.join(
    //   ","
    // )}`;
    // console.log("dataUrl-check", dataUrl);
    const dataUrl =
      "https://services.arcgis.com/KTcxiTD9dsQw4r7Z/arcgis/rest/services/Texas_County_Boundaries_Detailed/FeatureServer/0/query?outFields=*&where=1%3D1&f=geojson";
    const response = await axios.get(dataUrl);
    if (response.status === 200) {
      // response - object, eg { status: 200, message: 'OK' }
      // console.log("success stuff", response.data);
      return response;
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const fetchDistrictBoundariesData = async () => {
  try {
    const dataUrl =
      "https://services.arcgis.com/KTcxiTD9dsQw4r7Z/arcgis/rest/services/TxDOT_Districts/FeatureServer/0/query?where=1%3D1&outFields=*&outSR=4326&f=geojson";
    // console.log("dataUrl-check", dataUrl);
    const response = await axios.get(dataUrl);
    if (response.status === 200) {
      // response - object, eg { status: 200, message: 'OK' }
      // console.log("success stuff", response.data);
      return response;
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const fetchSandMinesData = async () => {
  try {
    const dataUrl = `${API_ROOT_COMMON}` + "/poi/getOdessaDistSandMines";
    // console.log("dataUrl-check", dataUrl);
    const response = await axios.get(dataUrl);
    if (response.status === 200) {
      // response - object, eg { status: 200, message: 'OK' }
      // console.log("success stuff", response.data);
      return response;
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const fetchDisposalWellsData = async () => {
  try {
    const dataUrl = `${API_ROOT_COMMON}` + "/poi/getOilDisposalWells";
    // console.log("dataUrl-check", dataUrl);
    const response = await axios.get(dataUrl);
    if (response.status === 200) {
      // response - object, eg { status: 200, message: 'OK' }
      // console.log("success stuff", response.data);
      return response;
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const fetchCommercialBorderCrossingData = async () => {
  try {
    const dataUrl =
      "https://services.arcgis.com/KTcxiTD9dsQw4r7Z/arcgis/rest/services/TxDOT_Border_Crossings/FeatureServer/0/query?where=VEH_COML%20=%201&outFields=*&outSR=4326&f=geojson";
    // console.log("dataUrl-check", dataUrl);
    const response = await axios.get(dataUrl);
    if (response.status === 200) {
      // response - object, eg { status: 200, message: 'OK' }
      // console.log("success stuff", response.data);
      return response;
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const fetchNonCommercialBorderCrossingData = async () => {
  try {
    const dataUrl =
      "https://services.arcgis.com/KTcxiTD9dsQw4r7Z/arcgis/rest/services/TxDOT_Border_Crossings/FeatureServer/0/query?where=VEH_COML%20%3C%3E%201%20AND%20VEH_CAR%20=%201&outFields=*&outSR=4326&f=geojson";
    // console.log("dataUrl-check", dataUrl);
    const response = await axios.get(dataUrl);
    if (response.status === 200) {
      // response - object, eg { status: 200, message: 'OK' }
      // console.log("success stuff", response.data);
      return response;
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const fetchRailBorderCrossingData = async () => {
  try {
    const dataUrl =
      "https://services.arcgis.com/KTcxiTD9dsQw4r7Z/arcgis/rest/services/TxDOT_Border_Crossings/FeatureServer/0/query?where=VEH_COML%20%3C%3E%201%20AND%20VEH_CAR%20%3C%3E%201%20AND%20RR_BRDGE%20=%201&outFields=*&outSR=4326&f=geojson";
    // console.log("dataUrl-check", dataUrl);
    const response = await axios.get(dataUrl);
    if (response.status === 200) {
      // response - object, eg { status: 200, message: 'OK' }
      // console.log("success stuff", response.data);
      return response;
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const fetchTruckParkingData = async (countyList) => {
  try {
    const dataUrl = `${API_ROOT_COMMON}/poi/getTruckParkingLocations?counties=${countyList.join(
      ","
    )}`;
    // console.log("dataUrl-check", dataUrl);
    const response = await axios.get(dataUrl);
    if (response.status === 200) {
      // response - object, eg { status: 200, message: 'OK' }
      // console.log("success stuff", response.data);
      return response;
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const fetchPortsData = async () => {
  try {
    const dataUrl =
      "https://opendata.arcgis.com/datasets/595556c111e14519be70b955e10aa8ff_0.geojson";
    const response = await axios.get(dataUrl);
    if (response.status === 200) {
      // response - object, eg { status: 200, message: 'OK' }
      //console.log("success stuff", response.data);
      return response;
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const fetchCargoAirportsData = async () => {
  try {
    const dataUrl =
      "https://geo.dot.gov/mapping/rest/services/NTAD/Airports/MapServer/0/query?where=State_Post_Office_Code+%3D+%27TX%27+AND+Other_Services+LIKE+%27%25CARGO%25%27&text=&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&distance=&units=esriSRUnit_Foot&relationParam=&outFields=*&returnGeometry=true&returnTrueCurves=false&maxAllowableOffset=&geometryPrecision=&outSR=&havingClause=&returnIdsOnly=false&returnCountOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&gdbVersion=&historicMoment=&returnDistinctValues=false&resultOffset=&resultRecordCount=&returnExtentOnly=false&datumTransformation=&parameterValues=&rangeValues=&quantizationParameters=&featureEncoding=esriDefault&f=geojson";
    const response = await axios.get(dataUrl);
    if (response.status === 200) {
      // response - object, eg { status: 200, message: 'OK' }
      //console.log("success stuff", response.data);
      return response;
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const fetchAirportsData = async () => {
  try {
    const dataUrl =
      "https://geo.dot.gov/mapping/rest/services/NTAD/Airports/MapServer/0/query?where=State_Post_Office_Code+%3D+%27TX%27+AND+Commercial_Ops+IS+NOT+NULL+AND+Other_Services+NOT+LIKE+%27%25CARGO%25%27&text=&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&distance=&units=esriSRUnit_Foot&relationParam=&outFields=*&returnGeometry=true&returnTrueCurves=false&maxAllowableOffset=&geometryPrecision=&outSR=&havingClause=&returnIdsOnly=false&returnCountOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&gdbVersion=&historicMoment=&returnDistinctValues=false&resultOffset=&resultRecordCount=&returnExtentOnly=false&datumTransformation=&parameterValues=&rangeValues=&quantizationParameters=&featureEncoding=esriDefault&f=geojson";
    const response = await axios.get(dataUrl);
    if (response.status === 200) {
      // response - object, eg { status: 200, message: 'OK' }
      //console.log("success stuff", response.data);
      return response;
      return true;
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const fetchUtpData = async (countyList) => {
  try {
    const dataUrl =
      "https://services.arcgis.com/KTcxiTD9dsQw4r7Z/arcgis/rest/services/TxDOT_Projects/FeatureServer/0/query?where=COUNTY_NUMBER IN (" +
      countyList.toString() +
      //") AND Shape__Length IS NOT NULL &outFields=*&outSR=4326&f=geojson";
      ") AND Shape__Length IS NOT NULL AND PRJ_UTP IS NOT NULL &outFields=*&outSR=4326&f=geojson";
    // console.log("dataUrl-check", dataUrl);
    const response = await axios.get(dataUrl);
    if (response.status === 200) {
      // response - object, eg { status: 200, message: 'OK' }
      // console.log("success stuff", response.data);
      return response;
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const fetchRoadData = async (props) => {
  const { type, route, year, file } = props;
  try {
    if(type.toLowerCase() === "custom" && file){
      const formData = new FormData();
      formData.append('file', file);
      formData.append('year', year);
      const dataUrl = `${API_ROOT}/upload_csv`
      const response = await axios.post(dataUrl, formData,{
        headers:{
          'Content-Type':'multipart/form-data'
        }
      })
      if(response.status === 200){
        if(response.data.skipped_rows){
          console.log(`Missing data in the file uploaded at lines:${response.data.skipped_rows}`);
        }
        return response.data.results;
      }
    } else if (type.toLowerCase() === "tx100") {
      const response = await axios.get(
        `${API_ROOT}` +
          route +
          (route.includes("?") ? "&" : "?") +
          "year=" +
          year
      );

      if (response.status === 200) {
        // response - object, eg { status: 200, message: 'OK' }
        // console.log("success stuff", response.data);
        return response.data;
      }
    } else if(route !== '/upload_csv') {
      const response = await axios.get(`${API_ROOT}` + route + "&year=" + year);
      if (response.status === 200) {
        // response - object, eg { status: 200, message: 'OK' }
        // console.log("success stuff", response.data);
        return response.data;
      }
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const fetchMonitoredRoads = async (countyList) => {
  try {
    const dataUrl = `${API_ROOT}` + "/roads/getTcatMonitoredRoads";

    const response = await axios.get(dataUrl);
    if (response.status === 200) {
      // response - object, eg { status: 200, message: 'OK' }
      // console.log("success stuff", response.data);
      // console.log("response-check", response);
      return response;
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const fetchRoadTrendsSummary = async (years, roads) => {
  try {
    let yearroad = [];
    for (var j = 0; j < years.length; j++) {
      yearroad.push([years[j], roads]);
    }
    let promises = yearroad.map((r) => {
      return axios.get(
        `${API_ROOT}/metrics/findByRouteIdYear?year=${r[0]}&route_id=${r[1].ria_rte_id}&min_dfo=${r[1].frm_dfo}&max_dfo=${r[1].to_dfo}`
      );
    });
    return promises;
  } catch (e) {
    console.log(e);
  }
};

export const fetchODByTrank = async (year, trank) => {
  try {
    const dataUrl = `${API_ROOT}/roads_tx100/fetchODByTrank?year=${year}&trank=${trank}`;
    // console.log("dataUrl-check", dataUrl);
    const response = await axios.get(dataUrl);
    if (response.status === 200) {
      // response - object, eg { status: 200, message: 'OK' }
      // console.log("success stuff", response.data);
      return response;
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const fetchRoadsByCustomPolygon = async (polygonCoords, year) => {
     const dataUrl = `${API_ROOT}/customRoadsSelectionByPolygon`;
    // console.log("dataUrl-check", dataUrl);
    try{
    const data = {
      coordinates: polygonCoords,
      year: year
    };
    const response = await axios.post(dataUrl, data, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
    if (response.status === 200) {
      // response - object, eg { status: 200, message: 'OK' }
      // console.log("success stuff", response.data);
      return response.data;
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};