import React, { useState } from "react";
import { Editor, DrawPolygonMode } from "react-map-gl-draw";
import filterFeatureByPolygon from "../utils/filterFeatureByPolygon";
import { fetchRoadsByCustomPolygon } from "../data-loaders/DataLoaders";
import Alert from "@mui/material/Alert";

const turf = require("@turf/turf");
const max_area = 148435985184.1993;

const DrawPolygonDeckGL = (props) => {
  const [mode] = useState(new DrawPolygonMode());
  const [showWarning, setShowWarning] = useState(false);
  const isFirstCustom =
    props.statusGuide[1] === "Polygon" &&
    props.roadGeomFeatures.numFeatures === 0;
  const [isDrawing, setIsDrawing] = useState(isFirstCustom);
  const [polygonVertices, setPolygonVertices] = useState([]);

  const svgPolygonStyle = {
    position: "absolute",
    top: "48%",
    left: "10px",
    transform: "translateY(-50%)",
    borderRadius: "4px",
    border: "2px solid rgba(0,0,0,.2)",
    width: "34px",
    height: "34px",
    padding: "3px",
    color: "rgb(0 0 0 / 75%)",
    backgroundClip: "padding-box",
    backgroundColor: "#fff",
  };

  const onSelect = (event) => {
    if (event && event.mapCoords) {
      setPolygonVertices([...polygonVertices, event.mapCoords]);
      if (
        event.selectedFeature &&
        event.selectedFeature.properties.guideType !== "tentative"
      ) {
        //changing it to api data coords and closing the polygon with same end coord
        const latLng2DArray = polygonVertices.map((coord) => [
          coord[0],
          coord[1],
        ]);
        latLng2DArray.push([...latLng2DArray[0]]); // Duplicate the first point to close the polygon
        if (props.statusGuide[1] !== "Polygon") {
          props.setIsLoading(true);

          filterFeatureByPolygon(latLng2DArray, props)
            .then((filteredFeatures) => {
              props.onFilteredGeoJSON({
                type: "FeatureCollection",
                features: filteredFeatures,
              });
              setPolygonVertices([]);
              setIsDrawing(false);
            })
            .catch((error) => {
              console.error(error);
            })
            .finally(() => {
              props.setIsLoading(false);
            });
        } else {
          var geojson = {
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [latLng2DArray],
            },
          };

          // Calculate the area using Turf.js
          var area = turf.area(geojson);
          if (area <= max_area) {
            props.setIsLoading(true);

            fetchRoadsByCustomPolygon(latLng2DArray, props.year)
              .then((customRoads) => {
                props.onFilteredGeoJSON({
                  type: "FeatureCollection",
                  features: customRoads,
                }); //for sql query API as it returns features only
                setPolygonVertices([]);
                setIsDrawing(true);
              })
              .catch((error) => {
                console.error(error);
              })
              .finally(() => {
                props.setIsLoading(false);
              });
          } else {
            setPolygonVertices([]);
            setIsDrawing(false);
            setShowWarning(true);
            setIsDrawing(true);
          }
        }
      }
    }
  };

  const startDrawing = () => {
    setIsDrawing(!isDrawing);
  };

  return (
    <>
      <img
        onClick={startDrawing}
        style={svgPolygonStyle}
        alt="Polygon"
        title="Draw a polygon"
        src={process.env.PUBLIC_URL + "/polygon.svg"}
      />
      {isDrawing && <Editor mode={mode} onSelect={onSelect}></Editor>}
      {showWarning && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1000,
          }}
        >
          <Alert
            severity="info"
            color="warning"
            onClose={() => setShowWarning(false)}
          >
            Select a smaller area.
          </Alert>
        </div>
      )}
    </>
  );
};

export default DrawPolygonDeckGL;
