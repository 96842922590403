import React, { useState, useEffect, useContext } from "react";
import { RhinoQueryContext } from "../map/RhinoQueryContext";
import { DICTIONARY_COLUMNS } from "../common/dictionary";
import PivotTableUI from "react-pivottable/PivotTableUI";
import "react-pivottable/pivottable.css";
//import TableRenderers from "react-pivottable/TableRenderers";
// import Plot from "plotly.js-basic-dist";
// import createPlotlyRenderers from "react-pivottable/PlotlyRenderers";
import { aggregators } from "react-pivottable/Utilities";
import {
  RURAL_URBAN_CODE,
  MPO_LIST,
  FUNCTION_CLASS2014,
  COUNTY_LIST,
  TXDOTdist_VARIABLES,
} from "../common/constants";

import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(5),
  },
  note: {
    textAlign: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const CustomSummary = (props) => {
  const classes = useStyles();
  //const PlotlyRenderers = createPlotlyRenderers(Plot);
  const [roads, setRoads] = useState();
  const query = useContext(RhinoQueryContext);
  const dict = DICTIONARY_COLUMNS;
  const TX100cols = [
    "delay",
    "dly",
    "pti",
    "tti",
    "tci",
    "vmt",
    "leng",
    "tdly",
    "tpti",
    "ttci",
    "congcost",
    "cost",
    "acost",
    "dmile",
    "tcost",
    "tdmile",
  ];
  const statewidecols = [
    "year",
    "trkdelay",
    "trktti",
    "trkpti80",
    "trkpti95",
    "ghg",
    "tti",
    "vmt",
    "delay",
    "dly",
    "pti80",
    "pti95",
    "tottrk",
    "trkvmt",
    "ttivmt",
    "adt_cur",
    "ghgbase",
    "len_sec",
    "pct_trk",
    "congcost",
    "cost",
    "acost",
  ];
  const customaggregators = ["hsys", "di", "co", "f_system", "mpa", "ru"];
  const filteredFuncs = [
    "Sum",
    "Average",
    "Count",
    "First",
    "Last",
    "Integer Sum",
    "Maximum",
    "Minimum",
    "Median",
  ];
  const updateRoads = async () => {
    let neededcols = customaggregators.concat(
      props.layerID === "statewide" ? statewidecols : TX100cols
    );
    let labeldict = {
      co: COUNTY_LIST.reduce((acc, x) => ({ ...acc, [x.id]: x.name }), {}),
      di: TXDOTdist_VARIABLES.reduce(
        (acc, x) => ({ ...acc, [x.mapped]: x.definition }),
        {}
      ),
      f_system: FUNCTION_CLASS2014.reduce(
        (acc, x) => ({ ...acc, [x.id]: x.label }),
        {}
      ),
      mpa: MPO_LIST.reduce((acc, x) => ({ ...acc, [x.id]: x.name }), {}),
      ru: RURAL_URBAN_CODE.reduce(
        (acc, x) => ({ ...acc, [x.id]: x.label }),
        {}
      ),
    };
    ////////////////////// PRE-PROCESSING
    let f = query[0].features;
    let roadsdata = f.map((ir) => {
      let row = {};
      for (let key in ir) {
        if (neededcols.includes(key)) {
          row[
            dict.find((x) => x.name === key) === undefined
              ? key
              : dict.find((x) => x.name === key).label
          ] = customaggregators.slice(1).includes(key)
            ? ir[key] in labeldict[key]
              ? labeldict[key][ir[key]]
              : "Unknown"
            : ir[key];
        }
      }
      return row;
    });
    //////////////////////
    setRoads(roadsdata);
  };
  useEffect(() => {
    updateRoads();
  }, [query]);

  return (
    <div style={{ overflowX: "scroll" }}>
      <Paper className={classes.paper + " table-null"}>
        <Typography variant="subtitle1">
          <strong>Note:</strong> 2021 reporting year (2020 data) was impacted by
          the COVID-19 pandemic and is not reflective of a typical year.
        </Typography>
      </Paper>
      <Typography variant="h5" className={classes.note}>
        Drag variables from above the table to the side or top of the table.
      </Typography>
      {/* <h4 style={{ padding: "10px" }}>
        Drag variables from above the table to the side or top of the table.
      </h4> */}
      {roads && (
        <PivotTableUI
          data={roads}
          // aggregatorName={'Sum over Sum'}
          aggregators={Object.keys(aggregators)
            .filter((key) => filteredFuncs.includes(key))
            .reduce((obj, key) => {
              return { ...obj, [key]: aggregators[key] };
            }, {})}
          hiddenFromDragDrop={(props.layerID === "statewide"
            ? statewidecols
            : TX100cols
          ).map((y) =>
            dict.find((x) => x.name === y) === undefined
              ? y
              : dict.find((x) => x.name === y).label
          )}
          hiddenFromAggregators={customaggregators.map((y) =>
            dict.find((x) => x.name === y) === undefined
              ? y
              : dict.find((x) => x.name === y).label
          )}
          onChange={(s) => setRoads(s)}
          // renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
          {...roads}
        />
      )}
    </div>
  );
};

export default CustomSummary;
